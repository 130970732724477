import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-exhibition-type',
  template: `
    <span class="type"><i class="icon-exhibition"></i> {{'label.exhibition.title' | translate}}</span>
  `
})
export class ExhibitionTypeComponent {

  constructor() { }
}
