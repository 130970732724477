import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { FileTypeInterface } from '../../interfaces/file-type.interface';
import { BaseFilesModel } from '../../models/base-files.model';
import { File } from '../../models/file.model';
import { GameAssetDictionaryEntry } from '../../models/game-asset-dictionary-entry';
import { Status } from '../../models/status.model';
import { FileService } from '../../services/file.service';

@Component({
  selector: 'app-game-asset-dictionary',
  templateUrl: './game-asset-list.component.html',
  styleUrls: [ './game-asset-list.component.css' ]
})
export class GameAssetListComponent implements OnInit {

  filteredEntries: GameAssetDictionaryEntry[];

  status = new Status();

  @Input() set entries(entries: GameAssetDictionaryEntry[]) {
    this.filteredEntries = entries.filter(param => !param.invalid);
  }

  @Input() form: FormGroup;
  @Input() fileTypes: FileTypeInterface[] = [];

  @Output() readonly changed: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private fileService: FileService
  ) {
  }

  ngOnInit() {
  }

  convertToFileTypeInterface(entry: GameAssetDictionaryEntry): FileTypeInterface {
    const { key, type } = entry;

    return {
      attribute: { label: key, value: key },
      hasLanguage: true,
      type
    };
  }

  addFiles(
    entry: GameAssetDictionaryEntry,
    payload: {
      files: { files: any },
      fileType: number,
      attribute: string
    }
  ): void {
    if (!this.fileService || !(entry instanceof BaseFilesModel)) {
      return;
    }

    this.fileService.create(payload.files.files, payload.fileType, payload.attribute, this.status, entry.type)
      .pipe(
        map((newFiles: File[]) => {

          if (newFiles) {
            newFiles.map(file => entry.addFile(file));
          }

          return newFiles;
        })
      )
      .subscribe(() => this.changed.emit());
  }
}
