import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../../core/services/auth.service';

import { BaseComponent } from '../../shared/components/base/base.component';
import { User } from '../../shared/models/user.model';
import { Status } from '../../shared/models/status.model';

import { AppConfig } from '../../app.config';
import { ConfigurationService } from "../../shared/services/configuration.service";
import { UserService } from "../../core/services/user.service";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent extends BaseComponent implements OnInit, OnDestroy {
  user: User;
  status = new Status();
  loginForm = new FormGroup({
    rememberMe: new FormControl(true)
  });
  siteKey: string;

  constructor(
    public authService: AuthService,
    private router: Router,
    private config: ConfigurationService,
    private userService: UserService
  ) {
    super();
    document.body.style.backgroundColor = AppConfig.APP_BG_LIGHT;
    this.siteKey = config.getGreCaptchaConfig().key;
    this.user = userService.emptyModel();
  }

  resolved(captchaResponse: string) {
    this.status.reset();
    if (captchaResponse) {
      this.authService.validateCaptcha(captchaResponse)
        .then(() => {
        }, error => this.loginForm.controls.recaptcha.reset());
    }
  }

  login() {
    this.authService.login(
      this.user,
      this.loginForm.controls.rememberMe.value,
      this.status
    ).pipe(
      takeUntil(this.destroy$))
      .subscribe(() => {
          if (this.authService.isLoggedIn()) {
            // this.userService.getCurrentUser(true)
            //   .then(user => {
            //     console.log(`Logged in user: [${JSON.stringify(user)}]`);
                this.config.initConfig(this.authService.getAccessToken().tenantId).subscribe(() => {
                    // Get the redirect URL from our auth service
                    // If no redirect has been set, use the default
                    const redirect = this.authService.redirectUrl
                    && this.authService.redirectUrl !== '/logout'
                      ? this.authService.redirectUrl : '/';

                    // Redirect the user
                    this.router.navigate([redirect]);
                  },
                  error => {
                    console.log('ERROR:', error);
                    this.router.navigate(['/logout'])
                  }
                )
              // });
          }
        },
        error => {
          if (error && error.error && error.error.presentCaptcha && !this.loginForm.controls.recaptcha) {
            this.loginForm.addControl('recaptcha', new FormControl(null, Validators.required));

          } else if (this.loginForm.controls.recaptcha) {
            this.loginForm.controls.recaptcha.reset();
          }
        }
      );
  }

  ngOnInit() {
    this.authService.clear();
    this.router.navigate([this.authService.redirectUrl]);
  }

  ngOnDestroy() {
    document.body.removeAttribute('style');
    super.ngOnDestroy();
  }
}
