import { Component } from '@angular/core';


@Component({
  selector: 'app-map-type',
  template: `
    <span class="type"><i class="icon-map"></i> Map</span>
  `
})
export class MapTypeComponent {
  constructor() { }
}
