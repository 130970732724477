import { Component, Input } from '@angular/core';

import { BtnBaseComponent } from '../../../../../app/shared/components/btns/btn-base/btn-base.component';


@Component({
  selector: 'app-btn-add',
  template: `
    <button type="button" class="btn btn-{{theme}}" [disabled]="disabled"
      appBtnSpinner [appBtnSpinnerLoading]="status && status.loading"
      (click)="onButtonClick($event)">
      <i class="fa fa-plus"></i> Add {{type | translate }}
    </button>
  `
})
export class BtnAddComponent extends BtnBaseComponent {

  @Input() type: string;

  constructor() {
    super();
  }
}
