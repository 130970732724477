import { Component, Input } from '@angular/core';

import { UserType } from '../../../../app/shared/enums/user-type.enum';


@Component({
  selector: 'app-user-type',
  template: `
    <span class="type" [ngSwitch]="UserType[userType]" *ngIf="UserType[userType]">
      <i class="icon-user"></i>
      {{UserType[userType] | titlecase}}</span>
  `
})
export class UserTypeComponent {
  UserType = UserType;

  @Input() userType: UserType;

  constructor() { }
}
