import { ModelHelper } from "./model.helper.interface";
import { Tour } from "../tour.model";
import Utils from "../../utils/utils";
import { TourTypeUtils } from "../../utils/tour-type-utils";
import { FormControlSelect } from "../form-control/form-control-select.model";
import { FormControlType } from "../../enums/form-control-type.enum";
import { FormControlNumber } from "../form-control/form-control-number.model";
import { FormControlBoolean } from "../form-control/form-control-boolean.model";
import { FormControlString } from "../form-control/form-control-string.model";
import * as moment from "moment";
import { VersionModel } from "../base.model";
import { FileType } from "../../enums/file-type.enum";
import { StationModelHelper } from "./station-model.helper";
import { ChannelModelHelper } from "./channel-model.helper";
import { FileModelHelper } from "./file-model.helper";
import { MultiLangBooleanHelper } from "./multi-lang-boolean.helper";
import { MultiLangStringHelper } from "./multi-lang-string.helper";
import { Channel } from "../channel.model";

export class TourModelHelper implements ModelHelper<Tour> {

  constructor(
    private tourTypeUtils: TourTypeUtils,
    private stationHelper: StationModelHelper,
    private channelHelper: ChannelModelHelper,
    private fileHelper: FileModelHelper,
    private multiLangBooleanHelper: MultiLangBooleanHelper,
    private multiLangStringHelper: MultiLangStringHelper
  ) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id) && this.tourTypeUtils.isValid(json.type);
  }

  emptyModel(defaultChannels: Channel[]): Tour {
    const tour = new Tour(
      '',
      this.multiLangBooleanHelper.fromJSON(
        {},
        'active',
        {label: 'label.general.active', hidden: false}
      ),
      new FormControlSelect({
        value: this.tourTypeUtils.getDefault(),
        key: 'type',
        label: 'label.general.type',
        options: this.tourTypeUtils.getSelectOptions(),
        required: true,
        hidden: false
      }),
      this.multiLangStringHelper.fromJSON(
        {},
        'title',
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'title', {
          label: 'label.tour.field.title'
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'subtitle',
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'subtitle', {
          label: 'label.tour.field.subtitle'
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'description',
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'description', {
          label: 'label.tour.field.description',
          formControlType: FormControlType.TEXTAREA,
          rows: 3
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'shortText',
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'shortText', {
          label: 'label.tour.field.short-text',
          formControlType: FormControlType.TEXTAREA,
          rows: 3
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'longText',
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'longText', {
          label: 'label.tour.field.long-text',
          formControlType: FormControlType.TEXTAREA,
          rows: 10
        })
      ),
      new FormControlNumber(
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'duration', {
          key: 'duration',
          label: 'label.tour.field.duration'
        })
      ),
      new FormControlBoolean(
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'flaggen', {
          key: 'flaggen',
          label: 'label.tour.field.flaggen'
        })
      ),
      new FormControlBoolean(
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'flaggen2', {
          key: 'flaggen2',
          label: 'label.tour.field.flaggen2'
        })
      ),
      new FormControlBoolean(
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'flaggen3', {
          key: 'flaggen3',
          label: 'label.tour.field.flaggen3'
        })
      ),
      new FormControlBoolean(
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'flaggen4', {
          key: 'flaggen4',
          label: 'label.tour.field.flaggen4'
        })
      ),
      new FormControlString(
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'strgen', {
          key: 'strgen',
          label: 'label.tour.field.strgen'
        })
      ),
      new FormControlString(
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'strgen2', {
          key: 'strgen2',
          label: 'label.tour.field.strgen2'
        })
      ),
      new FormControlString(
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'strgen3', {
          key: 'strgen3',
          label: 'label.tour.field.strgen3'
        })
      ),
      new FormControlString(
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'strgen4', {
          key: 'strgen4',
          label: 'label.tour.field.strgen4'
        })
      ),
      new FormControlNumber(
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'numgen', {
          key: 'numgen',
          label: 'label.tour.field.numgen'
        })
      ),
      new FormControlNumber(
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'numgen2', {
          key: 'numgen2',
          label: 'label.tour.field.numgen2'
        })
      ),
      new FormControlNumber(
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'numgen3', {
          key: 'numgen3',
          label: 'label.tour.field.numgen3'
        })
      ),
      new FormControlNumber(
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'numgen4', {
          key: 'numgen4',
          label: 'label.tour.field.numgen4'
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'mlstrgen',
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'mlstrgen', {
          label: 'label.tour.field.mlstrgen'
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'mlstrgen2',
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'mlstrgen2', {
          label: 'label.tour.field.mlstrgen2'
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'mlstrgen3',
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'mlstrgen3', {
          label: 'label.tour.field.mlstrgen3'
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'mlstrgen4',
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'mlstrgen4', {
          label: 'label.tour.field.mlstrgen4'
        })
      ),

      new FormControlSelect(
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'choice1', {
          label: 'label.station.field.choice1'
        })
      ),
      new FormControlSelect(
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'choice2', {
          label: 'label.station.field.choice2'
        })
      ),
      new FormControlSelect(
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'choice3', {
          label: 'label.station.field.choice3'
        })
      ),
      new FormControlSelect(
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'choice4', {
          label: 'label.station.field.choice4'
        })
      ),

      new FormControlNumber(
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'lat', {
          label: 'label.tour.field.latitude'
        })
      ),
      new FormControlNumber(
        this.tourTypeUtils.getFieldOptions(this.tourTypeUtils.getDefault(), 'lng', {
          label: 'label.tour.field.longitude'
        })
      )
    );

    tour.channels = defaultChannels;
    tour.channelRefs = defaultChannels ? defaultChannels.map(c => c.id) : [];

    return tour;
  }

  fromJSON(json: any, skipCheck = false): Tour {

    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    const tourType = Number(json.type);

    // create tour object
    const tour = new Tour(
      json._id,
      this.multiLangBooleanHelper.fromJSON(json.active, 'active', {label: 'label.general.active', hidden: false}),
      new FormControlSelect({
        value: tourType,
        key: 'type',
        label: 'label.general.type',
        options: this.tourTypeUtils.getSelectOptions(),
        required: true,
        hidden: false
      }),

      this.multiLangStringHelper.fromJSON(
        json.title,
        'title',
        this.tourTypeUtils.getFieldOptions(tourType, 'title', {
          label: 'label.tour.field.title'
        }),
        json.title ? Object.keys(json.title) : []
      ),

      this.multiLangStringHelper.fromJSON(
        json.subtitle,
        'subtitle',
        this.tourTypeUtils.getFieldOptions(tourType, 'subtitle', {
          label: 'label.tour.field.subtitle'
        }),
        json.title ? Object.keys(json.title) : []
      ),

      this.multiLangStringHelper.fromJSON(
        json.description,
        'description',
        this.tourTypeUtils.getFieldOptions(tourType, 'description', {
          label: 'label.tour.field.description',
          formControlType: FormControlType.TEXTAREA,
          rows: 3
        }),
        json.title ? Object.keys(json.title) : []
      ),

      this.multiLangStringHelper.fromJSON(
        json.shortText,
        'shortText',
        this.tourTypeUtils.getFieldOptions(tourType, 'shortText', {
          label: 'label.tour.field.short-text',
          formControlType: FormControlType.TEXTAREA,
          rows: 3
        }),
        json.title ? Object.keys(json.title) : []
      ),

      this.multiLangStringHelper.fromJSON(
        json.longText,
        'longText',
        this.tourTypeUtils.getFieldOptions(tourType, 'longText', {
          label: 'label.tour.field.long-text',
          formControlType: FormControlType.TEXTAREA,
          rows: 10
        }),
        json.title ? Object.keys(json.title) : []
      ),
      new FormControlNumber(
        this.tourTypeUtils.getFieldOptions(tourType, 'duration', {
          value: json.duration,
          key: 'duration',
          label: 'label.tour.field.duration'
        })
      ),

      new FormControlBoolean(
        this.tourTypeUtils.getFieldOptions(tourType, 'flaggen', {
          value: json.flaggen,
          key: 'flaggen',
          label: 'label.tour.field.flaggen'
        })
      ),
      new FormControlBoolean(
        this.tourTypeUtils.getFieldOptions(tourType, 'flaggen2', {
          value: json.flaggen2,
          key: 'flaggen2',
          label: 'label.tour.field.flaggen2'
        })
      ),
      new FormControlBoolean(
        this.tourTypeUtils.getFieldOptions(tourType, 'flaggen3', {
          value: json.flaggen3,
          key: 'flaggen3',
          label: 'label.tour.field.flaggen3'
        })
      ),
      new FormControlBoolean(
        this.tourTypeUtils.getFieldOptions(tourType, 'flaggen4', {
          value: json.flaggen4,
          key: 'flaggen4',
          label: 'label.tour.field.flaggen4'
        })
      ),
      new FormControlString(
        this.tourTypeUtils.getFieldOptions(tourType, 'strgen', {
          value: json.strgen,
          key: 'strgen',
          label: 'label.tour.field.strgen'
        })
      ),
      new FormControlString(
        this.tourTypeUtils.getFieldOptions(tourType, 'strgen2', {
          value: json.strgen2,
          key: 'strgen2',
          label: 'label.tour.field.strgen2'
        })
      ),
      new FormControlString(
        this.tourTypeUtils.getFieldOptions(tourType, 'strgen3', {
          value: json.strgen3,
          key: 'strgen3',
          label: 'label.tour.field.strgen3'
        })
      ),
      new FormControlString(
        this.tourTypeUtils.getFieldOptions(tourType, 'strgen4', {
          value: json.strgen4,
          key: 'strgen4',
          label: 'label.tour.field.strgen4'
        })
      ),
      new FormControlNumber(
        this.tourTypeUtils.getFieldOptions(tourType, 'numgen', {
          value: json.numgen,
          key: 'numgen',
          label: 'label.tour.field.numgen'
        })
      ),
      new FormControlNumber(
        this.tourTypeUtils.getFieldOptions(tourType, 'numgen2', {
          value: json.numgen2,
          key: 'numgen2',
          label: 'label.tour.field.numgen2'
        })
      ),
      new FormControlNumber(
        this.tourTypeUtils.getFieldOptions(tourType, 'numgen3', {
        value: json.numgen3,
        key: 'numgen3',
        label: 'label.tour.field.numgen3'
      })
      ),
      new FormControlNumber(
        this.tourTypeUtils.getFieldOptions(tourType, 'numgen4', {
          value: json.numgen4,
          key: 'numgen4',
          label: 'label.tour.field.numgen4'
        })
      ),
      this.multiLangStringHelper.fromJSON(
        json.mlstrgen,
        'mlstrgen',
        this.tourTypeUtils.getFieldOptions(tourType, 'mlstrgen', {
          label: 'label.tour.field.mlstrgen'
        })
      ),
      this.multiLangStringHelper.fromJSON(
        json.mlstrgen2,
        'mlstrgen2',
        this.tourTypeUtils.getFieldOptions(tourType, 'mlstrgen2', {
          label: 'label.tour.field.mlstrgen2'
        })
      ),
      this.multiLangStringHelper.fromJSON(
        json.mlstrgen3,
        'mlstrgen3',
        this.tourTypeUtils.getFieldOptions(tourType, 'mlstrgen3', {
          label: 'label.tour.field.mlstrgen3'
        })
      ),
      this.multiLangStringHelper.fromJSON(
        json.mlstrgen4,
        'mlstrgen4',
        this.tourTypeUtils.getFieldOptions(tourType, 'mlstrgen4', {
          label: 'label.tour.field.mlstrgen4'
        })
      ),

      new FormControlSelect(
        this.tourTypeUtils.getFieldOptions(tourType, 'choice1', {
          label: 'label.tour.field.choice1',
          value: json.choice1
        })
      ),
      new FormControlSelect(
        this.tourTypeUtils.getFieldOptions(tourType, 'choice2', {
          label: 'label.tour.field.choice2',
          value: json.choice2
        })
      ),
      new FormControlSelect(
        this.tourTypeUtils.getFieldOptions(tourType, 'choice3', {
          label: 'label.tour.field.choice3',
          value: json.choice3
        })
      ),
      new FormControlSelect(
        this.tourTypeUtils.getFieldOptions(tourType, 'choice4', {
          label: 'label.tour.field.choice4',
          value: json.choice4
        })
      ),

      new FormControlNumber({
        label: 'label.tour.field.latitude',
        value: isNaN(json.geolat) || json.geolat === '' ? undefined : Number(json.geolat),
        hidden: false
      }),

      new FormControlNumber({
        label: 'label.tour.field.longitude',
        value: isNaN(json.geolong) || json.geolong === '' ? undefined : Number(json.geolong),
        hidden: false
      }),
      json.blocked_users,
      json.created_on ? moment(json.created_on).toDate() : undefined,
      json.creatorname,
      json.lastmodified ? moment(json.lastmodified).toDate() : undefined,
      json.modifiedby,
      json.version,
      json.versions ? json.versions.map(ver => <VersionModel>{
        number: ver.number,
        date: moment(ver.date).toDate()
      }) : [],
      json.baseId,
      json.is_refered,
      json._embedded && json._embedded.stations ? json._embedded.stations
        .filter(station => this.stationHelper.checkJSON(station))
        .map(station => this.stationHelper.fromJSON(station)) : [],
      json.station_refs,
      json._embedded && json._embedded.channels ? json._embedded.channels
        .filter(channel => this.channelHelper.checkJSON(channel))
        .map(channel => this.channelHelper.fromJSON(channel)) : [],
      json.channel_refs,
      json.files ? json.files
        .filter(file => this.fileHelper.checkJSON(file))
        .map(file => this.fileHelper.fromJSON(file, FileType.IMAGE)) : []
    );

    // Sort based on ref objects
    tour.stations = Utils.mapOrder(tour.stations, tour.stationRefs, 'id');
    tour.channels = Utils.mapOrder(tour.channels, tour.channelRefs, 'id');

    return tour;
  }
}
