import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-username',
  template: `
    <em class="username" *ngIf="username && !userId">{{username}}</em>
    <a *ngIf="username && userId" [routerLink]="['/users/' + userId]"><em class="username">{{username}}</em></a>
  `
})
export class UsernameComponent {

  @Input() username: string;
  @Input() userId: string;
  @Input() icon: boolean;

  constructor() { }
}
