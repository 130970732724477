import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { GameResult } from '../../../models/game-result.model';
import { Status } from '../../../models/status.model';
import { GameResultTypeUtils } from '../../../utils/game-result-type-utils';

@Component({
  selector: 'app-game-result-list-modal',
  templateUrl: './game-result-list-modal.component.html'
})
export class GameResultListModalComponent {

  @Input() results: GameResult[];
  @Input() resultTypes: number[];
  @Input() status: Status;

  selection: GameResult[] = [];

  constructor(
    private resultTypeUtils: GameResultTypeUtils,
    public activeModal: NgbActiveModal
  ) {
    console.log('GameResultListModalComponent#constructor');
    if (!this.resultTypes) {
      this.resultTypes = this.resultTypeUtils.getTypeValues();
    }
  }
}
