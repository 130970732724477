import { BaseModel } from "./base.model";
import Utils from "../utils/utils";
import * as moment from 'moment';
import { HtmlContent } from "./html-content.model";
import { FormControlBoolean } from "./form-control/form-control-boolean.model";
import { MultiLangString } from "./multi-lang-string.model";
import { FormControlType } from "../enums/form-control-type.enum";
import { FormControlNumber } from "./form-control/form-control-number.model";
import { FormControlString } from "./form-control/form-control-string.model";

export class HtmlPage extends BaseModel {

  constructor(
    public id: string,
    public name: MultiLangString,
    public isIndexPage: FormControlBoolean,
    public menuOrderNumber: FormControlNumber,
    public bgColor: FormControlString,
    public showInMenu: FormControlBoolean,
    public active: FormControlBoolean,
    public contentList: HtmlContent[],
    public blockedUsers: string[],
    public version?,
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string
  ) {
    super(id, blockedUsers, createdOn, creatorName, lastModified, modifiedBy);
  }

  // static checkJSON(json: any): boolean {
  //   return json && Utils.isValidId(json._id);
  // }

  // static fromJSON(json: any, skipCheck = false): HtmlPage {
  //
  //   // integrity check
  //   if (!skipCheck && !this.checkJSON(json)) {
  //     return undefined;
  //   }
  //
  //   // create content object
  //   const htmlPage = new HtmlPage(
  //     json._id,
  //     MultiLangString.fromJSON(
  //       json.name,
  //       'name',
  //       {
  //         apiParam: 'name',
  //         label: 'Menu Entry Name'
  //       },
  //       json.title ? Object.keys(json.title) : []
  //     ),
  //     new FormControlBoolean({
  //       label: 'Index Page',
  //       value: !!json.isIndexPage
  //     }),
  //     new FormControlNumber({
  //       label: 'Menu Order Number',
  //       value: json.menuOrderNumber,
  //       apiParam: 'menuOrderNumber'
  //     }),
  //     new FormControlString({
  //       label: 'Background Color',
  //       value: json.bgColor,
  //       apiParam: 'bgColor'
  //     }),
  //     new FormControlBoolean({
  //       label: 'Menu Page',
  //       value: !!json.showInMenu
  //     }),
  //     new FormControlBoolean({
  //       label: 'Active',
  //       value: !!json.active
  //     }),
  //     json.contentList ? json.contentList.map(item => HtmlContent.fromJSON(item, true)) : [],
  //     json.blocked_users,
  //     json.version,
  //     json.created_on ? moment(json.created_on).toDate() : undefined,
  //     json.creatorname,
  //     json.lastmodified ? moment(json.lastmodified).toDate() : undefined,
  //     json.modifiedby
  //   );
  //
  //   // if (htmlPage.schema && htmlPage.contentList.length == 0) {
  //   //   const contentList = htmlPage.schema.fields.map(field => new HtmlContent('', htmlPage.schema.key, field.key));
  //   //   htmlPage.contentList = contentList;
  //   // }
  //
  //   return htmlPage;
  // }

  // static emptyHtmlPage(schema: HtmlContentSchema): HtmlPage {
  //   // const page = new HtmlPage('', schema);
  //
  //   const contentList = schema.fields.map(field => new HtmlContent('', schema.key, field.key));
  //   page.contentList = contentList;
  //   return page;
  // }

  toJSON(): object {
    const json = super.toJSON();

    // json['schema'] = this.schema.toJSON();
    json['name'] = this.name.toJSON();
    json['isIndexPage'] = this.isIndexPage.value;
    json['menuOrderNumber'] = this.menuOrderNumber.value;
    json['bgColor'] = this.bgColor.value;
    json['showInMenu'] = this.showInMenu.value;
    json['active'] = this.active.value;
    json['contentList'] = this.contentList ? this.contentList.map(item => item.toJSON()) : [];

    return json;
  }
}
