import { Component, Input, OnInit } from '@angular/core';

import { BtnBaseComponent } from '../../../../app/shared/components/btns/btn-base/btn-base.component';

import { File } from '../../../../app/shared/models/file.model';
import { EventEmitter } from 'events';


@Component({
  selector: 'app-image-edit',
  template: `
  <button type="button" class="img-circular-lg editable" (click)="onButtonClick($event)"
    [ngStyle]="{'background-image': 'url(' + files[0].getHref() + ')'}"
    *ngIf="files && files[0]" [disabled]="disabled"></button>
  <button type="button" class="img-circular-lg editable" (click)="onButtonClick($event)"
    *ngIf="!files || !files[0]" [disabled]="disabled"></button>
  `
})
export class ImageEditComponent extends BtnBaseComponent implements OnInit {
  @Input() files: File[];

  constructor() {
    super();
  }

  ngOnInit() {
    this.disabled = this.disabled || !this.onClick.observers[0];
  }
}
