import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UserService } from '../../core/services/user.service';

import { Group } from '../models/group.model';
import { Status } from '../models/status.model';

import { MessageModalComponent } from '../../core/components/message-modal/message-modal.component';
import { GroupListModalComponent } from '../components/modals/group-list-modal/group-list-modal.component';

import { ApiBaseService } from '../../core/services/api-base.service';

import { CreateOptions } from '../interfaces/create-options.interface';
import { ModelMapperService } from "./mapper/model-mapper.service";
import { ChannelService } from "./channel.service";


@Injectable()
export class GroupService extends ApiBaseService<Group> {

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected userService: UserService,
    protected modalService: NgbModal,
    private channelService: ChannelService,
    private modelMapper: ModelMapperService
  ) {
    super(Group, 'groups', http, router, userService);
  }

  select(exclude: Group[] = []): Promise<Group[]> {
    const modalRef = this.modalService.open(GroupListModalComponent, {size: 'lg'});
    modalRef.componentInstance.status = new Status();

    this.getAll(modalRef.componentInstance.status)
      .subscribe((groups: Group[]) => {
        try {
          modalRef.componentInstance.groups = groups
            .filter(group => exclude.findIndex(myGroup => group.id === myGroup.id) < 0);
        } catch (e) {
        }
      });

    return modalRef.result;
  }

  create(options: CreateOptions<Group>): Promise<Group> {
    return super.create(Object.assign(options, {
      model: this.emptyModel()
    }));
  }

  delete(id: string, status?: Status): Promise<any> {
    const modalRef = this.modalService.open(MessageModalComponent);
    modalRef.componentInstance.confirm = true;
    modalRef.componentInstance.message = 'Are you sure that you want to delete the group?';

    return modalRef.result.then(() => super.delete(id, status), () => Promise.reject('Dimissed'));
  }

  checkJSON(json: any): boolean {
    return this.modelMapper.groupCheckJSON(json);
  }

  fromJSON(json: any, skipCheck?: boolean): Group {
    return this.modelMapper.groupFromJSON(json, skipCheck);
  }

  emptyModel(): Group {
    return this.modelMapper.groupEmptyModel(this.channelService.getDefaultChannels());
  }
}
