import { Injectable } from '@angular/core';
import Utils from '../../../app/shared/utils/utils';
import { StationTypeConfigOptions } from '../../../config/station-type-config.interface';
import { FileType } from '../enums/file-type.enum';
import { FileTypeInterface } from '../interfaces/file-type.interface';
import { FormControlOptions } from '../interfaces/form-control-options.interface';
import { FormControlSelectOptions } from '../interfaces/form-control-select-options.interface';
import { ConfigurationService } from '../services/configuration.service';
import { ContentTypeUtils } from './content-type-utils';
import { GameTypeUtils } from './game-type-utils';

@Injectable({
  providedIn: 'root'
})
export class StationTypeUtils {

  private stationTypeValues;
  private mainStationTypeValues;
  private subStationTypeValues;

  constructor(
    private readonly config: ConfigurationService,
    private readonly contentTypeUtils: ContentTypeUtils,
    private readonly gameTypeUtils: GameTypeUtils,
  ) {
  }

  private getStationTypes(): StationTypeConfigOptions {
    if (this.config.isLoadingConfig()) {
      return undefined;
    }

    const config = this.config.getStationTypeConfig();
    if (config) {
      return Utils.clone(config);
    }

    return undefined;
  }

  getLabel(stationType: string | number) {
    if (this.getStationTypes() && this.isValid(stationType)) {
      return this.getStationTypes()[ Number(stationType) ].label;
    }

    return '';
  }

  hasField(stationType: string | number, field: string): boolean {
    if (this.getStationTypes()) {
      return Object.keys(this.getStationTypes()[ Number(stationType) ].fields).indexOf(field) >= 0;
    }

    return false;
  }

  getFieldOptions(stationType: string | number, field: string, defaultOptions: FormControlOptions<any>): FormControlOptions<any> {
    if (this.getStationTypes()) {
      const options = this.hasField(stationType, field) ? Utils.clone(this.getStationTypes()[ Number(stationType) ].fields[ field ]) : {};

      for (const optionName in defaultOptions) {
        if (Utils.isDefined(defaultOptions[ optionName ])) {
          options[ optionName ] = Utils.isDefined(options[ optionName ]) ? options[ optionName ] : defaultOptions[ optionName ];
        }
      }

      options.key = defaultOptions.key || field;
      options.hidden = Utils.isDefined(defaultOptions.hidden) ? defaultOptions.hidden : !this.hasField(stationType, field);

      return options;
    }

    return {};
  }

  getValues(): number[] {
    if (this.getStationTypes()) {
      this.stationTypeValues = this.stationTypeValues || Object.keys(this.getStationTypes())
        .filter(Number)
        .map(stationType => Number(stationType));

      return Utils.clone(this.stationTypeValues);
    }

    return [];
  }

  getMainStationTypeValues(): number[] {
    this.mainStationTypeValues = this.mainStationTypeValues || this.getValues()
      .filter(stationType => this.getSubStationTypeValues().indexOf(Number(stationType)) === -1);

    return Utils.clone(this.mainStationTypeValues);
  }

  getSubStationTypeValues(): number[] {
    if (this.getStationTypes()) {
      let subStationTypeValues = [];

      for (const stationType in this.getStationTypes()) {
        if (stationType && this.getSubStationTypes(stationType).indexOf(Number(stationType)) === -1) {
          subStationTypeValues = subStationTypeValues.concat(this.getStationTypes()[ stationType ].subStationTypes);
        }
      }

      return Utils.clone(subStationTypeValues.reduce((unique, item) => {
        return unique.includes(item) ? unique : [ ...unique, item ];
      }, []));
    }

    return [];
  }

  getDefault(): number {
    return this.getValues()[ 0 ];
  }

  isValid(stationType: string | number): boolean {
    return !isNaN(Number(stationType)) && this.getValues().indexOf(Number(stationType)) >= 0;
  }

  getSelectOptions(): FormControlSelectOptions<number> {
    return this.getValues()
      .map(stationType => {
        return {
          value: stationType,
          label: this.getLabel(stationType)
        };
      });
  }

  getSubStationTypes(stationType: string | number): number[] {
    if (this.getStationTypes()) {
      const stationTypes = this.getStationTypes()[ Number(stationType) ].subStationTypes;
      return (stationTypes === 'all' ? this.getValues() : stationTypes) || [];
    }

    return [];
  }

  getContentTypes(stationType: string | number): number[] {
    if (this.getStationTypes()) {
      const contentTypes = this.getStationTypes()[ Number(stationType) ].contentTypes;
      return (contentTypes === 'all' ? this.contentTypeUtils.getValues() : contentTypes) || [];
    }

    return [];
  }

  getGameTypes(stationType: string | number): number[] {
    if (this.getStationTypes()) {
      const gameTypes = this.getStationTypes()[ Number(stationType) ].gameTypes;
      return (gameTypes === 'all' ? this.gameTypeUtils.getGameTypeValues() : gameTypes) || [];
    }

    return [];
  }

  getImageTypes(stationType: string | number): FileTypeInterface[] {
    if (this.getStationTypes()) {
      const imageTypes = this.getStationTypes()[ Number(stationType) ] ? Object.assign({}, Utils.clone(
        this.getStationTypes()[ Number(stationType) ].imageTypes), { hasLanguage: false, hasDuration: false }) : [];

      const preparedImageTypes = [];

      for (const imageType in imageTypes) {
        if (imageTypes[ imageType ]) {
          preparedImageTypes.push({
            type: FileType.IMAGE,
            attribute: {
              label: imageTypes[ imageType ].label,
              value: imageType,
              hotspot: imageTypes[ imageType ].hotspot || false
            }
          });
        }
      }

      return preparedImageTypes;
    }

    return [];
  }

  isValidImageType(stationType: string | number, imageType: string): boolean {
    if (this.getStationTypes()) {
      return Object.keys(this.getStationTypes()[ Number(stationType) ].imageTypes || []).indexOf(imageType) >= 0;
    }

    return false;
  }

  isValidSubStation(stationType: string | number, subStationType: string | number): boolean {
    return this.getSubStationTypes(stationType).indexOf(Number(subStationType)) >= 0;
  }

  getModules(stationType: string | number): string[] {
    if (this.getStationTypes()) {
      return Utils.clone(this.getStationTypes()[ Number(stationType) ].modules  || []);
    }

    return [];
  }

  isModuleEnabled(stationType: string | number, module: string): boolean {
    return this.getModules(stationType).indexOf(module) >= 0;
  }
}
