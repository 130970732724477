import { Component, Input } from '@angular/core';

import { BtnBaseComponent } from '../../../../../app/shared/components/btns/btn-base/btn-base.component';


@Component({
  selector: 'app-btn-delete',
  template: `
  <button type="button" class="btn btn-{{theme}}" [disabled]="disabled"
    appBtnSpinner [appBtnSpinnerLoading]="status && status.loading"
    (click)="onButtonClick($event); $event.stopPropagation();">
    <i class="fa fa-trash-o"></i>
  </button>
  `
})
export class BtnDeleteComponent extends BtnBaseComponent {

  @Input() theme = 'outline-secondary';

  constructor() {
    super();
  }
}
