import { OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { ConfigurationService } from "../../services/configuration.service";


export abstract class BaseComponent implements OnDestroy {
  protected destroy$: Subject<any> = new Subject<any>();

  constructor(
    protected route?: ActivatedRoute
  ) {
  }

  get title(): string {
    return this.route ? this.route.snapshot.data.title : '';
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
