import { BaseModel } from "./base.model";
import { AwsModel } from "./aws.model";
import { FormControlString } from "./form-control/form-control-string.model";
import { FormControlSelect } from "./form-control/form-control-select.model";
import { AzureModel } from "./azure.model";

export class CommunityServerConfigModel extends BaseModel {

  constructor(public id: string,
              public tmpDirectory: FormControlString,
              public filesLocation: FormControlSelect,
              public localPath: FormControlString,
              public aws: AwsModel,
              public azure: AzureModel,
              public languages: string[],
              public version?: number,
              public createdOn?: Date,
              public creatorName?: string,
              public lastModified?: Date,
              public modifiedBy?: string
  ) {
    super(id, null, createdOn, creatorName, lastModified, modifiedBy, version);
  }

  toJSON() {
    const json = super.toJSON();
    delete json['blocked_users'];

    json['tmpDirectory'] = this.tmpDirectory.value;
    json['localPath'] = this.localPath.value;
    json['filesLocation'] = this.filesLocation.value;
    json['languages'] = this.languages;
    json['aws'] = this.aws ? this.aws.toJSON() : undefined;
    json['azure'] = this.azure ? this.azure.toJSON() : undefined;

    return json;
  }
}
