import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GameResultCategory } from '../../../game/enum/game-result-category.enum';
import { GameScoringSystem } from '../../models/game-scoring-system.model';
import { GameScoringSystemModelHelper } from '../../models/helper/game-scoring-system-model.helper';
import { GameUtilHelper } from '../../models/helper/game-util-helper';
import { ModelMapperService } from '../../services/mapper/model-mapper.service';
import { GameScoringSystemTypeUtils } from '../../utils/game-scoring-system-type-utils';

@Component({
  selector: 'app-game-scoring-system',
  templateUrl: './game-scoring-system.component.html',
  styleUrls: [ './game-scoring-system.component.css' ]
})
export class GameScoringSystemComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() scoringSystem: GameScoringSystem;

  constructor(
    private readonly scoringSystemTypeUtils: GameScoringSystemTypeUtils,
    private readonly gameUtilHelper: GameUtilHelper
  ) {
  }

  ngOnInit() {
  }

  onTypeChange() {
    const config = this.scoringSystemTypeUtils.getTypeConfig(this.scoringSystem.type.value);
    this.scoringSystem.category = GameResultCategory[config.category.toUpperCase()];
    this.scoringSystem.parameters = this.gameUtilHelper.createDictionary(config, 'parameters');
    this.scoringSystem.assetDictionary = this.gameUtilHelper.createDictionary(config, 'assetDictionary');
    this.scoringSystem.textDictionary = this.gameUtilHelper.createDictionary(config, 'textDictionary');
  }

}
