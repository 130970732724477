import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, map } from 'rxjs/operators';

const gamesApi = '/game/v1.0/game';
const levelsApi = '/game/v1.0/level';
const elementsApi = '/game/v1.0/element';
const resultsApi = '/game/v1.0/result';
const solutionsApi = '/game/v1.0/solution';
const selectionsApi = '/game/v1.0/selection';

@Injectable()
export class AdapterInterceptor implements HttpInterceptor {

  private readonly apisToAdapt: string[] = [
    gamesApi,
    // levelsApi,
    // elementsApi
  ];

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        catchError(error => throwError(error)),
        map((res: HttpResponse<any>) => this.checkResponse(res))
      );
  }

  checkResponse(res: HttpResponse<any>): HttpResponse<any> {
    const needsAdaptation = this.apisToAdapt.some(api => res.url && res.url.includes(api));

    if (needsAdaptation) {
      return this.adaptResponse(res);
    }

    return res;
  }

  adaptResponse(res: HttpResponse<any>) {
    if (res.url.includes(gamesApi)) {
      return this.adaptGamesApi(res);
    }

    if (res.url.includes(levelsApi)) {
      return this.adaptLevelsApi(res);
    }

    if (res.url.includes(elementsApi)) {
      return this.adaptElementsApi(res);
    }

    return res;
  }

  adaptGamesApi(res: HttpResponse<any>) {
    const lastPartOfUrl = res.url.split('/').pop();

    if (lastPartOfUrl === 'game') {
      const body = {
        _embedded: {
          games: res.body || []
        }
      };

      return res.clone({ body });
    }

    return res;
  }

  adaptLevelsApi(res: HttpResponse<any>) {
    const lastPartOfUrl = res.url.split('/').pop();

    if (lastPartOfUrl === 'level') {
      const body = {
        _embedded: {
          levels: res.body || []
        }
      };

      return res.clone({ body });
    }

    return res;
  }

  adaptElementsApi(res: HttpResponse<any>) {
    const lastPartOfUrl = res.url.split('/').pop();

    if (lastPartOfUrl === 'element') {
      const body = {
        _embedded: {
          elements: res.body || []
        }
      };

      return res.clone({ body });
    }

    return res;
  }
}
