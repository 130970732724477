import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { File } from '../../../../../app/shared/models/file.model';


@Component({
  selector: 'app-file-edit-modal',
  templateUrl: './file-edit-modal.component.html'
})
export class FileEditModalComponent {

  @Input() form: FormGroup;
  @Input() file: File;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  save(): void {
    this.activeModal.close(this.file);
  }
}
