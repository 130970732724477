import { FormControlString } from "./form-control/form-control-string.model";

export class CdnModel {

  constructor(
    public exportScript: FormControlString = new FormControlString({
      key: 'exportScript',
      label: 'label.config.cdn.field.export-script',
    })) {
  }

  static fromJSON(json) {
    const model = new CdnModel(
      new FormControlString({
        key: 'exportScript',
        label: 'label.config.cdn.field.export-script',
        value: json ? json.exportScript || '' : ''
      })
    );

    return model;
  }

  toJSON() {
    const json = {
      exportScript: this.exportScript.value
    };

    return json;
  }
}
