import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GameParameter } from '../../models/game-parameter';

@Component({
  selector: 'app-game-parameter-list',
  templateUrl: './game-parameter-list.component.html',
  styleUrls: [ './game-parameter-list.component.css' ]
})
export class GameParameterListComponent implements OnInit {

  filteredParameters: GameParameter[];

  @Input() set parameters(parameters: GameParameter[]) {
    this.filteredParameters = parameters.filter(param => !param.invalid && !param.hidden);
  }

  @Input() form: FormGroup;

  @Output() readonly changed: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

}
