import {routes} from '../config/routes';

declare global {
  interface Window {
    cmsConfig: {
      api: {
        url: string,
        restBasePath: string
      },
      auth: {
        url: string,
        basePath: string,
        authPath: string
      },
      secrets: {
        clientId: string,
        clientSecret: string
      },
      config: {
        url: string,
        basePath: string
      },
      sync: {
        url: string,
        basePath: string
      },
      game: {
        url: string,
        basePath: string
      },
      translation: {
        url: string,
        basePath: string
      }
    };
  }
}

export class AppConfig {
  public static readonly APP_VERSION: string = require('../../package.json').version;
  public static readonly APP_ROUTES = routes;

  public static readonly APP_SHOW_IDS: boolean = false;
  public static readonly APP_BG_LIGHT: string = '#f8f9fa';

  public static readonly API_URL: string = window.cmsConfig.api.url;
  public static readonly API_REST_BASE_PATH: string = window.cmsConfig.api.restBasePath;

  public static readonly AUTH_SERVER: string =  window.cmsConfig.auth.url || null;
  public static readonly AUTH_BASE_URL: string =  window.cmsConfig.auth.basePath || null;
  public static readonly AUTH_AUTH_URL: string =  window.cmsConfig.auth.authPath || null;

  public static readonly CONFIG_SERVER: string =  window.cmsConfig.config.url || null;
  public static readonly CONFIG_BASE_URL: string =  window.cmsConfig.config.basePath || null;

  public static readonly SYNC_SERVER: string =  window.cmsConfig.sync.url || null;
  public static readonly SYNC_BASE_URL: string =  window.cmsConfig.sync.basePath || null;

  public static readonly GAME_SERVER: string =  window.cmsConfig.game.url || null;
  public static readonly GAME_BASE_URL: string =  window.cmsConfig.game.basePath || null;

  public static readonly TRANSLATION_SERVER: string =  window.cmsConfig.translation.url || null;
  public static readonly TRANSLATION_BASE_URL: string =  window.cmsConfig.translation.basePath || null;

  public static readonly AUTH_CLIENT_ID: string = window.cmsConfig.secrets.clientId;
  public static readonly AUTH_CLIENT_SECRET: string = window.cmsConfig.secrets.clientSecret;

  public static readonly NC_CHANNEL_HEADER: string = 'cms';

  // public static readonly GOOGLE_MAPS_API_KEY: string = environment.googleMaps ? environment.googleMaps.key : 'AIzaSyBUiQkWGgLtHkPP2yrDaTqQzTtViEgDfwA';
  // public static readonly GRECAPTCHA_SITE_KEY: string = environment.grecaptcha ? environment.grecaptcha.key : '6Lc5i1kUAAAAAFxlWmTh80sHKD2J9tUgLd7A9opE';
}

