import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UserContent } from '../../../models/user-content.model';
import { UserContentTypeUtils } from '../../../utils/user-content-type-utils';

@Component({
  selector: 'app-user-content-create-modal',
  templateUrl: './user-content-create-modal.component.html'
})
export class UserContentCreateModalComponent implements OnInit {
  @Input() userContent: UserContent;
  @Input() userContentTypes: number[];
  form = new FormGroup({});

  constructor(
    public activeModal: NgbActiveModal,
    private userContentTypeUtils: UserContentTypeUtils
  ) {
  }

  ngOnInit() {
    if (this.userContentTypes) {
      this.setUserContentTypeOptions();
    } else {
      this.userContentTypes = this.userContentTypeUtils.getUserContentTypeValues();
      this.setUserContentTypeOptions();
    }
  }

  create(): void {
    this.activeModal.close(this.userContent);
  }

  updateFile(file: 'styleFile' | 'userContentFile' | 'htmlFile') {
    const config = this.userContentTypeUtils.getTypeConfig(this.userContent.type.value);

    this.userContent[ file ].href = config[ file ];
    this.userContent[ file ].attribute = { value: 'file' };
    this.userContent[ file ].name.value = this.getFileName(config[ file ]);
  }

  getFileName(href: string): string {
    return href && href
      .split('/')
      .reverse()[ 0 ];
  }

  private setUserContentTypeOptions() {
    this.userContent.type.options = this.userContent.type.options.filter(userContentType => {
      return this.userContentTypes.indexOf(userContentType.value) !== -1;
    });

    this.userContent.type.value = this.userContent.type.options[ 0 ].value;
  }
}
