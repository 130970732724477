import { Injectable } from '@angular/core';
import Utils from '../../../app/shared/utils/utils';
import { GameLevelTypeConfigOptions } from '../../../config/game-level-type-config.interface';
import { GameLevelCategory } from '../../game/enum/game-level-category.enum';
import { FormControlOptions } from '../interfaces/form-control-options.interface';
import { FormControlSelectOptions } from '../interfaces/form-control-select-options.interface';
import { Dictionary } from '../models/dictionary';
import { GameUtilHelper } from '../models/helper/game-util-helper';
import { ConfigurationService } from '../services/configuration.service';
import { GameControlFlowTypeUtils } from './game-control-flow-type-utils';
import { GameElementTypeUtils } from './game-element-type-utils';
import { GameResultTypeUtils } from './game-result-type-utils';

@Injectable({
  providedIn: 'root'
})
export class GameLevelTypeUtils {

  private levelTypeValues;

  constructor(
    private readonly config: ConfigurationService,
    private readonly elementTypeUtils: GameElementTypeUtils,
    private readonly resultTypeUtils: GameResultTypeUtils,
    private readonly controlFlowTypeUtils: GameControlFlowTypeUtils,
    private readonly gameUtilHelper: GameUtilHelper
  ) {
  }

  private getLevelTypes(): GameLevelTypeConfigOptions {
    if (this.config.isLoadingConfig()) {
      return undefined;
    }

    const config = this.config.getLevelTypeConfig();
    if (config) {
      return Utils.clone(config);
    }

    return undefined;
  }

  getTypeConfig(type: number) {
    return this.getLevelTypes()[ type ];
  }

  getLabel(levelType: string | number) {
    if (this.getLevelTypes() && this.isValid(levelType)) {
      return this.getLevelTypes()[ Number(levelType) ].label;
    }

    return '';
  }

  hasField(levelType: string | number, field: string): boolean {
    if (this.getLevelTypes()) {
      return Object.keys(this.getLevelTypes()[ Number(levelType) ].fields).indexOf(field) >= 0;
    }

    return false;
  }

  getFieldOptions(levelType: string | number, field: string, defaultOptions: FormControlOptions<any>): FormControlOptions<any> {
    if (this.getLevelTypes()) {
      const options = this.hasField(levelType, field) ? Utils.clone(this.getLevelTypes()[ Number(levelType) ].fields[ field ]) : {};

      for (const optionName in defaultOptions) {
        if (Utils.isDefined(defaultOptions[ optionName ])) {
          options[ optionName ] = Utils.isDefined(options[ optionName ]) ? options[ optionName ] : defaultOptions[ optionName ];
        }
      }

      options.key = defaultOptions.key || field;
      options.hidden = Utils.isDefined(defaultOptions.hidden) ? defaultOptions.hidden : !this.hasField(levelType, field);

      return options;
    }

    return {};
  }

  getLevelTypeValues(): number[] {
    if (this.getLevelTypes()) {
      this.levelTypeValues = this.levelTypeValues || Object.keys(this.getLevelTypes())
        .filter(Number)
        .map(levelType => Number(levelType));

      return Utils.clone(this.levelTypeValues);
    }

    return [];
  }

  getCategoryOfType(type: number): string {
    const config = this.getLevelTypes()[ type ];

    return config && config.category;
  }

  getLevelCategoryValues(): string[] {
    return Object.values(GameLevelCategory);
  }

  getDefaultType(): number {
    return this.getLevelTypeValues()[ 0 ];
  }

  getDefaultCategory(): string {
    return this.getLevelCategoryValues()[ 0 ];
  }

  isValid(levelType: string | number): boolean {
    return !isNaN(Number(levelType)) && this.getLevelTypeValues().indexOf(Number(levelType)) >= 0;
  }

  getTypeSelectOptions(): FormControlSelectOptions<number> {
    return this.getLevelTypeValues()
      .map(levelType => {
        return {
          value: levelType,
          label: this.getLabel(levelType)
        };
      });
  }

  getCategorySelectOptions(): FormControlSelectOptions<string> {
    return this.getLevelCategoryValues()
      .map(value => ({ value, label: value.toUpperCase() }));
  }

  getModules(levelType: string | number): string[] {
    if (this.getLevelTypes()) {
      return Utils.clone(this.getLevelTypes()[ Number(levelType) ].modules);
    }

    return [];
  }

  isModuleEnabled(levelType: string | number, module: string): boolean {
    return this.getModules(levelType).indexOf(module) >= 0;
  }

  getElementTypes(levelType: string | number): number[] {
    if (this.getLevelTypes()) {
      const elementTypes = this.getLevelTypes()[ Number(levelType) ].elementTypes;
      return (elementTypes === 'all' ? this.elementTypeUtils.getTypeValues() : elementTypes) || [];
    }

    return [];
  }

  getControlFlowTypes(levelType: string | number): number[] {
    return [10700]; // TODO remove when bug fixed

    // if (this.getLevelTypes()) {
    //   const controlFlowTypes = this.getLevelTypes()[ Number(levelType) ].controlFlowTypes;
    //   return (controlFlowTypes === 'all' ? this.controlFlowTypeUtils.getTypeValues() : controlFlowTypes) || [];
    // }
    //
    // return [];
  }

  getResultTypes(levelType: string | number): number[] {
    if (this.getLevelTypes()) {
      const resultTypes = this.getLevelTypes()[ Number(levelType) ].resultTypes;
      return (resultTypes === 'all' ? this.resultTypeUtils.getTypeValues() : resultTypes) || [];
    }

    return [];
  }

  createDictionary(levelType: string | number, key: 'parameters' | 'textDictionary' | 'assetDictionary'): any[] {

    const types = this.getLevelTypes();

    if (levelType && types) {
      const dict: Dictionary<any> = types[ Number(levelType) ];

      return this.gameUtilHelper.createDictionary(dict, key);
    }

    return [];
  }
}
