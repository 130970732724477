import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { MultiLangString } from '../../models/multi-lang-string.model';

import Utils from '../../../../app/shared/utils/utils';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-form-controls-multi-lang',
  templateUrl: './form-controls-multi-lang.component.html'
})
export class FormControlsMultiLangComponent implements OnInit {
  @Input() multiLangStringArray: MultiLangString[];
  @Input() form: FormGroup;

  pillsLimit: number;
  newLocale = '';
  show = false;
  private readonly availableLocals: string[];

  constructor(private config: ConfigurationService) {
    this.pillsLimit = config.getTabPillsLimit();
    this.availableLocals = config.getLocalesConfig().available
  }

  copy(masterLocale: string) {
    this.multiLangStringArray.forEach(multiLangString => {
      Object.keys(multiLangString).forEach(locale => {
        if (typeof locale === "string" && locale !== masterLocale && this.availableLocals.indexOf(locale) !== -1) {
          multiLangString[locale] = Utils.clone(multiLangString[masterLocale]);
        }
      });
    });

    this.form.markAsDirty();
  }

  incomplete(locale: string) {
    return this.multiLangStringArray.some(multiLangString => {
      return !multiLangString[locale].hidden && (
        !multiLangString[locale]['value'] || Utils.isUndefined(multiLangString[locale]['value']) || multiLangString[locale]['value'] === ''
      );
    });
  }

  addLocale(locale: string): void {
    for (const multiLangString of this.multiLangStringArray) {
      multiLangString.addLocale(locale);
    }
  }

  ngOnInit() {
    for (const multiLangString of this.multiLangStringArray) {
      this.show = this.show || !multiLangString[multiLangString.defaultLocale].hidden;
    }
  }
}
