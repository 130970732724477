import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { TableBaseComponent } from '../table-base/table-base.component';

import { UserService } from '../../../../core/services/user.service';
import { GameResult } from '../../../models/game-result.model';
import { ConfigurationService } from '../../../services/configuration.service';

@Component({
  selector: 'app-game-result-table',
  templateUrl: './game-result-table.component.html'
})
export class GameResultTableComponent extends TableBaseComponent<GameResult> {

  @Input() resultTypes: number[];
  @Input() detailLink = '/results';

  @Output() readonly selected: EventEmitter<GameResult> = new EventEmitter<GameResult>();

  tableConfig: any = this.getTableConfig('result');

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected config: ConfigurationService,
    protected userService: UserService
  ) {
    super(router, route, config, userService);
  }

  getGlobalFilterFields() {
    this.globalFilters = this.globalFilters || [{
      label: 'ID',
      field: 'id',
      active: this.showId
    }, {
      label: 'Name',
      field: 'name.value',
      active: true
    }];

    return this.globalFilters
      .filter(filter => filter.active)
      .map(filter => filter.field);
  }
}
