import * as moment from 'moment';

export class SyncMetaModel {

  constructor(
    public dirty: boolean,
    public locked: boolean,
    public building: boolean,
    public lastBuildFailed: boolean,
    public buildNumber: string,
    public lastBuildTimestamp: Date
  ) { }

  static fromJSON(json: any): SyncMetaModel {
    const syncMeta = new SyncMetaModel(
      json.dirty,
      json.locked,
      json.building,
      json.lastBuildFailed,
      json.buildNumber,
      json.lastBuildTimestamp ? moment(json.lastBuildTimestamp).toDate() : undefined,
    );

    return syncMeta;
  }
}
