import { Component, Input, Output, EventEmitter } from '@angular/core';

import { BtnBaseComponent } from '../../../../../app/shared/components/btns/btn-base/btn-base.component';


@Component({
  selector: 'app-btn-boolean',
  template: `
    <button type="button" class="btn btn-sm"
      [ngClass]="boolean ? 'btn-success' : 'btn-secondary'"
      [disabled]="disabled"
      (click)="boolean = !boolean; booleanChange.emit(boolean); onButtonClick($event); $event.stopPropagation();"
      appBtnSpinner [appBtnSpinnerLoading]="status && status.loading">
      <span *ngIf="boolean">{{trueValue}}</span>
      <span *ngIf="!boolean">{{falseValue}}</span>
    </button>
  `
})
export class BtnBooleanComponent extends BtnBaseComponent {
  @Input() boolean: boolean;
  @Input() trueValue = 'Active';
  @Input() falseValue = 'Inactive';
  @Output() booleanChange = new EventEmitter<boolean>();

  constructor() {
    super();
  }
}
