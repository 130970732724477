import { MultiLangString } from './multi-lang-string.model';
import { MultiLangBoolean } from './multi-lang-boolean.model';
import { FormControlSelect } from './form-control/form-control-select.model';

import { FileType } from '../enums/file-type.enum';

import { BaseFilesModel } from './base-files.model';
import { File } from './file.model';
import { Station } from './station.model';
import { Channel } from './channel.model';

import Utils from '../../../app/shared/utils/utils';
import { VersionModel } from "./base.model";
import { FormControlString } from "./form-control/form-control-string.model";
import { FormControlNumber } from "./form-control/form-control-number.model";
import { FormControlBoolean } from "./form-control/form-control-boolean.model";


export class Tour extends BaseFilesModel {

  constructor(
    public id: string,
    public active: MultiLangBoolean,
    public type: FormControlSelect,
    public title: MultiLangString,
    public subtitle: MultiLangString,
    public description: MultiLangString,
    public shortText: MultiLangString,
    public longText: MultiLangString,
    public duration: FormControlNumber,
    public flaggen: FormControlBoolean,
    public flaggen2: FormControlBoolean,
    public flaggen3: FormControlBoolean,
    public flaggen4: FormControlBoolean,
    public strgen: FormControlString,
    public strgen2: FormControlString,
    public strgen3: FormControlString,
    public strgen4: FormControlString,
    public numgen: FormControlNumber,
    public numgen2: FormControlNumber,
    public numgen3: FormControlNumber,
    public numgen4: FormControlNumber,
    public mlstrgen: MultiLangString,
    public mlstrgen2: MultiLangString,
    public mlstrgen3: MultiLangString,
    public mlstrgen4: MultiLangString,
    public choice1: FormControlSelect,
    public choice2: FormControlSelect,
    public choice3: FormControlSelect,
    public choice4: FormControlSelect,
    public lat: FormControlNumber,
    public lng: FormControlNumber,
    public blockedUser: string[] = [],
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string,
    public version?: number,
    public versions?: VersionModel[],
    public baseId?: string,
    public isReferred?: boolean,
    public stations: Station[] = [],
    public stationRefs: string[] = [],
    public channels: Channel[] = [],
    public channelRefs: string[] = [],
    public files: File[] = [],
    public filesToDelete: File[] = []
  ) {
    super(id, blockedUser, createdOn, creatorName, lastModified, modifiedBy, version, versions, baseId, files, filesToDelete);
  }

  toJSON(): object {
    const json = super.toJSON();

    json['active'] = this.active.toJSON();
    json['type'] = this.type.value;
    json['title'] = this.title.toJSON();
    json['subtitle'] = this.subtitle.toJSON();
    json['description'] = this.description.toJSON();
    json['shortText'] = this.shortText.toJSON();
    json['longText'] = this.longText.toJSON();
    json['duration'] = this.duration.value;

    json['flaggen'] = this.flaggen.value;
    json['flaggen2'] = this.flaggen2.value;
    json['flaggen3'] = this.flaggen3.value;
    json['flaggen4'] = this.flaggen4.value;
    json['strgen'] = this.strgen.value;
    json['strgen2'] = this.strgen2.value;
    json['strgen3'] = this.strgen3.value;
    json['strgen4'] = this.strgen4.value;
    json['numgen'] = this.numgen.value;
    json['numgen2'] = this.numgen2.value;
    json['numgen3'] = this.numgen3.value;
    json['numgen4'] = this.numgen4.value;
    json['mlstrgen'] = this.mlstrgen.toJSON();
    json['mlstrgen2'] = this.mlstrgen2.toJSON();
    json['mlstrgen3'] = this.mlstrgen3.toJSON();
    json['mlstrgen4'] = this.mlstrgen4.toJSON();
    json[ 'choice1' ] = this.choice1.value;
    json[ 'choice2' ] = this.choice2.value;
    json[ 'choice3' ] = this.choice3.value;
    json[ 'choice4' ] = this.choice4.value;


    json['geolat'] = Utils.isDefined(this.lat.value) ? Number(this.lat.value) : null;
    json['geolong'] = Utils.isDefined(this.lng.value) ? Number(this.lng.value) : null;
    // Fill ref objects based on current order
    json['station_refs'] = Utils.getIds(this.stations);
    json['channel_refs'] = Utils.getIds(this.channels);

    return json;
  }

  addStation(newStation: Station): void {
    this.stations = [...this.stations.filter(station => station.id !== newStation.id), newStation];
    this.setChanged(true);
  }

  deleteStation(station: Station): void {
    Utils.removeObjectFromArray(this.stations, station);
    this.stations = [...this.stations];
    this.setChanged(true);
  }

  addChannel(newChannel: Channel): void {
    this.channels = [...this.channels.filter(channel => channel.id !== newChannel.id), newChannel];
    this.setChanged(true);
  }

  deleteChannel(channel: Channel): void {
    Utils.removeObjectFromArray(this.channels, channel);
    this.channels = [...this.channels];
    this.setChanged(true);
  }

  getFileTypes(): { type: number, attribute: { label: string, value: string }, hasLanguage: boolean, hasDuration: boolean }[] {
    return [{
      type: FileType.IMAGE, attribute: {
        label: 'Image',
        value: 'image'
      },
      hasLanguage: false,
      hasDuration: false
    }];
  }

  setGeoLocation(lat: number, lng: number): void {
    this.lat.value = Number(lat);
    this.lng.value = Number(lng);
    this.setChanged(true);
  }

  clearGeoLocation(): void {
    this.lat.value = undefined;
    this.lng.value = undefined;
    this.setChanged(true);
  }
}
