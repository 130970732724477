import { BaseModel } from "./base.model";
import { AwsModel } from "./aws.model";
import { FormControlString } from "./form-control/form-control-string.model";
import { FormControlSelect } from "./form-control/form-control-select.model";
import { AzureModel } from "./azure.model";
import { SyncDataServerModel } from "./sync-dataserver.model";
import { FormControlNumber } from "./form-control/form-control-number.model";

export class SyncServerConfigModel extends BaseModel {

  constructor(public id: string,
              public tmpDirectory: FormControlString,
              public filesLocation: FormControlSelect,
              public languages: string[],
              public authUrl: FormControlString,
              public maxZipSize: FormControlNumber,
              public numberOfKeptBuildPackages: FormControlNumber,
              public dbName: FormControlString,
              public buildTimeout: FormControlNumber,
              public schedulerCronJob: FormControlString,
              public aws: AwsModel,
              public azure: AzureModel,
              public localPath: FormControlString,
              public clientId: FormControlString,
              public clientSecret: FormControlString,
              public dataServer: SyncDataServerModel[],
              public version?: number,
              public createdOn?: Date,
              public creatorName?: string,
              public lastModified?: Date,
              public modifiedBy?: string
  ) {
    super(id, null, createdOn, creatorName, lastModified, modifiedBy, version);
  }

  toJSON() {
    const json = super.toJSON();
    delete json['blocked_users'];
    json['authUrl'] = this.authUrl.value;
    json['clientId'] = this.clientId.value;
    json['clientSecret'] = this.clientSecret.value;
    json['tmpDirectory'] = this.tmpDirectory.value;
    json['localPath'] = this.localPath.value;
    json['filesLocation'] = this.filesLocation.value;
    json['languages'] = this.languages;
    json['aws'] = this.aws ? this.aws.toJSON() : undefined;
    json['azure'] = this.azure ? this.azure.toJSON() : undefined;

    json['maxZipSize'] = this.maxZipSize.value;
    json['numberOfKeptBuildPackages'] = this.numberOfKeptBuildPackages.value;
    json['dbName'] = this.dbName.value;
    json['buildTimeout'] = this.buildTimeout.value;
    json['schedulerCronJob'] = this.schedulerCronJob.value;

    json['dataServers'] = this.dataServer ? this.dataServer.map(ds => ds.toJSON()) : [];

    return json;
  }
}
