import { Injectable } from '@angular/core';
import { Channel } from '../../models/channel.model';
import { CommunityServerConfigModel } from '../../models/community-server-config.model';
import { Content } from '../../models/content.model';
import { Endpoint } from '../../models/endpoint.model';
import { Exhibition } from '../../models/exhibition.model';
import { File } from '../../models/file.model';
import { FormControlSelect } from '../../models/form-control/form-control-select.model';
import { GameElement } from '../../models/game-element.model';
import { GameLevel } from '../../models/game-level.model';
import { GameResult } from '../../models/game-result.model';
import { GameScoringSystem } from '../../models/game-scoring-system.model';
import { GameSelection } from '../../models/game-selection.model';
import { GameSolution } from '../../models/game-solution.model';
import { Game } from '../../models/game.model';
import { Group } from '../../models/group.model';
import { ChannelModelHelper } from '../../models/helper/channel-model.helper';
import { CommunityServerConfigModelHelper } from '../../models/helper/community-server-config-model.helper';
import { ContentModelHelper } from '../../models/helper/content-model.helper';
import { EndpointModelHelper } from '../../models/helper/endpoint-model.helper';
import { ExhibitionModelHelper } from '../../models/helper/exhibition-model.helper';
import { FileModelHelper } from '../../models/helper/file-model.helper';
import { GameControlFlowModelHelper } from '../../models/helper/game-control-flow-model.helper';
import { GameElementModelHelper } from '../../models/helper/game-element-model.helper';
import { GameLevelModelHelper } from '../../models/helper/game-level-model.helper';
import { GameModelHelper } from '../../models/helper/game-model.helper';
import { GameResultModelHelper } from '../../models/helper/game-result-model.helper';
import { GameScoringSystemModelHelper } from '../../models/helper/game-scoring-system-model.helper';
import { GameSelectionModelHelper } from '../../models/helper/game-selection-model.helper';
import { GameSolutionModelHelper } from '../../models/helper/game-solution-model.helper';
import { GameUtilHelper } from '../../models/helper/game-util-helper';
import { GroupModelHelper } from '../../models/helper/group-model.helper';
import { HotspotModelHelper } from '../../models/helper/hotspot-model.helper';
import { HtmlBaseModelHelper } from '../../models/helper/html-base-model.helper';
import { HtmlContentModelHelper } from '../../models/helper/html-content-model.helper';
import { HtmlFileFieldHelper } from '../../models/helper/html-filefield.helper';
import { HtmlPageModelHelper } from '../../models/helper/html-page-model.helper';
import { HtmlTextFieldHelper } from '../../models/helper/html-textfield.helper';
import { MapModelHelper } from '../../models/helper/map-model.helper';
import { MultiLangBooleanHelper } from '../../models/helper/multi-lang-boolean.helper';
import { MultiLangFileHelper } from '../../models/helper/multi-lang-file.helper';
import { MultiLangStringHelper } from '../../models/helper/multi-lang-string.helper';
import { OrganizationModelHelper } from '../../models/helper/organization-model.helper';
import { RoleModelHelper } from '../../models/helper/role-model.helper';
import { ServerConfigModelHelper } from '../../models/helper/server-config-model.helper';
import { StationModelHelper } from '../../models/helper/station-model.helper';
import { SyncServerConfigModelHelper } from '../../models/helper/sync-server-config-model.helper';
import { TourModelHelper } from '../../models/helper/tour-model.helper';
import { UserContentModelHelper } from '../../models/helper/user-content-model.helper';
import { UserContentPropertyHelper } from '../../models/helper/user-content-property-helper';
import { UserModelHelper } from '../../models/helper/user-model.helper';
import { Hotspot } from '../../models/hotspot.model';
import { HtmlBaseInformation } from '../../models/html-base-information.model';
import { HtmlContentSchema } from '../../models/html-content-schema.model';
import { HtmlContent } from '../../models/html-content.model';
import { HtmlFileField } from '../../models/html-file-field.model';
import { HtmlPage } from '../../models/html-page.model';
import { HtmlTextField } from '../../models/html-text-field.model';
import { Map } from '../../models/map.model';
import { Organization } from '../../models/organization.model';
import { Role } from '../../models/role.model';
import { ServerConfigModel } from '../../models/server-config.model';
import { Station } from '../../models/station.model';
import { SyncServerConfigModel } from '../../models/sync-server-config.model';
import { Tour } from '../../models/tour.model';
import { UserContent } from '../../models/user-content.model';
import { User } from '../../models/user.model';
import { ContentTypeUtils } from '../../utils/content-type-utils';
import { FileTypeUtils } from '../../utils/file-type-utils';
import { GameControlFlowTypeUtils } from '../../utils/game-control-flow-type-utils';
import { GameElementTypeUtils } from '../../utils/game-element-type-utils';
import { GameLevelTypeUtils } from '../../utils/game-level-type-utils';
import { GameResultTypeUtils } from '../../utils/game-result-type-utils';
import { GameScoringSystemTypeUtils } from '../../utils/game-scoring-system-type-utils';
import { GameSelectionTypeUtils } from '../../utils/game-selection-type-utils';
import { GameSolutionTypeUtils } from '../../utils/game-solution-type-utils';
import { GameTypeUtils } from '../../utils/game-type-utils';
import { StationTypeUtils } from '../../utils/station-type-utils';
import { TourTypeUtils } from '../../utils/tour-type-utils';
import { UserContentTypeUtils } from '../../utils/user-content-type-utils';
import { ConfigurationService } from '../configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ModelMapperService {

  private readonly htmlBaseHelper: HtmlBaseModelHelper;
  private readonly htmlPageFieldHelper: HtmlPageModelHelper;
  private readonly htmlContentHelper: HtmlContentModelHelper;
  private readonly htmlFileFieldHelper: HtmlFileFieldHelper;
  private readonly htmlTextFieldHelper: HtmlTextFieldHelper;

  private readonly multiLangBooleanHelper: MultiLangBooleanHelper;
  private readonly multiLangFileHelper: MultiLangFileHelper;
  private readonly multiLangStringHelper: MultiLangStringHelper;

  private readonly channelHelper: ChannelModelHelper;
  private readonly contentHelper: ContentModelHelper;
  private readonly endpointHelper: EndpointModelHelper;
  private readonly exhibitionHelper: ExhibitionModelHelper;
  private readonly fileHelper: FileModelHelper;
  private readonly groupHelper: GroupModelHelper;
  private readonly hotspotHelper: HotspotModelHelper;
  private readonly mapHelper: MapModelHelper;
  private readonly stationHelper: StationModelHelper;
  private readonly tourHelper: TourModelHelper;
  private readonly gameUtilHelper: GameUtilHelper;
  private readonly gameHelper: GameModelHelper;
  private readonly levelHelper: GameLevelModelHelper;
  private readonly gameElementHelper: GameElementModelHelper;
  private readonly solutionHelper: GameSolutionModelHelper;
  private readonly selectionHelper: GameSelectionModelHelper;
  private readonly resultHelper: GameResultModelHelper;
  private readonly scoringSystemHelper: GameScoringSystemModelHelper;
  private readonly controlFlowHelper: GameControlFlowModelHelper;
  private readonly userContentHelper: UserContentModelHelper;
  private readonly serverConfigHelper: ServerConfigModelHelper;
  private readonly syncServerConfigHelper: SyncServerConfigModelHelper;
  private readonly communityServerConfigHelper: CommunityServerConfigModelHelper;
  private readonly organizationHelper: OrganizationModelHelper;
  private readonly roleHelper: RoleModelHelper;
  private readonly userHelper: UserModelHelper;
  private readonly userContentPropertyHelper: UserContentPropertyHelper;

  constructor(
    private config: ConfigurationService,
    private fileTypeUtils: FileTypeUtils,
    private stationTypeUtils: StationTypeUtils,
    private gameTypeUtils: GameTypeUtils,
    private levelTypeUtils: GameLevelTypeUtils,
    private resultTypeUtils: GameResultTypeUtils,
    private elementTypeUtils: GameElementTypeUtils,
    private solutionTypeUtils: GameSolutionTypeUtils,
    private selectionTypeUtils: GameSelectionTypeUtils,
    private scoringSystemTypeUtils: GameScoringSystemTypeUtils,
    private controlFlowTypeUtils: GameControlFlowTypeUtils,
    private contentTypeUtils: ContentTypeUtils,
    private tourTypeUtils: TourTypeUtils,
    private userContentTypeUtils: UserContentTypeUtils
  ) {

    this.multiLangBooleanHelper = new MultiLangBooleanHelper(config);
    this.multiLangStringHelper = new MultiLangStringHelper(config);

    this.fileHelper = new FileModelHelper(config, this.multiLangStringHelper, fileTypeUtils);

    this.multiLangFileHelper = new MultiLangFileHelper(config, this.fileHelper);

    this.htmlBaseHelper = new HtmlBaseModelHelper(this.fileHelper, this.multiLangStringHelper);
    this.htmlFileFieldHelper = new HtmlFileFieldHelper(this.multiLangFileHelper);
    this.htmlTextFieldHelper = new HtmlTextFieldHelper(this.multiLangStringHelper);
    this.htmlContentHelper = new HtmlContentModelHelper(this.htmlTextFieldHelper, this.htmlFileFieldHelper);
    this.htmlPageFieldHelper = new HtmlPageModelHelper(this.multiLangStringHelper, this.htmlContentHelper);

    this.endpointHelper = new EndpointModelHelper();
    this.hotspotHelper = new HotspotModelHelper();
    this.channelHelper = new ChannelModelHelper(this.fileHelper);
    this.mapHelper = new MapModelHelper(this.fileHelper);
    this.contentHelper = new ContentModelHelper(contentTypeUtils, this.fileHelper, this.channelHelper, this.gameHelper, this.multiLangStringHelper);
    this.stationHelper = new StationModelHelper(stationTypeUtils, this.channelHelper, this.gameHelper, this.mapHelper, this.contentHelper, this.fileHelper, this.multiLangStringHelper);
    this.tourHelper = new TourModelHelper(tourTypeUtils, this.stationHelper, this.channelHelper, this.fileHelper, this.multiLangBooleanHelper, this.multiLangStringHelper);
    this.groupHelper = new GroupModelHelper(this.stationHelper, this.contentHelper, this.channelHelper, this.multiLangBooleanHelper, this.multiLangStringHelper);
    this.exhibitionHelper = new ExhibitionModelHelper(this.tourHelper, this.groupHelper, this.stationHelper);
    this.gameUtilHelper = new GameUtilHelper(this.multiLangStringHelper, this.fileHelper, this.config);
    this.selectionHelper = new GameSelectionModelHelper(this.multiLangStringHelper, this.selectionTypeUtils, this.gameUtilHelper);
    this.solutionHelper = new GameSolutionModelHelper(this.multiLangStringHelper, this.solutionTypeUtils, this.gameUtilHelper);
    this.resultHelper = new GameResultModelHelper(this.multiLangStringHelper, this.resultTypeUtils, this.gameUtilHelper);
    this.scoringSystemHelper = new GameScoringSystemModelHelper(this.scoringSystemTypeUtils, this.gameUtilHelper, this.multiLangStringHelper);
    this.controlFlowHelper = new GameControlFlowModelHelper(this.controlFlowTypeUtils, this.gameUtilHelper);
    this.gameElementHelper = new GameElementModelHelper(this.selectionHelper, this.solutionHelper, this.resultHelper, this.scoringSystemHelper, this.multiLangStringHelper, this.elementTypeUtils, this.gameUtilHelper);
    this.levelHelper = new GameLevelModelHelper(this.gameElementHelper, this.resultHelper, this.multiLangStringHelper, this.controlFlowHelper, this.levelTypeUtils, this.gameUtilHelper);
    this.gameHelper = new GameModelHelper(this.levelHelper, this.resultHelper, this.multiLangStringHelper, this.multiLangBooleanHelper, this.gameTypeUtils, this.gameUtilHelper, this.fileHelper);
    this.userContentPropertyHelper = new UserContentPropertyHelper(this.multiLangStringHelper, this.multiLangBooleanHelper);
    this.userContentHelper = new UserContentModelHelper(this.multiLangStringHelper, this.fileHelper, this.userContentPropertyHelper, this.userContentTypeUtils);

    this.serverConfigHelper = new ServerConfigModelHelper();
    this.syncServerConfigHelper = new SyncServerConfigModelHelper();
    this.communityServerConfigHelper = new CommunityServerConfigModelHelper();
    this.organizationHelper = new OrganizationModelHelper();
    this.roleHelper = new RoleModelHelper();
    this.userHelper = new UserModelHelper(config, this.organizationHelper);
  }

  /* ====== FORM CONTROL - LANGUAGE SELECT ====== */
  formControlLanguageSelect(value?: string): FormControlSelect {
    return new FormControlSelect({
      value: value ? value : this.config.getLocalesConfig().default,
      key: 'locale',
      label: 'label.general.language',
      options: this.config.getAvailableLocaleOptions()
    });
  }

  /* ====== HTML BASE INFORMATION ====== */
  htmlBaseFromJSON(json: any, skipCheck = false): HtmlBaseInformation {
    return this.htmlBaseHelper.fromJSON(json, skipCheck);
  }

  htmlBaseCheckJSON(json: any): boolean {
    return this.htmlBaseHelper.checkJSON(json);
  }

  htmlBaseEmptyModel(): HtmlBaseInformation {
    return this.htmlBaseHelper.emptyModel();
  }

  /* ====== HTML PAGE ====== */
  htmlPageFromJSON(json: any, skipCheck = false): HtmlPage {
    return this.htmlPageFieldHelper.fromJSON(json, skipCheck);
  }

  htmlPageCheckJSON(json: any): boolean {
    return this.htmlPageFieldHelper.checkJSON(json);
  }

  htmlPageEmptyModel(): HtmlPage {
    return this.htmlPageFieldHelper.emptyModel();
  }

  /* ====== HTML CONTENT ====== */
  htmlContentFromJSON(json: any, skipCheck = false): HtmlContent {
    return this.htmlContentHelper.fromJSON(json, skipCheck);
  }

  htmlContentCheckJSON(json: any): boolean {
    return this.htmlContentHelper.checkJSON(json);
  }

  htmlContentEmptyModel(schema: HtmlContentSchema): HtmlContent {
    return this.htmlContentHelper.emptyModel([], schema);
  }

  htmlTextFieldEmptyModel(fieldName?: string): HtmlTextField {
    return this.htmlTextFieldHelper.emptyModel([], fieldName);
  }

  htmlFileFiledEmptyModel(fieldName?: string): HtmlFileField {
    return this.htmlFileFieldHelper.emptyModel([], fieldName);
  }

  /* ====== CHANNEL ====== */
  channelFromJSON(json: any, skipCheck = false): Channel {
    return this.channelHelper.fromJSON(json, skipCheck, this.stationHelper);
  }

  channelCheckJSON(json: any): boolean {
    return this.channelHelper.checkJSON(json);
  }

  channelEmptyModel(): Channel {
    return this.channelHelper.emptyModel();
  }

  /* ====== ENDPOINT ====== */
  endpointFromJSON(json: any, skipCheck = false): Endpoint {
    return this.endpointHelper.fromJSON(json, skipCheck);
  }

  endpointCheckJSON(json: any): boolean {
    return this.endpointHelper.checkJSON(json);
  }

  endpointEmptyModel(): Endpoint {
    return this.endpointHelper.emptyModel();
  }

  /* ====== CONTENT ====== */
  contentFromJSON(json: any, skipCheck = false): Content {
    return this.contentHelper.fromJSON(json, skipCheck);
  }

  contentCheckJSON(json: any): boolean {
    return this.contentHelper.checkJSON(json);
  }

  contentEmptyModel(defaultChannels: Channel[]): Content {
    return this.contentHelper.emptyModel(defaultChannels);
  }

  /* ====== STATION ====== */
  stationFromJSON(json: any, skipCheck = false): Station {
    return this.stationHelper.fromJSON(json, skipCheck);
  }

  stationCheckJSON(json: any): boolean {
    return this.stationHelper.checkJSON(json);
  }

  stationEmptyModel(defaultChannels: Channel[]): Station {
    return this.stationHelper.emptyModel(defaultChannels);
  }

  /* ====== EXHIBITION ====== */
  exhibitionFromJSON(json: any, skipCheck = false): Exhibition {
    return this.exhibitionHelper.fromJSON(json, skipCheck);
  }

  exhibitionCheckJSON(json: any): boolean {
    return this.exhibitionHelper.checkJSON(json);
  }

  exhibitionEmptyModel(): Exhibition {
    return this.exhibitionHelper.emptyModel();
  }

  /* ====== TOUR ====== */
  tourFromJSON(json: any, skipCheck = false): Tour {
    return this.tourHelper.fromJSON(json, skipCheck);
  }

  tourCheckJSON(json: any): boolean {
    return this.tourHelper.checkJSON(json);
  }

  tourEmptyModel(defaultChannels: Channel[]): Tour {
    return this.tourHelper.emptyModel(defaultChannels);
  }

  /* ====== FILE MODEL ====== */
  fileFromJSON(json: any, type?: number, isAudioSlideImage?: boolean): File {
    return this.fileHelper.fromJSON(json, type, isAudioSlideImage);
  }

  fileCheckJSON(json: any): boolean {
    return this.fileHelper.checkJSON(json);
  }

  fileEmptyModel(): File {
    return this.fileHelper.emptyModel();
  }

  /* ====== GROUP ====== */
  groupFromJSON(json: any, skipCheck = false): Group {
    return this.groupHelper.fromJSON(json, skipCheck);
  }

  groupCheckJSON(json: any): boolean {
    return this.groupHelper.checkJSON(json);
  }

  groupEmptyModel(defaultChannels: Channel[]): Group {
    return this.groupHelper.emptyModel(defaultChannels);
  }

  /* ====== HOTSPOT ====== */
  hotspotFromJSON(json: any, skipCheck = false): Hotspot {
    return this.hotspotHelper.fromJSON(json, skipCheck);
  }

  hotspotCheckJSON(json: any): boolean {
    return this.hotspotHelper.checkJSON(json);
  }

  hotspotEmptyModel(stationId?: string, substationId?: string): Hotspot {
    return this.hotspotHelper.emptyModel([], stationId, substationId);
  }

  /* ====== MAP ====== */
  mapFromJSON(json: any, skipCheck = false): Map {
    return this.mapHelper.fromJSON(json, skipCheck);
  }

  mapCheckJSON(json: any): boolean {
    return this.mapHelper.checkJSON(json);
  }

  mapEmptyModel(): Map {
    return this.mapHelper.emptyModel();
  }

  /* ====== GAME ====== */
  gameFromJSON(json: any, skipCheck = false): Game {
    return this.gameHelper.fromJSON(json, skipCheck);
  }

  gameCheckJSON(json: any): boolean {
    return this.gameHelper.checkJSON(json);
  }

  gameEmptyModel(): Game {
    return this.gameHelper.emptyModel();
  }

  /* ====== LEVEL ====== */
  levelFromJSON(json: any, skipCheck = false): GameLevel {
    return this.levelHelper.fromJSON(json, skipCheck);
  }

  levelCheckJSON(json: any): boolean {
    return this.levelHelper.checkJSON(json);
  }

  levelEmptyModel(): GameLevel {
    return this.levelHelper.emptyModel();
  }

  /* ====== QUESTION ELEMENT ====== */
  gameElementFromJSON(json: any, skipCheck = false): GameElement {
    return this.gameElementHelper.fromJSON(json, skipCheck);
  }

  gameElementCheckJSON(json: any): boolean {
    return this.gameElementHelper.checkJSON(json);
  }

  gameElementEmptyModel(): GameElement {
    return this.gameElementHelper.emptyModel();
  }

  /* ====== GAME RESULT ====== */
  resultFromJSON(json: any, skipCheck = false): GameResult {
    return this.resultHelper.fromJSON(json, skipCheck);
  }

  resultCheckJSON(json: any): boolean {
    return this.resultHelper.checkJSON(json);
  }

  resultEmptyModel(types: number[] = []): GameResult {
    return this.resultHelper.emptyModel(types);
  }

  /* ====== GAME SOLUTION ====== */
  solutionFromJSON(json: any, skipCheck = false): GameSolution {
    return this.solutionHelper.fromJSON(json, skipCheck);
  }

  solutionCheckJSON(json: any): boolean {
    return this.solutionHelper.checkJSON(json);
  }

  solutionEmptyModel(types: number[] = []): GameSolution {
    return this.solutionHelper.emptyModel(types);
  }

  /* ====== GAME SELECTION ====== */
  selectionFromJSON(json: any, skipCheck = false): GameSelection {
    return this.selectionHelper.fromJSON(json, skipCheck);
  }

  selectionCheckJSON(json: any): boolean {
    return this.selectionHelper.checkJSON(json);
  }

  selectionEmptyModel(types: number[] = []): GameSelection {
    return this.selectionHelper.emptyModel(types);
  }

  /* ====== GAME SCORING SYSTEM ====== */
  scoringSystemFromJSON(json: any, skipCheck = false): GameScoringSystem {
    return this.scoringSystemHelper.fromJSON(json, skipCheck);
  }

  scoringSystemCheckJSON(json: any): boolean {
    return this.scoringSystemHelper.checkJSON(json);
  }

  scoringSystemEmptyModel(type: number): GameScoringSystem {
    return this.scoringSystemHelper.emptyModel(type);
  }

  /* ====== UserContent ====== */
  userContentFromJSON(json: any, skipCheck = false): UserContent {
    return this.userContentHelper.fromJSON(json, skipCheck);
  }

  userContentCheckJSON(json: any): boolean {
    return this.userContentHelper.checkJSON(json);
  }

  userContentEmptyModel(): UserContent {
    return this.userContentHelper.emptyModel();
  }

  /* ====== ORGANIZATION ====== */
  organizationFromJSON(json: any, skipCheck = false): Organization {
    return this.organizationHelper.fromJSON(json, skipCheck);
  }

  organizationCheckJSON(json: any): boolean {
    return this.organizationHelper.checkJSON(json);
  }

  organizationEmptyModel(): Organization {
    return this.organizationHelper.emptyModel();
  }

  /* ====== ROLE ====== */
  roleFromJSON(json: any, skipCheck = false): Role {
    return this.roleHelper.fromJSON(json, skipCheck);
  }

  roleCheckJSON(json: any): boolean {
    return this.roleHelper.checkJSON(json);
  }

  roleEmptyModel(tenantId?: string): Role {
    return this.roleHelper.emptyModel([], tenantId);
  }

  /* ====== USER ====== */
  userFromJSON(json: any, skipCheck = false): User {
    return this.userHelper.fromJSON(json, skipCheck);
  }

  userCheckJSON(json: any): boolean {
    return this.userHelper.checkJSON(json);
  }

  userEmptyModel(organization?: Organization): User {
    return this.userHelper.emptyModel([], organization);
  }

  /* ====== SERVER CONFIG ====== */
  serverConfigFromJSON(json: any, skipCheck = false): ServerConfigModel {
    return this.serverConfigHelper.fromJSON(json, skipCheck);
  }

  serverConfigCheckJSON(json: any): boolean {
    return this.serverConfigHelper.checkJSON(json);
  }

  serverConfigEmptyModel(): ServerConfigModel {
    return this.serverConfigHelper.emptyModel();
  }

  /* ====== SYNC SERVER CONFIG ====== */
  syncServerConfigFromJSON(json: any, skipCheck = false): SyncServerConfigModel {
    return this.syncServerConfigHelper.fromJSON(json, skipCheck);
  }

  syncServerConfigCheckJSON(json: any): boolean {
    return this.syncServerConfigHelper.checkJSON(json);
  }

  syncServerConfigEmptyModel(): SyncServerConfigModel {
    return this.syncServerConfigHelper.emptyModel();
  }

  /* ====== Community SERVER CONFIG ====== */
  communityServerConfigFromJSON(json: any, skipCheck = false): CommunityServerConfigModel {
    return this.communityServerConfigHelper.fromJSON(json, skipCheck);
  }

  communityServerConfigCheckJSON(json: any): boolean {
    return this.communityServerConfigHelper.checkJSON(json);
  }

  communityServerConfigEmptyModel(): CommunityServerConfigModel {
    return this.communityServerConfigHelper.emptyModel();
  }
}
