import { BaseModel } from "./base.model";
import { MultiLangString } from "./multi-lang-string.model";
import { File } from "./file.model";

export class HtmlBaseInformation extends BaseModel {
  constructor(
    public id: string,
    public active,
    public title: MultiLangString,
    public subtitle: MultiLangString,
    public menulabel: MultiLangString,
    public languagelabel: MultiLangString,
    public blockedUsers: string[],
    public logo?: File,
    public version?,
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string
  ) {
    super(id, blockedUsers, createdOn, creatorName, lastModified, modifiedBy);
  }

  toJSON(): object {
    const json = super.toJSON();

    json['active'] = this.active.value;
    json['title'] = this.title.toJSON();
    json['subtitle'] = this.subtitle.toJSON();
    json['menu_label'] = this.menulabel.toJSON();
    json['language_label'] = this.languagelabel.toJSON();
    json['logo'] = this.logo ? this.logo.toJSON() : undefined;

    return json;
  }
}
