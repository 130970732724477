import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Station } from '../../../models/station.model';
import { Status } from '../../../models/status.model';
import { StationTypeUtils } from "../../../utils/station-type-utils";


@Component({
  selector: 'app-station-list-modal',
  templateUrl: './station-list-modal.component.html'
})
export class StationListModalComponent {

  @Input() stations: Station[];
  @Input() stationTypes: number[];
  @Input() status: Status;

  selection: Station[] = [];

  constructor(
    private stationTypeUtils: StationTypeUtils,
    public activeModal: NgbActiveModal
  ) {
    if(!this.stationTypes) {
      this.stationTypes = this.stationTypeUtils.getValues()
    }

  }
}
