import { Component, Input } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { TableBaseComponent } from '../table-base/table-base.component';

import { User } from '../../../models/user.model';
import { UserService } from "../../../../core/services/user.service";
import { ConfigurationService } from "../../../services/configuration.service";


@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html'
})
export class UserTableComponent extends TableBaseComponent<User> {

  @Input() detailLink = '/users';

  tableConfig: any = this.getTableConfig('user');
  loggedInUserId: string = null;

  constructor(
    protected userService: UserService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected config: ConfigurationService
  ) {
    super(router, route, config, userService);
  }

  getGlobalFilterFields() {
    this.globalFilters = this.globalFilters || [{
      label: 'ID',
      field: 'id',
      active: this.showId
    }, {
      label: 'Username',
      field: 'username.value',
      active: true
    }];

    return this.globalFilters
      .filter(filter => filter.active)
      .map(filter => filter.field);
  }

  getRoleName(user: User): string {
    let roleStr = '';
    if (user.role.options && Array.isArray(user.role.options)) {
      const values: string[]  = [...user.role.value];
      const roles = user.role.options.filter(option => values.indexOf(option.value) > -1).map(option => option.label);

      if (roles && roles.length > 0) {
        roleStr = roles.length == 1 ? roles[0] : roles[0] + ', ...'
      }
    }

    return roleStr;
  }

  isLoggedInUser(user): boolean {
    return user.id === this.loggedInUserId;
  }

  ngOnInit() {
    super.ngOnInit();
    this.userService.getCurrentUser()
      .then(user => {
        this.loggedInUserId = user.id;
      });
  }
}
