import { FormControlOptions } from '../interfaces/form-control-options.interface';
import Utils from '../../../app/shared/utils/utils';
import { FileTypeConfigOptions } from '../../../config/file-type-config.interface';
import { ConfigurationService } from "../services/configuration.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class FileTypeUtils {

  constructor(private config: ConfigurationService) {
  }

  private getFileTypes(): FileTypeConfigOptions {
    return Utils.clone(this.config.getFileTypeConfig());
  }

  public hasField(field: string): boolean {
    return Object.keys(this.getFileTypes().fields).indexOf(field) >= 0;
  }

  public getFieldOptions(field: string, defaultOptions: FormControlOptions<any>): FormControlOptions<any> {
    const options = this.hasField(field) ? Utils.clone(this.getFileTypes().fields[field]) : {};

    for (const optionName in defaultOptions) {
      if (Utils.isDefined(defaultOptions[optionName])) {
        options[optionName] = Utils.isDefined(options[optionName]) ? options[optionName] : defaultOptions[optionName];
      }
    }

    options.key = defaultOptions.key || field;
    options.hidden = Utils.isDefined(defaultOptions.hidden) ? defaultOptions.hidden : !this.hasField(field);

    return options;
  }

}
