import { Component, Input } from '@angular/core';

import { BtnBaseComponent } from '../../../../../app/shared/components/btns/btn-base/btn-base.component';


@Component({
  selector: 'app-btn-save',
  template: `
    <button type="submit" [disabled]="disabled"
            [className]="disabled ? 'btn btn-' + disableTheme : 'btn btn-' + theme"
            appBtnSpinner [appBtnSpinnerLoading]="status && status.loading"
            (click)="onButtonClick($event)">
      <i class="fa fa-floppy-o"></i> {{title | translate}}
    </button>
  `
})
export class BtnSaveComponent extends BtnBaseComponent {

  @Input() disableTheme = 'primary';
  @Input() title = 'label.general.save';

  constructor() {
    super();
  }
}
