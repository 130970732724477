import { Component, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';

import { TableBaseComponent } from '../table-base/table-base.component';

import { FileService } from '../../../services/file.service';
import { File } from '../../../models/file.model';

import Utils from '../../../../../app/shared/utils/utils';
import { FileType } from "../../../enums/file-type.enum";
import { ConfigurationService } from "../../../services/configuration.service";
import { UserService } from "../../../../core/services/user.service";
import { FileTypeInterface } from "../../../interfaces/file-type.interface";
import { FormControlNumber } from "../../../models/form-control/form-control-number.model";
import { ModelMapperService } from "../../../services/mapper/model-mapper.service";

@Component({
  selector: 'app-file-table',
  templateUrl: './file-table.component.html',
  styleUrls: ['./file-table.component.scss']
})
export class FileTableComponent extends TableBaseComponent<File> implements OnDestroy {

  form = new FormGroup({});
  public fileShowingDetails: any;
  public imageShowingDetails: any;

  @Input() fileTypes: FileTypeInterface[];
  @Input() isAudioSlide: boolean;

  constructor(
    private fileService: FileService,
    protected router: Router,
    protected route: ActivatedRoute,
    private modelMapper: ModelMapperService,
    protected config: ConfigurationService,
    protected userService: UserService
  ) {
    super(router, route, config, userService);
  }

  getGlobalFilterFields() {
    this.globalFilters = this.globalFilters || [{
      label: 'Name',
      field: 'name.value',
      active: true
    }];

    return this.globalFilters
      .filter(filter => filter.active)
      .map(filter => filter.field);
  }

  handleRowSelect(file: File): void {
    if (this.isMultiSelection()) {
      Utils.addOrRemoveFromArray(this.selection, file);

    } else if (this.isSingleSelection()) {
      this.selectionChange.emit([file]);

    } else {
      this.fileService.edit(file).then(() => this.update(file));
    }
  }

  getData(): File[] {
    if (!this.isAudioSlide) {
      return this.data;
    } else {
      return this.data.filter(file => FileType.AUDIO === file.type);
    }
  }

  getAudioSlideImageList(audioFile: File): File[] {
    if (this.isAudioSlide && audioFile) {
      return this.data.filter(file => FileType.IMAGE === file.type && file.locale.value === audioFile.locale.value);
    } else {
      return []
    }
  }

  showLanguageColumn(): boolean {
    return !!this.fileTypes.find(t => t.hasLanguage === true);
  }

  showDurationColumn(): boolean {
    return !!this.fileTypes.find(t => t.hasDuration === true);
  }

  showImageColumn(): boolean {
    return !!this.fileTypes.find(t => t.type === FileType.IMAGE || t.type === FileType.SLIDE);
  }

  showDetails(file: any = undefined) {
    this.fileShowingDetails = file;
  }

  showImageDetails(file: any = undefined) {
    this.imageShowingDetails = file;
  }

  handleReplace(file: File): void {
    // Preparation for file replacement
  }

  getCurrentTime(file: File): number {
    if (file.isAudio()) {
      const curTime = file.getAudio().currentTime;
      return curTime * 1000;
    }

    return 0;
  }

  languageChangeHandler(file: File) {
    if (this.isAudioSlide) {
      this.checkAndCopyAudioImages(file);
      this.removeUnnecessaryImages(file);
    }

    file.setChanged();
    this.onUpdate.emit(file);
  }

  audioTimeChangeHandler(file: File, event: any) {
    if (event && event.value && file.isAudio()) {
      file.getAudio().currentTime = event.value / 1000
    }
  }

  private checkAndCopyAudioImages(audioFile: File): void {
    if (FileType.AUDIO != audioFile.type) {
      return;
    }

    const imgList = this.data.filter(file => FileType.IMAGE === file.type);
    if (!imgList || (Array.isArray(imgList) && imgList.length == 0)) {
      // no images
      return;
    }

    if (!this.existLanguageInList(audioFile.locale.value, imgList)) {
      // copy all images for that lang
      // select a language
      const defaultLang = imgList[0].locale.value;
      if (defaultLang) {
        // copy images of that language to the audioFile language
        imgList.filter(img => img.locale.value === defaultLang)
          .forEach(img => {
            this.data.push(this.copyImageFile(img, audioFile.locale.value));
          });
      }
    }
  }

  private removeUnnecessaryImages(audioFile: File) {
    if (FileType.AUDIO != audioFile.type) {
      return;
    }

    const languages = this.data.filter(file => FileType.AUDIO === file.type).map(file => file.locale.value);
    const unnecessaryImages = this.data.filter(file => FileType.IMAGE === file.type && languages.indexOf(file.locale.value) < 0);
    unnecessaryImages.forEach(img => Utils.removeObjectFromArray(this.data, img));
  }

  private existLanguageInList(lang: string, list: File[]): boolean {
    return list && !!list.find(file => lang === file.locale.value);
  }

  private copyImageFile(sourceImage: File, targetLanguage: string): File {
    const img = this.modelMapper.fileEmptyModel();
    Object.assign(img, sourceImage);
    img.locale = this.modelMapper.formControlLanguageSelect(targetLanguage);
    img.fieldgen3 = new FormControlNumber({
      value: 0,
      label: 'label.file.fieldgen3'
    });

    return img;
  }

  ngOnDestroy() {
    this.data.forEach(file => {
      if (file.isPlaying()) {
        file.play();
      }
    });
  }
}
