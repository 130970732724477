import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiBaseService } from '../../core/services/api-base.service';
import { UserService } from '../../core/services/user.service';
import { Hotspot } from '../models/hotspot.model';
import { FileService } from './file.service';
import { ModelMapperService } from "./mapper/model-mapper.service";


@Injectable()
export class HotspotService extends ApiBaseService<Hotspot> {

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected userService: UserService,
    protected fileService: FileService,
    protected modalService: NgbModal,
    private modelMapper: ModelMapperService
  ) {
    super(Hotspot, 'hotspots', http, router, userService, fileService, modalService);
  }

  checkJSON(json: any): boolean {
    return this.modelMapper.hotspotCheckJSON(json);
  }

  emptyModel(stationId?: string, substationId?: string): Hotspot {
    return this.modelMapper.hotspotEmptyModel(stationId, substationId);
  }

  fromJSON(json: any, skipCheck?: boolean): Hotspot {
    return this.modelMapper.hotspotFromJSON(json, skipCheck);
  }
}
