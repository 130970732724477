import { Component, Input } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { TableBaseComponent } from '../../../../../app/shared/components/tables/table-base/table-base.component';

import { Endpoint } from '../../../../../app/shared/models/endpoint.model';
import { ConfigurationService } from "../../../services/configuration.service";
import { TourTypeUtils } from "../../../utils/tour-type-utils";
import { UserService } from "../../../../core/services/user.service";


@Component({
  selector: 'app-endpoint-table',
  templateUrl: './endpoint-table.component.html'
})
export class EndpointTableComponent extends TableBaseComponent<Endpoint> {

  @Input() detailLink = '/endpoint';

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected config: ConfigurationService,
    protected userService: UserService
  ) {
    super(router, route, config, userService);
  }

  goToDetailView(): void {}

  getGlobalFilterFields() {
    this.globalFilters = this.globalFilters || [{
      label: 'ID',
      field: 'id',
      active: this.showId
    }, {
      label: 'Name',
      field:  'name.value',
      active: true
    }];

    return this.globalFilters
      .filter(filter => filter.active)
      .map(filter => filter.field);
  }
}
