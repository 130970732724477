import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { AdapterInterceptor } from './interceptors/adapter.interceptor';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { AuthGuard } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { ChangeGuard } from './services/change-guard.service';
import { DetailSyncService } from './services/detail-sync.service';
import { FormControlService } from './services/form-control.service';
import { FormService } from './services/formService';
import { MetaInformationService } from './services/meta-information.service';
import { PermissionGuard } from './services/permission-guard.service';
import { SyncService } from './services/sync.service';
import { UserService } from './services/user.service';

@NgModule({
  imports: [
    HttpClientModule,
    RouterModule,
    CommonModule,
    TranslateModule
  ],
  exports: [
    BreadcrumbsComponent,
    AccessDeniedComponent,
    MessageModalComponent
  ],
  declarations: [
    BreadcrumbsComponent,
    AccessDeniedComponent,
    MessageModalComponent
  ],
  providers: [],
  entryComponents: [
    MessageModalComponent
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [ // Singleton Services go here
        MetaInformationService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ApiInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AdapterInterceptor,
          multi: true
        },
        AuthGuard,
        PermissionGuard,
        AuthService,
        UserService,
        SyncService,
        FormControlService,
        DetailSyncService,
        FormService,
        ChangeGuard
      ]
    };
  }
}
