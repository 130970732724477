import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Station } from '../../../models/station.model';
import { StationTypeUtils } from "../../../utils/station-type-utils";


@Component({
  selector: 'app-station-create-modal',
  templateUrl: './station-create-modal.component.html'
})
export class StationCreateModalComponent implements OnInit {

  @Input() station: Station;
  @Input() stationTypes: number[];
  form = new FormGroup({});

  constructor(
    private stationTypeUtils: StationTypeUtils,
    public activeModal: NgbActiveModal
  ) { }

  create(): void {
    this.activeModal.close(this.station);
  }

  ngOnInit() {
    if (!this.stationTypes) {
      this.stationTypes = this.stationTypeUtils.getValues();
    }
    this.setStationOptions();
  }

  private setStationOptions() {
    if (this.stationTypes) {
      this.station.type.options = this.station.type.options.filter(stationType => {
        return this.stationTypes.indexOf(stationType.value) !== -1;
      });

      this.station.type.value = this.station.type.options[0].value;
    }
  }
}
