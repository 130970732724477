import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from "@angular/router";
import {PermissionsComponent} from "../../shared/components/permissions/permissions.component";
import {FormService} from "./formService";
import {MessageModalComponent} from "../components/message-modal/message-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class ChangeGuard implements CanDeactivate<PermissionsComponent> {

  constructor(private formService: FormService,
              protected modalService: NgbModal){
  }

  canDeactivate(component: PermissionsComponent,
                currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Promise<boolean> {

    if(this.formService.getForm() && this.formService.getForm().dirty) {
      const modalRef = this.modalService.open(MessageModalComponent);
      modalRef.componentInstance.confirm = true;
      modalRef.componentInstance.title= 'Unsaved Changes';
      modalRef.componentInstance.message = 'You have unsaved changes. Are you sure you want to leave the page without saving?';

      return modalRef.result.then(() => Promise.resolve(true), () => Promise.resolve(false));
    }

    return Promise.resolve(true);
  }

}
