import { BaseModel, VersionModel } from './base.model';
import {File} from './file.model';

import Utils from '../../../app/shared/utils/utils';
import { FileTypeInterface } from "../interfaces/file-type.interface";
import { FileType } from "../enums/file-type.enum";

/**
 * Base Model with files
 */
export abstract class BaseFilesModel extends BaseModel {


  protected constructor(
    public id: string,
    public blockedUser: string[] = [],
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string,
    public version?: number,
    public versions?: VersionModel[],
    public baseId?: string,
    public files: File[] = [],
    public filesToDelete: File[] = []
  ) {
    super(id, blockedUser, createdOn, creatorName, lastModified, modifiedBy, version, versions, baseId);
  }

  public get filesWithoutThumbnails(): File[] {
    return this.files.filter(file => !file.isThumbnail());
  }

  toJSON(createdOn?: string, creatorName?: string, lastModified?: string, modifiedBy?: string): object {
    const json = super.toJSON(createdOn, creatorName, lastModified, modifiedBy);

    json['files'] = this.files ? this.files
      .filter(file => Utils.isDefined(file))
      .map(file => file.toJSON()) : [];

    return json;
  }

  getThumbnailHref(): string {
    const thumbnail = this.files.find(file => Utils.isThumbnailSmall(file.attribute.value));
    return thumbnail ? thumbnail.getHref() : this.files[0].getHref();
  }

  getFileTypes(): FileTypeInterface[] {
    return [];
  }

  getAcceptedFileTypes(fileType: number): string {
    return Utils.getAcceptedFileTypes(fileType);
  }

  getUploadLimit(): number {
    return null;
  }

  checkFilesChanged(): void {
    for (const file of this.files) {
      if (file.hasChanges) {
        this.setChanged(true);
        break;
      }
    }
  }

  addFile(newFile: File): void {
    this.files = [...this.files.filter(file => file.href !== newFile.href), newFile];
    this.setChanged(true);
  }

  deleteFile(file: File, isAudioSlideAudio?: boolean): void {
    this.filesToDelete = [...this.filesToDelete.filter(oldFile => oldFile.href !== file.href), file];

    if(isAudioSlideAudio) {
      this.files.filter(f => FileType.IMAGE === f.type && file.locale.value ===f.locale.value).forEach(f => {
        Utils.removeObjectFromArray(this.files, f);
      });
    }

    this.files.filter(f => file.href === f.href).forEach(f => {
      Utils.removeObjectFromArray(this.files, f);
    });

    this.files = [...this.files];
    this.setChanged(true);
  }
}
