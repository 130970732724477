import { catchError, map, publishReplay, refCount, share } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { ApiBaseService } from "../../core/services/api-base.service";
import { HtmlPage } from "../models/html-page.model";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { UserService } from "../../core/services/user.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Status } from "../models/status.model";
import { Observable } from "rxjs";
import { CreateOptions } from "../interfaces/create-options.interface";
import { HtmlContentSchema } from "../models/html-content-schema.model";
import Utils from "../utils/utils";
import { HtmlBaseInformation } from "../models/html-base-information.model";
import { FileType } from "../enums/file-type.enum";
import { FileService } from "./file.service";
import { HtmlFileField } from "../models/html-file-field.model";
import { HtmlContent } from "../models/html-content.model";
import { ModelMapperService } from "./mapper/model-mapper.service";

@Injectable()
export class HtmlService extends ApiBaseService<HtmlPage> {

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected userService: UserService,
    protected fileService: FileService,
    protected modalService: NgbModal,
    private modelMapper: ModelMapperService
  ) {
    super(HtmlPage, 'html', http, router, userService, fileService, null, '_embedded.html_pages');
    this.modalService = modalService;
  }

  create(options: CreateOptions<HtmlPage>): Promise<HtmlPage> {
    return super.create(Object.assign(options, {
      model: this.emptyModel()
    }));
  }

  update(model: HtmlPage, status?: Status): Observable<HtmlPage> {
    if (this.userService && model.contentList) {
      model.contentList.forEach(element => {
        if (element.fields) {
          element.fields.forEach(field => this.userService.addCurrentUser(field));
        }
      });
    }

    return super.update(model, status);
  }

  updateBaseInformation(model: HtmlBaseInformation, status?: Status): Observable<HtmlBaseInformation> {
    this.userService.addCurrentUser(model);

    status = status || new Status();
    status.setLoading();

    const url = `${this.url}/baseinfo/${model.id}`;
    return this.http[Utils.isValidId(model.id) ? 'put' : 'post'](
      url,
      Utils.isValidId(model.id) ? {$set: model.toJSON()} : model.toJSON()
    ).pipe(
      share(),
      publishReplay(1),
      refCount(),
      map(response => {
        if (response) {
          status.setSuccess();
          return this.modelMapper.htmlBaseFromJSON(response);
        } else {
          status.setNoResults();
        }
      }),
      catchError(err => this.handleError(err, status))
    );
  }

  getBaseInformation(status?: Status): Observable<HtmlBaseInformation> {
    return new Observable(observable => {
      this.userService.getCurrentUser().then(user => {
        const url = `${this.url}/baseinfo/${user.organization.tenantId}`;

        this.http.get(url).subscribe(generalSettings => {
            status.setSuccess();
            observable.next(this.modelMapper.htmlBaseFromJSON(generalSettings));
            observable.complete();
          },
          error => this.handleError(error, status))
      });
    });
  }

  /**
   * Add files to a specific entry
   * must be instance of BaseFilesModel
   */
  addLogo(model: HtmlBaseInformation, file: any, string, status?: Status): void {
    if (!this.fileService) {
      return;
    }

    this.fileService.create([file], FileType.IMAGE, 'image', status)
      .subscribe(newFiles => {
        if (newFiles) {
          model.logo = newFiles[0];
          model.setChanged(true);
        }
      });
  }

  /**
   * Add files to a specific entry
   * must be instance of BaseFilesModel
   */
  addContentImage(model: HtmlFileField, file: any, locale: string, status?: Status): void {
    if (!this.fileService) {
      return;
    }

    this.fileService.create([file], FileType.IMAGE, 'image', status)
      .subscribe(newFiles => {
        if (newFiles) {
          model.content[locale] = newFiles[0];
          model.content[locale].locale.value = locale;
          model.setChanged(true);
        }
      });
  }

  getPageSchemas(status?: Status): Observable<HtmlContentSchema[]> {
    status = status || new Status();
    status.setLoading();
    const url = `${this.url}/contentlayout/list`;

    return this.http.get(url).pipe(
      share(),
      publishReplay(1),
      refCount(),
      map((response: any[]) => {
        if (response && response.length > 0) {
          status.setSuccess();
          return response.map(item => HtmlContentSchema.fromJSON(item));
        } else {
          status.setNoResults();
        }

      }),
      catchError(err => this.handleError(err, status)),);
  }

  getHeaderLayoutTypes(status?: Status): Observable<string[]> {
    status = status || new Status();
    status.setLoading();
    const url = `${this.url}/headerlayout/list`;

    return this.http.get(url).pipe(
      share(),
      publishReplay(1),
      refCount(),
      map((response: string[]) => {
        if (response && response.length > 0) {
          status.setSuccess();
          return response;
        } else {
          status.setNoResults();
        }

      }),
      catchError(err => this.handleError(err, status)),);
  }

  checkJSON(json: any): boolean {
    return this.modelMapper.htmlPageCheckJSON(json);
  }

  emptyModel(): HtmlPage {
    return this.modelMapper.htmlPageEmptyModel();
  }

  emptyContent(schema: HtmlContentSchema): HtmlContent {
    return this.modelMapper.htmlContentEmptyModel(schema);
  }

  fromJSON(json: any, skipCheck?: boolean): HtmlPage {
    return this.modelMapper.htmlPageFromJSON(json, skipCheck);
  }
}
