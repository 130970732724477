import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

import { FileType } from '../../../app/shared/enums/file-type.enum';

import Utils from '../../../app/shared/utils/utils';


@Pipe({
  name: 'fileType'
})
export class FileTypePipe implements PipeTransform {
  transform(fileType: number | string): string {
    return Utils.isDefined(FileType[fileType]) ? new TitleCasePipe().transform(FileType[fileType].toLowerCase()) : '';
  }
}
