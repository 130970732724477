import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UserService } from '../../core/services/user.service';

import { Exhibition } from '../models/exhibition.model';
import { Status } from '../models/status.model';

import { MessageModalComponent } from '../../core/components/message-modal/message-modal.component';

import { ApiBaseService } from '../../core/services/api-base.service';

import { CreateOptions } from '../interfaces/create-options.interface';
import { ModelMapperService } from "./mapper/model-mapper.service";


@Injectable()
export class ExhibitionService extends ApiBaseService<Exhibition> {

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected userService: UserService,
    protected modalService: NgbModal,
    private modelMapper: ModelMapperService
  ) {
    super(Exhibition, 'exhibitions', http, router, userService);
  }

  create(options: CreateOptions<Exhibition>): Promise<Exhibition> {
    return super.create(Object.assign(options, {
      model: this.emptyModel()
    }));
  }

  delete(id: string, status?: Status): Promise<any> {
    const modalRef = this.modalService.open(MessageModalComponent);
    modalRef.componentInstance.confirm = true;
    modalRef.componentInstance.message = 'Are you sure that you want to delete the exhibition?';

    return modalRef.result.then(() => super.delete(id, status), () => Promise.reject('Dimissed'));
  }

  checkJSON(json: any): boolean {
    return this.modelMapper.exhibitionCheckJSON(json);
  }

  fromJSON(json: any, skipCheck?: boolean): Exhibition {
    return this.modelMapper.exhibitionFromJSON(json, skipCheck);
  }

  emptyModel(): Exhibition {
    return this.modelMapper.exhibitionEmptyModel();
  }
}
