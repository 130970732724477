import { FormControlBoolean } from './form-control/form-control-boolean.model';
import { FormControlSelect } from './form-control/form-control-select.model';
import { Game } from './game.model';
import { MultiLangString } from './multi-lang-string.model';

import Utils from '../utils/utils';
import { BaseFilesModel } from './base-files.model';
import { VersionModel } from './base.model';
import { Channel } from './channel.model';
import { File } from './file.model';
import { FormControlString } from './form-control/form-control-string.model';
import { FormControlNumber } from "./form-control/form-control-number.model";

export class Content extends BaseFilesModel {

  // public fileTypes;

  constructor(
    public id: string = '',
    public active: FormControlBoolean,
    public secured: FormControlBoolean,
    public title: MultiLangString,
    public code: FormControlString,
    public description: MultiLangString,
    public copyright: MultiLangString,
    public mlgen1: MultiLangString,
    public mlgen2: MultiLangString,
    public fieldgen1: FormControlString,
    public nrgen1: FormControlNumber,
    public type: FormControlSelect,
    public blockedUsers: string[] = [],
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string,
    public isReferred?: boolean,
    public version?: number,
    public versions?: VersionModel[],
    public baseId?: string,
    public channels: Channel[] = [],
    public channelRefs: string[] = [],
    public gameRefs: string[] = [],
    public files: File[] = [],
    public filesToDelete: File[] = []
  ) {
    super(id, blockedUsers, createdOn, creatorName, lastModified, modifiedBy, version, versions, baseId, files, filesToDelete);
  }

  toJSON(): object {
    const json = super.toJSON();

    json['active'] = this.active.value;
    if (this.secured) {
      json['secured'] = this.secured.value;
    }

    json['title'] = this.title.toJSON();
    json['code'] = this.code.value;
    json['description'] = this.description.toJSON();
    json['copyright'] = this.copyright ? this.copyright.toJSON() : undefined;
    json['mlgen1'] = this.mlgen1 ? this.mlgen1.toJSON() : undefined;
    json['mlgen2'] = this.mlgen2 ? this.mlgen2.toJSON() : undefined;
    json['fieldgen1'] = this.fieldgen1.value;
    json['nrgen1'] = this.nrgen1.value;
    json['type'] = this.type.value;
    json['game_refs'] = this.gameRefs;

    // Fill ref objects based on current order
    json['channel_refs'] = Utils.getIds(this.channels);

    return json;
  }

  addChannel(newChannel: Channel): void {
    this.channels = [...this.channels.filter(channel => channel.id !== newChannel.id), newChannel];
    this.setChanged(true);
  }

  deleteChannel(channel: Channel): void {
    Utils.removeObjectFromArray(this.channels, channel);
    this.channels = [...this.channels];
    this.setChanged(true);
  }

  addGame(newGame: Game): void {
    this.gameRefs = [...this.gameRefs.filter(id => id !== newGame.id), newGame.id];
    this.setChanged(true);
  }

  deleteGame(game: Game): void {
    this.gameRefs = this.gameRefs.filter(id => id !== game.id);
    this.setChanged(true);
  }
}
