import { Pipe, PipeTransform } from '@angular/core';
import { StationTypeUtils } from "../utils/station-type-utils";

@Pipe({
  name: 'stationType'
})
export class StationTypePipe implements PipeTransform {

  constructor(private stationTypesUtils: StationTypeUtils){
  }

  transform(stationType: number | string): string {
    return this.stationTypesUtils.getLabel(stationType);
  }
}
