import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import Utils from '../../../app/shared/utils/utils';
import { ContentTypeConfigOptions } from '../../../config/content-type-config.interface';
import { FileType } from '../enums/file-type.enum';
import { FileTypeInterface } from '../interfaces/file-type.interface';
import { FormControlOptions } from '../interfaces/form-control-options.interface';
import { FormControlSelectOptions } from '../interfaces/form-control-select-options.interface';
import { ConfigurationService } from '../services/configuration.service';
import { GameTypeUtils } from './game-type-utils';

@Injectable({
  providedIn: 'root'
})
export class ContentTypeUtils {

  private contentTypeValues;

  constructor(
    private readonly config: ConfigurationService,
    private readonly gameTypeUtils: GameTypeUtils
  ) {
  }

  private getContentTypes(): ContentTypeConfigOptions {
    if (this.config.isLoadingConfig()) {
      return undefined;
    }

    const config = this.config.getContentTypeConfig();
    if (config) {
      return Utils.clone(config);
    }

    return undefined;
  }

  public getLabel(contentType: string | number): string {
    return this.isValid(contentType) ? this.config.getContentTypeConfig()[ Number(contentType) ].label : '';
  }

  // public hasField(contentType: string | number, field: string): boolean {
  //   return Object.keys(this.config.getContentTypeConfig()[Number(contentType)].fields).indexOf(field) >= 0;
  // }

  public getFieldOptions(contentType: string | number, field: string, defaultOptions: FormControlOptions<any>): FormControlOptions<any> {
    const contentTypes = this.config.getContentTypeConfig();
    const hasField = Object.keys(contentTypes[ Number(contentType) ].fields).indexOf(field) >= 0;
    const options = hasField ? Utils.clone(contentTypes[ Number(contentType) ].fields[ field ]) : {};

    for (const optionName in defaultOptions) {
      if (Utils.isDefined(defaultOptions[ optionName ])) {
        options[ optionName ] = Utils.isDefined(options[ optionName ]) ? options[ optionName ] : defaultOptions[ optionName ];
      }
    }

    options.key = defaultOptions.key || field;
    options.hidden = Utils.isDefined(defaultOptions.hidden) ? defaultOptions.hidden : !hasField;

    return options;

  }

  public getValues(): number[] {
    this.contentTypeValues = this.contentTypeValues ||
      Object.keys(this.config.getContentTypeConfig()).filter(Number).map(contentType => Number(contentType));

    return Utils.clone(this.contentTypeValues);
  }

  public getDefault(): number {
    return this.getValues()[ 0 ];
  }

  public isValid(contentType: string | number): boolean {
    return !isNaN(Number(contentType)) && this.getValues().indexOf(Number(contentType)) >= 0;

  }

  public getSelectOptions(): FormControlSelectOptions<number> {
    return this.getValues().map(contentType => {
      return {
        value: contentType,
        label: this.getLabel(contentType)
      };
    });
  }

  getGameTypes(contentType: string | number): number[] {
    if (this.getContentTypes()) {
      const gameTypes = this.getContentTypes()[ Number(contentType) ].gameTypes;
      return (gameTypes === 'all' ? this.gameTypeUtils.getGameTypeValues() : gameTypes) || [];
    }

    return [];
  }

  public getFileTypes(contentType: string | number): FileTypeInterface[] {
    const contentTypes = this.config.getContentTypeConfig();
    if (!contentTypes[ Number(contentType) ].fileTypes) {
      return undefined;
    }

    const fileTypes = [];
    for (const fileType of contentTypes[ Number(contentType) ] ? Utils.clone(
      contentTypes[ Number(contentType) ].fileTypes) : []) {

      fileType.attributes = fileType.attributes || [ {
        label: new TitleCasePipe().transform(FileType[ fileType.type ]),
        value: FileType[ fileType.type ].toLowerCase()
      } ];

      for (const attribute in fileType.attributes) {
        if (fileType.attributes[ attribute ]) {
          fileTypes.push({
            type: fileType.type,
            attribute: fileType.attributes[ attribute ].value ? fileType.attributes[ attribute ] : Object.assign(
              fileType.attributes[ attribute ], { value: attribute }
            ),
            hasLanguage: fileType.hasLanguage,
            hasDuration: fileType.hasDuration,
            limit: fileType.limit
          });
        }
      }
    }

    return fileTypes;
  }

  public getUploadLimit(contentType: string | number): number {
    return this.config.getContentTypeConfig()[ Number(contentType) ].uploadLimit || null;
  }

  public getFileType(contentType: string | number, attribute: string): number {
    const fileType = this.getFileTypes(contentType).find(ft => attribute === ft.attribute.value);
    return fileType ? fileType.type : undefined;
  }


  getModules(contentType: string | number): string[] {
    if (this.getContentTypes()) {
      return Utils.clone(this.getContentTypes()[ Number(contentType) ].modules || []);
    }

    return [];
  }

  isModuleEnabled(contentType: string | number, module: string): boolean {
    return this.getModules(contentType).indexOf(module) >= 0;
  }
}
