import { Pipe, PipeTransform } from '@angular/core';

import { Content } from '../../../app/shared/models/content.model';
import { ContentTypeUtils } from "../utils/content-type-utils";


@Pipe({
  name: 'contentTypeFilter'
})
export class ContentTypeFilterPipe implements PipeTransform {

  constructor(private contentTypeUtils: ContentTypeUtils) {
  }

  transform(contents: Content[], contentType: any): any {
    return contents ? contents.filter(content => {
      return content ? Number(content.type.value) === (isNaN(contentType) ?
        this.contentTypeUtils.getLabel(contentType) : contentType) : false;
    }) : [];
  }
}
