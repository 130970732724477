import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GameResult } from '../../../models/game-result.model';
import { GameResultTypeUtils } from '../../../utils/game-result-type-utils';

@Component({
  selector: 'app-game-result-create-modal',
  templateUrl: './game-result-create-modal.component.html'
})
export class GameResultCreateModalComponent implements OnInit {

  @Input() result: GameResult;
  @Input() resultTypes: number[];
  form = new FormGroup({});

  constructor(
    private resultTypeUtils: GameResultTypeUtils,
    public activeModal: NgbActiveModal
  ) { }

  create(): void {
    this.activeModal.close(this.result);
  }

  ngOnInit() {
    if (!this.resultTypes) {
      this.resultTypes = this.resultTypeUtils.getTypeValues();
    }
    this.setResultOptions();
  }

  private setResultOptions() {
    if (this.resultTypes) {
      this.result.type.options = this.result.type.options.filter(resultType => {
        return this.resultTypes.indexOf(resultType.value) !== -1;
      });

      this.result.type.value = this.result.type.options[0].value;
      this.result.parameters = this.resultTypeUtils.createDictionary(this.result.type.value, 'parameters');
      this.result.textDictionary = this.resultTypeUtils.createDictionary(this.result.type.value, 'textDictionary');
      this.result.assetDictionary = this.resultTypeUtils.createDictionary(this.result.type.value, 'assetDictionary');
    }
  }
}
