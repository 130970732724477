import { Pipe, PipeTransform } from '@angular/core';
import {File} from '../models/file.model';

@Pipe({
  name: 'filterThumbnail'
})
export class FilterThumbnailPipe implements PipeTransform {

  transform(files: File[]): any {
    return files.filter(file => !file.isThumbnail())
  }

}
