import { AudioLimitInterface } from "../interfaces/audio-limit.interface";
import { VideoLimitInterface } from "../interfaces/video-limit.interface";
import { ImageLimitInterface } from "../interfaces/image-limit.interface";
import { SizeLimitInterface } from "../interfaces/size-limit.interface";
import { FormControlNumber } from "./form-control/form-control-number.model";
import { FormControlSelect } from "./form-control/form-control-select.model";

export class FileLimitModel {

  constructor(
    public audio: AudioLimitInterface,
    public image: ImageLimitInterface,
    public video: VideoLimitInterface,
    public jsonSchema: SizeLimitInterface,
    public file: SizeLimitInterface) {
  }

  static fromJSON(json) {
    const model = new FileLimitModel(
      FileLimitModel._mapAudioModel(json.audio),
      FileLimitModel._mapImageModel(json.image),
      FileLimitModel._mapVideoModel(json.video),
      FileLimitModel._mapSizeLimitModel(json.jsonSchema),
      FileLimitModel._mapSizeLimitModel(json.file)
    );

    return model;
  }

  static _mapAudioModel(json): AudioLimitInterface {
    const model = {
      maximumSize: new FormControlNumber({
        apiParam: 'maximumSize',
        label: 'label.config.file-limit.field.max-size',
        value: json ? json.maximumSize : undefined
      }),
      maximumDuration: new FormControlNumber({
        apiParam: 'maximumDuration',
        label: 'label.config.file-limit.field.max-duration',
        value: json ? json.maximumDuration : undefined
      }),
      maximumBitrate: new FormControlNumber({
        apiParam: 'maximumBitrate',
        label: 'label.config.file-limit.field.max-bitrate',
        value: json ? json.maximumBitrate : undefined
      }),
      allowedChannelLayouts: new FormControlSelect({
        key: 'allowedChannelLayouts',
        label: 'label.config.file-limit.field.allowed-channel-layout',
        options: ['mono', 'stereo'].map(entry => {return {label: entry, value: entry}}),
        multiple: true,
        value: json ? json.allowedChannelLayouts : undefined
      })
    } as AudioLimitInterface;

    return model;
  }

  static _mapImageModel(json): ImageLimitInterface {
    const model = {
      maximumSize: new FormControlNumber({
        apiParam: 'maximumSize',
        label: 'label.config.file-limit.field.max-size',
        value: json ? json.maximumSize : undefined
      }),
      maximumDimension: new FormControlNumber({
        apiParam: 'maximumDimension',
        label: 'label.config.file-limit.field.max-dimension',
        value: json ? json.maximumDimension : undefined
      })
    } as ImageLimitInterface;

    return model;
  }

  static _mapVideoModel(json): VideoLimitInterface {
    const model = {
      maximumSize: new FormControlNumber({
        apiParam: 'maximumSize',
        label: 'label.config.file-limit.field.max-size',
        value: json ? json.maximumSize : undefined
      }),
      maximumDuration: new FormControlNumber({
        apiParam: 'maximumDuration',
        label: 'label.config.file-limit.field.max-duration',
        value: json ? json.maximumDuration : undefined
      }),
      maximumDimension: new FormControlNumber({
        apiParam: 'maximumDimension',
        label: 'label.config.file-limit.field.max-dimension',
        value: json ? json.maximumDimension : undefined
      }),
      maximumBitrate: new FormControlNumber({
        apiParam: 'maximumBitrate',
        label: 'label.config.file-limit.field.max-bitrate',
        value: json ? json.maximumBitrate : undefined
      }),
    } as VideoLimitInterface;

    return model;
  }

  static _mapSizeLimitModel(json): SizeLimitInterface {
    const model = {
      maximumSize: new FormControlNumber({
        apiParam: 'maximumSize',
        label: 'label.config.file-limit.field.max-size',
        value: json ? json.maximumSize : undefined
      })
    };

    return model;
  }

  toJSON() {
    const audioJson = {
      maximumSize: this.audio ? this.audio.maximumSize.value : undefined,
      maximumDuration: this.audio ? this.audio.maximumDuration.value : undefined,
      maximumBitrate: this.audio ? this.audio.maximumBitrate.value : undefined,
      allowedChannelLayouts: this.audio && this.audio.allowedChannelLayouts? this.audio.allowedChannelLayouts.value : undefined
    };

    const imageJson = {
      maximumSize: this.image ? this.image.maximumSize.value : undefined,
      maximumDimension: this.image ? this.image.maximumDimension.value : undefined
    };

    const videoJson = {
      maximumSize: this.video ? this.video.maximumSize.value : undefined,
      maximumDuration: this.video ? this.video.maximumDuration.value : undefined,
      maximumDimension: this.video ? this.video.maximumDimension.value : undefined,
      maximumBitrate: this.video ? this.video.maximumBitrate.value : undefined
    };

    const schemaJson = {
      maximumSize: this.jsonSchema ? this.jsonSchema.maximumSize.value : undefined
    };

    const fileJson = {
      maximumSize: this.file ? this.file.maximumSize.value : undefined
    };

    const json = {
      audio: audioJson,
      image: imageJson,
      video: videoJson,
      jsonSchema: schemaJson,
      file: fileJson
    };

    return json;
  }
}
