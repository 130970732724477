import { MultiLangBoolean } from "../multi-lang-boolean.model";
import { ConfigurationService } from "../../services/configuration.service";
import { FormControlOptions } from "../../interfaces/form-control-options.interface";
import Utils from "../../utils/utils";
import { FormControlBoolean } from "../form-control/form-control-boolean.model";

export class MultiLangBooleanHelper {
  constructor(private config: ConfigurationService) {
  }

  fromJSON(json: object = {}, keyPrefix: string, formControlOptions: FormControlOptions<boolean>, presets: string[] = []): MultiLangBoolean {
    const newJson = {};

    for (const locale in json) {
      if (Utils.isDefined(json[locale])) {
        formControlOptions['value'] = json[locale];
        formControlOptions['key'] = keyPrefix + '_' + locale;
        newJson[locale] = new FormControlBoolean(formControlOptions);
      }
    }

    for (const locale of [...this.config.getLocalesConfig().available, ...presets]) {
      if (Utils.isUndefined(newJson[locale])) {
        formControlOptions['key'] = keyPrefix + '_' + locale;
        formControlOptions['value'] = false;    // set default value to false
        newJson[locale] = new FormControlBoolean(formControlOptions);
      }
    }

    return new MultiLangBoolean(newJson || {}, this.getDefaultLocale());
  }

  getDefaultLocale(): string {
    return this.config.getLocalesConfig().default;
  }
}
