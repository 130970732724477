import { Injectable } from '@angular/core';
import { ApiBaseService } from "../../core/services/api-base.service";
import { ServerConfigModel } from "../models/server-config.model";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { UserService } from "../../core/services/user.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { AppConfig } from "../../app.config";
import { Status } from "../models/status.model";
import { catchError, map, tap } from "rxjs/operators";
import { ModelMapperService } from "./mapper/model-mapper.service";
import { SyncServerConfigModel } from "../models/sync-server-config.model";
import Utils from "../utils/utils";
import { BaseFilesModel } from "../models/base-files.model";

@Injectable()
export class SyncServerConfigService extends ApiBaseService<SyncServerConfigModel> {

  constructor(protected http: HttpClient,
              protected router: Router,
              protected userService: UserService,
              protected modalService: NgbModal,
              private modelMapper: ModelMapperService) {
    super(ServerConfigModel, 'config', http, router, userService);
    this.modalService = modalService;
    this.url = AppConfig.SYNC_SERVER + AppConfig.SYNC_BASE_URL;
  }

  /**
   * Get a single entry
   */
  public getConfigForTenant(tenantId: string, status?: Status): Observable<SyncServerConfigModel> {
    const url = `${this.url}/config/${tenantId}`;

    status = status || new Status();
    status.setLoading();

    return this.http.get(url).pipe(
      map(response => {
        if (this.checkJSON(response)) {
          status.setSuccess();
          return this.fromJSON(response);
        } else {
          status.setNoResults();
        }
      }),
      catchError(err => this.handleError(err, status)),);
  }

  /**
   * Create / Update specific entry
   */
  update(model: SyncServerConfigModel, status?: Status): Observable<SyncServerConfigModel> {
    status = status || new Status();
    status.setLoading();

    const url = `${this.url}/config/${model.id}`;

    if (this.userService) {
      this.userService.addCurrentUser(model);
    }

    return this.http[Utils.isValidId(model.id) ? 'put' : 'post'](
      url,
      Utils.isValidId(model.id) ? {$set: model.toJSON()} : model.toJSON()
    ).pipe(
      map(response => {

        if (model.id || this.checkJSON(response)) {
          status.setSuccess();
          return model.id ? model : this.fromJSON(response);
        } else {
          status.setError();
        }
      }),
      tap(() => {
        if (this.fileService && model instanceof BaseFilesModel
          && model.filesToDelete && model.filesToDelete.length > 0) {
          for (const file of model.filesToDelete) {
            this.fileService.delete(file.href, true)
              .then(() => {
              }, () => {
              });
          }
        }
      }),
      catchError(err => this.handleError(err, status)),);
  }

  /**
   * Create a default config for the selected tenant
   */
  // public createDefaultConfigForTenant(tenantId: string, status?: Status): Observable<ServerConfigModel> {
  //   const url = `${this.url}/cms-server/${tenantId}`;
  //
  //   status = status || new Status();
  //   status.setLoading();
  //
  //   return this.http.post(url, {}).pipe(
  //     map(response => {
  //       if (this.checkJSON(response)) {
  //         status.setSuccess();
  //         return this.fromJSON(response);
  //       } else {
  //         status.setNoResults();
  //       }
  //     }),
  //     catchError(err => this.handleError(err, status)),);
  // }

  checkJSON(json: any): boolean {
    return this.modelMapper.syncServerConfigCheckJSON(json);
  }

  emptyModel(): SyncServerConfigModel {
    return this.modelMapper.syncServerConfigEmptyModel();
  }

  fromJSON(json: any, skipCheck?: boolean): SyncServerConfigModel {
    return this.modelMapper.syncServerConfigFromJSON(json, skipCheck);
  }
}
