import { Component, Input } from '@angular/core';

import { BtnBaseComponent } from '../../../../../app/shared/components/btns/btn-base/btn-base.component';


@Component({
  selector: 'app-btn-download',
  template: `
  <a download href="{{href}}" target="_blank" class="btn btn-outline-primary"
    (click)="onButtonClick($event); $event.stopPropagation();">
    <i class="fa fa-download"></i>
  </a>
  `
})
export class BtnDownloadComponent extends BtnBaseComponent {

  @Input() href: string;

  constructor() {
    super();
  }
}
