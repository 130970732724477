import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControlSelect } from "../../../models/form-control/form-control-select.model";
import { HtmlService } from "../../../services/html.service";
import { Status } from "../../../models/status.model";
import { HtmlContentSchema } from "../../../models/html-content-schema.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-html-content-create-modal',
  templateUrl: './html-content-create-modal.component.html'
})
export class HtmlContentCreateModalComponent implements OnInit {

  status: Status = new Status();
  pageLayoutTypes: FormControlSelect = new FormControlSelect({
    // value: '',
    key: 'layoutType',
    label: 'Select the desired content layout type',
    options: []
  });

  pageSchemas: HtmlContentSchema[];

  form = new FormGroup({});

  constructor(public activeModal: NgbActiveModal,
              private translate: TranslateService,
              private htmlService: HtmlService) {
  }

  create(): void {
    const selectedSchema = this.pageSchemas.find(item => item.id === this.pageLayoutTypes.value);
    this.activeModal.close(this.htmlService.emptyContent(selectedSchema));
  }

  ngOnInit() {
    this.htmlService.getPageSchemas(this.status).subscribe((schemas: HtmlContentSchema[]) => {
      if (schemas && Array.isArray(schemas) && schemas.length > 0) {
        this.pageSchemas = schemas;
        this.pageLayoutTypes.options = schemas.map(item => {
          let label;
          this.translate.get(item.key).subscribe(trans => {
            label = trans;
          });
          return {value: item.id, label: label};
        });
      } else {
        this.status.setNoResults();
      }
    }, err => {
      console.error(err);
      this.status.setError()
    });
  }
}
