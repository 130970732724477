import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { GameControlFlow } from '../../../models/game-control-flow';
import { GameLevel } from '../../../models/game-level.model';

import { TableBaseComponent } from '../table-base/table-base.component';

import { UserService } from '../../../../core/services/user.service';
import { ConfigurationService } from '../../../services/configuration.service';

@Component({
  selector: 'app-game-control-flow-table',
  templateUrl: './game-control-flow-table.component.html'
})
export class GameControlFlowTableComponent extends TableBaseComponent<GameControlFlow> {

  @Input() controlflowTypes: number[];
  @Input() detailLink = '/controlflows';
  @Input() levels: GameLevel[] = [];

  @Output() readonly selected: EventEmitter<GameControlFlow> = new EventEmitter<GameControlFlow>();

  tableConfig: any = this.getTableConfig('controlFlow');

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected config: ConfigurationService,
    protected userService: UserService
  ) {
    super(router, route, config, userService);
  }

  getGlobalFilterFields() {
    this.globalFilters = this.globalFilters || [ {
      label: 'ID',
      field: 'id',
      active: this.showId
    }, {
      label: 'Name',
      field: 'name.value',
      active: true
    } ];

    return this.globalFilters
      .filter(filter => filter.active)
      .map(filter => filter.field);
  }

  getGameLevelTitle(levels: GameLevel[], levelId: string) {
    const level = levels.find(l => l.id === levelId);

    return (level && level.title && level.title.getDefault()) || levelId;
  }

  getGameResultTitle(levels: GameLevel[], resultId: string) {
    const results = levels.reduce((acc, curr) => acc.concat(curr.results), []);
    const result = results.find(r => r.id === resultId);

    return (result && result.title && result.title.getDefault()) || resultId;
  }
}
