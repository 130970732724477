import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AngularDraggableModule } from 'angular2-draggable';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { FileUploadModule, SharedModule as PrimeSharedModule, SliderModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { AreaMarkerComponent } from './components/area-marker/area-marker.component';
import { AuthorComponent } from './components/author/author.component';
import { BlockedUserModalComponent } from './components/bocked-user/blocked-user-modal.component';
import { BlockedUserComponent } from './components/bocked-user/blocked-user.component';
import { BtnAddComponent } from './components/btns/btn-add/btn-add.component';
import { BtnBaseComponent } from './components/btns/btn-base/btn-base.component';
import { BtnBooleanMultiLangComponent } from './components/btns/btn-boolean-multi-lang/btn-boolean-multi-lang.component';
import { BtnBooleanComponent } from './components/btns/btn-boolean/btn-boolean.component';
import { BtnCreateComponent } from './components/btns/btn-create/btn-create.component';
import { BtnDeleteComponent } from './components/btns/btn-delete/btn-delete.component';
import { BtnDownloadComponent } from './components/btns/btn-download/btn-download.component';
import { BtnEditComponent } from './components/btns/btn-edit/btn-edit.component';
import { BtnLocalesComponent } from './components/btns/btn-locales/btn-locales.component';
import { BtnPlayComponent } from './components/btns/btn-play/btn-play.component';
import { BtnReloadComponent } from './components/btns/btn-reload/btn-reload.component';
import { BtnReplaceComponent } from './components/btns/btn-replace/btn-replace.component';
import { BtnSaveComponent } from './components/btns/btn-save/btn-save.component';
import { CheckComponent } from './components/check/check.component';
import { FileMetadataComponent } from './components/file-metadata/file-metadata.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FormControlComponent } from './components/form-control/form-control.component';
import { FormControlsMultiLangComponent } from './components/form-controls-multi-lang/form-controls-multi-lang.component';
import { FormErrorsComponent } from './components/form-errors/form-errors.component';
import { GameAssetListComponent } from './components/game-asset-list/game-asset-list.component';
import { GameParameterListComponent } from './components/game-parameter-list/game-parameter-list.component';
import { GameScoringSystemComponent } from './components/game-scoring-system/game-scoring-system.component';
import { GameTextListComponent } from './components/game-text-list/game-text-list.component';
import { ImageEditComponent } from './components/image-edit/image-edit.component';
import { ChannelListModalComponent } from './components/modals/channel-list-modal/channel-list-modal.component';
import { ContentCreateModalComponent } from './components/modals/content-create-modal/content-create-modal.component';
import { ContentListModalComponent } from './components/modals/content-list-modal/content-list-modal.component';
import { FileEditModalComponent } from './components/modals/file-edit-modal/file-edit-modal.component';
import { GameControlFlowCreateModalComponent } from './components/modals/game-control-flow-create-modal/game-control-flow-create-modal.component';
import { GameCreateModalComponent } from './components/modals/game-create-modal/game-create-modal.component';
import { GameElementCreateModalComponent } from './components/modals/game-element-create-modal/game-element-create-modal.component';
import { GameElementListModalComponent } from './components/modals/game-element-list-modal/game-element-list-modal.component';
import { GameLevelCreateModalComponent } from './components/modals/game-level-create-modal/game-level-create-modal.component';
import { GameLevelListModalComponent } from './components/modals/game-level-list-modal/game-level-list-modal.component';
import { GameListModalComponent } from './components/modals/game-list-modal/game-list-modal.component';
import { GameResultCreateModalComponent } from './components/modals/game-result-create-modal/game-result-create-modal.component';
import { GameResultListModalComponent } from './components/modals/game-result-list-modal/game-result-list-modal.component';
import { GameSelectionCreateModalComponent } from './components/modals/game-selection-create-modal/game-selection-create-modal.component';
import { GameSelectionListModalComponent } from './components/modals/game-selection-list-modal/game-selection-list-modal.component';
import { GameSolutionCreateModalComponent } from './components/modals/game-solution-create-modal/game-solution-create-modal.component';
import { GameSolutionListModalComponent } from './components/modals/game-solution-list-modal/game-solution-list-modal.component';
import { GroupListModalComponent } from './components/modals/group-list-modal/group-list-modal.component';
import { HtmlContentCreateModalComponent } from './components/modals/html-create-modal/html-content-create-modal.component';
import { ImageEditModalComponent } from './components/modals/image-edit-modal/image-edit-modal.component';
import { MapListModalComponent } from './components/modals/map-list-modal/map-list-modal.component';
import { PasswordModalComponent } from './components/modals/password-modal/password-modal.component';
import { StationCreateModalComponent } from './components/modals/station-create-modal/station-create-modal.component';
import { StationInactiveCheckModalComponent, } from './components/modals/station-inactive-check-modal/station-inactive-check-modal.component';
import { StationListModalComponent } from './components/modals/station-list-modal/station-list-modal.component';
import { TourCreateModalComponent } from './components/modals/tour-create-modal/tour-create-modal.component';
import { TourListModalComponent } from './components/modals/tour-list-modal/tour-list-modal.component';
import { UserContentCreateModalComponent } from './components/modals/user-content-create-modal/user-content-create-modal.component';
import { UserContentListModalComponent } from './components/modals/user-content-list-modal/user-content-list-modal.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TableSearchComponent } from './components/table-search/table-search.component';
import { ChannelTableComponent } from './components/tables/channel-table/channel-table.component';
import { ContentTableComponent } from './components/tables/content-table/content-table.component';
import { EndpointTableComponent } from './components/tables/endpoint-table/endpoint-table.component';
import { ExhibitionTableComponent } from './components/tables/exhibition-table/exhibition-table.component';
import { FileTableComponent } from './components/tables/file-table/file-table.component';
import { GameControlFlowTableComponent } from './components/tables/game-control-flow-table/game-control-flow-table.component';
import { GameElementTableComponent } from './components/tables/game-element-table/game-element-table.component';
import { GameLevelTableComponent } from './components/tables/game-level-table/game-level-table.component';
import { GameResultTableComponent } from './components/tables/game-result-table/game-result-table.component';
import { GameSelectionTableComponent } from './components/tables/game-selection-table/game-selection-table.component';
import { GameSolutionTableComponent } from './components/tables/game-solution-table/game-solution-table.component';
import { GameTableComponent } from './components/tables/game-table/game-table.component';
import { GroupTableComponent } from './components/tables/group-table/group-table.component';
import { HtmlContentTableComponent } from './components/tables/html-content-table/html-content-table.component';
import { HtmlTableComponent } from './components/tables/html-table/html-table.component';
import { MapTableComponent } from './components/tables/map-table/map-table.component';
import { OrganizationTableComponent } from './components/tables/organization-table/organization-table.component';
import { PermissionTableComponent } from './components/tables/permission-table/permission-table.component';
import { RoleTableComponent } from './components/tables/role-table/role-table.component';
import { StationTableComponent } from './components/tables/station-table/station-table.component';
import { TourTableComponent } from './components/tables/tour-table/tour-table.component';
import { UserContentTableComponent } from './components/tables/user-content-table/user-content-table.component';
import { UserTableComponent } from './components/tables/user-table/user-table.component';
import { ChannelTypeComponent } from './components/type/channel-type.component';
import { ContentTypeComponent } from './components/type/content-type.component';
import { ExhibitionTypeComponent } from './components/type/exhibition-type.component';
import { FileTypeComponent } from './components/type/file-type.component';
import { GroupTypeComponent } from './components/type/group-type.component';
import { MapTypeComponent } from './components/type/map-type.component';
import { StationTypeComponent } from './components/type/station-type.component';
import { TourTypeComponent } from './components/type/tour-type.component';
import { UserTypeComponent } from './components/type/user-type.component';
import { UsernameComponent } from './components/username/username.component';
import { BtnSpinnerDirective } from './directives/btn-spinner.directive';
import { ExtendTableDirective } from './directives/extend-table.directive';
import { ContentTypeFilterPipe } from './pipes/content-type-filter.pipe';
import { ContentTypePipe } from './pipes/content-type.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { FileTypePipe } from './pipes/file-type.pipe';
import { FilterThumbnailPipe } from './pipes/filter-thumbnail.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { PluckPipe } from './pipes/pluck.pipe';
import { StationTypeFilterPipe } from './pipes/station-type-filter.pipe';
import { StationTypePipe } from './pipes/station-type.pipe';
import { TourTypeFilterPipe } from './pipes/tour-type-filter.pipe';
import { TourTypePipe } from './pipes/tour-type.pipe';
import { ValuesPipe } from './pipes/values.pipe';
import { CdnService } from './services/cdn.service';
import { ChannelService } from './services/channel.service';
import { ContentService } from './services/content.service';
import { ExhibitionService } from './services/exhibition.service';
import { FileService } from './services/file.service';
import { GameElementService } from './services/game-element.service';
import { GameLevelService } from './services/game-level.service';
import { GameResultService } from './services/game-result.service';
import { GameSelectionService } from './services/game-selection.service';
import { GameSolutionService } from './services/game-solution.service';
import { GameService } from './services/game.service';
import { GoogleMapsService } from './services/google-maps.service';
import { GroupService } from './services/group.service';
import { HotspotService } from './services/hotspot.service';
import { HtmlService } from './services/html.service';
import { MapService } from './services/map.service';
import { OrganizationService } from './services/organization.service';
import { RoleService } from './services/role.service';
import { ServerConfigService } from './services/server-config.service';
import { StationService } from './services/station.service';
import { SyncServerConfigService } from './services/sync-server-config.service';
import { TourService } from './services/tour.service';
import { SharedRoutingModule } from './shared-routing.module';
import { CommunityServerConfigService } from "./services/community-server-config.service";

const MODALS = [
  // Modal Components
  StationCreateModalComponent,
  StationListModalComponent,
  TourCreateModalComponent,
  TourListModalComponent,
  GroupListModalComponent,
  MapListModalComponent,
  ChannelListModalComponent,
  ContentCreateModalComponent,
  ContentListModalComponent,
  FileEditModalComponent,
  ImageEditModalComponent,
  PasswordModalComponent,
  StationInactiveCheckModalComponent,
  HtmlContentCreateModalComponent,
  GameCreateModalComponent,
  GameListModalComponent,
  GameResultCreateModalComponent,
  GameResultListModalComponent,
  GameLevelCreateModalComponent,
  GameLevelListModalComponent,
  GameElementCreateModalComponent,
  GameElementListModalComponent,
  GameSolutionCreateModalComponent,
  GameSolutionListModalComponent,
  GameSelectionCreateModalComponent,
  GameSelectionListModalComponent,
  BlockedUserModalComponent,
  GameControlFlowCreateModalComponent,
  UserContentListModalComponent,
  UserContentCreateModalComponent
];

const COMPONENTS = [
  SpinnerComponent,
  CheckComponent,
  AuthorComponent,
  UsernameComponent,
  ExhibitionTypeComponent,
  TourTypeComponent,
  GroupTypeComponent,
  StationTypeComponent,
  ContentTypeComponent,
  FileTypeComponent,
  UserTypeComponent,
  MapTypeComponent,
  ChannelTypeComponent,
  FormErrorsComponent,
  FormControlComponent,
  FormControlsMultiLangComponent,
  SidebarComponent,
  ImageEditComponent,
  TableSearchComponent,
  FileUploadComponent,
  FileMetadataComponent,
  BlockedUserComponent,
  GameParameterListComponent,
  GameTextListComponent,
  GameAssetListComponent,
  GameScoringSystemComponent
];

const BUTTONS = [
  BtnBaseComponent,
  BtnCreateComponent,
  BtnAddComponent,
  BtnDeleteComponent,
  BtnSaveComponent,
  BtnReplaceComponent,
  BtnReloadComponent,
  BtnLocalesComponent,
  BtnBooleanComponent,
  BtnBooleanMultiLangComponent,
  BtnPlayComponent,
  BtnDownloadComponent,
  BtnEditComponent
];

const TABLES = [
  ExhibitionTableComponent,
  TourTableComponent,
  GroupTableComponent,
  StationTableComponent,
  ContentTableComponent,
  FileTableComponent,
  ChannelTableComponent,
  MapTableComponent,
  EndpointTableComponent,
  UserTableComponent,
  OrganizationTableComponent,
  RoleTableComponent,
  PermissionTableComponent,
  HtmlTableComponent,
  HtmlContentTableComponent,
  GameTableComponent,
  GameLevelTableComponent,
  GameResultTableComponent,
  GameElementTableComponent,
  GameSolutionTableComponent,
  GameSelectionTableComponent,
  GameControlFlowTableComponent,
  UserContentTableComponent
];

const DIRECTIVES = [
  BtnSpinnerDirective,
  ExtendTableDirective
];

const PIPES = [
  KeysPipe,
  DurationPipe,
  FileSizePipe,
  StationTypePipe,
  StationTypeFilterPipe,
  ContentTypePipe,
  ContentTypeFilterPipe,
  TourTypePipe,
  TourTypeFilterPipe,
  FileTypePipe,
  FilterPipe,
  FilterThumbnailPipe,
  EnumToArrayPipe,
  ValuesPipe,
  PluckPipe
];

const SERVICES = [
  ExhibitionService,
  TourService,
  GroupService,
  StationService,
  ContentService,
  FileService,
  MapService,
  GameService,
  GameLevelService,
  GameResultService,
  GameElementService,
  GameSolutionService,
  GameSelectionService,
  CdnService,
  ChannelService,
  HotspotService,
  GoogleMapsService,
  OrganizationService,
  RoleService,
  HtmlService,
  ServerConfigService,
  SyncServerConfigService,
  CommunityServerConfigService
];

@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaFormsModule,
    SharedRoutingModule,
    PrimeSharedModule,
    FileUploadModule,
    SliderModule,
    TableModule,
    TranslateModule,
    AngularDraggableModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaFormsModule,

    ...MODALS,
    ...COMPONENTS,
    ...BUTTONS,
    ...TABLES,
    ...DIRECTIVES,
    ...PIPES,
    AreaMarkerComponent
  ],
  declarations: [
    ...MODALS,
    ...COMPONENTS,
    ...BUTTONS,
    ...TABLES,
    ...DIRECTIVES,
    ...PIPES,
    AreaMarkerComponent,
  ],
  providers: [
    ...SERVICES
  ],
  entryComponents: [
    ...MODALS
  ],
})
export class SharedModule {
}
