import { Component, DoCheck, EventEmitter, Input, Output } from '@angular/core';

import { BaseFilesModel } from '../../models/base-files.model';
import { File } from '../../models/file.model';
import { Status } from '../../models/status.model';
import { PermissionsComponent } from "../permissions/permissions.component";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "../../../core/services/user.service";
import { ConfigurationService } from "../../services/configuration.service";
import { FileTypeInterface } from "../../interfaces/file-type.interface";
import { ContentTypeEnum } from "../../enums/content-type.enum";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html'
})
export class FileUploadComponent extends PermissionsComponent implements DoCheck {
  @Input() model: BaseFilesModel;
  @Input() status: Status;
  @Input() rows: number;
  @Input() selection: File[];
  @Input() fileTypes: FileTypeInterface[] = [];
  @Input() uploadLimit: number = -1;
  @Input() contentType: number;

  @Output() onUpload = new EventEmitter();
  @Output() selectionChange = new EventEmitter<File[]>();
  @Output() fileDeleted = new EventEmitter();
  @Output() fileChanged = new EventEmitter();

  pillsLimit: number;
  ft: FileTypeInterface[];

  constructor(
    protected route: ActivatedRoute,
    protected userService: UserService,
    private config: ConfigurationService
  ) {
    super(route, userService);
    this.pillsLimit = this.config.getTabPillsLimit();
  }

  uploadHandler(files: object, form, fileType: number, attribute?: string) {
    this.onUpload.emit({files: files, fileType: fileType, attribute: attribute});
    form.clear();
  }

  ngOnInit() {
    super.ngOnInit();
    // workarround to avoid permanent reloading
    this.ft = this.fileTypes;
  }

  ngDoCheck() {
    this.selectionChange.emit(this.selection);
  }

  isUploadDisabled(type: FileTypeInterface): boolean {
    if (undefined == type.limit) {
      return false;
    }

    const fileList = this.model.files.filter(f => f.type === type.type);

    return !!fileList && fileList.length >= type.limit;
  }

  isAudioSlide(): boolean {
    return  ContentTypeEnum.AUDIO_SLIDE === this.contentType;
  }
}
