import { Injectable } from '@angular/core';
import Utils from '../../../app/shared/utils/utils';
import { UserContentTypeConfigOptions } from '../../../config/user-content-type-config.interface';
import { UserContentStatusType } from '../enums/user-content-status-type.enum';
import { FormControlOptions } from '../interfaces/form-control-options.interface';
import { FormControlSelectOptions } from '../interfaces/form-control-select-options.interface';
import { ConfigurationService } from '../services/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class UserContentTypeUtils {

  private userContentTypeValues;

  constructor(
    private readonly config: ConfigurationService
  ) {
  }

  private getUserContentTypes(): UserContentTypeConfigOptions {
    if (this.config.isLoadingConfig()) {
      return undefined;
    }

    const config = this.config.getUserContentTypeConfig();
    if (config) {
      return Utils.clone(config);
    }

    return undefined;
  }

  getTypeConfig(type: number) {
    return this.getUserContentTypes()[ type ];
  }

  getLabel(userContentType: string | number) {
    if (this.getUserContentTypes() && this.isValid(userContentType)) {
      return this.getUserContentTypes()[ Number(userContentType) ].label;
    }

    return '';
  }

  hasField(userContentType: string | number, field: string): boolean {
    if (this.getUserContentTypes()) {
      return Object.keys(this.getUserContentTypes()[ Number(userContentType) ].fields).indexOf(field) >= 0;
    }

    return false;
  }

  getFieldOptions(userContentType: string | number, field: string, defaultOptions: FormControlOptions<any>): FormControlOptions<any> {
    if (this.getUserContentTypes()) {
      const options = this.hasField(userContentType, field) ? Utils.clone(this.getUserContentTypes()[ Number(userContentType) ].fields[ field ]) : {};

      for (const optionName in defaultOptions) {
        if (Utils.isDefined(defaultOptions[ optionName ])) {
          options[ optionName ] = Utils.isDefined(options[ optionName ]) ? options[ optionName ] : defaultOptions[ optionName ];
        }
      }

      options.key = defaultOptions.key || field;
      options.hidden = Utils.isDefined(defaultOptions.hidden) ? defaultOptions.hidden : !this.hasField(userContentType, field);

      return options;
    }

    return {};
  }

  getUserContentTypeValues(): number[] {
    if (this.getUserContentTypes()) {
      this.userContentTypeValues = this.userContentTypeValues || Object.keys(this.getUserContentTypes())
        .filter(Number)
        .map(userContentType => Number(userContentType));

      return Utils.clone(this.userContentTypeValues);
    }

    return [];
  }

  getDefaultType(): number {
    return this.getUserContentTypeValues()[ 0 ];
  }

  isValid(userContentType: string | number): boolean {
    return !isNaN(Number(userContentType)) && this.getUserContentTypeValues().indexOf(Number(userContentType)) >= 0;
  }

  getTypeSelectOptions(): FormControlSelectOptions<number> {
    return this.getUserContentTypeValues()
      .map(userContentType => {
        return {
          value: userContentType,
          label: this.getLabel(userContentType)
        };
      });
  }

  getStatusSelectOptions(): FormControlSelectOptions<string> {
    return Object.keys(UserContentStatusType)
      .map(key => ({ value: key, label: key }));
  }

  getModules(userContentType: string | number): string[] {
    if (this.getUserContentTypes()) {
      return Utils.clone(this.getUserContentTypes()[ Number(userContentType) ].modules);
    }

    return [];
  }

  isModuleEnabled(userContentType: string | number, module: string): boolean {
    return this.getModules(userContentType).indexOf(module) >= 0;
  }
}
