import { GameScoringSystemCategory } from '../../game/enum/game-scoring-system-category.enum';
import { FormControlSelect } from './form-control/form-control-select.model';
import { GameAssetDictionaryEntry } from './game-asset-dictionary-entry';
import { GameMultiLangDictionaryEntry } from './game-multi-lang-dictionary-entry';
import { GameParameter } from './game-parameter';
import { MultiLangString } from './multi-lang-string.model';

export class GameScoringSystem {

  constructor(
    public id: string,
    public title: MultiLangString,
    public description: MultiLangString,
    public type: FormControlSelect,
    public category: GameScoringSystemCategory,
    public parameters: GameParameter[],
    public textDictionary: GameMultiLangDictionaryEntry[],
    public assetDictionary: GameAssetDictionaryEntry[]
  ) {
  }

  toJSON(): object {
    const json = {};

    json['id'] = this.id;
    json[ 'title' ] = this.title.toJSON();
    json[ 'description' ] = this.description.toJSON();
    json['type'] = this.type.value;
    json[ 'category' ] = this.category;
    json['parameters'] = this.parameters ? this.parameters.map(p => p.toJSON()) : [];
    json['textDictionary'] = this.textDictionary ? this.textDictionary.map(d => d.toJSON()) : [];
    json['assetDictionary'] = this.assetDictionary ? this.assetDictionary.map(d => d.toJSON()) : [];

    return json;
  }
}
