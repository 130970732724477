import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Group } from '../../../../../app/shared/models/group.model';
import { Status } from '../../../../../app/shared/models/status.model';


@Component({
  selector: 'app-group-list-modal',
  templateUrl: './group-list-modal.component.html'
})
export class GroupListModalComponent {

  @Input() groups: Group[];
  @Input() status: Status;

  selection: Group[] = [];

  constructor(
    public activeModal: NgbActiveModal
  ) { }
}
