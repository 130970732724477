import { ModelHelper } from "./model.helper.interface";
import Utils from "../../utils/utils";
import { Role } from "../role.model";
import { Permission } from "../permission.model";
import * as moment from "moment";
import { FormControlString } from "../form-control/form-control-string.model";
import { Channel } from "../channel.model";

export class RoleModelHelper implements ModelHelper<Role> {

  constructor() {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id);
  }

  emptyModel(defaultChannels: Channel[], tenantId?: string): Role {
    return new Role(
      '',
      new FormControlString({
        key: 'name',
        label: 'label.config.role.field.title',
        apiParam: 'name',
        required: true,
        value: ''
      }),
      [],
      tenantId,
      undefined
    );
  }

  fromJSON(json: any, skipCheck = false): Role {
// integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    return new Role(
      json._id,
      new FormControlString({
        key: 'name',
        label: 'label.config.role.field.title',
        apiParam: 'name',
        required: true,
        value: json.name || ''
      }),
      json.permissions ? json.permissions.map(p => Permission.fromJSON(p)) : [],
      json.tenantId,
      json.blocked_users,
      json.version,
      json.created_on ? moment(json.created_on).toDate() : undefined,
      json.creatorName,
      json.lastModified ? moment(json.lastModified).toDate() : undefined,
      json.modifiedBy,
    );
  }
}
