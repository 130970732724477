import Utils from "../utils/utils";
import { FormControlSelect } from "./form-control/form-control-select.model";
import { FormControlString } from "./form-control/form-control-string.model";

export class AwsModel {

  constructor(
    public bucket: FormControlString = new FormControlString({
      key: 'bucket',
      label: 'label.config.aws.field.bucket',
      required: true
    }),
    public productionBucket: FormControlString = new FormControlString({
      key: 'productionBucket',
      label: 'label.config.aws.field.production-bucket',
      required: true
    }),
    public accessKeyId: FormControlString = new FormControlString({
      key: 'accessKeyId',
      label: 'label.config.aws.field.access-key-id',
      required: true
    }),
    public secretAccessKey: FormControlString = new FormControlString({
      key: 'secretAccessKey',
      label: 'label.config.aws.field.access-key-secret',
      required: true
    }),

    public destinationFolder: FormControlString = new FormControlString({
      key: 'destinationFolder',
      label: 'label.config.aws.field.folder',
      required: false
    }),
    public region: FormControlSelect = new FormControlSelect({
      key: 'region',
      label: 'label.config.aws.field.region',
      options: ['eu-central-1', 'eu-central-2'].map(entry => {
        return {label: entry, value: entry}
      })
    })) {
  }

  static checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id);
  }

  static fromJSON(json): AwsModel {
    return new AwsModel(
      new FormControlString({
        key: 'bucket',
        label: 'label.config.aws.field.bucket',
        required: true,
        value: json.bucket
      }),
      new FormControlString({
        key: 'productionBucket',
        label: 'label.config.aws.field.production-bucket',
        required: true,
        value: json.productionBucket
      }),
      new FormControlString({
        key: 'accessKeyId',
        label: 'label.config.aws.field.access-key-id',
        required: true,
        value: json.accessKeyId
      }),
      new FormControlString({
        key: 'secretAccessKey',
        label: 'label.config.aws.field.access-key-secret',
        required: true,
        value: json.secretAccessKey
      }),
      new FormControlString({
        key: 'destinationFolder',
        label: 'label.config.aws.field.folder',
        required: false,
        value: json.destinationFolder
      }),
      new FormControlSelect({
        key: 'region',
        label: 'label.config.aws.field.region',
        options: ['eu-central-1', 'eu-central-2'].map(entry => {
          return {label: entry, value: entry}
        }),
        value: json.region
      })
    );
  }

  toJSON() {
    const json = {
      bucket: this.bucket.value,
      productionBucket: this.productionBucket.value,
      accessKeyId: this.accessKeyId.value,
      secretAccessKey: this.secretAccessKey.value,
      destinationFolder: this.destinationFolder.value,
      region: this.region.value
    };

    return json;
  }

}
