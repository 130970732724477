import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable } from 'rxjs';

import { UserService } from '../../core/services/user.service';

import { Content } from '../models/content.model';
import { Status } from '../models/status.model';

import { MessageModalComponent } from '../../core/components/message-modal/message-modal.component';
import { ContentCreateModalComponent } from '../components/modals/content-create-modal/content-create-modal.component';
import { ContentListModalComponent } from '../components/modals/content-list-modal/content-list-modal.component';

import { ApiBaseService } from '../../core/services/api-base.service';
import { FileService } from './file.service';

import { CreateOptions } from '../interfaces/create-options.interface';
import Utils from "../utils/utils";
import { Station } from "../models/station.model";
import { ModelMapperService } from "./mapper/model-mapper.service";
import { File } from "../models/file.model";
import { FileType } from "../enums/file-type.enum";
import { FormControlNumber } from "../models/form-control/form-control-number.model";
import { ContentTypeEnum } from "../enums/content-type.enum";
import { ChannelService } from "./channel.service";


@Injectable()
export class ContentService extends ApiBaseService<Content> {

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected userService: UserService,
    protected fileService: FileService,
    protected modalService: NgbModal,
    private channelService: ChannelService,
    private modelMapper: ModelMapperService
  ) {
    super(Content, 'contents', http, router, userService, fileService);
  }

  select(exclude: Content[] = [], contentTypes?: number[]): Promise<Content[]> {
    const modalRef = this.modalService.open(ContentListModalComponent, {size: 'lg'});
    modalRef.componentInstance.status = new Status();

    this.getAll(modalRef.componentInstance.status)
      .subscribe((contents: Content[]) => {
        try {
          modalRef.componentInstance.contents = contents
            .filter(content => exclude.findIndex(myContent => content.id === myContent.id) < 0);

          if (contentTypes && contentTypes.length > 0) {
            modalRef.componentInstance.contentTypes = contentTypes;
          }
        } catch (e) {
        }
      });

    return modalRef.result;
  }

  create(options: CreateOptions<Content>, contentTypes?: number[]): Promise<Content> {
    return super.create(Object.assign(options, {
      component: ContentCreateModalComponent,
      componentOptions: {
        content: this.emptyModel(),
        contentTypes
      }
    }));
  }

  getStations(contentId: string, status?: Status): Observable<Station[]> {
    status = status || new Status();
    status.setLoading();

    const url = `${this.url}/${contentId}/stations`;
    const responseKey = '_embedded.stations';

    return this.http
      .get(url).pipe(
        map(response => {
          if (Utils.deepAccessUsingString(response, responseKey) && Utils.deepAccessUsingString(response, responseKey).length > 0) {
            status.setSuccess();
            return Utils.deepAccessUsingString(response, responseKey)
              .filter(object => this.modelMapper.stationCheckJSON(object))
              .map(object => this.modelMapper.stationFromJSON(object));
          } else {
            status.setNoResults();
          }
        }),
        catchError(err => this.handleError(err, status)),);
  }

  /**
   * Add files to a specific entry
   * copy images for audio slideshows
   */
  addFiles(model: Content, files: any, fileType: number, attribute?: string, status?: Status, contentType?: number, secured?: boolean): Observable<File[]> {
      return super.addFiles(model, files, fileType, attribute, status, contentType, secured).pipe(
        map(files => {
          if (files && ContentTypeEnum.AUDIO_SLIDE === model.type.value) {
            const audioList = this.filterFilesByType(model.files, FileType.AUDIO);
            let existsAudios = audioList && Array.isArray(audioList) && audioList.length > 0;

            // get unique languages
            const languages = existsAudios ? audioList.map(f => f.locale.value)
              .filter((v, i, a) => a.indexOf(v) === i) : [];
            let defaultLang = existsAudios ? languages[0] : undefined;

            const imageList = this.filterFilesByType(model.files, FileType.IMAGE);
            const existsImages = imageList && Array.isArray(imageList) && imageList.length > 0;

            files.forEach(file => {
              if (FileType.IMAGE === file.type) {
                if(!existsAudios) {
                  // init fieldgen3
                  file.fieldgen3 = new FormControlNumber({
                    value: 0,
                    label: 'label.file.fieldgen3'
                  });
                } else {
                  // copy image for all languages
                  languages.forEach(lang => {
                    if (lang != file.locale.value) {
                      const img = this.modelMapper.fileEmptyModel();
                      Object.assign(img, file);
                      img.locale = this.modelMapper.formControlLanguageSelect(lang);
                      img.fieldgen3 = new FormControlNumber({
                        value: 0,
                        label: 'label.file.fieldgen3'
                      });
                      model.files.push(img);
                    } else {
                      file.fieldgen3 = new FormControlNumber({
                        value: 0,
                        label: 'label.file.fieldgen3'
                      });
                    }
                  });
                }
              } else if (FileType.AUDIO === file.type) {
                if (existsImages) {
                  // check if images are added to that language
                  const index = imageList.findIndex(img => img.locale.value == file.locale.value);
                  if(index < 0) {
                    // no images in that language
                    if(!existsAudios) {
                      // set all existing images to that language
                      imageList.forEach(img => img.locale.value = file.locale.value);
                      existsAudios = true;
                      defaultLang = defaultLang || file.locale.value;
                    } else {
                      // copy all images
                      const list = imageList.filter(img => defaultLang == img.locale.value);
                      list.forEach(img => {
                        const newImg = this.modelMapper.fileEmptyModel();
                        Object.assign(newImg, img);
                        newImg.locale = this.modelMapper.formControlLanguageSelect(file.locale.value);
                        newImg.fieldgen3 = new FormControlNumber({
                          value: 0,
                          label: 'label.file.fieldgen3'
                        });
                        model.files.push(newImg);
                      });
                    }
                  }
                }
              }
            });
          }

          return files;
        })
      )
  }

  delete(id: string, status?: Status): Promise<any> {
    const modalRef = this.modalService.open(MessageModalComponent);
    modalRef.componentInstance.confirm = true;
    modalRef.componentInstance.message = 'Are you sure that you want to delete the content?';

    return modalRef.result.then(() => super.delete(id, status), () => Promise.reject('Dimissed'));
  }

  checkJSON(json: any): boolean {
    return this.modelMapper.contentCheckJSON(json);
  }

  emptyModel(): Content {
    console.log('ContentService#emptyModel')
    return this.modelMapper.contentEmptyModel(this.channelService.getDefaultChannels());
  }

  fromJSON(json: any, skipCheck?: boolean): Content {
    return this.modelMapper.contentFromJSON(json, skipCheck);
  }

  private filterFilesByType(files: File[], type: FileType): File[] {
    if (files && Array.isArray(files) && files.length > 0) {
      return files.filter(file => type === file.type);
    }

    return [];
  }
}
