import { GameControlFlowCategory } from '../../game/enum/game-control-flow-category.enum';
import { BaseModel } from './base.model';
import { FormControlSelect } from './form-control/form-control-select.model';
import { GameAssetDictionaryEntry } from './game-asset-dictionary-entry';
import { GameMultiLangDictionaryEntry } from './game-multi-lang-dictionary-entry';
import { GameParameter } from './game-parameter';

export class GameControlFlow extends BaseModel {
  constructor(
    public id: string,
    public type: FormControlSelect,
    public category: GameControlFlowCategory,
    public trigger: FormControlSelect,
    public action: FormControlSelect,
    public parameters: GameParameter[],
    public textDictionary: GameMultiLangDictionaryEntry[],
    public assetDictionary: GameAssetDictionaryEntry[]
  ) {
    super(id);
  }

  toJSON(): object {
    const json = {};

    json[ 'id' ] = this.id;
    json[ 'type' ] = this.type.value;
    json[ 'category' ] = this.category;
    json[ 'trigger' ] = this.trigger.value;
    json[ 'action' ] = this.action.value;
    json[ 'parameters' ] = this.parameters.map(p => p.toJSON());
    json[ 'textDictionary' ] = this.textDictionary.map(d => d.toJSON());
    json[ 'assetDictionary' ] = this.assetDictionary.map(d => d.toJSON());

    return json;
  }
}
