import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'filter',
  pure: false
})

export class FilterPipe implements PipeTransform {
  transform(items: any, callback: (item: any) => boolean): any {
    if (!items || !callback) {
        return items;
    }

    const filterItems = [];
    const keys = Object.keys(items);

    for (let i = 0; i < keys.length; i++) {
      if (callback(items[keys[i]])) {
        filterItems.push(items[keys[i]]);
      }
    }

    return filterItems;
  }
}
