import { Injectable } from '@angular/core';
import clone from 'lodash.clonedeep';
import { LocalesConfigOptions } from '../../../../config/locales-config.interface';
import { ContentTypeEnum } from '../../enums/content-type.enum';
import { ConfigurationService } from '../../services/configuration.service';
import { FormControlBoolean } from '../form-control/form-control-boolean.model';
import { FormControlNumber } from '../form-control/form-control-number.model';
import { FormControlString } from '../form-control/form-control-string.model';
import { GameAssetDictionaryEntry } from '../game-asset-dictionary-entry';
import { GameMultiLangDictionaryEntry } from '../game-multi-lang-dictionary-entry';
import { GameParameter } from '../game-parameter';
import { MultiLangString } from '../multi-lang-string.model';
import { FileModelHelper } from './file-model.helper';
import { MultiLangStringHelper } from './multi-lang-string.helper';

@Injectable({ providedIn: 'root' })
export class GameUtilHelper {

  constructor(
    private readonly multiLangStringHelper: MultiLangStringHelper,
    private readonly fileHelper: FileModelHelper,
    private readonly config: ConfigurationService
  ) {
  }

  mapParameters(jsonParams: any[], config: any): GameParameter[] {
    const configParameters = (config && config.parameters) || {};
    const unionDict = this.createUnionDict(jsonParams, configParameters);

    return Object.keys(unionDict)
      .map(key => this.createFormControlsForParameters(unionDict[key], key));
  }

  mapMultiLangDictEntries(jsonEntries: any[], config: any): GameMultiLangDictionaryEntry[] {
    const configTextDict = (config && config.textDictionary) || {};
    const unionDict = this.createUnionDict(jsonEntries, configTextDict);

    return Object.keys(unionDict)
      .map(key => this.multiLangDictEntryFromJSON(unionDict[key], key));
  }

  multiLangDictEntryFromJSON(jsonEntry: any, key: string): GameMultiLangDictionaryEntry {
    const { id, invalid, version, created_on, creatorname, lastmodified, modifiedby, locales } = jsonEntry;

    const options = { key, label: key };
    const presets = locales ? Object.keys(locales) : [];
    const multiLangString: MultiLangString = this.multiLangStringHelper.fromJSON(locales, key, options, presets);

    return new GameMultiLangDictionaryEntry(
      id,
      key,
      multiLangString,
      invalid,
      version,
      created_on,
      creatorname,
      lastmodified,
      modifiedby
    );
  }

  mapAssetLangDictEntries(jsonEntries: any[], config: any): GameAssetDictionaryEntry[] {
    const configAssetDict = (config && config.assetDictionary) || {};
    const unionDict = this.createUnionDict(jsonEntries, configAssetDict);

    return Object.keys(unionDict)
      .map(key => this.assetDictEntryFromJSON(unionDict[key], key));
  }

  assetDictEntryFromJSON(jsonEntry: any, key: string): GameAssetDictionaryEntry {
    const { id, type, invalid, version, created_on, creatorname, lastmodified, modifiedby } = jsonEntry;

    const files = jsonEntry.files ? jsonEntry.files
      .filter(file => this.fileHelper.checkJSON(file))
      .map(file => {
        const isAudioSlideImage = type === ContentTypeEnum.AUDIO_SLIDE && file.attribute === 'image';

        return this.fileHelper.fromJSON(file, type, isAudioSlideImage);
      }) : [];

    return new GameAssetDictionaryEntry(
      id,
      key,
      files,
      type,
      invalid,
      version,
      created_on,
      creatorname,
      lastmodified,
      modifiedby
    );
  }

  createDictionary(dict: object, key: 'parameters' | 'textDictionary' | 'assetDictionary'): any[] {

    if (!dict || !dict[ key ]) {
      return [];
    }

    switch (key) {
      case 'parameters':
        return this.createParameters(dict[ key ]) as GameParameter[];
      case 'textDictionary':
        return this.createTextDictionary(dict[ key ], this.config.getLocalesConfig()) as GameMultiLangDictionaryEntry[];
      case 'assetDictionary':
        return this.createAssetDictionary(dict[ key ]) as GameAssetDictionaryEntry[];
      default:
    }
  }

  createParameters(parameters: object): GameParameter[] {
    return Object.keys(parameters)
      .map(key => this.createFormControlsForParameters(parameters[ key ], key));
  }

  createFormControlsForParameters(parameter: any, key: string) {
    const { type, value, invalid } = parameter;
    const label = key;

    switch (type) {
      case 'string':
        return new GameParameter(new FormControlString({ key, label, value }), type, invalid);
      case 'boolean':
        return new GameParameter(new FormControlBoolean({ key, label, value }), type, invalid);
      case 'number':
        return new GameParameter(new FormControlNumber({
            key,
            label,
            value: isNaN(value) ? null : Number(value)
          }),
          type,
          invalid
        );
      default:
        return new GameParameter(new FormControlString({ key, label, value }), type, invalid, true);
    }
  }

  createTextDictionary(textDictionary: any, config: LocalesConfigOptions) {
    return Object.keys(textDictionary)
      .map(key => {

        const options = { key, label: key };
        const multiLangString: MultiLangString = this.multiLangStringHelper.fromJSON({}, key, options, config.available);

        return new GameMultiLangDictionaryEntry('', key, multiLangString);
      });
  }

  createAssetDictionary(assetDictionary: any) {
    return Object.keys(assetDictionary)
      .map(key => {
        const { type } = assetDictionary[ key ];

        return new GameAssetDictionaryEntry('', key, [], type);
      });
  }

  createUnionDict(jsonValues: any[], configDict: { [ index: string ]: object }): { [ index: string ]: object } {
    return jsonValues.reduce((dict, jsonParam) => {
      const key = jsonParam.key;
      const paramInConfig: boolean = !!dict[ key ];

      dict[ key ] = { ...dict[ key ], ...jsonParam, invalid: !paramInConfig };

      return dict;

    }, clone(configDict, true));

  }
}
