import { Component, Input, Output, EventEmitter, OnInit, DoCheck } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { File } from '../../../models/file.model';
import { BaseFilesModel } from '../../../models/base-files.model';
import { Status } from '../../../models/status.model';
import { FileType } from '../../../enums/file-type.enum';
import { FileTypeInterface } from "../../../interfaces/file-type.interface";


@Component({
  selector: 'app-image-edit-modal',
  templateUrl: './image-edit-modal.component.html'
})
export class ImageEditModalComponent implements OnInit, DoCheck {

  @Input() model: BaseFilesModel;
  @Output() onUpload = new EventEmitter();

  selection: File[];
  status = new Status();

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  createFile(event): void {
    this.onUpload.emit({
      files: event.files['files'],
      fileType: event.fileType || FileType.IMAGE,
      attribute: event.attribute,
      status: this.status
    });
  }

  onSlideChange(event) {
    for (const file of this.model.files) {
      if (event.current === file.getAltId()) {
        this.selection = [file];
      }
    }
  }

  getUploadLimit(): number {
    return 5242880;
  }

  getFileTypes(): FileTypeInterface[] {
    return [{
      type: FileType.IMAGE,
      attribute: {label: 'label.content.image', value: 'image'}
    } as FileTypeInterface]
  }

  ngOnInit() {
    this.selection = this.model.files ? [this.model.files[0]] : undefined;
  }

  ngDoCheck() {
    this.selection = this.selection || (this.model.files ?  [this.model.files[0]] : undefined);
  }
}
