import { MultiLangString } from "./multi-lang-string.model";
import { HtmlField } from "./html-field.model";
import { HtmlFieldType } from "../enums/html-field-type.enum";

export class HtmlTextField extends HtmlField {

  constructor(
    public id: string,
    public fieldName: string,
    public content: MultiLangString,
    public version?,
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string
  ) {
    super(id, fieldName, content, HtmlFieldType.TEXT, version, createdOn, creatorName, lastModified, modifiedBy);
  }
}
