import { Pipe, PipeTransform } from '@angular/core';

import { Station } from '../../../app/shared/models/station.model';
import { StationTypeUtils } from "../utils/station-type-utils";


@Pipe({
  name: 'stationTypeFilter'
})
export class StationTypeFilterPipe implements PipeTransform {

  constructor(private stationTypesUtils: StationTypeUtils){
  }

  transform(stations: Station[], stationType: any): any {
    return stations ? stations.filter(station => {
      return station ? Number(station.type.value) === (isNaN(stationType) ?
      this.stationTypesUtils.getLabel(stationType) : stationType) : false;
    }) : [];
  }
}
