import { Component, Input } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { TableBaseComponent } from '../../../../../app/shared/components/tables/table-base/table-base.component';

import { Group } from '../../../../../app/shared/models/group.model';
import { ConfigurationService } from "../../../services/configuration.service";
import { UserService } from "../../../../core/services/user.service";
import { PermissionEnum } from "../../../enums/permission.enum";


@Component({
  selector: 'app-group-table',
  templateUrl: './group-table.component.html'
})
export class GroupTableComponent extends TableBaseComponent<Group> {

  @Input() detailLink = '/groups';

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected config: ConfigurationService,
    protected userService: UserService
  ) {
    super(router, route, config, userService);
  }

  getGlobalFilterFields() {
    this.globalFilters = this.globalFilters || [{
      label: 'ID',
      field: 'id',
      active: this.showId
    }, {
      label: 'Title',
      field: 'title.' + this.data[0].title.defaultLocale + '.value',
      active: true
    }];

    return this.globalFilters
      .filter(filter => filter.active)
      .map(filter => filter.field);
  }

  goToDetailView(group: Group): void {
    this.router.navigate(
      this.hasPermission(PermissionEnum.STATION_READ) ? [this.detailLink, group.id, 'stations'] : [this.detailLink, group.id],
      {relativeTo: this.route}
    );
  }
}
