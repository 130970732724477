import { FormControlString } from './form-control/form-control-string.model';
import { BaseModel, VersionModel } from './base.model';

export class Endpoint extends BaseModel {

  constructor(
    public readonly id: string,
    public readonly name: FormControlString,
    public readonly buildNumber: number,
    public readonly building: boolean,
    public readonly password: string,
    public blockedUsers: string[],
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string,
    public version?: number,
    public versions?: VersionModel[],
    public baseId?: string
  ) {
    super(id, blockedUsers, createdOn, creatorName, lastModified, modifiedBy);
  }

}
