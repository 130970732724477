export enum PermissionEnum {
  // EXHIBITION
  EXHIBITION_CREATE = 'nous.cms.exhibition.create',
  EXHIBITION_READ = 'nous.cms.exhibition.read',
  EXHIBITION_UPDATE = 'nous.cms.exhibition.update',
  EXHIBITION_DELETE = 'nous.cms.exhibition.delete',
  EXHIBITION_VISIBILITY = 'nous.cms.exhibition.change.visibility',

  // TOUR
  TOUR_CREATE = 'nous.cms.tour.create',
  TOUR_READ = 'nous.cms.tour.read',
  TOUR_UPDATE = 'nous.cms.tour.update',
  TOUR_DELETE = 'nous.cms.tour.delete',
  TOUR_VISIBILITY = 'nous.cms.tour.change.visibility',

  // GROUP
  GROUP_CREATE = 'nous.cms.group.create',
  GROUP_READ = 'nous.cms.group.read',
  GROUP_UPDATE = 'nous.cms.group.update',
  GROUP_DELETE = 'nous.cms.group.delete',
  GROUP_VISIBILITY = 'nous.cms.group.change.visibility',

  // BEACON
  BEACON_CREATE = 'nous.cms.beacon.create',
  BEACON_READ = 'nous.cms.beacon.read',
  BEACON_UPDATE = 'nous.cms.beacon.update',
  BEACON_DELETE = 'nous.cms.beacon.delete',
  BEACON_VISIBILITY = 'nous.cms.beacon.change.visibility',

  // STATION
  STATION_CREATE = 'nous.cms.station.create',
  STATION_READ = 'nous.cms.station.read',
  STATION_UPDATE = 'nous.cms.station.update',
  STATION_DELETE = 'nous.cms.station.delete',
  STATION_VISIBILITY = 'nous.cms.station.change.visibility',

  // CONTENT
  CONTENT_CREATE = 'nous.cms.content.create',
  CONTENT_READ = 'nous.cms.content.read',
  CONTENT_UPDATE = 'nous.cms.content.update',
  CONTENT_DELETE = 'nous.cms.content.delete',
  CONTENT_VISIBILITY = 'nous.cms.content.change.visibility',

  // CHANNEL
  CHANNEL_CREATE = 'nous.cms.channel.create',
  CHANNEL_READ = 'nous.cms.channel.read',
  CHANNEL_UPDATE = 'nous.cms.channel.update',
  CHANNEL_DELETE = 'nous.cms.channel.delete',
  CHANNEL_VISIBILITY = 'nous.cms.channel.change.visibility',

  // MAP
  MAP_CREATE = 'nous.cms.map.create',
  MAP_READ = 'nous.cms.map.read',
  MAP_UPDATE = 'nous.cms.map.update',
  MAP_DELETE = 'nous.cms.map.delete',
  MAP_VISIBILITY = 'nous.cms.map.change.visibility',

  // CDN
  CDN_CREATE = 'nous.cms.cdn.create',
  CDN_READ = 'nous.cms.cdn.read',
  CDN_UPDATE = 'nous.cms.cdn.update',
  CDN_DELETE = 'nous.cms.cdn.delete',

  // HOTSPOT
  HOTSPOT_CREATE = 'nous.cms.hotspot.create',
  HOTSPOT_READ = 'nous.cms.hotspot.read',
  HOTSPOT_UPDATE = 'nous.cms.hotspot.update',
  HOTSPOT_DELETE = 'nous.cms.hotspot.delete',
  HOTSPOT_VISIBILITY = 'nous.cms.hotspot.change.visibility',

  // HTML - Module
  HTML_READ = 'nous.cms.html.read',

  // HTML GENERAL INFORMATION
  HTML_GENERAL_INFORMATION_CREATE = 'nous.cms.html.general.information.create',
  HTML_GENERAL_INFORMATION_READ = 'nous.cms.html.general.information.read',
  HTML_GENERAL_INFORMATION_UPDATE = 'nous.cms.html.general.information.update',
  HTML_GENERAL_INFORMATION_DELETE = 'nous.cms.html.general.information.delete',

  // HTML PAGE
  HTML_PAGE_CREATE = 'nous.cms.html.page.create',
  HTML_PAGE_READ = 'nous.cms.html.page.read',
  HTML_PAGE_UPDATE = 'nous.cms.html.page.update',
  HTML_PAGE_DELETE = 'nous.cms.html.page.delete',

  HTML_PAGE_GENERAL_SETTINGS_CREATE = 'nous.cms.html.page.general.settings.create',
  HTML_PAGE_GENERAL_SETTINGS_READ = 'nous.cms.html.page.general.settings.read',
  HTML_PAGE_GENERAL_SETTINGS_UPDATE = 'nous.cms.html.page.general.settings.update',
  HTML_PAGE_GENERAL_SETTINGS_DELETE = 'nous.cms.html.page.general.settings.delete',

  // FILE
  FILE_CREATE = 'nous.cms.file.create',
  FILE_READ = 'nous.cms.file.read',
  FILE_UPDATE = 'nous.cms.file.update',
  FILE_DELETE = 'nous.cms.file.delete',

  // SYNC
  SYNC_CREATE = 'nous.cms.sync.create',
  SYNC_READ = 'nous.cms.sync.read',
  SYNC_UPDATE = 'nous.cms.sync.update',
  SYNC_DELETE = 'nous.cms.sync.delete',

  // USER
  USER_CREATE = 'nous.auth.user.create',
  USER_READ = 'nous.auth.user.read',
  USER_UPDATE = 'nous.auth.user.update',
  USER_DELETE = 'nous.auth.user.delete',

  // ROLE
  ROLE_CREATE = 'nous.auth.role.create',
  ROLE_READ = 'nous.auth.role.read',
  ROLE_UPDATE = 'nous.auth.role.update',
  ROLE_DELETE = 'nous.auth.role.delete',

  // ORGANIZATION
  ORGANIZATION_CREATE = 'nous.auth.organization.create',
  ORGANIZATION_READ = 'nous.auth.organization.read',
  ORGANIZATION_UPDATE = 'nous.auth.organization.update',
  ORGANIZATION_DELETE = 'nous.auth.organization.delete',

  // SERVER CONFIG
  SERVER_CONFIG_CREATE = 'nous.cms.server.config.create',
  SERVER_CONFIG_READ = 'nous.cms.server.config.read',
  SERVER_CONFIG_UPDATE = 'nous.cms.server.config.update',
  SERVER_CONFIG_DELETE = 'nous.cms.server.config.delete',

  // GAME
  GAME_CREATE = 'nous.cms.game.create',
  GAME_READ = 'nous.cms.game.read',
  GAME_UPDATE = 'nous.cms.game.update',
  GAME_DELETE = 'nous.cms.game.delete',
  GAME_VISIBILITY = 'nous.cms.game.change.visibility',

  // RESULT
  RESULT_CREATE = 'nous.cms.game.result.create',
  RESULT_READ = 'nous.cms.game.result.read',
  RESULT_UPDATE = 'nous.cms.game.result.update',
  RESULT_DELETE = 'nous.cms.game.result.delete',
  RESULT_VISIBILITY = 'nous.cms.game.result.change.visibility',

  // LEVEL
  LEVEL_CREATE = 'nous.cms.game.level.create',
  LEVEL_READ = 'nous.cms.game.level.read',
  LEVEL_UPDATE = 'nous.cms.game.level.update',
  LEVEL_DELETE = 'nous.cms.game.level.delete',
  LEVEL_VISIBILITY = 'nous.cms.game.level.change.visibility',

  // ELEMENT
  ELEMENT_CREATE = 'nous.cms.game.element.create',
  ELEMENT_READ = 'nous.cms.game.element.read',
  ELEMENT_UPDATE = 'nous.cms.game.element.update',
  ELEMENT_DELETE = 'nous.cms.game.element.delete',
  ELEMENT_VISIBILITY = 'nous.cms.game.element.change.visibility',

  // SELECTION
  SELECTION_CREATE = 'nous.cms.game.selection.create',
  SELECTION_READ = 'nous.cms.game.selection.read',
  SELECTION_UPDATE = 'nous.cms.game.selection.update',
  SELECTION_DELETE = 'nous.cms.game.selection.delete',
  SELECTION_VISIBILITY = 'nous.cms.game.selection.change.visibility',

  // SOLUTION
  SOLUTION_CREATE = 'nous.cms.game.solution.create',
  SOLUTION_READ = 'nous.cms.game.solution.read',
  SOLUTION_UPDATE = 'nous.cms.game.solution.update',
  SOLUTION_DELETE = 'nous.cms.game.solution.delete',
  SOLUTION_VISIBILITY = 'nous.cms.game.solution.change.visibility',

  // COMMUNITY
  COMMUNITY_CREATE = 'nous.cms.community.create',
  COMMUNITY_READ = 'nous.cms.community.read',
  COMMUNITY_UPDATE = 'nous.cms.community.update',
  COMMUNITY_DELETE = 'nous.cms.community.delete',
  COMMUNITY_VISIBILITY = 'nous.cms.community.change.visibility',

  // USER_CONTENT
  USER_CONTENT_CREATE = 'nous.cms.community.user.content.create',
  USER_CONTENT_READ = 'nous.cms.community.user.content.read',
  USER_CONTENT_UPDATE = 'nous.cms.community.user.content.update',
  USER_CONTENT_DELETE = 'nous.cms.community.user.content.delete'
}
