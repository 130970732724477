/********************************************
Defines default content types
 ********************************************/

export enum ContentTypeEnum {
  AUDIO = 1000,
  IMAGE = 1001,
  VIDEO = 1002,
  SLIDE = 1003,

  AUDIO_SLIDE = 1042
}
