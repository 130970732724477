import { ModelHelper } from "./model.helper.interface";
import { Map } from "../map.model";
import Utils from "../../utils/utils";
import { FormControlString } from "../form-control/form-control-string.model";
import { FormControlNumber } from "../form-control/form-control-number.model";
import * as moment from 'moment';
import { VersionModel } from "../base.model";
import { FileType } from "../../enums/file-type.enum";
import { FileModelHelper } from "./file-model.helper";

export class MapModelHelper implements ModelHelper<Map> {

  constructor(
    private fileHelper: FileModelHelper
  ) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id);
  }

  emptyModel(): Map {
    return new Map('',
      new FormControlString({
        label: 'label.map.field.label',
        required: true
      }),
      new FormControlNumber({
        label: 'label.map.field.scale-x',
        value: 1,
        disabled: true
      }),
      new FormControlNumber({
        label: 'label.map.field.scale-y',
        value: 1,
        disabled: true
      }),
      [],
      [],
      []);
  }

  fromJSON(json: any, skipCheck = false): Map {

    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    // create tour object
    const map = new Map(
      json._id,

      new FormControlString({
        value: json.label,
        label: 'label.map.field.label',
        required: true
      }),

      new FormControlNumber({
        label: 'label.map.field.scale-x',
        value: isNaN(json.scale_x) ? json.scale_x : 1,
        disabled: true
      }),

      new FormControlNumber({
        label: 'label.map.field.scale-y',
        value: isNaN(json.scale_y) ? json.scale_y : 1,
        disabled: true
      }),
      json.files ? json.files
          .filter(file => this.fileHelper.checkJSON(file))
          .map(file => this.fileHelper.fromJSON(file, FileType.IMAGE))
        : [],
      [],
      json.blocked_users,
      json.created_on ? moment(json.created_on).toDate() : undefined,
      json.creatorname,
      json.lastmodified ? moment(json.lastmodified).toDate() : undefined,
      json.modifiedby,
      json.version,
      json.versions ? json.versions.map(ver => <VersionModel>{
        number: ver.number,
        date: moment(ver.date).toDate()
      }) : [],
      json.baseId
    );

    return map;
  }
}
