import { Injectable } from '@angular/core';
import clone from 'lodash.clonedeep';
import { PropertyValueType } from '../../enums/property-value-type.enum';
import { Dictionary } from '../dictionary';
import { FormControlBoolean } from '../form-control/form-control-boolean.model';
import { FormControlNumber } from '../form-control/form-control-number.model';
import { FormControlString } from '../form-control/form-control-string.model';
import { PropertyDictionaryEntry } from '../property-dictionary-entry';
import { MultiLangBooleanHelper } from './multi-lang-boolean.helper';
import { MultiLangStringHelper } from './multi-lang-string.helper';

@Injectable({ providedIn: 'root' })
export class UserContentPropertyHelper {

  constructor(
    private readonly multiLangStringHelper: MultiLangStringHelper,
    private readonly multiLangBooleanHelper: MultiLangBooleanHelper,
  ) {
  }

  mapProperties(properties: Dictionary<object>, typeConfig: any): Dictionary<PropertyDictionaryEntry> {
    const fieldsDict = (typeConfig && clone(typeConfig.fields)) || {};

    return this.createUnionDict(properties || {}, fieldsDict);
  }

  createUnionDict(properties: Dictionary<any>, fieldsDict: Dictionary<any>): Dictionary<PropertyDictionaryEntry> {
    Object.keys(properties).forEach(key => {
      const mergedObject = { ...fieldsDict[ key ], ...properties[ key ] };
      fieldsDict[ key ] = this.createFormControlForParameter(mergedObject, key);
    });

    return fieldsDict;
  }

  createFormControlForParameter(entry: any, key: string): PropertyDictionaryEntry {
    const { value, type, invalid } = entry;
    const label = entry.label || key;

    switch (type) {
      case PropertyValueType.MultiLanguageString:
        return this.createMultiLangString(key, label, type, value, invalid);
      case PropertyValueType.MultiLanguageBoolean:
        return this.createMultiLangBoolean(key, label, type, value, invalid);
      case PropertyValueType.boolean:
        return new PropertyDictionaryEntry(new FormControlBoolean({ key, label, value }), type, invalid);
      case PropertyValueType.boolean_array:
        const boolArray = value.map(v => new FormControlBoolean({ key, label, value: v }));
        return new PropertyDictionaryEntry(boolArray, type, invalid);
      case PropertyValueType.number:
        return new PropertyDictionaryEntry(new FormControlNumber({ key, label, value }), type, invalid);
      case PropertyValueType.number_array:
        const numberArray = value.map(v => new FormControlNumber({ key, label, value: v }));
        return new PropertyDictionaryEntry(numberArray, type, invalid);
      case PropertyValueType.string_array:
        const stringArray = value.map(v => new FormControlString({ key, label, value: v }));
        return new PropertyDictionaryEntry(stringArray, type, invalid);
      case PropertyValueType.string:
      default:
        return new PropertyDictionaryEntry(new FormControlString({ key, label, value }), type, invalid);
    }
  }

  createMultiLangString(key: string, label: string, type: PropertyValueType, value: any, invalid: boolean) {
    const options = { key, label };
    const presets = value ? Object.keys(value) : [];
    const multiLangString = this.multiLangStringHelper.fromJSON(value, key, options, presets);
    return new PropertyDictionaryEntry(multiLangString, type, invalid);
  }

  createMultiLangBoolean(key: string, label: string, type: PropertyValueType, value: any, invalid: boolean) {
    const options = { key, label };
    const presets = value ? Object.keys(value) : [];
    const multiLangBoolean = this.multiLangBooleanHelper.fromJSON(value, key, options, presets);
    return new PropertyDictionaryEntry(multiLangBoolean, type, invalid);
  }

}
