import { HtmlContentSchema } from "./html-content-schema.model";
import { HtmlField } from "./html-field.model";
import { FormControlNumber } from "./form-control/form-control-number.model";
import { BaseModel } from "./base.model";

export class HtmlContent extends BaseModel {

  constructor(
    public id,
    public schema: HtmlContentSchema,
    public active,
    public orderNumber: FormControlNumber,
    public fields: HtmlField[],
  ) {
    super(id);
  }

  toJSON(): object {
    const json = {
      _id: this.id,
      schema: this.schema.toJSON(),
      active: this.active.value,
      orderNumber: this.orderNumber.value,
      fields: this.fields ? this.fields.map(field => field.toJSON()) : []
    };

    return json;
  }
}
