import { Component, Input } from '@angular/core';

import Utils from '../../../../app/shared/utils/utils';


@Component({
  selector: 'app-tour-type',
  template: `
    <span class="type" *ngIf="show">
      <i class="icon-tour"></i>
      {{(tourType | tourType) | translate}}</span>
  `
})
export class TourTypeComponent {
  @Input() tourType: number | string;

  constructor() { }

  get show(): boolean {
    return Utils.isDefined(this.tourType);
  }
}
