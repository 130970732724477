import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { RecaptchaModule } from 'ng-recaptcha';

import { CoreModule } from '../app/core/core.module';

import { AppRoutingModule } from '../app/app-routing.module';
import { AppComponent } from '../app/app.component';

import { LoginModule } from '../app/user/login/login.module';
import { LogoutModule } from '../app/user/logout/logout.module';

import { DashboardModule } from '../app/dashboard/dashboard.module';
import { QuillModule } from 'ngx-quill';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SharedModule } from "./shared/shared.module";
import { CmsTranslationLoader } from "./core/services/translation-loader";


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule.forRoot(),
    RecaptchaModule.forRoot(),
    CoreModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CmsTranslationLoader
      }
    }),
    LoginModule,
    LogoutModule,
    DashboardModule,
    QuillModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
