import { Component, Input } from '@angular/core';

import { GroupType } from '../../../../app/shared/enums/group-type.enum';


@Component({
  selector: 'app-group-type',
  template: `
    <span class="type" [ngSwitch]="GroupType[groupType]" *ngIf="GroupType[groupType]">
      <i *ngSwitchCase="'GROUP'" class="icon-group"></i>
      {{GroupType[groupType] | titlecase}}</span>
  `
})
export class GroupTypeComponent {

  GroupType = GroupType;
  @Input() groupType: GroupType;


  constructor() { }
}
