import { ModelHelper } from "./model.helper.interface";
import Utils from "../../utils/utils";
import { User } from "../user.model";
import { UserType } from "../../enums/user-type.enum";
import { FormControlPassword } from "../form-control/form-control-password.model";
import { ConfigurationService } from "../../services/configuration.service";
import { OrganizationModelHelper } from "./organization-model.helper";
import * as moment from "moment";
import { FormControlSelectOption } from "../../interfaces/form-control-select-options.interface";
import { Organization } from "../organization.model";
import { FormControlString } from "../form-control/form-control-string.model";
import { FormControlSelect } from "../form-control/form-control-select.model";
import { FormControlBoolean } from "../form-control/form-control-boolean.model";
import { Address } from "../address.model";
import { Channel } from "../channel.model";

export class UserModelHelper implements ModelHelper<User> {

  constructor(
    private config: ConfigurationService,
    private organizationHelper: OrganizationModelHelper
  ) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id);
  }

  emptyModel(defaultChannels: Channel[], organization?: Organization, address?: Address): User {
    return new User(
      '',
      new FormControlSelect({
          value: UserType.USER,
          key: 'type',
          label: 'label.user.field.type',
          options: Object.keys(UserType).map(key => {return {value: UserType[key], label: key}}),
          multiple: false
        }),
      new FormControlSelect({
        value: '',
        key: 'role',
        label: 'label.user.field.roles',
        multiple: true
      }),
      new FormControlString({
        value: '',
        key: 'username',
        label: 'label.user.field.username'
      }),
      new FormControlPassword({
        value: '',
        key: 'password',
        label: 'label.user.field.password',
        apiParam: 'password',
        minLength: this.config.getPasswordMinLength(),
        required: false
      }),
      [],
      organization,
      [],
      new FormControlString({
        value: '',
        key: 'firstname',
        label: 'label.user.field.firstname'
      }),
      new FormControlString({
        value: '',
        key: 'lastname',
        label: 'label.user.field.lastname'
      }),
      new FormControlString({
        value: '',
        key: 'email',
        label: 'label.user.field.email'
      }),
      new FormControlBoolean({
        value: false,
        key: 'registrationCompleted',
        label: 'label.user.field.registrationCompleted'
      }),
      new FormControlBoolean({
        value: false,
        key: 'blocked',
        label: 'label.user.field.blocked'
      }),
      new FormControlString({
        value: '',
        key: 'clientId',
        label: 'label.user.field.clientId'
      }),
      new FormControlString({
        value: '',
        key: 'clientSecret',
        label: 'label.user.field.clientSecret'
      }),
      address
    );
  }

  fromJSON(json: any, skipCheck = false): User {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    // create content object
    const user = new User(
      json._id,
      new FormControlSelect({
        value: json.type,
        key: 'type',
        label: 'label.user.field.type',
        options: Object.keys(UserType).map(key => {return {value: UserType[key], label: key}}),
        multiple: false
      }),
      new FormControlSelect({
        value: '',
        key: 'role',
        label: 'label.user.field.roles',
        multiple: true
      }),
      new FormControlString({
        value: json.username,
        key: 'username',
        label: 'label.user.field.username'
      }),
      new FormControlPassword({
        value: json.password,
        key: 'password',
        label: 'label.user.field.password',
        apiParam: 'password',
        // minLength: this.config.getAuthenticationConfig().passwordMinLength,
        required: false
      }),
      json.permissions ? json.permissions : [],
      json.organization ? this.organizationHelper.fromJSON(json.organization) : undefined,
      json.blocked_users,
      new FormControlString({
        value: json.firstname,
        key: 'firstname',
        label: 'label.user.field.firstname'
      }),
      new FormControlString({
        value: json.lastname,
        key: 'lastname',
        label: 'label.user.field.lastname'
      }),
      new FormControlString({
        value: json.email,
        key: 'email',
        label: 'label.user.field.email'
      }),
      new FormControlBoolean({
        value: json.registrationCompleted,
        key: 'registrationCompleted',
        label: 'label.user.field.registrationCompleted'
      }),
      new FormControlBoolean({
        value: json.blocked,
        key: 'blocked',
        label: 'label.user.field.blocked'
      }),
      new FormControlString({
        value: json.clientId,
        key: 'clientId',
        label: 'label.user.field.clientId'
      }),
      new FormControlString({
        value: json.clientSecret,
        key: 'clientSecret',
        label: 'label.user.field.clientSecret'
      }),
      json.address ? Address.fromJSON(json.address) : undefined,
      json.created_on ? moment(json.created_on).toDate() : undefined,
      json.creatorname,
      json.lastmodified ? moment(json.lastmodified).toDate() : undefined,
      json.modifiedby
    );

    user.role.options = json.roles && Array.isArray(json.roles) ? json.roles
      .map(r => {
        return {label: r.name, value: r._id} as FormControlSelectOption<string>;
      }) : [];
    user.role.value = json.roles && Array.isArray(json.roles) ?
      json.roles.map(r => r._id) : undefined;

    return user;
  }
}
