import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AppConfig } from '../../app.config';
import { MessageModalComponent } from '../../core/components/message-modal/message-modal.component';
import { ApiBaseService } from '../../core/services/api-base.service';
import { UserService } from '../../core/services/user.service';
import { GameCreateModalComponent } from '../components/modals/game-create-modal/game-create-modal.component';
import { GameListModalComponent } from '../components/modals/game-list-modal/game-list-modal.component';
import { CreateOptions } from '../interfaces/create-options.interface';
import { BaseFilesModel } from '../models/base-files.model';
import { Content } from '../models/content.model';
import { GameResult } from '../models/game-result.model';
import { Game } from '../models/game.model';
import { Station } from '../models/station.model';
import { Status } from '../models/status.model';
import Utils from '../utils/utils';
import { FileService } from './file.service';
import { ModelMapperService } from './mapper/model-mapper.service';

@Injectable({providedIn: 'root'})
export class GameService extends ApiBaseService<Game> {

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected userService: UserService,
    protected fileService: FileService,
    protected modalService: NgbModal,
    private modelMapper: ModelMapperService
  ) {
    super(Game, 'games', http, router, userService, fileService);

    this.url = AppConfig.GAME_SERVER + AppConfig.GAME_BASE_URL + '/game';
  }

  select(exclude: Game[] = [], gameTypes?: number[]): Promise<Game[]> {
    const modalRef = this.modalService.open(GameListModalComponent, { size: 'lg' });
    modalRef.componentInstance.status = new Status();

    this.getAll(modalRef.componentInstance.status)
      .subscribe((games: Game[]) => {
        try {
          modalRef.componentInstance.games = games
            .filter(game => exclude.findIndex(myGame => game.id === myGame.id) < 0);

          if (gameTypes && gameTypes.length > 0) {
            modalRef.componentInstance.gameTypes = gameTypes;
          }
        } catch (e) {
        }
      });

    return modalRef.result;
  }

  create(options: CreateOptions<Game>, gameTypes?: number[]): Promise<Game> {
    return super.create(Object.assign(options, {
      component: GameCreateModalComponent,
      componentOptions: {
        game: this.emptyModel(),
        gameTypes,
      }
    }));
  }

  update(model: any, status?: Status): Observable<any> {
    status = status || new Status();
    status.setLoading();

    const url = `${this.url}/${model.id}`;

    if (this.userService) {
      this.userService.addCurrentUser(model);
    }

    return this.http[ Utils.isValidId(model.id) ? 'put' : 'post' ](
      url,
      (model as GameResult).toJSON()
    ).pipe(
      map(response => {

        if (model.id || this.checkJSON(response)) {
          status.setSuccess();
          return model.id ? model : this.fromJSON(response);
        } else {
          status.setError();
        }
      }),
      tap(() => {
        if (this.fileService && model instanceof BaseFilesModel
          && model.filesToDelete && model.filesToDelete.length > 0) {
          for (const file of model.filesToDelete) {
            this.fileService.delete(file.href, true)
              .then(() => {
              }, () => {
              });
          }
        }
      }),
      catchError(err => this.handleError(err, status)),);
  }

  getStations(gameId: string, status?: Status): Observable<Station[]> {
    status = status || new Status();
    status.setLoading();

    const url = `${this.url}/${gameId}/stations`;
    const responseKey = '_embedded.stations';

    return this.http
      .get(url).pipe(
        map(response => {
          if (Utils.deepAccessUsingString(response, responseKey) && Utils.deepAccessUsingString(response, responseKey).length > 0) {
            status.setSuccess();
            return Utils.deepAccessUsingString(response, responseKey)
              .filter(object => this.modelMapper.stationCheckJSON(object))
              .map(object => this.modelMapper.stationFromJSON(object));
          } else {
            status.setNoResults();
          }
        }),
        catchError(err => this.handleError(err, status)), );
  }

  getContents(gameId: string, status?: Status): Observable<Content[]> {
    status = status || new Status();
    status.setLoading();

    const url = `${this.url}/${gameId}/contents`;
    const responseKey = '_embedded.contents';

    return this.http
      .get(url).pipe(
        map(response => {
          if (Utils.deepAccessUsingString(response, responseKey) && Utils.deepAccessUsingString(response, responseKey).length > 0) {
            status.setSuccess();
            return Utils.deepAccessUsingString(response, responseKey)
              .filter(object => this.modelMapper.contentCheckJSON(object))
              .map(object => this.modelMapper.contentFromJSON(object));
          } else {
            status.setNoResults();
          }
        }),
        catchError(err => this.handleError(err, status)), );
  }

  delete(id: string, status?: Status): Promise<any> {
    const modalRef = this.modalService.open(MessageModalComponent);
    modalRef.componentInstance.confirm = true;
    modalRef.componentInstance.message = 'Are you sure that you want to delete the game?';

    return modalRef.result.then(() => super.delete(id, status), () => Promise.reject('Dimissed'));
  }

  checkJSON(json: any): boolean {
    return this.modelMapper.gameCheckJSON(json);
  }

  emptyModel(): Game {
    return this.modelMapper.gameEmptyModel();
  }

  fromJSON(json: any, skipCheck?: boolean): Game {
    return this.modelMapper.gameFromJSON(json, skipCheck);
  }
}
