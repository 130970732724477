import { HtmlField } from "./html-field.model";
import { HtmlFieldType } from "../enums/html-field-type.enum";
import { MultiLangFile } from "./multi-lang-file.model";

export class HtmlFileField extends HtmlField {

  constructor(
    public id: string,
    public fieldName: string,
    public content: MultiLangFile,
    public version?,
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string
  ) {
    super(id, fieldName, content, HtmlFieldType.FILE, version, createdOn, creatorName, lastModified, modifiedBy);
  }
}
