import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'values'
})
export class ValuesPipe implements PipeTransform {

  transform(obj: object): any[] {
    if (typeof obj === 'object' && obj !== null) {
      return Object.values(obj);
    }

    return [];
  }

}
