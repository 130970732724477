import { FormControlString } from "./form-control/form-control-string.model";
import { FormControlNumber } from "./form-control/form-control-number.model";

export class SyncConfigModel {

   constructor(
    public port: FormControlNumber = new FormControlNumber({
      apiParam: 'port',
      label: 'label.config.rest.field.sync-port',
    }),
    public serverUrl: FormControlString = new FormControlString({
      key: 'server',
      label: 'label.config.rest.field.sync-server',
      required: true
    }),
    public protocol: FormControlString = new FormControlString({
      key: 'protocol',
      label: 'label.config.rest.field.sync-protocol'
    })) {
  }

  static fromJSON(json): SyncConfigModel {
    const model = new SyncConfigModel(
      new FormControlNumber({
        apiParam: 'port',
        label: 'label.config.rest.field.sync-port',
        value: json.port
      }),
      new FormControlString({
        key: 'server',
        label: 'label.config.rest.field.sync-server',
        required: true,
        value: json.server
      }),
      new FormControlString({
        key: 'protocol',
        label: 'label.config.rest.field.sync-protocol',
        value: json.protocol
      })
    );

    return model;
  }

  toJSON() {
    const json = {
      port: this.port.value,
      server: this.serverUrl.value,
      protocol: this.protocol.value,
    };

    return json;
  }
}
