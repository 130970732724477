export enum ModuleType {
  EXHIBITION = 'exhibition',
  TOUR = 'tour',
  GROUP = 'group',
  STATION = 'station',
  CONTENT = 'content',
  CHANNEL = 'channel',
  MAP = 'map',
  CDN = 'cdn',
  SYNC = 'sync',
  HTML = 'html',
  USER = 'user',
  ORGANIZATION = 'organization',
  GAME = 'game',
  RESULT = 'result',
  LEVEL = 'level',
  ELEMENT = 'element',
  SELECTION = 'selection',
  SOLUTION = 'solution',
  COMMUNITY = 'community',
  USER_CONTENT = 'user_content'
}
