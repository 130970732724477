import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {File} from '../../models/file.model';

@Component({
  selector: 'app-file-metadata',
  templateUrl: './file-metadata.component.html'
})
export class FileMetadataComponent implements OnInit {

  @Input() public file: File;

  @Output() public close: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChange = new EventEmitter();

  public form: FormGroup = new FormGroup({});

  constructor() {
  }

  ngOnInit() {
  }

}
