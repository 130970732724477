import { catchError, map, takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Subject } from 'rxjs';

import { UserService } from '../../core/services/user.service';

import { Endpoint } from '../models/endpoint.model';
import { Status } from '../models/status.model';

import { MessageModalComponent } from '../../core/components/message-modal/message-modal.component';
import { PasswordModalComponent } from '../components/modals/password-modal/password-modal.component';

import { ApiBaseService } from '../../core/services/api-base.service';
import { FileService } from './file.service';
import { ModelMapperService } from "./mapper/model-mapper.service";


@Injectable()
export class CdnService extends ApiBaseService<Endpoint> {

  public destroy$: Subject<any> = new Subject<any>();

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected userService: UserService,
    protected fileService: FileService,
    protected modalService: NgbModal,
    private modelMapper: ModelMapperService
  ) {
    super(Endpoint, 'cdn/endpoints', http, router, userService, fileService, modalService, 'endpoints');
  }

  poll(endpoint: Endpoint, ms = 0) {
    setTimeout(() => {
      this.get(endpoint.id)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(newEndpoint => {
          Object.assign(endpoint, newEndpoint);

          if (endpoint.building) {
            this.poll(endpoint, 2000);
          }
        });
    }, ms);
  }

  publish(endpoint: Endpoint, status?: Status): void {
    let modalRef;

    if (endpoint.password) {
      modalRef = this.modalService.open(PasswordModalComponent);
      modalRef.componentInstance.password = endpoint.password;
    }

    (modalRef ? modalRef.result : Promise.resolve()).then(() => {
      status = status || new Status();
      status.setLoading();

      let url = `${this.url}/${endpoint.id}`;
      url += '/publish';

      this.http.post(url, {}).pipe(
        map(response => {
          if (response && response['status'] && response['status'] === 'building') {
            status.setSuccess();
            this.poll(endpoint);
            return response;

          } else if (response && response['status'] && response['status'] === 'upToDate') {
            status.setSuccess();
            modalRef = this.modalService.open(MessageModalComponent);
            modalRef.componentInstance.title = 'Info';
            modalRef.componentInstance.message = response['msg'];

          } else {
            status.setError();
          }
        }),
        catchError(err => this.handleError(err, status)),)
        .subscribe();
    }, () => {
    });
  }

  checkJSON(json: any): boolean {
    return this.modelMapper.endpointCheckJSON(json);
  }

  emptyModel(): Endpoint {
    return this.modelMapper.endpointEmptyModel();
  }

  fromJSON(json: any, skipCheck?: boolean): Endpoint {
    return this.modelMapper.endpointFromJSON(json, skipCheck);
  }
}
