import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LogoutComponent } from '../../../app/user/logout/logout.component';


const logoutRoutes: Routes = [
  {
    path: 'logout',
    component: LogoutComponent,
    data: {
      title: 'Logout'
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(logoutRoutes)],
  exports: [RouterModule]
})
export class LogoutRoutingModule { }
