import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../../../app/shared/shared.module';

import { ChannelListRoutingModule } from '../../../app/channel/channel-list/channel-list-routing.module';
import { ChannelListComponent } from '../../../app/channel/channel-list/channel-list.component';
import { SyncModule } from "../../sync/sync.module";
import { TranslateModule } from "@ngx-translate/core";


@NgModule({
  declarations: [
    ChannelListComponent
  ],
    imports: [
        NgbModule,
        SharedModule,
        ChannelListRoutingModule,
        SyncModule,
        TranslateModule
    ],
  providers: [],
  exports: [
    ChannelListComponent
  ]
})
export class ChannelListModule { }
