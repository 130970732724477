import * as moment from 'moment';

import { FormControlString } from './form-control/form-control-string.model';
import { FormControlBoolean } from './form-control/form-control-boolean.model';

import { BaseFilesModel } from './base-files.model';
import { Station } from './station.model';
import { File } from './file.model';

import { FileType } from '../enums/file-type.enum';

import Utils from '../utils/utils';
import { VersionModel } from "./base.model";


export class Channel extends BaseFilesModel {

  constructor(
    public id: string,
    public active: FormControlBoolean,
    public isDefault: FormControlBoolean,
    public name: FormControlString,
    public stations: Station[],
    public files: File[],
    public filesToDelete: File[],
    public blockedUsers: string[],
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string,
    public version?: number,
    public versions?: VersionModel[],
    public baseId?: string,
  ) {
    super(id, blockedUsers, createdOn, creatorName, lastModified, modifiedBy, version, versions, baseId, files, filesToDelete);
  }

  // static checkJSON(json: any): boolean {
  //   return json && Utils.isValidId(json._id);
  // }

  // static fromJSON(json: any, skipCheck = false): Channel {
  //
  // }

  toJSON(): object {
    const json = super.toJSON();

    json['active'] = this.active.value;
    json['isDefault'] = this.isDefault.value;
    json['name'] = this.name.value;

    return json;
  }

  addStation(newStation: Station): void {
    this.stations = [...this.stations.filter(station => station.id !== newStation.id), newStation];
    this.setChanged(true);
  }

  deleteStation(station: Station): void {
    Utils.removeObjectFromArray(this.stations, station);
    this.stations = [...this.stations];
    this.setChanged(true);
  }

  getFileTypes(): { type: number, attribute: { label: string, value: string }, hasLanguage: boolean, hasDuration: boolean }[] {
    return [{
      type: FileType.FILE, attribute: {
        label: 'label.channel.field.html-template',
        value: 'file'
      },
      hasLanguage: false,
      hasDuration: false
    }];
  }
}
