import { BaseModel } from "./base.model";
import Utils from "../utils/utils";
import * as moment from 'moment';

export interface KeyModel {
  key: string;
}

export class HtmlContentSchema extends BaseModel {

  constructor(
    public id: string = '',
    public key: string,
    public fields: KeyModel[],
    public version,
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string
  ) {
    super(id, undefined, createdOn, creatorName, lastModified, modifiedBy);
  }

  static checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id);
  }

  static fromJSON(json: any, skipCheck = false): HtmlContentSchema {

    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    // create content object
    const htmlContent = new HtmlContentSchema(
      json._id,
      json.key,
      json.fields ? json.fields.map(field => {
        return {key: field.key} as KeyModel
      }) : [],
      json.version,
      json.created_on ? moment(json.created_on).toDate() : undefined,
      json.creatorName,
      json.lastModified ? moment(json.lastModified).toDate() : undefined,
      json.modifiedBy
    );

    return htmlContent;
  }


  toJSON(): object {
    const json = super.toJSON();
    delete json['blocked_users'];

    json['key'] = this.key;
    json['fields'] = this.fields;

    return json;
  }
}
