export class Status {
  public loading: boolean;
  public error: boolean;
  public success: boolean;
  public noResults: boolean;
  public progress: number;

  constructor(
    loading: boolean = false,
    error: boolean = false,
    success: boolean = false,
    noResults: boolean = false,
    progress: number = 0
  ) { }

  reset() {
    this.loading = false;
    this.error = false;
    this.success = false;
    this.noResults = false;
    this.progress = 0;
  }

  setLoading() {
    this.reset();
    this.loading = true;
    this.progress = 0;
  }

  setError() {
    this.reset();
    this.error = true;
  }

  setSuccess() {
    this.reset();
    this.success = true;
    setTimeout(() => this.reset(), 2000);
  }

  setNoResults() {
    this.reset();
    this.noResults = true;
  }
}
