import { filter, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { Subject } from 'rxjs';


import { AuthService } from '../../../app/core/services/auth.service';
import { SyncService } from '../../../app/core/services/sync.service';

import { Status } from '../../../app/shared/models/status.model';

import { AppConfig } from '../../../app/app.config';


@Injectable()
export class MetaInformationService {
  breadcrumbsChange: Subject<Array<object>> = new Subject<Array<object>>();

  public readonly meta = {
    title: 'NOUS Conductor',
    version: AppConfig.APP_VERSION,
    // sync: {
    //   buildNr: ''
    // },
    api: {
      title: '',
      version: ''
    }
  };

  private breadcrumbsRoute: ActivatedRoute; // Save the route where the breadcrumbs were initiated
  private breadcrumbs: Array<object>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private http: HttpClient,
    private authService: AuthService,
    private syncService: SyncService
  ) {
  }

  /* Title */

  private initTitle(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data),)
      .subscribe(event => {
        this.titleService.setTitle(event['title'] + ' | ' + this.meta.title);
      });
  }

  updateRouteTitle(route: ActivatedRoute, title: string): void {
    if (title) {
      route.snapshot.data.cmsTitle = title;
      this.updateBreadcrumbs(this.breadcrumbsRoute);
    }
  }

  updateRouteHasChanges(route: ActivatedRoute, hasChanges: boolean): void {
    route.snapshot.data.hasChanges = hasChanges;
    this.updateBreadcrumbs(this.breadcrumbsRoute);
  }

  /* Breadcrumbs */

  initBreadcrumbs(breadcrumbsRoute: ActivatedRoute): void {
    this.breadcrumbsRoute = breadcrumbsRoute;

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(event => {  // note, we don't use event
        this.updateBreadcrumbs(breadcrumbsRoute);
      });
  }

  updateBreadcrumbs(breadcrumbsRoute: ActivatedRoute): void {
    this.breadcrumbs = [];
    let currentRoute = breadcrumbsRoute,
      url = '';
    do {
      const childrenRoutes = currentRoute.children;
      currentRoute = null;

      childrenRoutes.forEach(route => {
        if (route.outlet === 'primary') {
          const routeSnapshot = route.snapshot;
          url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
          if (route.snapshot.data.breadcrumb !== false) {
            this.breadcrumbs.push({
              title: route.snapshot.data.title,
              cmsTitle: route.snapshot.data.cmsTitle,
              hasChanges: route.snapshot.data.hasChanges,
              url: url,
              arrow: Object.keys(route.snapshot.params).length === 0
            });
          }
          currentRoute = route;
        }
      });

      this.breadcrumbsChange.next(this.breadcrumbs);

    } while (currentRoute);
  }

  /* Util */

  private getApiMeta(status?: Status): Promise<any> {
    return this.http
      .get(AppConfig.API_URL + AppConfig.API_REST_BASE_PATH).pipe(
        map((response: any) => {
          this.meta.api.title = response.title;
          this.meta.api.version = response.version;
        }))
      .toPromise()
      .then(() => {
      }, () => {
      });
  }

//   private getSyncMeta(): Promise<any> {
//     return this.syncService.getMeta()
//       .then(response => {
//         this.meta.sync.buildNr = response.buildNumber;
//       }, () => {
//       });
// }

  private getMeta = (isLoggedIn?: boolean): void => {
    if (isLoggedIn || this.authService.isLoggedIn()) {
      this.getApiMeta();
      // this.getSyncMeta();
    }
  };

  /* Init */
  init(): void {
    this.initTitle();
    this.getMeta();
    this.authService.subscribeLoggedIn().subscribe(this.getMeta);
  }
}
