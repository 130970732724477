import * as moment from 'moment';

import Utils from '../../../app/shared/utils/utils';
import { ConfigOptions } from "../../../config/config.interface";


export interface VersionModel {
  number: number,
  date: Date
}

export abstract class BaseModel {

  private changed = false;

  protected constructor(
    public id: string,
    public blockedUsers: string[] = [],
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string,
    public version?: number,
    public versions?: VersionModel[],
    public baseId?: string
  ) {
    this.changed = !Boolean(id);
  }

  // static checkJSON(json: any): boolean {
  //   return true;
  // }
  //
  // static fromJSON(json: any, utils?: any) {}

  toJSON(createdOn?: string, creatorName?: string, lastModified?: string, modifiedBy?: string, version?: number): object {
    const json = {
      _id: this.id // TODO: check if ever needed
    };

    json[createdOn || 'created_on'] = Utils.isValidId(this.id) ? moment(this.createdOn).valueOf() : moment().valueOf();
    json[creatorName || 'creatorname'] = this.creatorName;
    json[lastModified || 'lastmodified'] = moment().valueOf();
    json[modifiedBy || 'modifiedby'] = this.modifiedBy;
    json[version || 'version'] = this.version;
    json['blocked_users'] = this.blockedUsers;

    return json;
  }

  setChanged(changed = true): void {
    this.changed = changed;
  }

  get hasChanges(): boolean {
    return this.changed;
  }
}
