import { NgModule } from '@angular/core';

import { SharedModule } from '../../../app/shared/shared.module';

import { OrganizationListComponent } from "./organization-list.component";
import { OrganizationListRoutingModule } from "./organization-list-routing.module";


@NgModule({
  declarations: [
    OrganizationListComponent
  ],
  imports: [
    SharedModule,
    OrganizationListRoutingModule
  ],
  providers: [],
  exports: [
    OrganizationListComponent
  ]
})
export class OrganizationListModule { }
