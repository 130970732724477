import { EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { BaseModel } from '../../../models/base.model';

import { TableOptions } from '../../../interfaces/table-options.interface';
import { TableSearch } from '../../../interfaces/table-search.interface';

import Utils from '../../../../../app/shared/utils/utils';

import { AppConfig } from '../../../../app.config';
import { UserService } from '../../../../core/services/user.service';
import { ConfigurationService } from '../../../services/configuration.service';

export class TableBaseComponent<T extends BaseModel> implements OnInit {

  globalFilters;
  showId = AppConfig.APP_SHOW_IDS;
  pillsLimit: number;

  @Input() rows: number;
  @Input() data: T[];
  @Input() detailLink = '/stations';
  @Input() options: TableOptions = {};
  @Output() onUpdate = new EventEmitter<T>();
  @Output() onDelete = new EventEmitter<T>();
  @Output() onReorder = new EventEmitter();

  @Input() selection: T[] = [];
  @Output() selectionChange = new EventEmitter<T[]>();

  permissionList: string[];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected config: ConfigurationService,
    protected userService: UserService
  ) {
    this.pillsLimit = this.config.getTabPillsLimit();
  }

  getGlobalFilterFields(): TableSearch {
    this.globalFilters = this.globalFilters || [{
      label: 'ID',
      field: 'id',
      active: this.showId
    }];

    return this.globalFilters
      .filter(filter => filter.active)
      .map(filter => filter.field);
  }

  delete(model: T): void {
    this.onDelete.emit(model);
  }

  update(model: T): void {
    this.onUpdate.emit(model);
  }

  goToDetailView(model: T): void {
    this.router.navigate([this.detailLink, model.id], {relativeTo: this.route});
  }

  handleRowSelect(model: T): void {
    if (this.isMultiSelection()) {
      Utils.addOrRemoveFromArray(this.selection, model);

    } else if (this.isSingleSelection()) {
      this.selectionChange.emit([model]);

    } else {
      this.goToDetailView(model);
    }
  }

  handleRowReorder(): void {
    if (this.options.canReorder) {
      this.onReorder.emit();
    }
  }

  handleExpandedRowReorder(): void {
    // if (this.options.canReorder) {
      // this.onReorder.emit();
    // }
  }

  isSingleSelection(): boolean {
    return this.options.multiSelect === false;
  }

  isMultiSelection(): boolean {
    return this.options.multiSelect === true;
  }

  getTableConfig(model: string): any {
    return this.config.getTableConfig()[model];
  }

  ngOnInit() {
    this.rows = this.options.canReorder ? 9999 : this.rows;
    this.options.canDelete = this.options.canDelete && Boolean(this.onDelete.observers[0]);
    this.options.canUpdate = this.options.canUpdate && Boolean(this.onUpdate.observers[0]);
    this.userService.getCurrentUser().then(user => {
      this.permissionList = (user && user.permissions) ? user.permissions : [];
    });
  }

  protected hasPermission(permission: string): boolean {
    return !!this.permissionList && !!this.permissionList.find(p => p === permission);
  }
}
