import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GameResultCategory } from '../../../../game/enum/game-result-category.enum';
import { GameControlFlow } from '../../../models/game-control-flow';
import { GameLevel } from '../../../models/game-level.model';
import { GameResult } from '../../../models/game-result.model';
import { GameUtilHelper } from '../../../models/helper/game-util-helper';
import { GameControlFlowTypeUtils } from '../../../utils/game-control-flow-type-utils';

@Component({
  selector: 'app-game-control-flow-create-modal',
  templateUrl: './game-control-flow-create-modal.component.html'
})
export class GameControlFlowCreateModalComponent implements OnInit {

  @Input() controlFlow: GameControlFlow;
  @Input() controlFlowTypes: number[];

  @Input() set levels(levels: GameLevel[]) {
    this.controlFlow.action.options = levels.map(level => ({
      value: level.id,
      label: level.title.getDefault() || level.id
    }));
  }

  @Input() set results(results: GameResult[]) {
    this.controlFlow.trigger.options = results.map(level => ({
      value: level.id,
      label: level.title.getDefault() || level.id
    }));
  }

  form = new FormGroup({});

  constructor(
    public activeModal: NgbActiveModal,
    private controlFlowTypeUtils: GameControlFlowTypeUtils,
    private readonly gameUtilHelper: GameUtilHelper
  ) {
  }

  create(): void {
    this.activeModal.close(this.controlFlow);
  }

  ngOnInit() {
  }

  private setControlFlowTypeOptions() {
    this.controlFlow.type.options = this.controlFlow.type.options.filter(controlFlowType => {
      return this.controlFlowTypes.indexOf(controlFlowType.value) !== -1;
    });

    this.controlFlow.type.value = this.controlFlow.type.options[ 0 ].value;
    this.controlFlow.parameters = this.controlFlowTypeUtils.createDictionary(this.controlFlow.type.value, 'parameters');
    this.controlFlow.textDictionary = this.controlFlowTypeUtils.createDictionary(this.controlFlow.type.value, 'textDictionary');
    this.controlFlow.assetDictionary = this.controlFlowTypeUtils.createDictionary(this.controlFlow.type.value, 'assetDictionary');
  }

  onTypeChange() {
    const config = this.controlFlowTypeUtils.getTypeConfig(this.controlFlow.type.value);
    this.controlFlow.category = GameResultCategory[ config.category.toUpperCase() ];
    this.controlFlow.parameters = this.gameUtilHelper.createDictionary(config, 'parameters');
    this.controlFlow.assetDictionary = this.gameUtilHelper.createDictionary(config, 'assetDictionary');
    this.controlFlow.textDictionary = this.gameUtilHelper.createDictionary(config, 'textDictionary');
  }
}
