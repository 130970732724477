import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ChannelListComponent } from '../../../app/channel/channel-list/channel-list.component';


const channelListRoutes: Routes = [
  {
    path: '',
    component: ChannelListComponent,
    data: {
      title: 'Channels',
      breadcrumb: false
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(channelListRoutes)],
  exports: [RouterModule]
})
export class ChannelListRoutingModule { }
