import { Injectable } from '@angular/core';
import { ApiBaseService } from "../../core/services/api-base.service";
import { ServerConfigModel } from "../models/server-config.model";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { UserService } from "../../core/services/user.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { AppConfig } from "../../app.config";
import { Status } from "../models/status.model";
import { catchError, map } from "rxjs/operators";
import { ModelMapperService } from "./mapper/model-mapper.service";

@Injectable()
export class ServerConfigService extends ApiBaseService<ServerConfigModel> {

  constructor(protected http: HttpClient,
              protected router: Router,
              protected userService: UserService,
              protected modalService: NgbModal,
              private modelMapper: ModelMapperService) {
    super(ServerConfigModel, 'cms-server', http, router, userService);
    this.modalService = modalService;
    this.url = AppConfig.CONFIG_SERVER + AppConfig.CONFIG_BASE_URL;
  }

  /**
   * Get a single entry
   */
  public getConfigForTenant(tenantId: string, status?: Status): Observable<ServerConfigModel> {
    const url = `${this.url}/cms-server/${tenantId}`;

    status = status || new Status();
    status.setLoading();

    return this.http.get(url).pipe(
      map(response => {
        if (this.checkJSON(response)) {
          status.setSuccess();
          return this.fromJSON(response);
        } else {
          status.setNoResults();
        }
      }),
      catchError(err => this.handleError(err, status)),);
  }

  /**
   * Create a default config for the selected tenant
   */
  public createDefaultConfigForTenant(tenantId: string, status?: Status): Observable<ServerConfigModel> {
    const url = `${this.url}/cms-server/${tenantId}`;

    status = status || new Status();
    status.setLoading();

    return this.http.post(url, {}).pipe(
      map(response => {
        if (this.checkJSON(response)) {
          status.setSuccess();
          return this.fromJSON(response);
        } else {
          status.setNoResults();
        }
      }),
      catchError(err => this.handleError(err, status)),);
  }

  checkJSON(json: any): boolean {
    return this.modelMapper.serverConfigCheckJSON(json);
  }

  emptyModel(): ServerConfigModel {
    return this.modelMapper.serverConfigEmptyModel();
  }

  fromJSON(json: any, skipCheck?: boolean): ServerConfigModel {
    return this.modelMapper.serverConfigFromJSON(json, skipCheck);
  }
}
