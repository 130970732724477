import { FormControlBase } from './form-control-base.model';
import { FormControlOptions } from '../../../../app/shared/interfaces/form-control-options.interface';
import { FormControlSelectOptions } from '../../../../app/shared/interfaces/form-control-select-options.interface';
import { FormControlType } from '../../enums/form-control-type.enum';


export class FormControlSelect extends FormControlBase<any> {
  formControlType: FormControlType = FormControlType.SELECT;
  options: FormControlSelectOptions<any> = []; // options for select input (!= formControlOptions)
  multiple = false;

  constructor(options: FormControlOptions<any>) {
    super(options);
    this.options = options['options'] || [];
    this.multiple = options['multiple'] || false;
  }
}
