import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GameMultiLangDictionaryEntry } from '../../models/game-multi-lang-dictionary-entry';
@Component({
  selector: 'app-game-text-list',
  templateUrl: './game-text-list.component.html',
  styleUrls: [ './game-text-list.component.css' ]
})
export class GameTextListComponent implements OnInit {

  filteredEntries: GameMultiLangDictionaryEntry[];

  @Input() set entries(entries: GameMultiLangDictionaryEntry[]) {
    this.filteredEntries = entries.filter(param => !param.invalid);
  }

  @Input() form: FormGroup;

  @Output() readonly changed: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

}
