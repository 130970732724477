import { NgModule } from '@angular/core';

import { SharedModule } from '../../../app/shared/shared.module';

import { LogoutRoutingModule } from '../../../app/user/logout/logout-routing.module';

import { LogoutComponent } from '../../../app/user/logout/logout.component';


@NgModule({
  declarations: [
    LogoutComponent
  ],
  imports: [
    SharedModule,
    LogoutRoutingModule
  ],
  providers: []
})
export class LogoutModule { }
