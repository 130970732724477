export enum PropertyValueType {
  boolean = 'boolean',
  boolean_array = 'boolean_array',
  string = 'string',
  string_array = 'string_array',
  number = 'number',
  number_array = 'number_array',
  MultiLanguageString = 'MultiLanguageString',
  MultiLanguageBoolean = 'MultiLanguageBoolean'
}
