import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { Injectable } from '@angular/core';
import { TranslationInterface } from "../../shared/models/translation.interface";
import { AuthService } from "./auth.service";

@Injectable()
export class CmsTranslationLoader implements TranslateLoader {

  protected url: string;
  protected tenantId = '';
  protected fallbackUrl = './assets/i18n/en.json';

  constructor(private httpClient: HttpClient,
              private authService: AuthService) {
    this.url = AppConfig.TRANSLATION_SERVER + AppConfig.TRANSLATION_BASE_URL + '/text';
  }

  getTranslation(lang: string): Observable<any> {
    return new Observable(observer => {
      if (!this.authService.isLoggedIn() || 'local_en' === lang) {
        console.log('TranslationLoader - user logged out');
        this.httpClient.get(`${this.fallbackUrl}`).subscribe((next: TranslationInterface) => {
          console.log('TranslationLoader - File loaded');
          observer.next(next);
          observer.complete();
        });
      } else {
        const url = `${this.url}/json/${lang}`;
        this.httpClient.get(url).subscribe((next: TranslationInterface) => {
            if (next) {
              // this.translationMap.set(lang, next);
              observer.next(next);
              observer.complete();
            } else {
              console.log('TranslationLoader - error use fallback');

              this.httpClient.get(`${this.fallbackUrl}`).subscribe((next: TranslationInterface) => {
                console.log('TranslationLoader - File loaded');
                observer.next(next);
                observer.complete();
              });
            }
          },
          err => {
            console.log('TranslationLoader - error response - use fallback');
            this.httpClient.get(`${this.fallbackUrl}`).subscribe((next: TranslationInterface) => {
              console.log('TranslationLoader - File loaded');
              observer.next(next);
              observer.complete();
            });
          });
      }
    });
  }
}
