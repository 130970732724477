import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToArray'
})
export class EnumToArrayPipe implements PipeTransform {

  transform(value: any): Object {
    return Object.keys(value).map(o => { return {value: value[o], name: o}});
  }
}
