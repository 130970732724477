import { Injectable } from '@angular/core';
import { FormControlOptions } from '../../interfaces/form-control-options.interface';
import { ConfigurationService } from '../../services/configuration.service';
import Utils from '../../utils/utils';
import { FormControlString } from '../form-control/form-control-string.model';
import { MultiLangString } from '../multi-lang-string.model';

@Injectable({providedIn: 'root'})
export class MultiLangStringHelper {
  constructor(
    private config: ConfigurationService
  ) {
  }

  fromJSON(json: object = {}, keyPrefix: string, formControlOptions: FormControlOptions<string>, presets: string[] = []): MultiLangString {
    const newJson = {};

    for (const locale in json) {
      if (Utils.isDefined(json[locale])) {
        formControlOptions['value'] = json[locale];
        formControlOptions['key'] = keyPrefix + '_' + locale;
        newJson[locale] = new FormControlString(formControlOptions);
      }
    }

    for (const locale of [...this.config.getLocalesConfig().available, ...presets]) {
      if (Utils.isUndefined(newJson[locale])) {
        formControlOptions['key'] = keyPrefix + '_' + locale;
        formControlOptions['value'] = '';
        newJson[locale] = new FormControlString(formControlOptions);
      }
    }

    return new MultiLangString(newJson || {}, this.getDefaultLocale());
  }

  getDefaultLocale(): string {
    return this.config.getLocalesConfig().default;
  }
}
