import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GameCategory } from '../../../../game/enum/game-category.enum';

import { Game } from '../../../models/game.model';
import { FileModelHelper } from '../../../models/helper/file-model.helper';
import { GameTypeUtils } from '../../../utils/game-type-utils';

@Component({
  selector: 'app-game-create-modal',
  templateUrl: './game-create-modal.component.html'
})
export class GameCreateModalComponent implements OnInit {
  @Input() game: Game;
  @Input() gameTypes: number[];
  form = new FormGroup({});

  constructor(
    public activeModal: NgbActiveModal,
    private gameTypeUtils: GameTypeUtils
  ) {
  }

  ngOnInit() {
    if (this.gameTypes) {
      this.setGameTypeOptions();
    } else {
      this.gameTypes = this.gameTypeUtils.getGameTypeValues();
      this.setGameTypeOptions();
    }
  }

  create(): void {
    this.updateModel();
    this.activeModal.close(this.game);
  }

  updateModel() {
    this.updateFile('styleFile');
    this.updateFile('gameFile');
    this.updateFile('htmlFile');
    this.game.category = this.getCategoryOfType();
    this.game.parameters = this.gameTypeUtils.createDictionary(this.game.type.value, 'parameters');
    this.game.textDictionary = this.gameTypeUtils.createDictionary(this.game.type.value, 'textDictionary');
    this.game.assetDictionary = this.gameTypeUtils.createDictionary(this.game.type.value, 'assetDictionary');
  }

  updateFile(file: 'styleFile' | 'gameFile' | 'htmlFile') {
    const config = this.gameTypeUtils.getTypeConfig(this.game.type.value);

    this.game[ file ].href = config[ file ];
    this.game[ file ].attribute = { value: 'file' };
    this.game[ file ].name.value = this.getFileName(config[ file ]);
  }

  getFileName(href: string): string {
    return href && href
      .split('/')
      .reverse()[ 0 ];
  }

  getCategoryOfType(): GameCategory {
    return GameCategory[ this.gameTypeUtils.getCategoryOfType(this.game.type.value).toUpperCase() ];
  }

  private setGameTypeOptions() {
    this.game.type.options = this.game.type.options.filter(gameType => {
      return this.gameTypes.indexOf(gameType.value) !== -1;
    });

    this.game.type.value = this.game.type.options[ 0 ].value;
    this.updateModel();
  }
}
