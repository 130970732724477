import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OrganizationListComponent } from "./organization-list.component";


const organizationListRoutes: Routes = [
  {
    path: '',
    component: OrganizationListComponent,
    data: {
      title: 'Organizations',
      breadcrumb: false
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(organizationListRoutes)],
  exports: [RouterModule]
})
export class OrganizationListRoutingModule { }
