import { FormControlOptions } from '../interfaces/form-control-options.interface';
import { FormControlSelectOptions } from '../interfaces/form-control-select-options.interface';

import Utils from '../../../app/shared/utils/utils';

import { TourTypeConfigOptions } from '../../../config/tour-type-config.interface';
import { ConfigurationService } from "../services/configuration.service";
import { StationTypeUtils } from "./station-type-utils";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class TourTypeUtils {

  private tourTypeValues;

  constructor(private config: ConfigurationService,
              private stationTypeUtils: StationTypeUtils) {
  }

  private getTourTypes(): TourTypeConfigOptions {
    if (this.config.isLoadingConfig()) {
      return undefined;
    }

    return Utils.clone(this.config.getTourTypeConfig());
  }

  public getLabel(tourType: string | number) {
    if (this.getTourTypes() && this.isValid(tourType)) {
      return this.getTourTypes()[Number(tourType)].label;
    }

    return '';
  }

  public hasField(tourType: string | number, field: string): boolean {
    if (this.getTourTypes()) {
      return Object.keys(this.getTourTypes()[Number(tourType)].fields).indexOf(field) >= 0;
    }

    return false;
  }

  public getFieldOptions(tourType: string | number, field: string, defaultOptions: FormControlOptions<any>): FormControlOptions<any> {
    if (this.getTourTypes()) {
      const options = this.hasField(tourType, field) ? Utils.clone(this.getTourTypes()[Number(tourType)].fields[field]) : {};

      for (const optionName in defaultOptions) {
        if (Utils.isDefined(defaultOptions[optionName])) {
          options[optionName] = Utils.isDefined(options[optionName]) ? options[optionName] : defaultOptions[optionName];
        }
      }

      options.key = defaultOptions.key || field;
      options.hidden = Utils.isDefined(defaultOptions.hidden) ? defaultOptions.hidden : !this.hasField(tourType, field);

      return options;
    }

    return {};
  }

  public getValues(): number[] {
    if (this.getTourTypes()) {
      this.tourTypeValues = this.tourTypeValues || Object.keys(this.getTourTypes())
        .filter(Number)
        .map(tourType => Number(tourType));

      return Utils.clone(this.tourTypeValues);
    }

    return [];
  }

  public getDefault(): number {
    return this.getValues()[0];
  }

  public isValid(tourType: string | number): boolean {
    return !isNaN(Number(tourType)) && this.getValues().indexOf(Number(tourType)) >= 0;
  }

  public getSelectOptions(): FormControlSelectOptions<number> {
    return this.getValues()
      .map(tourType => {
        return {
          value: tourType,
          label: this.getLabel(tourType)
        };
      });
  }

  public getStationTypes(tourType: string | number): number[] {
    if (this.getTourTypes()) {
      const stationTypes = this.getTourTypes()[Number(tourType)].stationTypes;
      return (stationTypes === 'all' ? this.stationTypeUtils.getMainStationTypeValues() : stationTypes) || [];
    }

    return [];
  }

  getModules(tourType: string | number): string[] {
    if (this.getTourTypes()) {
      return Utils.clone(this.getTourTypes()[Number(tourType)].modules  || []);
    }

    return [];
  }

  isModuleEnabled(tourType: string | number, module: string): boolean {
    return this.getModules(tourType).indexOf(module) >= 0;
  }
}
