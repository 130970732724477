import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MetaInformationService } from '../../../../app/core/services/meta-information.service';


@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  breadcrumbs: Array<object>;

  private breadcrumbsSubscription: any;

  constructor(
    private route: ActivatedRoute,
    private metaInformationService: MetaInformationService
  ) {
    // TODO: Should be in ngOnInit block,
    // only a temporary solution for this issue https://github.com/angular/angular/issues/17473
    this.breadcrumbsSubscription = this.metaInformationService.breadcrumbsChange
      .subscribe(breadcrumbs => this.breadcrumbs = breadcrumbs);

    this.metaInformationService.initBreadcrumbs(route);
  }

  ngOnInit() {
    // this.breadcrumbsSubscription = this.metaInformationService.breadcrumbsChange
    //   .subscribe(breadcrumbs => this.breadcrumbs = breadcrumbs);
  }

  ngOnDestroy() {
    this.breadcrumbsSubscription.unsubscribe();
  }
}
