import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../app/core/services/auth-guard.service';
import { PermissionGuard } from '../app/core/services/permission-guard.service';
import { AccessDeniedComponent } from './core/components/access-denied/access-denied.component';
import { BreadcrumbsComponent } from '../app/core/components/breadcrumbs/breadcrumbs.component';

import { DashboardComponent } from '../app/dashboard/dashboard.component';

import Utils from '../app/shared/utils/utils';

import { AppConfig } from '../app/app.config';


const appRoutes: Routes = [
  {
    path: '', // Used for login check
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
        data: {
          title: 'Dashboard'
        }
      },
      {
        path: '',
        component: BreadcrumbsComponent,
        children: [
          {
            path: '', // Used for breadcrumb only
            data: {
              title: 'Dashboard'
            },
            children: [
              {
                path: AppConfig.APP_ROUTES.exhibition.path,
                data: AppConfig.APP_ROUTES.exhibition.data,
                loadChildren: '../app/exhibition/exhibition.module#ExhibitionModule',
                canActivate: [PermissionGuard]
              },
              {
                path: AppConfig.APP_ROUTES.tour.path,
                data: AppConfig.APP_ROUTES.tour.data,
                loadChildren: '../app/tour/tour.module#TourModule',
                canActivate: [PermissionGuard]
              },
              {
                path: AppConfig.APP_ROUTES.group.path,
                data: AppConfig.APP_ROUTES.group.data,
                loadChildren: '../app/group/group.module#GroupModule',
                canActivate: [PermissionGuard]
              },
              {
                path: AppConfig.APP_ROUTES.station.path,
                data: AppConfig.APP_ROUTES.station.data,
                loadChildren: '../app/station/station.module#StationModule',
                canActivate: [PermissionGuard]
              },
              {
                path: 'substations',
                data: Object.assign(Utils.clone(AppConfig.APP_ROUTES.station.data), {
                  title: 'Sub Stations',
                  subStations: true
                }),
                loadChildren: '../app/station/station.module#StationModule',
                canActivate: [PermissionGuard]
              },
              {
                path: AppConfig.APP_ROUTES.content.path,
                data: AppConfig.APP_ROUTES.content.data,
                loadChildren: '../app/content/content.module#ContentModule',
                canActivate: [PermissionGuard]
              },
              {
                path: AppConfig.APP_ROUTES.channel.path,
                data: AppConfig.APP_ROUTES.channel.data,
                loadChildren: '../app/channel/channel.module#ChannelModule',
                canActivate: [PermissionGuard]
              },
              {
                path: AppConfig.APP_ROUTES.game.path,
                data: AppConfig.APP_ROUTES.game.data,
                loadChildren: '../app/game/game.module#GameModule',
                canActivate: [PermissionGuard]
              },
              {
                path: AppConfig.APP_ROUTES.community.path,
                data: AppConfig.APP_ROUTES.community.data,
                loadChildren: '../app/community/community.module#CommunityModule',
                canActivate: [PermissionGuard]
              },
              {
                path: AppConfig.APP_ROUTES.userContent.path,
                data: AppConfig.APP_ROUTES.userContent.data,
                loadChildren: '../app/community/user-content/user-content.module#UserContentModule',
                canActivate: [PermissionGuard]
              },
              {
                path: AppConfig.APP_ROUTES.map.path,
                data: AppConfig.APP_ROUTES.map.data,
                loadChildren: '../app/map/map.module#MapModule',
                canActivate: [PermissionGuard]
              },
              {
                path: AppConfig.APP_ROUTES.cdn.path,
                data: AppConfig.APP_ROUTES.cdn.data,
                loadChildren: '../app/cdn/cdn.module#CdnModule',
                canActivate: [PermissionGuard]
              },
              {
                path: AppConfig.APP_ROUTES.user.path,
                data: AppConfig.APP_ROUTES.user.data,
                loadChildren: '../app/user/user.module#UserModule',
                canActivate: [PermissionGuard]
              },
              {
                path: AppConfig.APP_ROUTES.html.path,
                data: AppConfig.APP_ROUTES.html.data,
                loadChildren: '../app/html/html.module#HtmlModule',
                canActivate: [PermissionGuard]
              },
              {
                path: AppConfig.APP_ROUTES.organization.path,
                data: AppConfig.APP_ROUTES.organization.data,
                loadChildren: '../app/organization/organization.module#OrganizationModule',
                canActivate: [PermissionGuard]
              }
            ]
          }
        ]
      }
    ],
  },
  {
    path: 'accessdenied',
    data: {
      title: 'Access Denied'
    },
    component: AccessDeniedComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
