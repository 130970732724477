import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluck'
})
export class PluckPipe implements PipeTransform {

  transform(arr: object[], key: string): any[] {
    if (Array.isArray(arr)) {
      return arr
        .filter(item => typeof item === 'object' && !!item[key])
        .map(item => item[key]);
    }

    return [];
  }

}
