import * as moment from 'moment';
import { UserContentStatusType } from '../../enums/user-content-status-type.enum';
import { UserContentTypeUtils } from '../../utils/user-content-type-utils';
import Utils from '../../utils/utils';
import { FormControlSelect } from '../form-control/form-control-select.model';
import { UserContent } from '../user-content.model';
import { FileModelHelper } from './file-model.helper';
import { ModelHelper } from './model.helper.interface';
import { MultiLangStringHelper } from './multi-lang-string.helper';
import { UserContentPropertyHelper } from './user-content-property-helper';

export class UserContentModelHelper implements ModelHelper<UserContent> {

  constructor(
    private readonly multiLangStringHelper: MultiLangStringHelper,
    private readonly fileHelper: FileModelHelper,
    private readonly userContentPropertyHelper: UserContentPropertyHelper,
    private readonly userContentTypeUtils: UserContentTypeUtils,
  ) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json.id);
  }

  emptyModel(): UserContent {
    return new UserContent(
      '',
      this.multiLangStringHelper.fromJSON(
        {},
        'title',
        this.userContentTypeUtils.getFieldOptions(this.userContentTypeUtils.getDefaultType(), 'title', {
          label: 'label.userContent.field.title',
          hidden: false
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'description',
        this.userContentTypeUtils.getFieldOptions(this.userContentTypeUtils.getDefaultType(), 'description', {
          label: 'label.userContent.field.description',
          hidden: false
        })
      ),
      new FormControlSelect({
        value: UserContentStatusType.NEW,
        key: 'status',
        label: 'label.general.status',
        options: this.userContentTypeUtils.getStatusSelectOptions(),
        required: true,
        hidden: false
      }),
      new FormControlSelect({
        value: this.userContentTypeUtils.getDefaultType(),
        key: 'type',
        label: 'label.general.type',
        options: this.userContentTypeUtils.getTypeSelectOptions(),
        required: true,
        hidden: false
      }),
      [],
      undefined,
      {},
      []
    );
  }

  fromJSON(json: any, skipCheck = false): UserContent {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    const userContentType = Number(json.type);
    const typeConfig = this.userContentTypeUtils.getTypeConfig(userContentType);

    // create userContent object
    return new UserContent(
      json.id,
      this.multiLangStringHelper.fromJSON(
        json.title,
        'title',
        this.userContentTypeUtils.getFieldOptions(this.userContentTypeUtils.getDefaultType(), 'title', {
          label: 'label.userContent.field.title',
          hidden: false
        }),
        json.title ? Object.keys(json.title) : []
      ),
      this.multiLangStringHelper.fromJSON(
        json.description,
        'description',
        this.userContentTypeUtils.getFieldOptions(this.userContentTypeUtils.getDefaultType(), 'description', {
          label: 'label.userContent.field.description',
          hidden: false
        }),
        json.description ? Object.keys(json.description) : []
      ),
      new FormControlSelect({
        value: json.status,
        key: 'status',
        label: 'label.general.status',
        options: this.userContentTypeUtils.getStatusSelectOptions(),
        required: true,
        hidden: false
      }),
      new FormControlSelect({
        value: userContentType,
        key: 'type',
        label: 'label.general.type',
        options: this.userContentTypeUtils.getTypeSelectOptions(),
        required: true,
        hidden: false
      }),
      json.channelIds,
      json.elementRef,
      this.userContentPropertyHelper.mapProperties(json.properties, typeConfig),
      (json.files || [])
        .filter(file => this.fileHelper.checkJSON(file))
        .map(file => this.fileHelper.fromJSON(file)),
      json.createdOn ? moment(json.createdOn).toDate() : undefined,
      json.createdBy,
      json.lastModified ? moment(json.lastmodified).toDate() : undefined,
      json.modifiedBy,
      json.version
    );
  }
}
