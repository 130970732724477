import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-check',
  template: `
    <i class="fa" [ngClass]="{'text-success fa-check-circle': check, 'text-muted fa-circle-thin': !check, 'icon-lg': !small}"></i>
  `
})
export class CheckComponent {

  @Input() check = false;
  @Input() small = false;

  constructor() { }
}
