import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { GameSelection } from '../../../models/game-selection.model';
import { GameSelectionTypeUtils } from '../../../utils/game-selection-type-utils';

@Component({
  selector: 'app-game-selection-create-modal',
  templateUrl: './game-selection-create-modal.component.html'
})
export class GameSelectionCreateModalComponent implements OnInit {

  @Input() selection: GameSelection;
  @Input() selectionTypes: number[];
  form = new FormGroup({});

  constructor(
    public activeModal: NgbActiveModal,
    private selectionTypeUtils: GameSelectionTypeUtils
  ) {
  }

  create(): void {
    this.activeModal.close(this.selection);
  }

  ngOnInit() {
    if (this.selectionTypes) {
      this.setSelectionTypeOptions();
    } else {
      this.selectionTypes = this.selectionTypeUtils.getTypeValues();
      this.setSelectionTypeOptions();
    }
  }

  private setSelectionTypeOptions() {
    this.selection.type.options = this.selection.type.options.filter(selectionType => {
      return this.selectionTypes.indexOf(selectionType.value) !== -1;
    });

    this.selection.type.value = this.selection.type.options[0].value;
    this.selection.parameters = this.selectionTypeUtils.createDictionary(this.selection.type.value, 'parameters');
    this.selection.textDictionary = this.selectionTypeUtils.createDictionary(this.selection.type.value, 'textDictionary');
    this.selection.assetDictionary = this.selectionTypeUtils.createDictionary(this.selection.type.value, 'assetDictionary');
  }
}
