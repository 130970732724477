import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { MultiLangBoolean } from '../../../../../app/shared/models/multi-lang-boolean.model';
import { PermissionsComponent } from "../../permissions/permissions.component";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "../../../../core/services/user.service";
import { PermissionEnum } from "../../../enums/permission.enum";
import { ConfigurationService } from "../../../services/configuration.service";


@Component({
  selector: 'app-btn-boolean-multi-lang',
  templateUrl: './btn-boolean-multi-lang.component.html'
})
export class BtnBooleanMultiLangComponent extends PermissionsComponent implements OnInit {
  selectedLocale: string;

  @Input() boolean: MultiLangBoolean;
  @Input() active = 'Active';
  @Input() inactive = 'Inactive';
  @Input() updatePermission = PermissionEnum.TOUR_UPDATE;
  @Input() createPermission = PermissionEnum.TOUR_CREATE;
  @Output() onClick = new EventEmitter();

  constructor(protected route: ActivatedRoute,
              protected userService: UserService) {
    super(route, userService);
  }

  onButtonClick($event?): void {
    this.onClick.emit();
  }

  ngOnInit() {
    super.ngOnInit();
    this.selectedLocale = this.boolean.defaultLocation;
  }
}
