import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardComponent } from '../../app/dashboard/dashboard.component';

import { ExhibitionListModule } from '../../app/exhibition/exhibition-list/exhibition-list.module';
import { ChannelListModule } from '../../app/channel/channel-list/channel-list.module';
import { SyncModule } from '../sync/sync.module';
import { OrganizationListModule } from "../organization/organization-list/organization-list.module";


@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    CommonModule,
    ExhibitionListModule,
    ChannelListModule,
    SyncModule,
    OrganizationListModule
  ],
  exports: [
    DashboardComponent
  ],
  providers: []
})
export class DashboardModule { }
