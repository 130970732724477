import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';

import { UserService } from '../../core/services/user.service';

import { PermissionsComponent } from '../../shared/components/permissions/permissions.component';

import { Exhibition } from '../../shared/models/exhibition.model';
import { Status } from '../../shared/models/status.model';
import { ExhibitionService } from '../../shared/services/exhibition.service';

@Component({
  selector: 'app-exhibition-list',
  templateUrl: './exhibition-list.component.html'
})
export class ExhibitionListComponent extends PermissionsComponent implements OnInit {

  @Input() detailLink = './';

  exhibitions$: Observable<Exhibition[]>;
  status = new Status();

  constructor(
    protected route: ActivatedRoute,
    protected userService: UserService,
    private exhibitionService: ExhibitionService
  ) {
    super(route, userService);
  }

  private getExhibitions(): void {
    this.exhibitions$ = this.exhibitionService.getAll(this.status);
  }

  create(): void {
    this.exhibitionService.create({ route: this.route, url: this.detailLink || './' });
  }

  delete(exhibition: Exhibition): void {
    this.exhibitionService.delete(exhibition.id, this.status)
      .then(() => this.loadData(), () => {
      });
  }

  loadData(): void {
    this.getExhibitions();
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
  }
}
