import { FileType } from '../enums/file-type.enum';

import { BaseFilesModel } from './base-files.model';
import { File } from './file.model';
import { VersionModel } from "./base.model";


export class Map extends BaseFilesModel {

  constructor(
    public id: string,
    public label,
    public scaleX,
    public scaleY,
    public files: File[],
    public filesToDelete: File[],
    public blockedUsers: string[],
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string,
    public version?: number,
    public versions?: VersionModel[],
    public baseId?: string,
  ) {
    super(id, blockedUsers, createdOn, creatorName, lastModified, modifiedBy, version, versions, baseId, files, filesToDelete);
  }

  toJSON(): object {
    const json = super.toJSON();

    json['label'] = this.label.value;
    json['scale_x'] = this.scaleX.value;
    json['scale_y'] = this.scaleY.value;

    return json;
  }

  getFileTypes(): { type: number, attribute: { label: string, value: string }, hasLanguage: boolean, hasDuration: boolean }[] {
    return [{
      type: FileType.IMAGE, attribute: {
        label: 'label.map.field.image',
        value: 'image'
      },
      hasLanguage: false,
      hasDuration: false
    }];
  }
}
