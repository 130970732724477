import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../core/services/user.service';
import { PermissionEnum } from '../../enums/permission.enum';
import { BaseComponent } from '../base/base.component';

export abstract class PermissionsComponent extends BaseComponent implements OnInit, OnDestroy {
  Permissions: typeof PermissionEnum = PermissionEnum;

  permissionsLoaded = false;
  permissionList: string[];

  constructor(
    protected route: ActivatedRoute,
    protected userService: UserService
  ) {
    super(route);
  }

  ngOnInit() {
    this.userService.getCurrentUser()
      .then(user => {
        this.permissionList = (user && user.permissions) ? user.permissions : [];
        this.permissionsLoaded = true;
      }, () => this.permissionsLoaded = true);
  }

  hasPermission(permission: string): boolean {
    return !!this.permissionList && !!this.permissionList.find(p => p === permission);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}

