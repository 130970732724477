import { BaseModel } from "./base.model";
import { FormControlString } from "./form-control/form-control-string.model";

export class Organization extends BaseModel {

  constructor(
    public id: string,
    public tenantId: string,
    public name: FormControlString ,
    public active: boolean,
    public blockedUsers: string[],
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string
  ) {
    super(id, blockedUsers, createdOn, creatorName, lastModified, modifiedBy);
  }

  toJSON(): object {
    const json = super.toJSON();

    json['id'] = this.id;
    json['tenantId'] = this.tenantId;
    json[this.name.apiParam] = this.name.value;
    json['active'] = this.active;

    return json;
  }
}
