import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';

import { UserService } from '../../../app/core/services/user.service';

import { PermissionsComponent } from '../../../app/shared/components/permissions/permissions.component';

import { Channel } from '../../../app/shared/models/channel.model';
import { Status } from '../../../app/shared/models/status.model';
import { ChannelService } from '../../../app/shared/services/channel.service';
import { SyncService } from "../../core/services/sync.service";
import { SyncMetaModel } from "../../shared/models/sync-meta.model";


@Component({
  selector: 'app-channel-list',
  templateUrl: './channel-list.component.html'
})
export class ChannelListComponent extends PermissionsComponent implements OnInit {

  @Input() detailLink = './';

  channels$: Observable<Channel[]>;
  status = new Status();

  constructor(
    protected route: ActivatedRoute,
    protected userService: UserService,
    private channelService: ChannelService,
    private syncService: SyncService
  ) {
    super(route, userService);
  }

  private getChannels(): void {
    this.channels$ = this.channelService.getAll(this.status);

  }

  create(): void {
    this.channelService.create({route: this.route, url: this.detailLink || './'});
  }

  update(channel: Channel): void {
    this.channelService.setActive(channel).pipe(
      takeUntil(this.destroy$))
      .subscribe();
  }

  delete(channel: Channel): void {
    this.channelService.delete(channel.id, this.status)
      .then(() => this.loadData(), () => {
      });
  }

  triggerBuild(channel: Channel) {
    this.syncService.createDeltaForChannel(channel.name.value).then(() => {
      console.log('build triggered for channel: ' + channel.name.value);
      this.loadData();
    });
  }

  loadData(): void {
    this.getChannels();
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
  }
}
