import { Component, Input } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { TableBaseComponent } from '../table-base/table-base.component';

import { Station } from '../../../models/station.model';
import { ConfigurationService } from "../../../services/configuration.service";
import { TableSearch } from "../../../interfaces/table-search.interface";
import { UserService } from "../../../../core/services/user.service";


@Component({
  selector: 'app-station-table',
  templateUrl: './station-table.component.html'
})
export class StationTableComponent extends TableBaseComponent<Station> {

  @Input() stationTypes: number[];
  @Input() showAll: boolean;
  @Input() detailLink = '/stations';

  tableConfig: any = this.getTableConfig('station');
  stationTableSearch: TableSearch;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected config: ConfigurationService,
    protected userService: UserService
  ) {
    super(router, route, config, userService);
    this.stationTableSearch = this.config.getStationTableSearchConfig();
  }

  getGlobalFilterFields() {
    this.globalFilters = this.globalFilters || this.stationTableSearch || [{
      label: 'ID',
      field: 'id',
      active: this.showId
    }, {
      label: 'Title',
      field: 'title.' + this.data[0].title.defaultLocale + '.value',
      active: true
    }];

    return this.globalFilters
      .filter(filter => filter.active)
      .map(filter => filter.field);
  }
}
