import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Status } from '../../../../../app/shared/models/status.model';
import { GameLevel } from '../../../models/game-level.model';
import { GameLevelTypeUtils } from '../../../utils/game-level-type-utils';

@Component({
  selector: 'app-game-level-list-modal',
  templateUrl: './game-level-list-modal.component.html'
})
export class GameLevelListModalComponent {

  @Input() levels: GameLevel[];
  @Input() levelTypes: number[];
  @Input() status: Status;

  selection: GameLevel[] = [];

  constructor(
    private levelTypeUtils: GameLevelTypeUtils,
    public activeModal: NgbActiveModal
  ) {
    if (!this.levelTypes) {
      this.levelTypes = this.levelTypeUtils.getLevelTypeValues();
    }
  }
}
