import { Pipe, PipeTransform } from '@angular/core';

import { Tour } from '../models/tour.model';
import { TourTypeUtils } from "../utils/tour-type-utils";

@Pipe({
  name: 'tourTypeFilter'
})
export class TourTypeFilterPipe implements PipeTransform {

  constructor(private tourTypeUtils: TourTypeUtils) {
  }

  transform(tours: Tour[], tourType: any): any {
    return tours ? tours.filter(tour => {
      return tour ? Number(tour.type.value) === (isNaN(tourType) ?
      this.tourTypeUtils.getLabel(tourType) : tourType) : false;
    }) : [];
  }
}
