import { ModuleType } from '../app/shared/enums/module-type.enum';
import { PermissionEnum } from '../app/shared/enums/permission.enum';

export const routes = {
  exhibition: {
    path: 'exhibitions',
    data: {
      title: 'label.exhibition.title',
      module: ModuleType.EXHIBITION,
      permission: PermissionEnum.EXHIBITION_READ
    }
  },
  tour: {
    path: 'tours',
    data: {
      title: 'label.tour.title',
      module: ModuleType.TOUR,
      permission: PermissionEnum.TOUR_READ
    }
  },
  group: {
    path: 'groups',
    data: {
      title: 'label.group.title',
      module: ModuleType.GROUP,
      permission: PermissionEnum.GROUP_READ
    }
  },
  station: {
    path: 'stations',
    data: {
      title: 'label.station.title',
      module: ModuleType.STATION,
      permission: PermissionEnum.STATION_READ
    }
  },
  content: {
    path: 'content',
    data: {
      title: 'label.content.title',
      module: ModuleType.CONTENT,
      permission: PermissionEnum.CONTENT_READ
    }
  },
  channel: {
    path: 'channels',
    data: {
      title: 'label.channel.title',
      module: ModuleType.CHANNEL,
      permission: PermissionEnum.CHANNEL_READ
    }
  },
  map: {
    path: 'maps',
    data: {
      title: 'label.map.title',
      module: ModuleType.MAP,
      permission: PermissionEnum.MAP_READ
    }
  },
  game: {
    path: 'games',
    data: {
      title: 'label.game.title',
      module: ModuleType.GAME,
      permission: PermissionEnum.GAME_READ
    }
  },
  level: {
    path: 'levels',
    data: {
      title: 'label.level.title',
      module: ModuleType.LEVEL,
      permission: PermissionEnum.LEVEL_READ
    }
  },
  result: {
    path: 'results',
    data: {
      title: 'label.result.title',
      module: ModuleType.RESULT,
      permission: PermissionEnum.RESULT_READ
    }
  },
  element: {
    path: 'elements',
    data: {
      title: 'label.element.title',
      module: ModuleType.ELEMENT,
      permission: PermissionEnum.ELEMENT_READ
    }
  },
  selection: {
    path: 'selections',
    data: {
      title: 'label.selection.title',
      module: ModuleType.SELECTION,
      permission: PermissionEnum.SELECTION_READ
    }
  },
  solution: {
    path: 'solutions',
    data: {
      title: 'label.solution.title',
      module: ModuleType.SOLUTION,
      permission: PermissionEnum.SOLUTION_READ
    }
  },
  community: {
    path: 'community',
    data: {
      title: 'label.community.title',
      module: ModuleType.COMMUNITY,
      permission: PermissionEnum.COMMUNITY_READ
    }
  },
  userContent: {
    path: 'user-content',
    data: {
      title: 'label.user-content.title',
      module: ModuleType.USER_CONTENT,
      permission: PermissionEnum.USER_CONTENT_READ
    }
  },
  cdn: {
    path: 'cdn',
    data: {
      title: 'label.cdn.title',
      module: ModuleType.CDN,
      permission: PermissionEnum.CDN_READ
    }
  },
  user: {
    path: 'users',
    data: {
      title: 'label.user.title',
      module: ModuleType.USER,
      permission: PermissionEnum.USER_UPDATE
    }
  },
  organization: {
    path: 'organizations',
    data: {
      title: 'label.organization.title',
      module: ModuleType.ORGANIZATION,
      permission: PermissionEnum.ORGANIZATION_READ
    }
  },
  html: {
    path: 'html',
    data: {
      title: 'label.html.title',
      module: ModuleType.HTML,
      permission: PermissionEnum.HTML_READ
    }
  },
};
