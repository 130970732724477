import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { TableBaseComponent } from "../table-base/table-base.component";
import { HtmlPage } from "../../../models/html-page.model";
import { HtmlContent } from "../../../models/html-content.model";
import { ConfigurationService } from "../../../services/configuration.service";
import { TourTypeUtils } from "../../../utils/tour-type-utils";
import { UserService } from "../../../../core/services/user.service";

@Component({
  selector: 'app-html-content-table',
  templateUrl: './html-content-table.component.html'
})
export class HtmlContentTableComponent extends TableBaseComponent<HtmlContent> {

  @Input() detailLink = '/html-content';

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected config: ConfigurationService,
              protected userService: UserService) {
    super(router, route, config, userService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getGlobalFilterFields() {
    // this.globalFilters = this.globalFilters || [{
    //   label: 'Name',
    //   field: 'name.' + this.data[0].name.defaultLocale + '.value',
    //   active: true
    // }];

    return [];
    // this.globalFilters
    //   .filter(filter => filter.active)
    //   .map(filter => filter.field);
  }
}
