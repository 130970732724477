import { GameSolutionCategory } from '../../game/enum/game-solution-category.enum';
import { FormControlBase } from './form-control/form-control-base.model';
import { FormControlNumber } from './form-control/form-control-number.model';
import { FormControlSelect } from './form-control/form-control-select.model';
import { GameAssetDictionaryEntry } from './game-asset-dictionary-entry';
import { GameMultiLangDictionaryEntry } from './game-multi-lang-dictionary-entry';
import { GameParameter } from './game-parameter';

import { BaseModel } from './base.model';
import { MultiLangString } from './multi-lang-string.model';

export class GameSolution extends BaseModel {

  constructor(
    public id: string,
    public title: MultiLangString,
    public description: MultiLangString,
    public type: FormControlSelect,
    public category: GameSolutionCategory,
    public score: FormControlNumber,
    public parameters: GameParameter[],
    public textDictionary: GameMultiLangDictionaryEntry[],
    public assetDictionary: GameAssetDictionaryEntry[]
  ) {
    super(id);
  }

  get selections(): FormControlBase<any> {
    const selections = this.parameters.find(param => param.ctrl.key === 'selections');

    return selections && selections.ctrl;
  }

  toJSON(): object {
    const json = {};

    json[ 'id' ] = this.id;
    json[ 'title' ] = this.title.toJSON();
    json[ 'description' ] = this.description.toJSON();
    json[ 'type' ] = this.type.value;
    json[ 'category' ] = this.category;
    json[ 'score' ] = this.score.value;
    json[ 'parameters' ] = this.parameters ? this.parameters.map(p => p.toJSON()) : [];
    json[ 'textDictionary' ] = this.textDictionary ? this.textDictionary.map(d => d.toJSON()) : [];
    json[ 'assetDictionary' ] = this.assetDictionary ? this.assetDictionary.map(d => d.toJSON()) : [];

    return json;
  }

  addSelection(id: string): void {

    if (!this.selections || !Array.isArray(this.selections.value)) {
      return;
    }

    this.selections.value = [...this.selections.value .filter(selection => selection !== id), id ];
    this.setChanged(true);
  }

  deleteSelection(id: string): void {
    if (!this.selections || !Array.isArray(this.selections.value)) {
      return;
    }

    this.selections.value = this.selections.value.filter(selection => selection !== id);
    this.setChanged(true);
  }
}
