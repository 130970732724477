import { FormControlSelect } from "./form-control/form-control-select.model";

export class FileConverterModel {

  constructor(
    public audioStereo: FormControlSelect = new FormControlSelect({
      key: 'audioStereo',
      label: 'label.config.converter.field.audio-stereo',
      options: ['noOpConverter', 'audioMonoConverter'].map(entry => {
        return {label: entry, value: entry}
      })
    }),
    public image: FormControlSelect = new FormControlSelect({
      key: 'image',
      label: 'label.config.converter.field.image',
      options: ['noOpConverter'].map(entry => {
        return {label: entry, value: entry}
      })
    }),
    public video: FormControlSelect = new FormControlSelect({
      key: 'video',
      label: 'label.config.converter.field.videor',
      options: ['noOpConverter'].map(entry => {
        return {label: entry, value: entry}
      })
    })) {
  }

  static fromJSON(json) {
    const model = new FileConverterModel(
      new FormControlSelect({
        key: 'audioStereo',
        label: 'label.config.converter.field.audio-stereo',
        options: ['noOpConverter', 'audioMonoConverter'].map(entry => {
          return {label: entry, value: entry}
        }),
        value: json ? json.audioStereo || 'noOpConverter' : 'noOpConverter'
      }),
      new FormControlSelect({
        key: 'image',
        label: 'label.config.converter.field.image',
        options: ['noOpConverter'].map(entry => {
          return {label: entry, value: entry}
        }),
        value: json ? json.image || 'noOpConverter' : 'noOpConverter'
      }),
      new FormControlSelect({
        key: 'video',
        label: 'label.config.converter.field.video',
        options: ['noOpConverter'].map(entry => {
          return {label: entry, value: entry}
        }),
        value: json ? json.video || 'noOpConverter' : 'noOpConverter'
      })
    );

    return model;
  }

  toJSON() {
    const json = {
      audioStereo: this.audioStereo.value,
      image: this.image.value,
      video: this.video.value
    };

    return json;
  }
}
