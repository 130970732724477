import { FormControlString } from "./form-control/form-control-string.model";

export class AzureModel {

  constructor(
    public containerName: FormControlString = new FormControlString({
      key: 'containerName',
      label: 'label.config.azure.field.container-name',
      required: true
    }),
    public accountName: FormControlString = new FormControlString({
      key: 'accountName',
      label: 'label.config.azure.field.account-name',
      required: true
    }),
    public accessKey: FormControlString = new FormControlString({
      key: 'accessKey',
      label: 'label.config.azure.field.access-key',
      required: true
    }),
    public destinationFolder: FormControlString = new FormControlString({
      key: 'destinationFolder',
      label: 'label.config.azure.field.folder',
      required: false
    })) {
  }

  static checkJSON(json: any): boolean {
    return json;
  }

  static fromJSON(json): AzureModel {
    return new AzureModel(
      new FormControlString({
        key: 'containerName',
        label: 'label.config.azure.field.container-name',
        required: true,
        value: json.containerName
      }),
      new FormControlString({
        key: 'accountName',
        label: 'label.config.azure.field.account-name',
        required: true,
        value: json.accountName
      }),
      new FormControlString({
        key: 'accessKey',
        label: 'label.config.azure.field.access-key',
        required: true,
        value: json.accessKey
      }),
      new FormControlString({
        key: 'destinationFolder',
        label: 'label.config.azure.field.folder',
        required: false,
        value: json.destinationFolder
      })
    );
  }

  toJSON() {
    const json = {
      containerName: this.containerName.value,
      accountName: this.accountName.value,
      accessKey: this.accessKey.value,
      destinationFolder: this.destinationFolder.value
    };

    return json;
  }

}
