import {
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GameSolution } from '../../models/game-solution.model';

@Component({
  selector: 'app-area-marker',
  templateUrl: './area-marker.component.html',
  styleUrls: [ './area-marker.component.css' ]
})
export class AreaMarkerComponent implements OnInit {

  @Input() solution: GameSolution;
  @Input() otherSolutions: GameSolution[] = [];

  @Input() form: FormGroup;
  @Input() imgUrl: string;
  @ViewChild('marker') marker: ElementRef;
  @ViewChildren('otherMarkers') otherMarkers: QueryList<ElementRef>;

  constructor(
    private el: ElementRef
  ) {
  }

  ngOnInit() {

  }

  setMarkerStyles(): void {
    this.setMarkerStyle(this.marker.nativeElement, this.solution);
    this.otherMarkers.forEach((otherMarker, i) => this.setMarkerStyle(otherMarker.nativeElement, this.otherSolutions[i]));
  }

  setMarkerStyle(marker: HTMLElement, solution: GameSolution): void {
    marker.style.left = this.calcLeft(solution) + 'px';
    marker.style.top = this.calcTop(solution) + 'px';
    marker.style.width = this.calcWidth(solution) + 'px';
    marker.style.height = this.calcHeight(solution) + 'px';
  }

  calcLeft(solution: GameSolution): number {
    const xMinPercent = this.getParameter('xMinPercent', solution);

    return this.el.nativeElement.offsetWidth * xMinPercent / 100;
  }

  calcTop(solution: GameSolution): number {
    const yMinPercent = this.getParameter('yMinPercent', solution);

    return this.el.nativeElement.offsetHeight * yMinPercent / 100;
  }

  calcWidth(solution: GameSolution): number {
    const xMinPercent = this.getParameter('xMinPercent', solution);
    const xMaxPercent = this.getParameter('xMaxPercent', solution);

    return this.el.nativeElement.offsetWidth * (xMaxPercent - xMinPercent) / 100;
  }

  calcHeight(solution: GameSolution): number {
    const yMinPercent = this.getParameter('yMinPercent', solution);
    const yMaxPercent = this.getParameter('yMaxPercent', solution);

    return this.el.nativeElement.offsetHeight * (yMaxPercent - yMinPercent) / 100;
  }

  updateModel(marker: HTMLElement) {
    const markerRect = marker.getBoundingClientRect();
    const containerRect = this.el.nativeElement.getBoundingClientRect();

    const xMinPercent = (markerRect.left - containerRect.left) * 100 / containerRect.width;
    const xMaxPercent = (markerRect.right - containerRect.left) * 100 / containerRect.width;
    const yMinPercent = (markerRect.top - containerRect.top) * 100 / containerRect.height;
    const yMaxPercent = (markerRect.bottom - containerRect.top) * 100 / containerRect.height;

    this.setParameter('xMinPercent', Math.min(Math.max(0, xMinPercent), 100), this.solution);
    this.setParameter('xMaxPercent', Math.min(Math.max(0, xMaxPercent), 100), this.solution);
    this.setParameter('yMinPercent', Math.min(Math.max(0, yMinPercent), 100), this.solution);
    this.setParameter('yMaxPercent', Math.min(Math.max(0, yMaxPercent), 100), this.solution);

    this.solution.setChanged();
  }

  getParameter(key: string, solution: GameSolution): number {
    const param = this.findParameter(key, solution);

    return param && +param.ctrl.value;
  }

  setParameter(key: string, value: number, solution: GameSolution): void {
    const param = this.findParameter(key, solution);

    if (param) {
      param.ctrl.value = value;
    }
  }

  findParameter(key: string, solution: GameSolution) {
    return solution && solution.parameters.find(p => p.ctrl.key === key);
  }

}
