import { FormControlBase } from './form-control-base.model';
import { FormControlOptions } from '../../../../app/shared/interfaces/form-control-options.interface';
import { FormControlType } from '../../enums/form-control-type.enum';


export class FormControlNumber extends FormControlBase<number> {
  formControlType: FormControlType = FormControlType.INPUT;
  inputType = 'number';

  constructor(options: FormControlOptions<number>) {
    super(options);
  }
}
