import { Component, OnInit } from '@angular/core';

import { AuthService } from './core/services/auth.service';
import { MetaInformationService } from './core/services/meta-information.service';
import { PermissionGuard } from './core/services/permission-guard.service';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { PermissionEnum } from "./shared/enums/permission.enum";
import { UserService } from "./core/services/user.service";
import { TranslateService } from "@ngx-translate/core";
import { StationTypeUtils } from "./shared/utils/station-type-utils";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isNavbarCollapsed = true;
  hasSubStations = false;

  Permissions: typeof PermissionEnum = PermissionEnum;

  constructor(
    public metaInformationService: MetaInformationService,
    public translate: TranslateService,
    private authService: AuthService,
    private userService: UserService,
    private stationTypeUtils: StationTypeUtils,
    public permissionGuard: PermissionGuard
  ) {
    translate.setDefaultLang('local_en');
  }

  get showNavBar(): boolean {
    const accessToken = this.authService.getAccessToken();
    return Boolean(accessToken && accessToken.accessToken);
  }

  ngOnInit() {
    this.metaInformationService.init();

    if(this.authService.isLoggedIn()) {
      this.translate.use('en');
    }

    this.authService.subscribeLoggedIn().subscribe(success => {
      if(success) {
        this.translate.use('en');
      } else {
        this.translate.use('local_en');
      }
    });

    registerLocaleData(localeDe, localeDeExtra);
  }

  showSubstationTab() {
    if (this.hasPermission(PermissionEnum.STATION_READ)) {
      if (this.hasSubStations) {
        return true;
      }

      if (this.authService.isLoggedIn()) {
        this.hasSubStations = this.stationTypeUtils.getSubStationTypeValues().length > 0;
        return this.hasSubStations
      }
    }

    return false;
  }

  public hasPermission(permission: string): boolean {
    return this.userService.hasCurrentUserPermission(permission);
  }
}
