import { ModelHelper } from "./model.helper.interface";
import { Content } from "../content.model";
import Utils from "../../utils/utils";
import { FormControlType } from "../../enums/form-control-type.enum";
import * as moment from "moment";
import { MultiLangStringHelper } from "./multi-lang-string.helper";
import { HtmlTextField } from "../html-text-field.model";
import { Channel } from "../channel.model";

export class HtmlTextFieldHelper implements ModelHelper<HtmlTextField> {

  constructor(private multiLangStringHelper: MultiLangStringHelper) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id);
  }

  emptyModel(defaultChannels: Channel[], fieldName?: string): HtmlTextField {
    return new HtmlTextField(
      '',
      fieldName || '',
      this.multiLangStringHelper.fromJSON(
        {},
        'text',
        {label: 'label.html.field.textfield-content', apiParam: 'text', formControlType: FormControlType.INPUT}
      )
    );
  }

  fromJSON(json: any, skipCheck = false): HtmlTextField {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    // create content object
    return new HtmlTextField(
      json._id,
      json.fieldName,
      this.multiLangStringHelper.fromJSON(
        json.content,
        'text',
        {
          apiParam: 'content',
          label: 'label.html.field.textfield-content'
        },
        json.title ? Object.keys(json.title) : []
      ),
      json.version,
      json.created_on ? moment(json.created_on).toDate() : undefined,
      json.creatorname,
      json.lastmodified ? moment(json.lastmodified).toDate() : undefined,
      json.modifiedby
    );
  }
}
