import { BaseModel } from "./base.model";
import { FileConverterModel } from "./file-converter.model";
import { FileLimitModel } from "./file-limit.model";
import { AwsModel } from "./aws.model";
import { SyncConfigModel } from "./sync-config.model";
import { CdnModel } from "./cdn.model";
import { FormControlBoolean } from "./form-control/form-control-boolean.model";
import { FormControlString } from "./form-control/form-control-string.model";
import { FormControlSelect } from "./form-control/form-control-select.model";
import { AzureModel } from "./azure.model";

export class ServerConfigModel extends BaseModel {

  constructor(public id: string,
              public tmpDirectory: FormControlString,
              public clientRole: FormControlSelect,
              public filesLocation: FormControlSelect,
              public fileConverter: FileConverterModel,
              public thumbnailImplementation: FormControlSelect,
              public fileExtensions: string[],
              public fileLimits: FileLimitModel,
              public languages: string[],
              public aws: AwsModel,
              public azure: AzureModel,
              public webconductor: FormControlString,
              public sync: SyncConfigModel,
              public cdn: CdnModel,
              public channelsEnabled: FormControlBoolean,
              public exhibitionsEnabled: FormControlBoolean,
              public stationsEnabled: FormControlBoolean,
              public contentEnabled: FormControlBoolean,
              public mapsEnabled: FormControlBoolean,
              public beaconsEnabled: FormControlBoolean,
              public groupsEnabled: FormControlBoolean,
              public hotspotsEnabled: FormControlBoolean,
              public htmlEnabled: FormControlBoolean,
              public cdnEnabled: FormControlBoolean,
              public filesEnabled: FormControlBoolean,
              public toursEnabled: FormControlBoolean,
              public version?: number,
              public createdOn?: Date,
              public creatorName?: string,
              public lastModified?: Date,
              public modifiedBy?: string
  ) {
    super(id, null, createdOn, creatorName, lastModified, modifiedBy, version);
  }

  toJSON() {
    const json = super.toJSON();
    delete json['blocked_users'];
    json['tmpDirectory'] = this.tmpDirectory.value;
    json['webconductor'] = this.webconductor.value;
    json['clientRole'] = this.clientRole.value;
    json['filesLocation'] = this.filesLocation.value;
    json['fileConverter'] = this.fileConverter ? this.fileConverter.toJSON() : undefined;
    json['thumbnailImplementation'] = this.thumbnailImplementation.value;
    json['fileExtensions'] = this.fileExtensions;
    json['fileLimits'] = this.fileLimits ? this.fileLimits.toJSON() : undefined;
    json['languages'] = this.languages;
    json['aws'] = this.aws ? this.aws.toJSON() : undefined;
    json['azure'] = this.azure ? this.azure.toJSON() : undefined;
    json['sync'] = this.sync ? this.sync.toJSON() : undefined;
    json['cdn'] = this.cdn ? this.cdn.toJSON() : undefined;
    json['channelsEnabled'] = this.channelsEnabled.value;
    json['exhibitionsEnabled'] = this.exhibitionsEnabled.value;
    json['stationsEnabled'] = this.stationsEnabled.value;
    json['contentEnabled'] = this.contentEnabled.value;
    json['mapsEnabled'] = this.mapsEnabled.value;
    json['beaconsEnabled'] = this.beaconsEnabled.value;
    json['groupsEnabled'] = this.groupsEnabled.value;
    json['hotspotsEnabled'] = this.hotspotsEnabled.value;
    json['htmlEnabled'] = this.htmlEnabled.value;
    json['cdnEnabled'] = this.cdnEnabled.value;
    json['filesEnabled'] = this.filesEnabled.value;
    json['toursEnabled'] = this.toursEnabled.value;

    return json;
  }
}
