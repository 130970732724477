import { TableConfigOptions } from './table-config.interface.ts';

export const tableConfigDefault: TableConfigOptions = {
  user: {
    default: {
      username: true,
      role: true,
      createdOn: true,
      lastModified: true,
      search: true
    }
  },
  content: {
    default: {
      active: true,
      title: true,
      description: false,
      type: true,
      createdOn: true,
      lastModified: true,
      files: true,
      search: true,
      isReferred: true
    }
  },
  tour: {
    default: {
      active: true,
      title: true,
      description: false,
      type: true,
      createdOn: true,
      lastModified: true,
      subtitle: false,
      shortText: false,
      longText: false,
      search: true,
      isReferred: true
    }
  },
  station: {
    default: {
      image: true,
      title: true,
      type: true,
      createdOn: true,
      lastModified: true,
      active: true,
      code: false,
      shortText: false,
      description: false,
      number: false,
      inventoryNumber: false,
      copyright: false,
      creatorBirth: false,
      creatorDeath: false,
      creationDate: false,
      creatorName: false,
      modifiedBy: false,
      mapId: false,
      mapX: false,
      mapY: false,
      lat: false,
      lng: false,
      locationText: false,
      fieldgen: false,
      fieldgen1: false,
      fieldgen2: false,
      fieldgen3: false,
      fieldgen4: false,
      fieldgen5: false,
      fieldgen6: false,
      flaggen: false,
      flaggen1: false,
      flaggen2: false,
      flaggen3: false,
      flaggen4: false,
      flaggen5: false,
      flaggen6: false,
      choice: false,
      choice1: false,
      choice2: false,
      choice3: false,
      choice4: false,
      isReferred: true
    }
  },
  game: {
    default: {
      title: true,
      category: true
    }
  },
  level: {
    default: {
      title: true,
      category: true
    }
  },
  result: {
    default: {
      title: true,
      category: true,
      minValue: true,
      maxValue: true,
      isResolved: true
    }
  },
  element: {
    default: {
      title: true,
      category: true
    }
  },
  selection: {
    default: {
      title: true,
      category: true
    }
  },
  solution: {
    default: {
      title: true,
      category: true
    }
  },
  controlFlow: {
    default: {
      category: true,
      resultId: true,
      next: true
    }
  },
  userContent: {
    default: {
      title: true,
      description: true
    }
  },
};
