import { Component, Input } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { TableBaseComponent } from '../table-base/table-base.component';

import { Tour } from '../../../models/tour.model';

import { ConfigurationService } from "../../../services/configuration.service";
import { UserService } from "../../../../core/services/user.service";
import { PermissionEnum } from "../../../enums/permission.enum";
import { TourTypeUtils } from "../../../utils/tour-type-utils";


@Component({
  selector: 'app-tour-table',
  templateUrl: './tour-table.component.html'
})
export class TourTableComponent extends TableBaseComponent<Tour> {

  @Input() tourTypes: number[];;
  @Input() detailLink = '/tours';

  tableConfig: any = this.getTableConfig('tour');

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected config: ConfigurationService,
    private tourTypeUtils: TourTypeUtils,
    protected userService: UserService
  ) {
    super(router, route, config, userService);
  }

  getGlobalFilterFields() {
    this.globalFilters = this.globalFilters || [{
      label: 'ID',
      field: 'id',
      active: this.showId
    }, {
      label: 'Title',
      field: 'title.' + this.data[0].title.defaultLocale + '.value',
      active: true
    }];

    return this.globalFilters
      .filter(filter => filter.active)
      .map(filter => filter.field);
  }

  delete(tour: Tour): void {
    this.onDelete.emit(tour);
  }

  goToDetailView(tour: Tour): void {
    this.router.navigate(
      this.hasPermission(PermissionEnum.STATION_READ) ? [this.detailLink, tour.id, 'stations'] : [this.detailLink, tour.id],
      {relativeTo: this.route}
    );
  }

  ngOnInit() {
    super.ngOnInit();
    if(!this.tourTypes) {
      this.tourTypes = this.tourTypeUtils.getValues();
    }
  }
}
