import { catchError, map, publishReplay, refCount, share } from 'rxjs/operators';
import { ApiBaseService } from "../../core/services/api-base.service";
import { Organization } from "../models/organization.model";
import { AppConfig } from "../../app.config";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { UserService } from "../../core/services/user.service";
import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CreateOptions } from "../interfaces/create-options.interface";
import { Status } from "../models/status.model";
import { Observable } from "rxjs";
import { Role } from "../models/role.model";
import Utils from "../utils/utils";
import { User } from "../models/user.model";
import { ModelMapperService } from "./mapper/model-mapper.service";

@Injectable()
export class OrganizationService extends ApiBaseService<Organization> {
  private organizationUrl = AppConfig.AUTH_SERVER + AppConfig.AUTH_BASE_URL + '/organization';

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected userService: UserService,
    protected modalService: NgbModal,
    private modelMapper: ModelMapperService
  ) {
    super(Organization, 'organizations', http, router, userService);
    this.modalService = modalService;
    this.url = this.organizationUrl;
  }

  create(options: CreateOptions<Organization>): Promise<Organization> {
    return super.create(Object.assign(options, {
      model: this.emptyModel()
    }));
  }

  getRolesForOrganizations(organizationId: string, status?: Status): Observable<Role[]> {
    status = status || new Status();
    status.setLoading();

    const url = `${this.url}/${organizationId}/roles`;

    return this.http.get(url).pipe(
      share(),
      publishReplay(1),
      refCount(),
      map((response: any[]) => {
        if (Utils.deepAccessUsingString(response, this.responseKey) && Utils.deepAccessUsingString(response, this.responseKey).length > 0) {
          status.setSuccess();
          return Utils.deepAccessUsingString(response, this.responseKey)
            .filter(object => this.modelMapper.roleCheckJSON(object))
            .map(object => this.modelMapper.roleFromJSON(object));
        } else {
          status.setNoResults();
        }

      }),
      catchError(err => this.handleError(err, status)),);
  }

  getUserForOrganizations(organizationId: string, status?: Status): Observable<User[]> {
    status = status || new Status();
    status.setLoading();

    const url = `${this.url}/${organizationId}/users`;

    return this.http.get(url).pipe(
      share(),
      publishReplay(1),
      refCount(),
      map((response: any[]) => {
        if (Utils.deepAccessUsingString(response, this.responseKey) && Utils.deepAccessUsingString(response, this.responseKey).length > 0) {
          status.setSuccess();
          return Utils.deepAccessUsingString(response, this.responseKey)
            .filter(object => this.modelMapper.userCheckJSON(object))
            .map(object => this.modelMapper.userFromJSON(object));
        } else {
          status.setNoResults();
        }

      }),
      catchError(err => this.handleError(err, status)),);
  }

  checkJSON(json: any): boolean {
    return this.modelMapper.organizationCheckJSON(json);
  }

  emptyModel(): Organization {
    return this.modelMapper.organizationEmptyModel();
  }

  fromJSON(json: any, skipCheck?: boolean): Organization {
    return this.modelMapper.organizationFromJSON(json, skipCheck);
  }
}
