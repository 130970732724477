import { Component } from '@angular/core';


@Component({
  selector: 'app-channel-type',
  template: `
    <span class="type"><i class="icon-channel"></i> Channel</span>
  `
})
export class ChannelTypeComponent {
  constructor() { }
}
