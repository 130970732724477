import { BaseModel } from './base.model';
import { FileType } from '../enums/file-type.enum';
import { FormControlSelect } from './form-control/form-control-select.model';
import Utils from '../utils/utils';
import { MultiLangString } from "./multi-lang-string.model";

export class File extends BaseModel {

  private audio: HTMLAudioElement;
  randomId: string;

  constructor(
    private contentBaseUrl: string,
    public id: string,
    public name,
    public type: number,
    public locale: FormControlSelect,
    public attribute,
    public fieldgen1,
    public fieldgen2,
    public fieldgen3,
    public duration?: number,
    public size?: number,
    public href?: string,
    public width?: number,
    public height?: number
  ) {
    super(id);
  }

  toJSON(): object {
    return {
      subContentId: this.id,
      filename: this.name.value,
      duration: this.duration,
      size: this.size,
      language: this.locale ? this.locale.value : undefined,
      attribute: this.attribute.value,
      href: this.href,
      fieldgen1: this.fieldgen1 ? this.fieldgen1.value : undefined,
      fieldgen2: this.fieldgen2 ? Array.isArray(this.fieldgen2) ? this.fieldgen2 : this.fieldgen2.value : undefined,
      fieldgen3: this.fieldgen3 ? this.fieldgen3 instanceof MultiLangString ? this.fieldgen3.toJSON() : this.fieldgen3.value : undefined,
      width: this.width,
      height: this.height
    };
  }

  getAltId() {
    return this.name.value + '_' + this.randomId;
  }

  getHref(): string {
    return this.contentBaseUrl + this.href;
  }

  isThumbnail(): boolean {
    return Utils.isThumbnail(this.attribute.value);
  }

  isImage(): boolean {
    return Utils.isDefined(this.type) && (this.type === FileType.IMAGE || this.type === FileType.SLIDE);
  }

  hasLanguage(): boolean {
    return !(this.type === FileType.IMAGE && this.attribute.value.indexOf('imagemultilang') === -1) && !(this.attribute.value.indexOf('omnilanguage') > -1);
  }

  isAudio(): boolean {
    return Utils.isDefined(this.type) && this.type === FileType.AUDIO;
  }

  getAudio(): HTMLAudioElement {
    this.audio = this.audio || new Audio(this.getHref());
    return this.audio;
  }

  play(): void {
    this.audio = this.audio || new Audio(this.getHref());
    if (this.isPlaying()) {
      this.audio.pause();
    } else {
      this.audio.play();
    }
  }

  isPlaying(): boolean {
    return this.audio && !this.audio.paused;
  }
}
