import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Game } from '../../../models/game.model';
import { Status } from '../../../models/status.model';

@Component({
  selector: 'app-game-list-modal',
  templateUrl: './game-list-modal.component.html'
})
export class GameListModalComponent {

  @Input() games: Game[];
  @Input() status: Status;

  selection: Game[] = [];

  constructor(
    public activeModal: NgbActiveModal
  ) { }
}
