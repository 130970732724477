import { Component, Input, OnInit } from '@angular/core';
import { FileType } from '../../enums/file-type.enum';
import Utils from '../../../../app/shared/utils/utils';

@Component({
  selector: 'app-file-type',
  template: `
    <span class="type" [ngSwitch]="FileType[fileType]" *ngIf="show">
      <i *ngSwitchCase="'AUDIO'" class="fa fa-file-audio-o"></i>
      <i *ngSwitchCase="'FILE'" class="fa fa-file-o"></i>
      <i *ngSwitchCase="'IMAGE'" class="fa fa-file-image-o"></i>
      <i *ngSwitchCase="'SLIDE'" class="fa fa-file-powerpoint-o"></i>
      <i *ngSwitchCase="'VIDEO'" class="fa fa-file-video-o"></i>
      {{label || attribute}}</span>
  `
})
export class FileTypeComponent implements OnInit {

  FileType = FileType;
  label: string;

  @Input() fileType: FileType;
  @Input() attribute: string;
  @Input() fileTypes: {type: number, attribute: {label: string, value: string}}[];

  constructor() { }

  get show(): boolean {
    return Utils.isDefined(this.fileType) && Utils.isDefined(this.attribute);
  }

  ngOnInit() {
    for (const fileType of this.fileTypes) {
      if (Utils.isDefined(this.fileType) && Utils.isDefined(this.attribute) && Utils.isDefined(fileType.type)
        && fileType.type.toString() === this.fileType.toString() && this.attribute === fileType.attribute.value) {
        this.label = fileType.attribute.label;
      }
    }
  }
}
