import { Component, Input, Output, EventEmitter } from '@angular/core';

interface MenuItem {
  id: string;
  label: string;
  styleClassIcon?: string;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent {
  @Input() menu: MenuItem[];
  @Input() activeMenuItem: string;
  @Output() onClick = new EventEmitter();

  constructor() { }

  onMenuItemClick($event, id: string) {
    this.onClick.emit(id);
  }
}
