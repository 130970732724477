import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { FormControlPassword } from '../../../../../app/shared/models/form-control/form-control-password.model';


@Component({
  selector: 'app-password-modal',
  templateUrl: './password-modal.component.html'
})
export class PasswordModalComponent {

  @Input() title?: string;
  @Input() password?: string;

  form = new FormGroup({});

  passwordConfirm = new FormControlPassword({
    key: 'password',
    label: 'Password',
    required: true
  });

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  submit(): void {
    this.activeModal.close(this.passwordConfirm.value);
  }
}
