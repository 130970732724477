import { GameResultCategory } from '../../../game/enum/game-result-category.enum';
import { GameResultTypeUtils } from '../../utils/game-result-type-utils';
import Utils from '../../utils/utils';
import { FormControlBoolean } from '../form-control/form-control-boolean.model';
import { FormControlNumber } from '../form-control/form-control-number.model';
import { FormControlSelect } from '../form-control/form-control-select.model';
import { GameResult } from '../game-result.model';
import { GameUtilHelper } from './game-util-helper';
import { MultiLangStringHelper } from './multi-lang-string.helper';

export class GameResultModelHelper {

  constructor(
    private readonly multiLangStringHelper: MultiLangStringHelper,
    private readonly resultTypeUtils: GameResultTypeUtils,
    private readonly gameUtilHelper: GameUtilHelper
  ) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json.id);
  }

  emptyModel(resultTypes: number[]): GameResult {
    const filteredTypes = this.resultTypeUtils.getTypeSelectOptions().filter(resultType => {
      return resultTypes.indexOf(resultType.value) !== -1;
    });

    const type = resultTypes[0];
    const config = this.resultTypeUtils.getTypeConfig(type);

    return new GameResult(
      '',
      this.multiLangStringHelper.fromJSON(
        {},
        'title',
        this.resultTypeUtils.getFieldOptions(this.resultTypeUtils.getDefaultType(), 'title', {
          label: 'label.game.result.field.title',
          hidden: false
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'description',
        this.resultTypeUtils.getFieldOptions(this.resultTypeUtils.getDefaultType(), 'description', {
          label: 'label.game.result.field.description',
          hidden: false
        })
      ),
      new FormControlSelect({
        value: type,
        key: 'type',
        label: 'label.general.type',
        options: filteredTypes,
        required: true,
        hidden: false
      }),
      GameResultCategory[ this.resultTypeUtils.getCategoryOfType(type).toUpperCase() ],
      new FormControlNumber(
        this.resultTypeUtils.getFieldOptions(this.resultTypeUtils.getDefaultType(), 'minValue', {
          label: 'label.game.result.field.min-value',
          hidden: false
        })
      ),
      new FormControlNumber(
        this.resultTypeUtils.getFieldOptions(this.resultTypeUtils.getDefaultType(), 'maxValue', {
          label: 'label.game.result.field.max-value',
          hidden: false
        })
      ),
      new FormControlBoolean(
        this.resultTypeUtils.getFieldOptions(this.resultTypeUtils.getDefaultType(), 'resolve', {
          label: 'label.game.result.field.resolve',
          hidden: false
        })
      ),
      this.gameUtilHelper.createDictionary(config, 'parameters'),
      this.gameUtilHelper.createDictionary(config, 'textDictionary'),
      this.gameUtilHelper.createDictionary(config, 'assetDictionary')
    );
  }

  fromJSON(json: any, skipCheck = false): GameResult {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    const resultType = Number(json.type);
    const config = this.resultTypeUtils.getTypeConfig(resultType);

    // create result object
    const result = new GameResult(
      json.id,
      this.multiLangStringHelper.fromJSON(
        json.title,
        'title',
        this.resultTypeUtils.getFieldOptions(this.resultTypeUtils.getDefaultType(), 'title', {
          label: 'label.game.result.field.title',
          hidden: false
        }),
        json.title ? Object.keys(json.title) : []
      ),
      this.multiLangStringHelper.fromJSON(
        json.description,
        'description',
        this.resultTypeUtils.getFieldOptions(this.resultTypeUtils.getDefaultType(), 'description', {
          label: 'label.game.result.field.description',
          hidden: false
        }),
        json.description ? Object.keys(json.description) : []
      ),
      new FormControlSelect({
        value: resultType,
        key: 'type',
        label: 'label.general.type',
        options: this.resultTypeUtils.getTypeSelectOptions(),
        required: true,
        hidden: false
      }),
      json.category,
      new FormControlNumber(
        this.resultTypeUtils.getFieldOptions(resultType, 'minValue', {
          value: isNaN(json.minValue) ? undefined : Number(json.minValue),
          label: 'label.game.result.field.min-value',
          hidden: false
        })
      ),
      new FormControlNumber(
        this.resultTypeUtils.getFieldOptions(resultType, 'maxValue', {
          value: isNaN(json.maxValue) ? undefined : Number(json.maxValue),
          label: 'label.game.result.field.max-value',
          hidden: false
        })
      ),
      new FormControlBoolean({
        key: 'resolve',
        value: json.resolve,
        label: 'label.game.result.field.resolve',
        hidden: false
      }),
      this.gameUtilHelper.mapParameters(json.parameters, config),
      this.gameUtilHelper.mapMultiLangDictEntries(json.textDictionary, config),
      this.gameUtilHelper.mapAssetLangDictEntries(json.assetDictionary, config)
    );

    return result;
  }
}
