import * as moment from 'moment';
import { ContentTypeEnum } from '../../enums/content-type.enum';
import { GameTypeUtils } from '../../utils/game-type-utils';
import Utils from '../../utils/utils';
import { FormControlBoolean } from '../form-control/form-control-boolean.model';
import { FormControlSelect } from '../form-control/form-control-select.model';
import { FormControlString } from '../form-control/form-control-string.model';
import { Game } from '../game.model';
import { FileModelHelper } from './file-model.helper';
import { GameLevelModelHelper } from './game-level-model.helper';
import { GameResultModelHelper } from './game-result-model.helper';
import { GameUtilHelper } from './game-util-helper';
import { ModelHelper } from './model.helper.interface';
import { MultiLangBooleanHelper } from './multi-lang-boolean.helper';
import { MultiLangStringHelper } from './multi-lang-string.helper';

export class GameModelHelper implements ModelHelper<Game> {

  constructor(
    private readonly levelHelper: GameLevelModelHelper,
    private readonly resultHelper: GameResultModelHelper,
    private readonly multiLangStringHelper: MultiLangStringHelper,
    private readonly multiLangBooleanHelper: MultiLangBooleanHelper,
    private readonly gameTypeUtils: GameTypeUtils,
    private readonly gameUtilHelper: GameUtilHelper,
    private readonly fileHelper: FileModelHelper,
  ) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json.id);
  }

  emptyModel(): Game {
    return new Game(
      '',
      this.multiLangBooleanHelper.fromJSON(
        {},
        'active',
        { label: 'label.general.active', hidden: false }
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'title',
        this.gameTypeUtils.getFieldOptions(this.gameTypeUtils.getDefaultType(), 'title', {
          label: 'label.game.field.title',
          hidden: false
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'description',
        this.gameTypeUtils.getFieldOptions(this.gameTypeUtils.getDefaultType(), 'description', {
          label: 'label.game.field.description',
          hidden: false
        })
      ),
      new FormControlSelect({
        value: this.gameTypeUtils.getDefaultType(),
        key: 'type',
        label: 'label.general.type',
        options: this.gameTypeUtils.getTypeSelectOptions(),
        required: true,
        hidden: false
      }),
      undefined,
      new FormControlBoolean({
        key: 'reportScore',
        label: 'label.game.field.reportScore',
        value: false,
        hidden: false
      }),
      new FormControlString({
        key: 'reportUrl',
        label: 'label.game.field.reportUrl',
        hidden: false
      }),
      [],
      [],
      [],
      [],
      [],
      this.fileHelper.emptyModel(),
      this.fileHelper.emptyModel(),
      this.fileHelper.emptyModel(),
      []
    );
  }

  fromJSON(json: any, skipCheck = false): Game {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    const gameType = Number(json.type);
    const config = this.gameTypeUtils.getTypeConfig(gameType);

    // create game object
    return new Game(
      json.id,
      this.multiLangBooleanHelper.fromJSON(json.active, 'active', { label: 'label.general.active', hidden: false }),
      this.multiLangStringHelper.fromJSON(
        json.title,
        'title',
        this.gameTypeUtils.getFieldOptions(this.gameTypeUtils.getDefaultType(), 'title', {
          label: 'label.game.field.title',
          hidden: false
        }),
        json.title ? Object.keys(json.title) : []
      ),
      this.multiLangStringHelper.fromJSON(
        json.description,
        'description',
        this.gameTypeUtils.getFieldOptions(this.gameTypeUtils.getDefaultType(), 'description', {
          label: 'label.game.field.description',
          hidden: false
        }),
        json.description ? Object.keys(json.description) : []
      ),
      new FormControlSelect({
        value: gameType,
        key: 'type',
        label: 'label.general.type',
        options: this.gameTypeUtils.getTypeSelectOptions(),
        required: true,
        hidden: false
      }),
      json.category,
      new FormControlBoolean({
        key: 'reportScore',
        value: json.reportScore,
        label: 'label.game.field.reportScore',
        hidden: false
      }),
      new FormControlString({
        key: 'reportUrl',
        value: json.reportUrl,
        label: 'label.game.field.reportUrl',
        hidden: false
      }),
      json.levels || [],
      json.results ? json.results.filter(result => this.resultHelper.checkJSON(result))
        .map(result => this.resultHelper.fromJSON(result)) : [],
      this.gameUtilHelper.mapParameters(json.parameters, config),
      this.gameUtilHelper.mapMultiLangDictEntries(json.textDictionary, config),
      this.gameUtilHelper.mapAssetLangDictEntries(json.assetDictionary, config),
      json.styleFile && this.fileHelper.checkJSON(json.styleFile) ? this.fileHelper.fromJSON(json.styleFile) : undefined,
      json.gameFile && this.fileHelper.checkJSON(json.gameFile) ? this.fileHelper.fromJSON(json.gameFile) : undefined,
      json.htmlFile && this.fileHelper.checkJSON(json.htmlFile) ? this.fileHelper.fromJSON(json.htmlFile) : undefined,
      json.blocked_users || [],
      json.created_on ? moment(json.created_on).toDate() : undefined,
      json.creatorname,
      json.lastmodified ? moment(json.lastmodified).toDate() : undefined,
      json.modifiedby,
      json.version
    );
  }
}
