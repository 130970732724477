import * as moment from 'moment';
import { FileType } from '../../enums/file-type.enum';
import { FormControlType } from '../../enums/form-control-type.enum';
import { StationTypeUtils } from '../../utils/station-type-utils';
import Utils from '../../utils/utils';
import { VersionModel } from '../base.model';
import { FormControlBoolean } from '../form-control/form-control-boolean.model';
import { FormControlNumber } from '../form-control/form-control-number.model';
import { FormControlSelect } from '../form-control/form-control-select.model';
import { FormControlString } from '../form-control/form-control-string.model';
import { Station } from '../station.model';
import { ChannelModelHelper } from './channel-model.helper';
import { ContentModelHelper } from './content-model.helper';
import { FileModelHelper } from './file-model.helper';
import { GameModelHelper } from './game-model.helper';
import { MapModelHelper } from './map-model.helper';
import { ModelHelper } from './model.helper.interface';
import { MultiLangStringHelper } from './multi-lang-string.helper';
import { Channel } from "../channel.model";

export class StationModelHelper implements ModelHelper<Station> {

  constructor(
    private stationTypeUtils: StationTypeUtils,
    private channelHelper: ChannelModelHelper,
    private gameHelper: GameModelHelper,
    private mapHelper: MapModelHelper,
    private contentHelper: ContentModelHelper,
    private fileHelper: FileModelHelper,
    private multiLangStringHelper: MultiLangStringHelper
  ) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id) && this.stationTypeUtils.isValid(json.type);
  }

  emptyModel(defaultChannels: Channel[]): Station {
    return new Station(
      '',
      new FormControlBoolean(this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'active', {
        value: false,
        hidden: false
      })),
      new FormControlSelect({
        value: this.stationTypeUtils.getDefault(),
        key: 'type',
        label: 'label.general.type',
        options: this.stationTypeUtils.getSelectOptions(),
        required: true,
        hidden: false
      }),
      new FormControlNumber(this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'code', {
          label: 'label.station.field.code'
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'title',
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'title', {
          label: 'label.station.field.title'
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'shortText',
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'shortText', {
          label: 'label.station.field.short-text',
          formControlType: FormControlType.TEXTAREA,
          rows: 3
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'description',
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'description', {
          label: 'label.station.field.description',
          formControlType: FormControlType.TEXTAREA,
          rows: 15
        })
      ),
      new FormControlNumber(
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'number', {
          label: 'label.station.field.number'
        })
      ),
      new FormControlString(
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'inventoryNumber', {
          label: 'label.station.field.inventory-number'
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'copyright',
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'copyright', {
          label: 'label.station.field.copyright',
          formControlType: FormControlType.TEXTAREA,
          rows: 5
        })
      ),
      new FormControlString(
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'creatorBirth', {
          label: 'Creator Birth'
        })
      ),
      new FormControlString(
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'creatorDeath', {
          label: 'label.station.field.creator-birth'
        })
      ),
      new FormControlString(
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'creationDate', {
          label: 'label.station.field.creation-date'
        })
      ),
      '',
      0,
      0,
      new FormControlNumber(
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'lat', {
          label: 'label.station.field.latitude'
        })
      ),
      new FormControlNumber(
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'lng', {
          label: 'label.station.field.longitude'
        })
      ),
      [],
      this.multiLangStringHelper.fromJSON(
        {},
        'locationText',
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'locationText', {
          label: 'label.station.field.location',
        })
      ),
      new FormControlString(
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'fieldgen', {
          label: 'label.station.field.fieldgen'
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'fieldgen1',
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'fieldgen1', {
          label: 'label.station.field.fieldgen1'
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'fieldgen2',
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'fieldgen2', {
          label: 'label.station.field.fieldgen2'
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'fieldgen3',
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'fieldgen3', {
          label: 'label.station.field.fieldgen3'
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'fieldgen4',
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'fieldgen4', {
          label: 'label.station.field.fieldgen4'
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'fieldgen5',
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'fieldgen5', {
          label: 'label.station.field.fieldgen5'
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'fieldgen6',
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'fieldgen6', {
          label: 'label.station.field.fieldgen6'
        })
      ),
      new FormControlBoolean(
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'flaggen', {
            label: 'label.station.field.flaggen'
          }
        )),
      new FormControlBoolean(
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'flaggen2', {
            label: 'label.station.field.flaggen2'
          }
        )),
      new FormControlBoolean(
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'flaggen3', {
            label: 'label.station.field.flaggen3'
          }
        )),
      new FormControlBoolean(
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'flaggen4', {
            label: 'label.station.field.flaggen4'
          }
        )),
      new FormControlBoolean(
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'flaggen5', {
            label: 'label.station.field.flaggen5'
          }
        )),
      new FormControlBoolean(
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'flaggen6', {
            label: 'label.station.field.flaggen6'
          }
        )),
      new FormControlSelect(
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'choice1', {
          label: 'label.station.field.choice1'
        })
      ),
      new FormControlSelect(
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'choice2', {
          label: 'label.station.field.choice2'
        })
      ),
      new FormControlSelect(
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'choice3', {
          label: 'label.station.field.choice3'
        })
      ),
      new FormControlSelect(
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'choice4', {
          label: 'label.station.field.choice4'
        })
      ),
      [],
      [],
      [],
      [],
      defaultChannels,
      defaultChannels ? defaultChannels.map(c => c.id) : [],
      [],
      [],
      [],
      [],
      [],
      [],
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
  }

  fromJSON(json: any, skipCheck = false): Station {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    const stationType = Number(json.type);

    // create station object
    const station = new Station(
      json._id,

      new FormControlBoolean(this.stationTypeUtils.getFieldOptions(stationType, 'active', {
        value: json.active,
        hidden: false
      })),

      new FormControlSelect({
        value: stationType,
        key: 'label.general.type',
        label: 'Type',
        options: this.stationTypeUtils.getSelectOptions(),
        required: true,
        hidden: false
      }),

      new FormControlNumber(this.stationTypeUtils.getFieldOptions(stationType, 'code', {
        value: json.code,
        label: 'label.station.field.code'
      })),

      this.multiLangStringHelper.fromJSON(
        json.title,
        'title',
        this.stationTypeUtils.getFieldOptions(stationType, 'title', {
          label: 'label.station.field.title'
        }),
        json.title ? Object.keys(json.title) : []
      ),

      this.multiLangStringHelper.fromJSON(
        json.textshort,
        'shortText',
        this.stationTypeUtils.getFieldOptions(stationType, 'shortText', {
          label: 'label.station.field.short-text',
          formControlType: FormControlType.TEXTAREA,
          rows: 3
        }),
        json.title ? Object.keys(json.title) : []
      ),

      this.multiLangStringHelper.fromJSON(
        json.textlong,
        'description',
        this.stationTypeUtils.getFieldOptions(stationType, 'description', {
          label: 'label.station.field.description',
          formControlType: FormControlType.TEXTAREA,
          rows: 15
        }),
        json.title ? Object.keys(json.title) : []
      ),

      new FormControlNumber(
        this.stationTypeUtils.getFieldOptions(stationType, 'number', {
          value: isNaN(json.number) ? undefined : Number(json.number),
          label: 'label.station.field.number'
        })
      ),

      new FormControlString(
        this.stationTypeUtils.getFieldOptions(stationType, 'inventoryNumber', {
          value: json.invnumber,
          label: 'label.station.field.inventory-number'
        })
      ),

      this.multiLangStringHelper.fromJSON(
        json.copyrighttext,
        'copyright',
        this.stationTypeUtils.getFieldOptions(stationType, 'copyright', {
          label: 'label.station.field.copyright',
          formControlType: FormControlType.TEXTAREA,
          rows: 5
        }),
        json.title ? Object.keys(json.title) : []
      ),

      new FormControlString(
        this.stationTypeUtils.getFieldOptions(stationType, 'creatorBirth', {
          value: json.creatorbirth,
          label: 'Creator Birth'
        })
      ),

      new FormControlString(
        this.stationTypeUtils.getFieldOptions(stationType, 'creatorDeath', {
          value: json.creatordeath,
          label: 'label.station.field.creator-birth'
        })
      ),

      new FormControlString(
        this.stationTypeUtils.getFieldOptions(stationType, 'creationDate', {
          value: json.creationdate,
          label: 'label.station.field.creation-date'
        })
      ),
      json.map_ref,
      json.mapPosition ? Number(json.mapPosition[0]) : undefined,
      json.mapPosition ? Number(json.mapPosition[1]) : undefined,

      new FormControlNumber({
        label: 'label.station.field.latitude',
        value: isNaN(json.geolat) || json.geolat === '' ? undefined : Number(json.geolat),
        hidden: false
      }),

      new FormControlNumber({
        label: 'label.station.field.longitude',
        value: isNaN(json.geolong) || json.geolong === '' ? undefined : Number(json.geolong),
        hidden: false
      }),

      json.threeDObjects,

      this.multiLangStringHelper.fromJSON(
        json.locationText,
        'locationText',
        this.stationTypeUtils.getFieldOptions(this.stationTypeUtils.getDefault(), 'locationText', {
          label: 'label.station.field.location',
        }),
        json.title ? Object.keys(json.title) : []
      ),

      new FormControlString(
        this.stationTypeUtils.getFieldOptions(stationType, 'fieldgen', {
          value: json.fieldgen,
          label: 'label.station.field.fieldgen'
        })
      ),
      this.multiLangStringHelper.fromJSON(
        json.fieldgen1,
        'fieldgen1',
        this.stationTypeUtils.getFieldOptions(stationType, 'fieldgen1', {
          label: 'label.station.field.fieldgen1'
        }),
        json.title ? Object.keys(json.title) : []
      ),
      this.multiLangStringHelper.fromJSON(
        json.fieldgen2,
        'fieldgen2',
        this.stationTypeUtils.getFieldOptions(stationType, 'fieldgen2', {
          label: 'label.station.field.fieldgen2'
        }),
        json.title ? Object.keys(json.title) : []
      ),
      this.multiLangStringHelper.fromJSON(
        json.fieldgen3,
        'fieldgen3',
        this.stationTypeUtils.getFieldOptions(stationType, 'fieldgen3', {
          label: 'label.station.field.fieldgen3'
        }),
        json.title ? Object.keys(json.title) : []
      ),
      this.multiLangStringHelper.fromJSON(
        json.fieldgen4,
        'fieldgen4',
        this.stationTypeUtils.getFieldOptions(stationType, 'fieldgen4', {
          label: 'label.station.field.fieldgen4'
        }),
        json.title ? Object.keys(json.title) : []
      ),
      this.multiLangStringHelper.fromJSON(
        json.fieldgen5,
        'fieldgen5',
        this.stationTypeUtils.getFieldOptions(stationType, 'fieldgen5', {
          label: 'label.station.field.fieldgen5'
        }),
        json.title ? Object.keys(json.title) : []
      ),
      this.multiLangStringHelper.fromJSON(
        json.fieldgen6,
        'fieldgen6',
        this.stationTypeUtils.getFieldOptions(stationType, 'fieldgen6', {
          label: 'label.station.field.fieldgen6'
        }),
        json.title ? Object.keys(json.title) : []
      ),

      new FormControlBoolean(
        this.stationTypeUtils.getFieldOptions(stationType, 'flaggen', {
            label: 'label.station.field.flaggen',
            value: json.flaggen
          }
        )),

      new FormControlBoolean(
        this.stationTypeUtils.getFieldOptions(stationType, 'flaggen2', {
            label: 'label.station.field.flaggen2',
            value: json.flaggen2
          }
        )),

      new FormControlBoolean(
        this.stationTypeUtils.getFieldOptions(stationType, 'flaggen3', {
            label: 'label.station.field.flaggen3',
            value: json.flaggen3
          }
        )),

      new FormControlBoolean(
        this.stationTypeUtils.getFieldOptions(stationType, 'flaggen4', {
            label: 'label.station.field.flaggen4',
            value: json.flaggen4
          }
        )),

      new FormControlBoolean(
        this.stationTypeUtils.getFieldOptions(stationType, 'flaggen5', {
            label: 'label.station.field.flaggen5',
            value: json.flaggen5
          }
        )),

      new FormControlBoolean(
        this.stationTypeUtils.getFieldOptions(stationType, 'flaggen6', {
            label: 'label.station.field.flaggen6',
            value: json.flaggen6
          }
        )),

      new FormControlSelect(
        this.stationTypeUtils.getFieldOptions(stationType, 'choice1', {
          label: 'label.station.field.choice1',
          value: json.choice1
        })
      ),

      new FormControlSelect(
        this.stationTypeUtils.getFieldOptions(stationType, 'choice2', {
          label: 'label.station.field.choice2',
          value: json.choice2
        })
      ),

      new FormControlSelect(
        this.stationTypeUtils.getFieldOptions(stationType, 'choice3', {
          label: 'label.station.field.choice3',
          value: json.choice3
        })
      ),

      new FormControlSelect(
        this.stationTypeUtils.getFieldOptions(stationType, 'choice4', {
          label: 'label.station.field.choice4',
          value: json.choice4
        })
      ),
      json._embedded && json._embedded.stations ? json._embedded.stations
        .filter(subStation => this.checkJSON(subStation))
        .map(subStation => this.fromJSON(subStation)) : [],
      json.substation_order,
      json._embedded && json._embedded.contents ? json._embedded.contents
        .filter(content => this.contentHelper.checkJSON(content))
        .map(content => this.contentHelper.fromJSON(content)) : [],
      json.content_refs,
      json._embedded && json._embedded.channels ? json._embedded.channels
        .filter(channel => this.channelHelper.checkJSON(channel))
        .map(channel => this.channelHelper.fromJSON(channel)) : [],
      json.channel_refs,
      json._embedded && json._embedded.maps ? json._embedded.maps
        .filter(map => this.mapHelper.checkJSON(map))
        .map(map => this.mapHelper.fromJSON(map)) : [],
      json.files ? json.files
        .filter(file => this.fileHelper.checkJSON(file))
        .filter(file => this.stationTypeUtils.isValidImageType(stationType, file.attribute) || Utils.isThumbnail(file.attribute))
        .map(file => this.fileHelper.fromJSON(file, FileType.IMAGE)) : [],
      [],
      [],
      json.game_refs,

      json.blocked_users,
      json.is_refered,

      json.created_on ? moment(json.created_on).toDate() : undefined,
      json.creatorname,
      json.lastmodified ? moment(json.lastmodified).toDate() : undefined,
      json.modifiedby,
      json.version,
      json.versions ? json.versions.map(ver => <VersionModel>{
        number: ver.number,
        date: moment(ver.date).toDate()
      } as VersionModel) : [],
      json.baseId,
    );

    // Sort based on ref objects
    station.subStations = Utils.mapOrder(station.subStations, station.subStationRefs, 'id');
    station.contents = Utils.mapOrder(station.contents, station.contentRefs, 'id');
    station.channels = Utils.mapOrder(station.channels, station.channelRefs, 'id');

    return station;
  }
}
