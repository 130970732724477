import { ModelHelper } from "./model.helper.interface";
import { Group } from "../group.model";
import Utils from "../../utils/utils";
import * as moment from "moment";
import { VersionModel } from "../base.model";
import { Station } from "../station.model";
import { ChannelModelHelper } from "./channel-model.helper";
import { ContentModelHelper } from "./content-model.helper";
import { StationModelHelper } from "./station-model.helper";
import { GroupType } from "../../enums/group-type.enum";
import { MultiLangBooleanHelper } from "./multi-lang-boolean.helper";
import { MultiLangStringHelper } from "./multi-lang-string.helper";
import { Channel } from "../channel.model";

export class GroupModelHelper implements ModelHelper<Group> {

  constructor(
    private stationHelper: StationModelHelper,
    private contentHelper: ContentModelHelper,
    private channelHelper: ChannelModelHelper,
    private multiLangBooleanHelper: MultiLangBooleanHelper,
    private multiLangStringHelper: MultiLangStringHelper
  ) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id);
  }

  emptyModel(defaultChannels: Channel[]): Group {
    return new Group(
      '',
      this.multiLangBooleanHelper.fromJSON(
        {},
        'active',
        {label: 'label.general.active', hidden: false}
      ),
      GroupType.GROUP,
      this.multiLangStringHelper.fromJSON({}, 'title', {label: 'label.group.field.title'}),
      [],
      [],
      [],
      [],
      [],
      [],
      defaultChannels,
      defaultChannels ? defaultChannels.map(c => c.id) : [],
      []
    );
  }

  fromJSON(json: any, skipCheck = false): Group {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    // create group object
    const group = new Group(
      json._id,
      this.multiLangBooleanHelper.fromJSON(json.active, 'active', {label: 'label.general.active', hidden: false}),
      isNaN(json.type) ? undefined : Number(json.type),
      this.multiLangStringHelper.fromJSON(json.title, 'title', {label: 'label.group.field.title'}),
      json._embedded && json._embedded.groups ? json._embedded.groups
        .filter(subGroup => this.checkJSON(subGroup))
        .map(subGgroup => this.fromJSON(subGgroup)) : [],
      json.group_refs,
      json._embedded && json._embedded.substations ? json._embedded.substations
        .filter(station => this.stationHelper.checkJSON(Station))
        .map(station => this.stationHelper.fromJSON(station)) : [],
      json.station_refs,
      json._embedded && json._embedded.contents ? json._embedded.contents
        .filter(content => this.contentHelper.checkJSON(content))
        .map(content => this.contentHelper.fromJSON(content)) : [],
      json.content_refs,
      json._embedded && json._embedded.channels ? json._embedded.channels
        .filter(channel => this.channelHelper.checkJSON(channel))
        .map(channel => this.channelHelper.fromJSON(channel)) : [],
      json.channel_refs,
      json.blocked_users,
      json.created_on ? moment(json.created_on).toDate() : undefined,
      json.creatorname,
      json.lastmodified ? moment(json.lastmodified).toDate() : undefined,
      json.modifiedby,
      json.version,
      json.versions ? json.versions.map(ver => <VersionModel>{
        number: ver.number,
        date: moment(ver.date).toDate()
      }) : [],
      json.baseId
    );

    // Sort based on ref objects
    group.groups = Utils.mapOrder(group.groups, group.groupRefs, 'id');
    group.stations = Utils.mapOrder(group.stations, group.stationRefs, 'id');
    group.contents = Utils.mapOrder(group.contents, group.contentRefs, 'id');
    group.channels = Utils.mapOrder(group.channels, group.channelRefs, 'id');

    return group;
  }
}
