import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Channel } from '../../../../../app/shared/models/channel.model';
import { Status } from '../../../../../app/shared/models/status.model';


@Component({
  selector: 'app-channel-list-modal',
  templateUrl: './channel-list-modal.component.html'
})
export class ChannelListModalComponent {

  @Input() channels: Channel[];
  @Input() status: Status;

  selection: Channel[] = [];

  constructor(
    public activeModal: NgbActiveModal
  ) { }
}
