import { Component, Input } from '@angular/core';

import { BtnBaseComponent } from '../../../../../app/shared/components/btns/btn-base/btn-base.component';


@Component({
  selector: 'app-btn-reload',
  template: `
    <button type="button" [disabled]="disabled"
            [className]="disabled ? 'btn btn-' + disableTheme : 'btn btn-' + theme"
            appBtnSpinner [appBtnSpinnerLoading]="status && status.loading"
            (click)="onButtonClick($event)">
      <i class="fa fa-refresh"></i> {{ title | translate }}
    </button>
  `
})
export class BtnReloadComponent extends BtnBaseComponent {

  @Input() title: string = "Reload";
  @Input() disableTheme = 'primary';

  constructor() {
    super();
  }
}
