import { FormControlSelect } from './form-control/form-control-select.model';
import { FormControlString } from './form-control/form-control-string.model';
import { FormControlPassword } from './form-control/form-control-password.model';

import { BaseModel } from './base.model';
import { Role } from './role.model';
import { Organization } from "./organization.model";
import { FormControlBoolean } from "./form-control/form-control-boolean.model";
import { Address } from "./address.model";

export class User extends BaseModel {

  constructor(
    public id: string,
    public type: FormControlSelect,
    public role: FormControlSelect,
    public username: FormControlString,
    public password: FormControlPassword,
    public permissions: string[],
    public organization: Organization,
    public blockedUsers: string[],
    public firstname: FormControlString,
    public lastname: FormControlString,
    public email: FormControlString,
    public registrationCompleted: FormControlBoolean,
    public blocked: FormControlBoolean,
    public clientId: FormControlString,
    public clientSecret: FormControlString,
    public address: Address,
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string
  ) {
    super(id, blockedUsers, createdOn, creatorName, lastModified, modifiedBy);
  }


  toJSON(): object {
    const json = super.toJSON();

    // delete json['_id'];
    json['id'] = this.id;
    json['type'] = this.type.value;
    json['roles'] = this.role.value ? this.role.value.map(id => new Role(id, undefined, undefined, undefined, undefined).toJSON()) : [];
    json['username'] = this.username.value;
    json['password'] = this.password.value;
    json['firstname'] = this.firstname.value;
    json['lastname'] = this.lastname.value;
    json['email'] = this.email.value;
    json['registrationCompleted'] = this.registrationCompleted.value;
    json['blocked'] = this.blocked.value;
    json['clientId'] = this.clientId.value;
    json['clientSecret'] = this.clientSecret.value;
    json['address'] = this.address.toJSON();
    json['organization'] = this.organization ? this.organization.toJSON() : undefined;

    return json;
  }
}
