import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpParams, HttpRequest } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable } from 'rxjs';

import { File } from '../models/file.model';
import { Status } from '../models/status.model';

import { FileType } from '../enums/file-type.enum';

import { MessageModalComponent } from '../../core/components/message-modal/message-modal.component';
import { FileEditModalComponent } from '../components/modals/file-edit-modal/file-edit-modal.component';

import { AppConfig } from '../../app.config';

import Utils from '../../../app/shared/utils/utils';
import { FileTypeUtils } from "../utils/file-type-utils";
import { ModelMapperService } from "./mapper/model-mapper.service";
import { ContentTypeEnum } from "../enums/content-type.enum";


@Injectable()
export class FileService {

  private filesUrl = AppConfig.API_URL + AppConfig.API_REST_BASE_PATH + 'files';

  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    private fileTypeUtils: FileTypeUtils,
    private modelMapper: ModelMapperService
  ) {
  }

  private handleError(error: any, status?: Status): Promise<any> {
    status = status || new Status();
    status.setError();
    return Promise.reject(error.message || error);
  }


  create(files: any, fileType: number, attribute?: string, status?: Status, contentType?: number, secured?: boolean): Observable<File[]> {
    status = status || new Status();
    status.setLoading();

    // TOD0: Check if all files have the same file type
    const url = `${this.filesUrl}/${FileType[fileType].toLowerCase()}`;

    const formData = new FormData();
    formData.append('contentType', attribute);

    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i], files[i].name);
    }

    if(secured) {
      formData.append('secured', 'true');
    }

    const request = new HttpRequest('POST', url, formData, {reportProgress: true});

    return this.http.request(request).pipe(
      map(event => {
        if (event.type === HttpEventType.UploadProgress) {
          status.progress = Math.round(100 * event.loaded / event.total);
        } else if (event.type === HttpEventType.Response && event.body instanceof Array && event.body.length > 0) {
          status.setSuccess();
          return event.body
            .filter(file => this.checkJSON(file))
            .map(file => {
              file['attribute'] = attribute && !Utils.isThumbnail(file['attribute']) ? attribute : file['attribute'];
              file['attribute'] = file['attribute'] || FileType[fileType].toLowerCase();

              const isAudioSlideImage = FileType.IMAGE === fileType && ContentTypeEnum.AUDIO_SLIDE === contentType;

              return this.fromJSON(file, fileType, isAudioSlideImage);
            });
        } else if (event.type === HttpEventType.Response && event.body instanceof Array && event.body.length === 0) {
          status.setNoResults();
        }
      }),
      catchError(err => this.handleError(err, status)),);
  }

  edit(file: File): Promise<any> {
    const form = new FormGroup({});

    const modalRef = this.modalService.open(
      FileEditModalComponent, {
        beforeDismiss: function () {
          return !form.invalid;
        }
      });

    modalRef.componentInstance.form = form;
    modalRef.componentInstance.file = file;

    return modalRef.result.then(() => {
      return Promise.resolve();
    }, () => Promise.resolve());
  }

  delete(href: string, noConfirm?: boolean, status?: Status): Promise<any> {
    let promise = Promise.resolve();

    if (!noConfirm) {
      const modalRef = this.modalService.open(MessageModalComponent);
      modalRef.componentInstance.confirm = true;
      modalRef.componentInstance.message = `Are you sure that you want to delete the file with href ${href}?`;
      promise = modalRef.result;
    }

    return promise.then(() => {
      status = status || new Status();
      status.setLoading();

      const url = this.filesUrl + href;

      return this.http.delete(url).pipe(
        catchError(err => this.handleError(err, status)))
        .toPromise();

    }, () => Promise.reject('Dimissed'));
  }

  checkJSON(json: any): boolean {
    return this.modelMapper.fileCheckJSON(json);
  }

  fromJSON(json: any, type?: number, isAudioSlideImage?: boolean): File {
    return this.modelMapper.fileFromJSON(json, type, isAudioSlideImage)
  }
}
