import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { GameSolution } from '../../../models/game-solution.model';
import { GameSolutionTypeUtils } from '../../../utils/game-solution-type-utils';

@Component({
  selector: 'app-game-solution-create-modal',
  templateUrl: './game-solution-create-modal.component.html'
})
export class GameSolutionCreateModalComponent implements OnInit {

  @Input() solution: GameSolution;
  @Input() solutionTypes: number[];
  form = new FormGroup({});

  constructor(
    public activeModal: NgbActiveModal,
    private solutionTypeUtils: GameSolutionTypeUtils
  ) {
  }

  create(): void {
    this.activeModal.close(this.solution);
  }

  ngOnInit() {
    if (this.solutionTypes) {
      this.setSolutionTypeOptions();
    } else {
      this.solutionTypes = this.solutionTypeUtils.getTypeValues();
      this.setSolutionTypeOptions();
    }
  }

  private setSolutionTypeOptions() {
    this.solution.type.options = this.solution.type.options.filter(solutionType => {
      return this.solutionTypes.indexOf(solutionType.value) !== -1;
    });

    this.solution.type.value = this.solution.type.options[0].value;
    this.solution.parameters = this.solutionTypeUtils.createDictionary(this.solution.type.value, 'parameters');
    this.solution.textDictionary = this.solutionTypeUtils.createDictionary(this.solution.type.value, 'textDictionary');
    this.solution.assetDictionary = this.solutionTypeUtils.createDictionary(this.solution.type.value, 'assetDictionary');
  }
}
