import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-author',
  template: `
    <span *ngIf="author">by <app-username [username]="author"></app-username></span>
  `
})
export class AuthorComponent {

  @Input() author: string;

  constructor() { }
}
