import { ModelHelper } from "./model.helper.interface";
import Utils from "../../utils/utils";
import { HtmlPage } from "../html-page.model";
import { FormControlBoolean } from "../form-control/form-control-boolean.model";
import { FormControlNumber } from "../form-control/form-control-number.model";
import { FormControlString } from "../form-control/form-control-string.model";
import * as moment from "moment";
import { FormControlType } from "../../enums/form-control-type.enum";
import { MultiLangStringHelper } from "./multi-lang-string.helper";
import { HtmlContentModelHelper } from "./html-content-model.helper";

export class HtmlPageModelHelper implements ModelHelper<HtmlPage> {

  constructor(private multiLangStringHelper: MultiLangStringHelper,
              private htmlContentHelper: HtmlContentModelHelper) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id);
  }

  emptyModel(): HtmlPage {
    return new HtmlPage(
      '',
      this.multiLangStringHelper.fromJSON(
        {},
        'name',
        {label: 'label.html.field.menu-entry-name', apiParam: 'name', formControlType: FormControlType.INPUT}
      ),
      new FormControlBoolean({
        label: 'label.html.field.index-page',
        value: false
      }),
      new FormControlNumber({
        label: 'label.html.field.menu-entry-order',
        value: null,
        apiParam: 'menuOrderNumber'
      }),
      new FormControlString({
        label: 'label.html.field.background-color',
        value: '',
        apiParam: 'bgColor'
      }),
      new FormControlBoolean({
        label: 'label.html.field.menu-page',
        value: false
      }),
      new FormControlBoolean({
        label: 'label.general.active',
        value: false
      }),
      [],
      []
    );
  }

  fromJSON(json: any, skipCheck = false): HtmlPage {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    // create content object
    return new HtmlPage(
      json._id,
      this.multiLangStringHelper.fromJSON(
        json.name,
        'name',
        {
          apiParam: 'name',
          label: 'label.html.field.menu-entry-name'
        },
        json.name ? Object.keys(json.name) : []
      ),
      new FormControlBoolean({
        label: 'label.html.field.index-page',
        value: !!json.isIndexPage
      }),
      new FormControlNumber({
        label: 'label.html.field.menu-entry-order',
        value: json.menuOrderNumber,
        apiParam: 'menuOrderNumber'
      }),
      new FormControlString({
        label: 'label.html.field.background-color',
        value: json.bgColor,
        apiParam: 'bgColor'
      }),
      new FormControlBoolean({
        label: 'label.html.field.menu-page',
        value: !!json.showInMenu
      }),
      new FormControlBoolean({
        label: 'label.general.active',
        value: !!json.active
      }),
      json.contentList ? json.contentList.map(item => this.htmlContentHelper.fromJSON(item, true)) : [],
      json.blocked_users,
      json.version,
      json.created_on ? moment(json.created_on).toDate() : undefined,
      json.creatorname,
      json.lastmodified ? moment(json.lastmodified).toDate() : undefined,
      json.modifiedby
    );

    // if (htmlPage.schema && htmlPage.contentList.length == 0) {
    //   const contentList = htmlPage.schema.fields.map(field => new HtmlContent('', htmlPage.schema.key, field.key));
    //   htmlPage.contentList = contentList;
    // }
  }
}
