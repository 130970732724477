import { MultiLangString } from './multi-lang-string.model';

export class GameMultiLangDictionaryEntry {

  constructor(
    public id: string,
    public key: string,
    public locales: MultiLangString,
    public invalid?: boolean,
    public version?: number,
    public created_on?: string,
    public creatorname?: string,
    public lastmodified?: string,
    public modifiedby?: string,
  ) {
  }

  toJSON(): any {
    const locales = this.locales.toJSON();

    return {
      id: this.id,
      key: this.key,
      invalid: this.invalid,
      version: this.version,
      created_on: this.created_on,
      creatorname: this.creatorname,
      lastmodified: this.lastmodified,
      modifiedby: this.modifiedby,
      locales
    };
  }
}
