import { Injectable } from '@angular/core';
import Utils from '../../../app/shared/utils/utils';
import { GameSelectionTypeConfigOptions } from '../../../config/game-selection-type-config.interface';
import { GameSelectionCategory } from '../../game/enum/game-selection-category.enum';
import { FormControlOptions } from '../interfaces/form-control-options.interface';
import { FormControlSelectOptions } from '../interfaces/form-control-select-options.interface';
import { Dictionary } from '../models/dictionary';
import { GameUtilHelper } from '../models/helper/game-util-helper';
import { ConfigurationService } from '../services/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class GameSelectionTypeUtils {

  private selectionTypeValues;

  constructor(private config: ConfigurationService, private gameUtilHelper: GameUtilHelper) {
  }

  private getSelectionTypes(): GameSelectionTypeConfigOptions {
    if (this.config.isLoadingConfig()) {
      return undefined;
    }

    const config = this.config.getSelectionTypeConfig();
    if (config) {
      return Utils.clone(config);
    }

    return undefined;
  }

  getTypeConfig(type: number) {
    return this.getSelectionTypes()[ type ];
  }

  getLabel(selectionType: string | number) {
    if (this.getSelectionTypes() && this.isValid(selectionType)) {
      return this.getSelectionTypes()[ Number(selectionType) ].label;
    }

    return '';
  }

  hasField(selectionType: string | number, field: string): boolean {
    if (this.getSelectionTypes()) {
      return Object.keys(this.getSelectionTypes()[ Number(selectionType) ].fields).indexOf(field) >= 0;
    }

    return false;
  }

  getFieldOptions(selectionType: string | number, field: string, defaultOptions: FormControlOptions<any>): FormControlOptions<any> {
    if (this.getSelectionTypes()) {
      const options = this.hasField(selectionType, field) ? Utils.clone(this.getSelectionTypes()[ Number(selectionType) ].fields[ field ]) : {};

      for (const optionName in defaultOptions) {
        if (Utils.isDefined(defaultOptions[ optionName ])) {
          options[ optionName ] = Utils.isDefined(options[ optionName ]) ? options[ optionName ] : defaultOptions[ optionName ];
        }
      }

      options.key = defaultOptions.key || field;
      options.hidden = Utils.isDefined(defaultOptions.hidden) ? defaultOptions.hidden : !this.hasField(selectionType, field);

      return options;
    }

    return {};
  }

  getTypeValues(): number[] {
    if (this.getSelectionTypes()) {
      this.selectionTypeValues = this.selectionTypeValues || Object.keys(this.getSelectionTypes())
        .filter(Number)
        .map(selectionType => Number(selectionType));

      return Utils.clone(this.selectionTypeValues);
    }

    return [];
  }

  getCategoryOfType(type: number): string {
    const config = this.getSelectionTypes()[ type ];

    return config && config.category;
  }

  getCategoryValues(): string[] {
    return Object.values(GameSelectionCategory);
  }

  getDefaultType(): number {
    return this.getTypeValues()[ 0 ];
  }

  getDefaultCategory(): string {
    return this.getCategoryValues()[ 0 ];
  }

  isValid(selectionType: string | number): boolean {
    return !isNaN(Number(selectionType)) && this.getTypeValues().indexOf(Number(selectionType)) >= 0;
  }

  getTypeSelectOptions(): FormControlSelectOptions<number> {
    return this.getTypeValues()
      .map(selectionType => {
        return {
          value: selectionType,
          label: this.getLabel(selectionType)
        };
      });
  }

  getCategorySelectOptions(): FormControlSelectOptions<string> {
    return this.getCategoryValues()
      .map(value => ({ value, label: value.toUpperCase() }));
  }

  getModules(selectionType: string | number): string[] {
    if (this.getSelectionTypes()) {
      return Utils.clone(this.getSelectionTypes()[ Number(selectionType) ].modules);
    }

    return [];
  }

  isModuleEnabled(selectionType: string | number, module: string): boolean {
    return this.getModules(selectionType).indexOf(module) >= 0;
  }

  createDictionary(levelType: string | number, key: 'parameters' | 'textDictionary' | 'assetDictionary'): any[] {

    const types = this.getSelectionTypes();

    if (levelType && types) {
      const dict: Dictionary<any> = types[ Number(levelType) ];

      return this.gameUtilHelper.createDictionary(dict, key);
    }

    return [];
  }
}
