import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html'
})
export class MessageModalComponent implements OnInit {

  @ViewChild('modalBody') dataContainer: ElementRef;
  @Input() confirm = false;
  @Input() title?: string;
  @Input() message: string | string[];

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    if (!this.message) {
      return;
    }

    let text = '';

    if (typeof this.message === 'string') {
      text = this.message.toString();

    } else {
      const keys = Object.keys(this.message);
      keys.forEach((key, index) => {
        text += this.message[key] + (index === keys.length - 1 ? '' : '<br>');
      });
    }

    this.dataContainer.nativeElement.innerHTML = text;
  }
}
