import { GameSelectionCategory } from '../../../game/enum/game-selection-category.enum';
import { GameSelectionTypeUtils } from '../../utils/game-selection-type-utils';
import Utils from '../../utils/utils';
import { FormControlSelect } from '../form-control/form-control-select.model';
import { GameSelection } from '../game-selection.model';
import { GameUtilHelper } from './game-util-helper';
import { MultiLangStringHelper } from './multi-lang-string.helper';

export class GameSelectionModelHelper {

  constructor(
    private readonly multiLangStringHelper: MultiLangStringHelper,
    private readonly selectionTypeUtils: GameSelectionTypeUtils,
    private readonly gameUtilHelper: GameUtilHelper,
  ) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json.id);
  }

  emptyModel(selectionTypes: number[]): GameSelection {
    const filteredTypes = this.selectionTypeUtils.getTypeSelectOptions().filter(selectionType => {
        return selectionTypes.indexOf(selectionType.value) !== -1;
      });

    const type = selectionTypes[0];
    const config = this.selectionTypeUtils.getTypeConfig(type);

    return new GameSelection(
      '',
      this.multiLangStringHelper.fromJSON(
        {},
        'title',
        this.selectionTypeUtils.getFieldOptions(this.selectionTypeUtils.getDefaultType(), 'title', {
          label: 'label.game.selection.field.title',
          hidden: false
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'description',
        this.selectionTypeUtils.getFieldOptions(this.selectionTypeUtils.getDefaultType(), 'description', {
          label: 'label.game.selection.field.description',
          hidden: false
        })
      ),
      new FormControlSelect({
        value: type,
        key: 'type',
        label: 'label.general.type',
        options: filteredTypes,
        required: true,
        hidden: false
      }),
      GameSelectionCategory[ this.selectionTypeUtils.getCategoryOfType(type).toUpperCase() ],
      undefined,
      this.gameUtilHelper.createDictionary(config, 'parameters'),
      this.gameUtilHelper.createDictionary(config, 'textDictionary'),
      this.gameUtilHelper.createDictionary(config, 'assetDictionary')
    );
  }

  fromJSON(json: any, skipCheck = false): GameSelection {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    const selectionType = Number(json.type);
    const config = this.selectionTypeUtils.getTypeConfig(selectionType);

    // create selection object
    const selection = new GameSelection(
      json.id,
      this.multiLangStringHelper.fromJSON(
        json.title,
        'title',
        this.selectionTypeUtils.getFieldOptions(this.selectionTypeUtils.getDefaultType(), 'title', {
          label: 'label.game.selection.field.title',
          hidden: false
        }),
        json.title ? Object.keys(json.title) : []
      ),
      this.multiLangStringHelper.fromJSON(
        json.description,
        'description',
        this.selectionTypeUtils.getFieldOptions(this.selectionTypeUtils.getDefaultType(), 'description', {
          label: 'label.game.selection.field.description',
          hidden: false
        }),
        json.title ? Object.keys(json.description) : []
      ),
      new FormControlSelect({
        value: selectionType,
        key: 'type',
        label: 'label.general.type',
        options: this.selectionTypeUtils.getTypeSelectOptions(),
        required: true,
        hidden: false
      }),
      json.category,
      json.sortOrder,
      this.gameUtilHelper.mapParameters(json.parameters, config),
      this.gameUtilHelper.mapMultiLangDictEntries(json.textDictionary, config),
      this.gameUtilHelper.mapAssetLangDictEntries(json.assetDictionary, config),
    );

    return selection;
  }
}
