import * as moment from 'moment';
import { JwtDto } from "./jwt.dto";
import * as jwtDecode from 'jwt-decode';


export class AccessToken {

  constructor(
    public accessToken: string,
    public refreshToken: string,
    public expirationTime: number,
    public userId: string,
    public username: string,
    public tenantId: string,
    public organizationId: string,
    public permissions: string[],
    public roles: string[],
    public iat?: number,
    public expiresIn?: string
  ) {
  }

  static checkJSON(json: any): boolean {
    return json && (json.access_token || json.accessToken);
  }

  static fromJSON(json: any): AccessToken {

    // integrity check
    if (!this.checkJSON(json)) {
      return undefined;
    }

    const decoded = this.decodeAccessToken(json.access_token || json.accessToken);
    if(!decoded) {
      return undefined;
    }

    // create content object
    const accessToken = new AccessToken(
      json.access_token || json.accessToken, json.refresh_token || json.refreshToken,
      json.expirationTime || moment().add(json.expires_in, 'seconds'), decoded.userId,
      decoded.username, decoded.tenantId, decoded.organizationId, decoded.permissions, decoded.roles, decoded.iat
    );

    accessToken.calcExpiresIn(200);

    return accessToken;
  }

  calcExpiresIn(timeout: number) {
    const diff = moment(this.expirationTime).diff(moment());
    this.expiresIn = moment.duration(diff).humanize();

    if (diff > 0) {
      setTimeout(() => {
        timeout = 5000;
        this.calcExpiresIn(timeout);
      }, timeout);
    }
  }

  isExpired(): boolean {
    return !this.expirationTime || moment().isAfter(moment(this.expirationTime));
  }

  private static decodeAccessToken(token: string): JwtDto {
    try {
      const decoded = jwtDecode(token);
      console.info(`JWT decoding successful`);

      return decoded;
    } catch (e) {
      console.error(`JWT decoding failed with error: ${e}`);
      return null;
    }
  }
}
