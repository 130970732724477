import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, tap } from 'rxjs/operators';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable } from 'rxjs';
import { AppConfig } from '../../app.config';

import { UserService } from '../../core/services/user.service';
import { Game } from '../models/game.model';

import { GameResult } from '../models/game-result.model';
import { Status } from '../models/status.model';

import { MessageModalComponent } from '../../core/components/message-modal/message-modal.component';
import { GameResultCreateModalComponent } from '../components/modals/game-result-create-modal/game-result-create-modal.component';
import { GameResultListModalComponent } from '../components/modals/game-result-list-modal/game-result-list-modal.component';

import { ApiBaseService } from '../../core/services/api-base.service';
import Utils from '../utils/utils';
import { FileService } from './file.service';

import { CreateOptions } from '../interfaces/create-options.interface';
import { ModelMapperService } from './mapper/model-mapper.service';

@Injectable()
export class GameResultService extends ApiBaseService<GameResult> {

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected userService: UserService,
    protected fileService: FileService,
    protected modalService: NgbModal,
    private modelMapper: ModelMapperService
  ) {
    super(GameResult, 'results', http, router, userService, fileService);

    this.url = AppConfig.GAME_SERVER + AppConfig.GAME_BASE_URL;
  }

  select(exclude: GameResult[] = [], resultTypes?: number[]): Promise<GameResult[]> {
    const modalRef = this.modalService.open(GameResultListModalComponent, {size: 'lg'});
    modalRef.componentInstance.status = new Status();

    this.getAll(modalRef.componentInstance.status)
      .subscribe((results: GameResult[]) => {
        try {
          modalRef.componentInstance.results = results
            .filter(result => exclude.findIndex(myResult => result.id === myResult.id) < 0);

          if (resultTypes && resultTypes.length > 0) {
            modalRef.componentInstance.resultTypes = resultTypes;
          }
        } catch (e) {
        }
      });

    return modalRef.result;
  }

  create(options: CreateOptions<GameResult>, resultTypes?: number[], gameTypes?: number[]): Promise<GameResult> {
    return super.create(Object.assign(options, {
      component: GameResultCreateModalComponent,
      componentOptions: {
        result: this.emptyModel(),
        resultTypes,
        gameTypes,
      }
    }));
  }

  getGames(resultId: string, status?: Status): Observable<Game[]> {
    status = status || new Status();
    status.setLoading();

    const url = `${this.url}/${resultId}/games`;
    const responseKey = '_embedded.games';

    return this.http
      .get(url).pipe(
        map(response => {
          if (Utils.deepAccessUsingString(response, responseKey) && Utils.deepAccessUsingString(response, responseKey).length > 0) {
            status.setSuccess();
            return Utils.deepAccessUsingString(response, responseKey)
              .filter(object => this.modelMapper.gameCheckJSON(object))
              .map(object => this.modelMapper.gameFromJSON(object));
          } else {
            status.setNoResults();
          }
        }),
        catchError(err => this.handleError(err, status)),);
  }

  delete(id: string, status?: Status): Promise<any> {
    const modalRef = this.modalService.open(MessageModalComponent);
    modalRef.componentInstance.confirm = true;
    modalRef.componentInstance.message = 'Are you sure that you want to delete the result?';

    return modalRef.result.then(() => super.delete(id, status), () => Promise.reject('Dimissed'));
  }

  checkJSON(json: any): boolean {
    return this.modelMapper.resultCheckJSON(json);
  }

  emptyModel(): GameResult {
    return this.modelMapper.resultEmptyModel();
  }

  fromJSON(json: any, skipCheck?: boolean): GameResult {
    return this.modelMapper.resultFromJSON(json, skipCheck);
  }
}
