import { Observable, of } from 'rxjs';

import { catchError, map, publishReplay, refCount, share, tap } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Utils from '../../../app/shared/utils/utils';
import { AppConfig } from '../../app.config';
import { ImageEditModalComponent } from '../../shared/components/modals/image-edit-modal/image-edit-modal.component';
import { CreateOptions } from '../../shared/interfaces/create-options.interface';
import { BaseFilesModel } from '../../shared/models/base-files.model';
import { BaseModel } from '../../shared/models/base.model';
import { File } from '../../shared/models/file.model';
import { Status } from '../../shared/models/status.model';
import { FileService } from '../../shared/services/file.service';
import { UserService } from './user.service';

export abstract class ApiBaseService<T extends BaseModel> {

  protected url;
  protected temp: T;

  protected constructor(
    private type, // TOOD: typeof BaseModel
    private api: string,
    protected http: HttpClient,
    protected router: Router,
    protected userService?: UserService,
    protected fileService?: FileService,
    protected modalService?: NgbModal,
    protected responseKey?: string
  ) {
    this.url = AppConfig.API_URL + AppConfig.API_REST_BASE_PATH + api;
    this.responseKey = this.responseKey || '_embedded.' + this.api;
  }

  protected handleError(error?: any, status?: Status): Promise<any> {
    status = status || new Status();
    status.setError();
    return Promise.reject(error ? error.message : error);
  }

  abstract checkJSON(json: any): boolean;

  abstract fromJSON(json: any, skipCheck?: boolean): T;

  abstract emptyModel(): T;

  /**
   * Get a single entry
   */
  get(id: string, version?: number, status?: Status): Observable<T> {
    status = status || new Status();
    status.setLoading();

    let url = `${this.url}/${id}`;

    if (version) {
      url += `/version/${version}`;
    }

    return this.http
      .get(url).pipe(
        map(response => {
          if (this.checkJSON(response)) {
            status.setSuccess();
            return this.fromJSON(response);
          } else {
            status.setNoResults();
          }
        }),
        catchError(err => this.handleError(err, status)));
  }

  /**
   * Get a list of all entries
   */
  getAll(status?: Status, params?: Array<{ param: string, value: string }>, path?: string): Observable<T[]> {
    status = status || new Status();
    status.setLoading();

    let queryParams = new HttpParams();
    const url = path ? path : this.url;

    if (params) {
      params.forEach(param => queryParams = queryParams.append(param.param, param.value));
    }

    return this.http
      .get(url, { params: queryParams }).pipe(
        share(),
        publishReplay(1),
        refCount(),
        map(response => {
          const payload = Utils.deepAccessUsingString(response, this.responseKey);

          if (payload && payload.length > 0) {
            status.setSuccess();
            return payload
              .filter(object => this.checkJSON(object))
              .map(object => this.fromJSON(object));
          } else {
            status.setNoResults();
          }
        }),
        catchError(err => this.handleError(err, status)));
  }

  getTemp(): T {
    return this.temp;
  }

  create(options: CreateOptions<T>): Promise<T> {
    options.status = options.status || new Status();

    this.temp = undefined;
    options.url = options.url || './';

    let modalRef;

    if (options.component) {
      modalRef = this.modalService.open(options.component);
      Object.assign(modalRef.componentInstance, options.componentOptions);

    } else if (!options.model) {
      return;
    }

    return (modalRef ? modalRef.result : Promise.resolve(options.model)).then((model: T) => {
      model = this.fromJSON(model.toJSON(), true); // We need this to reinitialize the model fields

      return (options.save ? this.update(model, options.status, options.path) : of(model))
        .toPromise()
        .then((updatedModel: T) => {
          this.temp = updatedModel;

          if (options.route) {
            setTimeout(() => {
              this.router.navigate([ options.url, updatedModel.id || 'create' ], { relativeTo: options.route });
            });
          }

          options.status.setSuccess();
          return updatedModel;
        });
    }, err => this.handleError(err, options.status));
  }

  setActive(model: T, status?: Status): Observable<T> {
    if (Utils.isUndefined(!model[ 'active' ]) || Utils.isUndefined(!model[ 'active' ].value)) {
      return Observable.throw('Error');
    }

    status = status || new Status();
    status.setLoading();

    const url = `${this.url}/${model.id}`;

    return this.http.put(
      url,
      {
        $set: {
          active: model[ 'active' ].value
        }
      }
    ).pipe(catchError(err => this.handleError(err, status)));
  }

  /**
   * Create / Update specific entry
   */
  update(model: T, status?: Status, path?: string): Observable<T> {
    status = status || new Status();
    status.setLoading();

    const url = path ? `${this.url}${path}/${model.id}` : `${this.url}/${model.id}`;

    if (this.userService) {
      this.userService.addCurrentUser(model);
    }

    return this.http[ Utils.isValidId(model.id) ? 'put' : 'post' ](
      url,
      Utils.isValidId(model.id) ? { $set: model.toJSON() } : model.toJSON()
    ).pipe(
      map(response => {

        if (model.id || this.checkJSON(response)) {
          status.setSuccess();
          return model.id ? model : this.fromJSON(response);
        } else {
          status.setError();
        }
      }),
      tap(() => {
        if (this.fileService && model instanceof BaseFilesModel
          && model.filesToDelete && model.filesToDelete.length > 0) {
          for (const file of model.filesToDelete) {
            this.fileService.delete(file.href, true)
              .then(() => {
              }, () => {
              });
          }
        }
      }),
      catchError(err => this.handleError(err, status)));
  }

  /**
   * Delete specific entry
   */
  delete(id: string, status?: Status): Promise<any> {
    status = status || new Status();
    status.setLoading();

    const url = `${this.url}/${id}`;

    return this.http
      .delete(url)
      .toPromise()
      .catch(err => this.handleError(err, status));
  }

  /**
   * Add files to a specific entry
   * must be instance of BaseFilesModel
   */
  addFiles(model: BaseFilesModel, files: any, fileType: number, attribute?: string, status?: Status, contentType?: number, secured?: boolean): Observable<File[]> {
    if (!this.fileService || !(model instanceof BaseFilesModel)) {
      return;
    }

    return this.fileService.create(files, fileType, attribute, status, contentType, secured).pipe(
      map((newFiles: File[]) => {

        if (newFiles) {
          newFiles.map(file => model.addFile(file));
        }

        return newFiles;
      })
    );
  }

  /**
   * Edit images of a specific entry
   * must be instance of BaseFilesModel
   */
  editImages(model: BaseFilesModel, callback?: any): void {
    if (!this.modalService || !(model instanceof BaseFilesModel)) {
      return;
    }

    const modalRef = this.modalService.open(ImageEditModalComponent, { size: 'lg' });
    modalRef.componentInstance.model = model;

    modalRef.componentInstance.onUpload.subscribe($event => {
      this.addFiles(model, $event.files, $event.fileType, $event.attribute, $event.status).subscribe(() => {
        // nothing to do
      });
    });

    modalRef.result.then(
      () => callback(model),
      () => callback(model)
    );
  }
}
