import { Injectable } from '@angular/core';
import { GameScoringSystemCategory } from '../../../game/enum/game-scoring-system-category.enum';
import { GameScoringSystemTypeUtils } from '../../utils/game-scoring-system-type-utils';
import Utils from '../../utils/utils';
import { FormControlSelect } from '../form-control/form-control-select.model';
import { GameScoringSystem } from '../game-scoring-system.model';
import { GameUtilHelper } from './game-util-helper';
import { MultiLangStringHelper } from './multi-lang-string.helper';

@Injectable({providedIn: 'root'})
export class GameScoringSystemModelHelper {

  constructor(
    private readonly scoringSystemTypeUtils: GameScoringSystemTypeUtils,
    private readonly gameUtilHelper: GameUtilHelper,
    private readonly multiLangStringHelper: MultiLangStringHelper
  ) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json.id);
  }

  emptyModel(type: number): GameScoringSystem {
    const filteredTypes = this.scoringSystemTypeUtils.getTypeSelectOptions().filter(scoringSystemType =>
      scoringSystemType.value === type
    );

    const config = this.scoringSystemTypeUtils.getTypeConfig(type);

    return new GameScoringSystem(
      '',
      this.multiLangStringHelper.fromJSON(
        {},
        'title',
        this.scoringSystemTypeUtils.getFieldOptions(this.scoringSystemTypeUtils.getDefaultType(), 'title', {
          label: 'label.game.field.title',
          hidden: false
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'description',
        this.scoringSystemTypeUtils.getFieldOptions(this.scoringSystemTypeUtils.getDefaultType(), 'description', {
          label: 'label.game.field.description',
          hidden: false
        })
      ),
      new FormControlSelect({
        value: type,
        key: 'type',
        label: 'label.general.type',
        options: filteredTypes,
        required: true,
        hidden: false
      }),
      GameScoringSystemCategory[ this.scoringSystemTypeUtils.getCategoryOfType(type).toUpperCase() ],
      this.gameUtilHelper.createDictionary(config, 'parameters'),
      this.gameUtilHelper.createDictionary(config, 'textDictionary'),
      this.gameUtilHelper.createDictionary(config, 'assetDictionary')
    );
  }

  fromJSON(json: any, skipCheck = false): GameScoringSystem {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    const type = Number(json.type);
    const filteredTypes = this.scoringSystemTypeUtils.getTypeSelectOptions().filter(scoringSystemType =>
      scoringSystemType.value === type
    );
    const config = this.scoringSystemTypeUtils.getTypeConfig(type);

    // create element object
    return new GameScoringSystem(
      json.id,
      this.multiLangStringHelper.fromJSON(
        json.title,
        'title',
        this.scoringSystemTypeUtils.getFieldOptions(this.scoringSystemTypeUtils.getDefaultType(), 'title', {
          label: 'label.game.field.title',
          hidden: false
        }),
        json.title ? Object.keys(json.title) : []
      ),
      this.multiLangStringHelper.fromJSON(
        json.description,
        'description',
        this.scoringSystemTypeUtils.getFieldOptions(this.scoringSystemTypeUtils.getDefaultType(), 'description', {
          label: 'label.game.field.description',
          hidden: false
        }),
        json.description ? Object.keys(json.description) : []
      ),
      new FormControlSelect({
        value: type,
        key: 'type',
        label: 'label.general.type',
        options: filteredTypes,
        required: true,
        hidden: false
      }),
      json.category,
      this.gameUtilHelper.mapParameters(json.parameters, config),
      this.gameUtilHelper.mapMultiLangDictEntries(json.textDictionary, config),
      this.gameUtilHelper.mapAssetLangDictEntries(json.assetDictionary, config),
    );
  }
}
