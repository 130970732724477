import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Status } from '../../../../../app/shared/models/status.model';

@Component({
  selector: 'app-btn-base',
  template: `
    <button type="button" class="btn btn-{{theme}}" [disabled]="disabled"
      appBtnSpinner [appBtnSpinnerLoading]="status && status.loading"
      (click)="onButtonClick($event)">
        <i class="{{icon}}" *ngIf="icon"></i> {{text | translate}}
    </button>
  `
})
export class BtnBaseComponent {

  @Input() text: string;
  @Input() theme = 'primary';
  @Input() icon: string;
  @Input() status: Status;
  @Input() disabled = false;
  @Output() onClick = new EventEmitter();

  constructor() { }

  onButtonClick($event?): void {
    this.onClick.emit();
  }
}
