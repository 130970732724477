import { NgModule } from '@angular/core';

import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';

import { SharedModule } from '../../../app/shared/shared.module';

import { LoginRoutingModule } from '../../../app/user/login/login-routing.module';
import { LoginComponent } from '../../../app/user/login/login.component';
import { LoginStatusComponent } from '../../../app/user/login/login-status.component';

@NgModule({
  declarations: [
    LoginComponent,
    LoginStatusComponent
  ],
  imports: [
    SharedModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    LoginRoutingModule
  ],
  exports: [
    LoginStatusComponent
  ],
  providers: []
})
export class LoginModule { }
