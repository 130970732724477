import { Injectable } from '@angular/core';
import Utils from '../../../app/shared/utils/utils';
import { GameTypeConfigOptions } from '../../../config/game-type-config.interface';
import { GameCategory } from '../../game/enum/game-category.enum';
import { FormControlOptions } from '../interfaces/form-control-options.interface';
import { FormControlSelectOptions } from '../interfaces/form-control-select-options.interface';
import { Dictionary } from '../models/dictionary';
import { GameUtilHelper } from '../models/helper/game-util-helper';
import { ConfigurationService } from '../services/configuration.service';
import { GameLevelTypeUtils } from './game-level-type-utils';

@Injectable({
  providedIn: 'root'
})
export class GameTypeUtils {

  private gameTypeValues;

  constructor(
    private readonly config: ConfigurationService,
    private readonly levelTypeUtils: GameLevelTypeUtils,
    private readonly resultTypeUtils: GameLevelTypeUtils,
    private readonly gameUtilHelper: GameUtilHelper
  ) {
  }

  private getGameTypes(): GameTypeConfigOptions {
    if (this.config.isLoadingConfig()) {
      return undefined;
    }

    const config = this.config.getGameTypeConfig();
    if (config) {
      return Utils.clone(config);
    }

    return undefined;
  }

  getTypeConfig(type: number) {
    return this.getGameTypes()[ type ];
  }

  getLabel(gameType: string | number) {
    if (this.getGameTypes() && this.isValid(gameType)) {
      return this.getGameTypes()[ Number(gameType) ].label;
    }

    return '';
  }

  hasField(gameType: string | number, field: string): boolean {
    if (this.getGameTypes()) {
      return Object.keys(this.getGameTypes()[ Number(gameType) ].fields).indexOf(field) >= 0;
    }

    return false;
  }

  getFieldOptions(gameType: string | number, field: string, defaultOptions: FormControlOptions<any>): FormControlOptions<any> {
    if (this.getGameTypes()) {
      const options = this.hasField(gameType, field) ? Utils.clone(this.getGameTypes()[ Number(gameType) ].fields[ field ]) : {};

      for (const optionName in defaultOptions) {
        if (Utils.isDefined(defaultOptions[ optionName ])) {
          options[ optionName ] = Utils.isDefined(options[ optionName ]) ? options[ optionName ] : defaultOptions[ optionName ];
        }
      }

      options.key = defaultOptions.key || field;
      options.hidden = Utils.isDefined(defaultOptions.hidden) ? defaultOptions.hidden : !this.hasField(gameType, field);

      return options;
    }

    return {};
  }

  getGameTypeValues(): number[] {
    if (this.getGameTypes()) {
      this.gameTypeValues = this.gameTypeValues || Object.keys(this.getGameTypes())
        .filter(Number)
        .map(gameType => Number(gameType));

      return Utils.clone(this.gameTypeValues);
    }

    return [];
  }

  getCategoryOfType(type: number): string {
    const config = this.getGameTypes()[ type ];

    return config && config.category;
  }

  getGameCategoryValues(): string[] {
    return Object.values(GameCategory);
  }

  getDefaultType(): number {
    return this.getGameTypeValues()[ 0 ];
  }

  getDefaultCategory(): string {
    return this.getGameCategoryValues()[ 0 ];
  }

  isValid(gameType: string | number): boolean {
    return !isNaN(Number(gameType)) && this.getGameTypeValues().indexOf(Number(gameType)) >= 0;
  }

  getTypeSelectOptions(): FormControlSelectOptions<number> {
    return this.getGameTypeValues()
      .map(gameType => {
        return {
          value: gameType,
          label: this.getLabel(gameType)
        };
      });
  }

  getCategorySelectOptions(): FormControlSelectOptions<string> {
    return this.getGameCategoryValues()
      .map(value => ({ value, label: value.toUpperCase() }));
  }

  getModules(gameType: string | number): string[] {
    if (this.getGameTypes()) {
      return Utils.clone(this.getGameTypes()[ Number(gameType) ].modules);
    }

    return [];
  }

  isModuleEnabled(gameType: string | number, module: string): boolean {
    return this.getModules(gameType).indexOf(module) >= 0;
  }

  getLevelTypes(gameType: string | number): number[] {
    if (this.getGameTypes()) {
      const levelTypes = this.getGameTypes()[ Number(gameType) ].levelTypes;
      return (levelTypes === 'all' ? this.levelTypeUtils.getLevelTypeValues() : levelTypes) || [];
    }

    return [];
  }

  getResultTypes(gameType: string | number): number[] {
    if (this.getGameTypes()) {
      const resultTypes = this.getGameTypes()[ Number(gameType) ].resultTypes;
      return (resultTypes === 'all' ? this.resultTypeUtils.getLevelTypeValues() : resultTypes) || [];
    }

    return [];
  }

  createDictionary(levelType: string | number, key: 'parameters' | 'textDictionary' | 'assetDictionary'): any[] {

    const types = this.getGameTypes();

    if (levelType && types) {
      const dict: Dictionary<any> = types[ Number(levelType) ];

      return this.gameUtilHelper.createDictionary(dict, key);
    }

    return [];
  }
}
