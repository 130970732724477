import Utils from "../utils/utils";
import { PermissionEnum } from "../enums/permission.enum";
import { BaseModel } from "./base.model";


export class Permission extends BaseModel{

  constructor(
    public id = '',
    public key: PermissionEnum,
    public description
  ) {
    super(id, undefined, undefined, undefined, undefined);
  }

  static checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id);
  }

  static fromJSON(json: any): Permission {
    // integrity check
    if (!this.checkJSON(json)) { return undefined; }

    return new Permission(
      json._id,
      json.key,
      json.description
    );
  }

  toJSON(): object {
    const json = {
      _id: this.id,
      id: this.id,
      description: this.description,
      key: this.key.toString()
    };

    return json;
  }
}
