import { ModelHelper } from "./model.helper.interface";
import Utils from "../../utils/utils";
import { FormControlString } from "../form-control/form-control-string.model";
import { Endpoint } from "../endpoint.model";
import * as moment from "moment";
import { VersionModel } from "../base.model";

export class EndpointModelHelper implements ModelHelper<Endpoint> {

  constructor() {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id);
  }

  emptyModel(): Endpoint {
    return new Endpoint(
      '',
      new FormControlString({
        label: 'label.cdn.field.name',
        required: true
      }),
      0,
      false,
      '',
      []
    );
  }

  fromJSON(json: any, skipCheck = false): Endpoint {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    // create tour object
    const cdn = new Endpoint(
      json._id,

      new FormControlString({
        value: json.name,
        label: 'label.cdn.field.name',
        required: true
      }),

      json.buildNumber,
      json.building,
      json.password,
      json.blocked_users,
      json.created_on ? moment(json.created_on).toDate() : undefined,
      json.creatorname,
      json.lastmodified ? moment(json.lastmodified).toDate() : undefined,
      json.modifiedby,
      json.version,
      json.versions ? json.versions.map(ver => <VersionModel>{
        number: ver.number,
        date: moment(ver.date).toDate()
      }) : [],
      json.baseId,
    );

    return cdn;
  }
}
