import { NgModule } from '@angular/core';

import { SharedModule } from '../../../app/shared/shared.module';

import { ExhibitionListRoutingModule } from '../../../app/exhibition/exhibition-list/exhibition-list-routing.module';
import { ExhibitionListComponent } from '../../../app/exhibition/exhibition-list/exhibition-list.component';
import { TranslateModule } from "@ngx-translate/core";


@NgModule({
  declarations: [
    ExhibitionListComponent
  ],
  imports: [
    SharedModule,
    ExhibitionListRoutingModule,
    TranslateModule
  ],
  providers: [],
  exports: [
    ExhibitionListComponent
  ]
})
export class ExhibitionListModule { }
