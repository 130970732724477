import { catchError, map, publishReplay, refCount, share } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Status } from "../models/status.model";
import { Observable } from "rxjs";
import { FormControlSelectOptions } from "../interfaces/form-control-select-options.interface";
import { AppConfig } from "../../app.config";
import { ApiBaseService } from "../../core/services/api-base.service";
import { Role } from "../models/role.model";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "../../core/services/user.service";
import { Permission } from "../models/permission.model";
import { ModelMapperService } from "./mapper/model-mapper.service";
import Utils from "../utils/utils";

@Injectable()
export class RoleService extends ApiBaseService<Role> {

  private rolesUrl = AppConfig.AUTH_SERVER + AppConfig.AUTH_BASE_URL + '/role';
  private permissionUrl = AppConfig.AUTH_SERVER + AppConfig.AUTH_BASE_URL + '/permission';

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected userService: UserService,
    protected modalService: NgbModal,
    private modelMapper: ModelMapperService
  ) {
    super(Role, 'roles', http, router, userService);
    this.url = this.rolesUrl;
  }

  getRoleNames(status?: Status): Observable<FormControlSelectOptions<string>> {
    status = status || new Status();
    status.setLoading();

    const url = this.url + '/list/names';

    return this.http.get(url).pipe(
      share(),
      publishReplay(1),
      refCount(),
      map((response: any[]) => {
        if (response.length > 0) {
          status.setSuccess();
          return response
            .map(role => {
              return {label: role, value: role};
            });
        } else {
          status.setNoResults();
        }
      }),
      catchError(err => this.handleError(err, status))
    );
  }

  getRolesForTenant(tenantId: string, status?: Status): Observable<Role[]> {
    status = status || new Status();
    status.setLoading();

    const url = this.url + '/list/tenant/' + tenantId;
    return this.http.get(url).pipe(
      share(),
      publishReplay(1),
      refCount(),
      map((response: any[]) => {
        if (Utils.deepAccessUsingString(response, this.responseKey) && Utils.deepAccessUsingString(response, this.responseKey).length > 0) {
          status.setSuccess();
          return Utils.deepAccessUsingString(response, this.responseKey)
            .filter(role => this.checkJSON(role))
            .map(role => this.fromJSON(role));
        } else {
          status.setNoResults();
        }
      }),
      catchError(err => this.handleError(err, status))
    );

  }

  getAllPermissions(status?: Status): Observable<Permission[]> {
    status = status || new Status();
    status.setLoading();

    return this.http.get(this.permissionUrl).pipe(
      share(),
      publishReplay(1),
      refCount(),
      map((response: any[]) => {
        if (response.length > 0) {
          status.setSuccess();
          return response
            .map(permission => Permission.fromJSON(permission));
        } else {
          status.setNoResults();
        }
      }),
      catchError(err => this.handleError(err, status)),);
  }

  checkJSON(json: any): boolean {
    return this.modelMapper.roleCheckJSON(json);
  }

  emptyModel(tenantId?: string): Role {
    return this.modelMapper.roleEmptyModel(tenantId);
  }

  fromJSON(json: any, skipCheck?: boolean): Role {
    return this.modelMapper.roleFromJSON(json, skipCheck);
  }
}
