import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable } from 'rxjs';

import { UserService } from '../../core/services/user.service';

import { Tour } from '../models/tour.model';
import { Status } from '../models/status.model';

import { MessageModalComponent } from '../../core/components/message-modal/message-modal.component';
import { TourCreateModalComponent } from '../components/modals/tour-create-modal/tour-create-modal.component';
import { TourListModalComponent } from '../components/modals/tour-list-modal/tour-list-modal.component';

import { ApiBaseService } from '../../core/services/api-base.service';
import { FileService } from './file.service';

import { CreateOptions } from '../interfaces/create-options.interface';
import Utils from "../utils/utils";
import { Exhibition } from "../models/exhibition.model";
import { ModelMapperService } from "./mapper/model-mapper.service";
import { ChannelService } from "./channel.service";


@Injectable()
export class TourService extends ApiBaseService<Tour> {

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected userService: UserService,
    protected fileService: FileService,
    protected modalService: NgbModal,
    private channelService: ChannelService,
    private modelMapper: ModelMapperService
  ) {
    super(Tour, 'tours', http, router, userService, fileService, modalService);
  }

  select(exclude: Tour[] = []): Promise<Tour[]> {
    const modalRef = this.modalService.open(TourListModalComponent, {size: 'lg'});
    modalRef.componentInstance.status = new Status();

    this.getAll(modalRef.componentInstance.status)
      .subscribe((tours: Tour[]) => {
        try {
          modalRef.componentInstance.tours = tours
            .filter(tour => exclude.findIndex(myTour => tour.id === myTour.id) < 0);
        } catch (e) {
        }
      });

    return modalRef.result;
  }

  create(options: CreateOptions<Tour>): Promise<Tour> {
    return super.create(Object.assign(options, {
      component: TourCreateModalComponent,
      componentOptions: {
        tour: this.emptyModel()
      }
    }));
  }

  getExhibitions(tourId: string, status?: Status): Observable<Exhibition[]> {
    status = status || new Status();
    status.setLoading();

    const url = `${this.url}/${tourId}/exhibitions`;
    const responseKey = '_embedded.exhibitions';

    return this.http
      .get(url).pipe(
        map(response => {
          if (Utils.deepAccessUsingString(response, responseKey) && Utils.deepAccessUsingString(response, responseKey).length > 0) {
            status.setSuccess();
            return Utils.deepAccessUsingString(response, responseKey)
              .filter(object => this.modelMapper.exhibitionCheckJSON(object))
              .map(object => this.modelMapper.exhibitionFromJSON(object));
          } else {
            status.setNoResults();
          }
        }),
        catchError(err => this.handleError(err, status)),);
  }

  delete(id: string, status?: Status): Promise<any> {
    const modalRef = this.modalService.open(MessageModalComponent);
    modalRef.componentInstance.confirm = true;
    modalRef.componentInstance.message = 'Are you sure that you want to delete the tour?';

    return modalRef.result.then(() => super.delete(id, status), () => Promise.reject('Dimissed'));
  }

  checkJSON(json: any): boolean {
    return this.modelMapper.tourCheckJSON(json);
  }

  fromJSON(json: any, skipCheck?: boolean): Tour {
    return this.modelMapper.tourFromJSON(json, skipCheck);
  }

  emptyModel(): Tour {
    return this.modelMapper.tourEmptyModel(this.channelService.getDefaultChannels());
  }
}
