import { ModelHelper } from "./model.helper.interface";
import Utils from "../../utils/utils";
import { FormControlString } from "../form-control/form-control-string.model";
import { FormControlSelect } from "../form-control/form-control-select.model";
import { AwsModel } from "../aws.model";
import { AzureModel } from "../azure.model";
import { SyncServerConfigModel } from "../sync-server-config.model";
import { SyncDataServerModel } from "../sync-dataserver.model";
import { FormControlNumber } from "../form-control/form-control-number.model";
import { CommunityServerConfigModel } from "../community-server-config.model";

export class CommunityServerConfigModelHelper implements ModelHelper<CommunityServerConfigModel> {

  constructor() {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json.id);
  }

  emptyModel(): CommunityServerConfigModel {
    return new CommunityServerConfigModel(
      '',
      new FormControlString({
        key: 'tmpDirectory',
        label: 'label.config.field.tmp-directory',
        required: true
      }),
      new FormControlSelect({
        key: 'filesLocation',
        label: 'label.config.field.file-location',
        options: ['local', 'aws', 'azure'].map(entry => {
          return {label: entry, value: entry}
        }),
        required: true
      }),
      new FormControlString({
        key: 'localPath',
        label: 'label.config.field.content-folder',
        required: false
      }),
      new AwsModel(),
      new AzureModel(),
      []
    );
  }

  fromJSON(json: any, skipCheck = false): CommunityServerConfigModel {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    const model = new CommunityServerConfigModel(
      json.id,
      new FormControlString({
        key: 'tmpDirectory',
        label: 'label.server-config.tmp.directory',
        required: true,
        value: json.tmpDirectory
      }),
      new FormControlSelect({
        key: 'filesLocation',
        label: 'label.config.field.file-location',
        options: ['local', 'aws', 'azure'].map(entry => {
          return {label: entry, value: entry}
        }),
        value: json.filesLocation
      }),
      new FormControlString({
        key: 'localPath',
        label: 'label.config.field.content-folder',
        value: json.localPath
      }),
      json.aws ? AwsModel.fromJSON(json.aws) : undefined,
      json.azure ? AzureModel.fromJSON(json.azure) : undefined,
      json.languages,
      json.version,
      json.created_on,
      json.creatorname,
      json.lastmodified,
      json.modifiedby,
    );

    return model;
  }
}
