import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-station-inactive-check-modal',
  templateUrl: './station-inactive-check-modal.component.html',
  styleUrls: ['./station-inactive-check-modal.component.scss']
})
export class StationInactiveCheckModalComponent implements OnInit {

  @Input() title: string = 'Station still inactive';
  @Input() message: string = 'This station is still inactive. Do you want to set it to active?';

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }

}
