import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';


@Injectable()
export class DetailSyncService {

  private subject = new Subject<any>();

  subscribe(): Observable<any> {
    return this.subject.asObservable();
  }

  notify(id: string): void {
    this.subject.next(id);
  }
}
