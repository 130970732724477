import { Observable, of as observableOf } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MessageModalComponent } from '../../core/components/message-modal/message-modal.component';
import { ApiBaseService } from '../../core/services/api-base.service';
import { UserService } from '../../core/services/user.service';
import { StationCreateModalComponent, } from '../components/modals/station-create-modal/station-create-modal.component';
import { StationListModalComponent, } from '../components/modals/station-list-modal/station-list-modal.component';
import { CreateOptions } from '../interfaces/create-options.interface';
import { Station } from '../models/station.model';
import { Status } from '../models/status.model';
import { FileService } from './file.service';
import { StationInactiveCheckModalComponent, } from '../components/modals/station-inactive-check-modal/station-inactive-check-modal.component';
import { Exhibition } from "../models/exhibition.model";
import { Tour } from "../models/tour.model";
import Utils from "../utils/utils";
import { ModelMapperService } from "./mapper/model-mapper.service";
import { ChannelService } from "./channel.service";

@Injectable()
export class StationService extends ApiBaseService<Station> {

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected userService: UserService,
    protected fileService: FileService,
    protected modalService: NgbModal,
    private channelService: ChannelService,
    private modelMapper: ModelMapperService
  ) {
    super(Station, 'stations', http, router, userService, fileService, modalService);
  }

  select(exclude: Station[] = [], stationTypes?: number[]): Promise<Station[]> {
    const modalRef = this.modalService.open(StationListModalComponent, {size: 'lg'});
    modalRef.componentInstance.status = new Status();

    this.getAll(modalRef.componentInstance.status)
      .subscribe((stations: Station[]) => {
        try {
          modalRef.componentInstance.stations = stations
            .filter(station => exclude.findIndex(myStation => station.id === myStation.id) < 0);

          if (stationTypes && stationTypes.length > 0) {
            modalRef.componentInstance.stationTypes = stationTypes;
          }
        } catch (e) {
        }
      });

    return modalRef.result;
  }

  create(options: CreateOptions<Station>, stationTypes?: number[]): Promise<Station> {
    return super.create(Object.assign(options, {
      component: StationCreateModalComponent,
      componentOptions: {
        station: this.emptyModel(),
        stationTypes
      }
    }));
  }

  getTours(stationId: string, status?: Status): Observable<Tour[]> {
    status = status || new Status();
    status.setLoading();

    const url = `${this.url}/${stationId}/tours`;
    const responseKey = '_embedded.tours';

    return this.http
      .get(url).pipe(
        map(response => {
          if (Utils.deepAccessUsingString(response, responseKey) && Utils.deepAccessUsingString(response, responseKey).length > 0) {
            status.setSuccess();
            return Utils.deepAccessUsingString(response, responseKey)
              .filter(object => this.modelMapper.tourCheckJSON(object))
              .map(object => this.modelMapper.tourFromJSON(object));
          } else {
            status.setNoResults();
            return observableOf([]);
          }
        }),
        catchError(err => this.handleError(err, status)),);
  }

  getExhibitions(stationId: string, status?: Status): Observable<Exhibition[]> {
    status = status || new Status();
    status.setLoading();

    const url = `${this.url}/${stationId}/exhibitions`;
    const responseKey = '_embedded.exhibitions';

    return this.http
      .get(url).pipe(
        map(response => {
          if (Utils.deepAccessUsingString(response, responseKey) && Utils.deepAccessUsingString(response, responseKey).length > 0) {
            status.setSuccess();
            return Utils.deepAccessUsingString(response, responseKey)
              .filter(object => this.modelMapper.exhibitionCheckJSON(object))
              .map(object => this.modelMapper.exhibitionFromJSON(object));
          } else {
            status.setNoResults();
            return observableOf([]);
          }
        }),
        catchError(err => this.handleError(err, status)));
  }



  delete(id: string, status?: Status): Promise<any> {
    const modalRef = this.modalService.open(MessageModalComponent);
    modalRef.componentInstance.confirm = true;
    modalRef.componentInstance.message = 'Are you sure that you want to delete the station?';

    return modalRef.result.then(() => super.delete(id, status), () => Promise.reject('Dimissed'));
  }

  confirmCreateInactive(): Promise<any> {
    const modalRef = this.modalService.open(StationInactiveCheckModalComponent);

    return modalRef.result.then((res: any) => res);
  }

  checkJSON(json: any): boolean {
    return this.modelMapper.stationCheckJSON(json);
  }

  fromJSON(json: any, skipCheck?: boolean): Station {
    return this.modelMapper.stationFromJSON(json, skipCheck);
  }

  emptyModel(): Station {
    return this.modelMapper.stationEmptyModel(this.channelService.getDefaultChannels());
  }
}
