import { GameLevelCategory } from '../../game/enum/game-level-category.enum';
import Utils from '../utils/utils';
import { BaseModel } from './base.model';
import { FormControlNumber } from './form-control/form-control-number.model';
import { FormControlSelect } from './form-control/form-control-select.model';
import { GameAssetDictionaryEntry } from './game-asset-dictionary-entry';
import { GameControlFlow } from './game-control-flow';
import { GameMultiLangDictionaryEntry } from './game-multi-lang-dictionary-entry';
import { GameParameter } from './game-parameter';
import { GameResult } from './game-result.model';
import { MultiLangString } from './multi-lang-string.model';

export class GameLevel extends BaseModel {

  constructor(
    public id: string,
    public title: MultiLangString,
    public description: MultiLangString,
    public type: FormControlSelect,
    public category: GameLevelCategory,
    public sortOrder: number,
    public elements: string[],
    public results: GameResult[],
    public parameters: GameParameter[],
    public textDictionary: GameMultiLangDictionaryEntry[],
    public assetDictionary: GameAssetDictionaryEntry[],
    public controlFlows: GameControlFlow[],
    public blockedUsers: string[],
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string,
    public version?: number
  ) {
    super(id, blockedUsers, createdOn, creatorName, lastModified, modifiedBy, version);
  }

  toJSON(): object {
    const json = super.toJSON();

    json[ 'id' ] = this.id;
    json[ 'title' ] = this.title.toJSON();
    json[ 'description' ] = this.description.toJSON();
    json[ 'type' ] = this.type.value;
    json[ 'category' ] = this.category;
    json[ 'sortOrder' ] = this.sortOrder || 0;
    json[ 'controlFlows' ] = this.controlFlows;
    json[ 'elements' ] = this.elements;
    json[ 'results' ] = this.results;
    json['parameters'] = this.parameters ? this.parameters.map(p => p.toJSON()) : [];
    json['textDictionary'] = this.textDictionary ? this.textDictionary.map(d => d.toJSON()) : [];
    json['assetDictionary'] = this.assetDictionary ? this.assetDictionary.map(d => d.toJSON()) : [];
    json[ 'controlFlows' ] = this.controlFlows ? this.controlFlows.map(c => c.toJSON()) : [];

    return json;
  }

  addElement(id: string): void {
    this.elements = [ ...this.elements.filter(element => element !== id), id ];
    this.setChanged(true);
  }

  deleteElement(id: string): void {
    this.elements = this.elements.filter(element => element !== id);
    this.setChanged(true);
  }

  addResult(newResult: GameResult): void {
    this.results = [ ...this.results, newResult ];
    this.setChanged(true);
  }

  deleteResult(result: GameResult): void {
    Utils.removeObjectFromArray(this.results, result);
    this.results = [ ...this.results ];
    this.setChanged(true);
  }

  addControlFlow(newControlFLow: GameControlFlow): void {
    this.controlFlows = [ ...this.controlFlows.filter(controlFlow => controlFlow.id !== newControlFLow.id), newControlFLow ];
    this.setChanged(true);
  }

  deleteControlFlow(controlFlow: GameControlFlow): void {
    Utils.removeObjectFromArray(this.controlFlows, controlFlow);
    this.controlFlows = [ ...this.controlFlows ];
    this.setChanged(true);
  }
}
