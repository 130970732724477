import {Directive, Input} from '@angular/core';
import Utils from '../utils/utils';

@Directive({
  selector: '[appExtendTable]'
})
export class ExtendTableDirective {

  @Input() set table(table: any) {

    table.filterConstraints.containsAccentFolded = (value, filter: any): boolean => {

      const simplifiedValue: string = Utils.accentFold(value).toLowerCase();
      const simplifiedFilter: string = Utils.accentFold(filter).toLowerCase();

      return simplifiedValue.includes(simplifiedFilter);
    };

  }

  constructor() {

  }


}
