import { Component } from '@angular/core';
import { TableBaseComponent } from "../table-base/table-base.component";
import { Permission } from "../../../models/permission.model";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfigurationService } from "../../../services/configuration.service";
import { TourTypeUtils } from "../../../utils/tour-type-utils";
import { UserService } from "../../../../core/services/user.service";

@Component({
  selector: 'app-permission-table',
  templateUrl: './permission-table.component.html'
})
export class PermissionTableComponent extends TableBaseComponent<Permission> {

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected config: ConfigurationService,
    protected userService: UserService
  ) {
    super(router, route, config, userService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  handleRowSelect(model: Permission): void {
    if (this.isMultiSelection()) {
      this.addOrRemoveFromArray(model);
      this.selectionChange.emit(this.selection);
    } else {
      this.selectionChange.emit([model]);
    }
  }

  isSelected(id: string): boolean {
    return this.selection && !!this.selection.find(p => p.id === id);
  }

  private addOrRemoveFromArray(selected: Permission): void {
    const index = this.selection.findIndex(p => p.id == selected.id);

    if (index > -1) {
      this.selection.splice(index, 1);
    } else {
      this.selection.push(selected);
    }
  }
}
