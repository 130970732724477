import { BaseModel } from "./base.model";
import Utils from "../utils/utils";
import * as moment from 'moment';
import { MultiLangString } from "./multi-lang-string.model";
import { FormControlType } from "../enums/form-control-type.enum";

export abstract class HtmlField extends BaseModel {

  protected constructor(
    public id: string,
    public fieldName: string,
    public content: any,
    public type: string,
    public version?,
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string
  ) {
    super(id, undefined, createdOn, creatorName, lastModified, modifiedBy);
  }

  static checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id);
  }

  toJSON(): object {
    const json = super.toJSON();
    delete json['blocked_users'];

    json['fieldName'] = this.fieldName;
    json['content'] = this.content.toJSON();
    json['fieldType'] = this.type;

    return json;
  }
}
