import { FormControlOptions } from '../interfaces/form-control-options.interface';
import Utils from '../utils/utils';
import { FormControlString } from './form-control/form-control-string.model';

export class MultiLangString {

  private readonly defaultFormControlOptions: FormControlOptions<string>;
  public readonly defaultLocale: string;

  constructor(locales: object = {}, defaultLocale: string) {
    Object.keys(locales).forEach((locale) => {
      this[locale] = locales[locale];
    });

    this.defaultLocale = defaultLocale;

    // save default formControlOptions for later usage
    this.defaultFormControlOptions = this[defaultLocale] || this[0] || {};
    this.defaultFormControlOptions = Utils.clone(this.defaultFormControlOptions);
    this.defaultFormControlOptions.value = '';
  }

  toJSON(): object {
    const json = {};

    for (const locale of Object.keys(this)) {
      if (this[locale] && Utils.isDefined(this[locale].value) && Utils.isValidLocale(locale)) {
        json[locale] = this[locale].value ? '' + this[locale].value : '';
      }
    }

    return Object.keys(json).length > 0 ? json : '';
  }

  addLocale(locale: string): void {
    if (Utils.isUndefined(this[locale]) && Utils.isValidLocale(locale)) {
      const formControlOptions = Utils.clone(this.defaultFormControlOptions);

      formControlOptions['key'] = formControlOptions['key'].split('_')[0] + '_' + locale;

      this[locale] = new FormControlString(formControlOptions);
    }
  }

  deleteLocale(locale: string): void {
    if (Utils.isDefined(this[locale]) && Utils.isValidLocale(locale) && locale !== this.defaultLocale) {
      delete this[locale];
    }
  }

  getDefault(): string {
    return this[this.defaultLocale].value;
  }

  getLocales(): string[] {
    return Object.keys(this).filter(key => Utils.isValidLocale(key)).sort();
  }
}
