import { Component, Input } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { GameCategory } from '../../../../game/enum/game-category.enum';
import Utils from '../../../utils/utils';

import { TableBaseComponent } from '../table-base/table-base.component';

import { UserService } from '../../../../core/services/user.service';
import { Game } from '../../../models/game.model';
import { ConfigurationService } from '../../../services/configuration.service';

@Component({
  selector: 'app-game-table',
  templateUrl: './game-table.component.html'
})
export class GameTableComponent extends TableBaseComponent<Game> {

  @Input() detailLink = '/games';

  tableConfig: any = this.getTableConfig('game');

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected config: ConfigurationService,
    protected userService: UserService
  ) {
    super(router, route, config, userService);
  }

  getGlobalFilterFields() {
    this.globalFilters = this.globalFilters || [ {
      label: 'ID',
      field: 'id',
      active: this.showId
    }, {
      label: 'Name',
      field: 'name.value',
      active: true
    } ];

    return this.globalFilters
      .filter(filter => filter.active)
      .map(filter => filter.field);
  }

  // TODO remove when games module is fully implemented
  handleRowSelect(model: Game): void {
    if (this.isMultiSelection()) {
      Utils.addOrRemoveFromArray(this.selection, model);

    } else if (this.isSingleSelection()) {
      this.selectionChange.emit([model]);

    } else {
      this.goToDetailView(model);
    }
  }
}
