import { ModelHelper } from "./model.helper.interface";
import Utils from "../../utils/utils";
import { HtmlContent } from "../html-content.model";
import { HtmlContentSchema } from "../html-content-schema.model";
import { FormControlBoolean } from "../form-control/form-control-boolean.model";
import { FormControlNumber } from "../form-control/form-control-number.model";
import { HtmlFieldType } from "../../enums/html-field-type.enum";
import { HtmlTextFieldHelper } from "./html-textfield.helper";
import { HtmlFileFieldHelper } from "./html-filefield.helper";
import { Channel } from "../channel.model";

export class HtmlContentModelHelper implements ModelHelper<HtmlContent> {

  constructor(
    private htmlTextFieldHelper: HtmlTextFieldHelper,
    private htmlFileFieldHelper: HtmlFileFieldHelper) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id);
  }

  emptyModel(defaultChannels: Channel[], schema?: HtmlContentSchema): HtmlContent {
    const content = new HtmlContent(
      '',
      schema,
      new FormControlBoolean({
        label: 'label.general.active',
        value: false
      }),
      new FormControlNumber({
        label: 'label.html.field.order-number',
        value: null,
        apiParam: 'orderNumber'
      }),
      []);

    content.fields = schema.fields ? schema.fields.map(field => {
      return this.htmlTextFieldHelper.emptyModel([], field.key);
    }) : [];

    return content;
  }

  fromJSON(json: any, skipCheck = false): HtmlContent {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    // create content object
    return new HtmlContent(
      json._id,
      HtmlContentSchema.fromJSON(json.schema),
      new FormControlBoolean({
        label: 'label.general.active',
        value: !!json.active
      }),
      new FormControlNumber({
        label: 'label.html.field.order-number',
        value: json.orderNumber,
        apiParam: 'orderNumber'
      }),
      json.fields ? json.fields.map(field => {
        if (field.fieldType === HtmlFieldType.TEXT) {
          return this.htmlTextFieldHelper.fromJSON(field)
        } else if (field.fieldType === HtmlFieldType.FILE) {
          return this.htmlFileFieldHelper.fromJSON(field)
        } else {
          console.log('invalid field type');
          return undefined;
        }
      }) : [],
    );
  }
}
