import { ModelHelper } from "./model.helper.interface";
import Utils from "../../utils/utils";
import { FormControlString } from "../form-control/form-control-string.model";
import { ServerConfigModel } from "../server-config.model";
import { FormControlSelect } from "../form-control/form-control-select.model";
import { FileConverterModel } from "../file-converter.model";
import { FileLimitModel } from "../file-limit.model";
import { AwsModel } from "../aws.model";
import { SyncConfigModel } from "../sync-config.model";
import { CdnModel } from "../cdn.model";
import { FormControlBoolean } from "../form-control/form-control-boolean.model";
import { AzureModel } from "../azure.model";

export class ServerConfigModelHelper implements ModelHelper<ServerConfigModel> {

  constructor() {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id);
  }

  emptyModel(): ServerConfigModel {
    return new ServerConfigModel(
      '',
      new FormControlString({
        key: 'tmpDirectory',
        label: 'label.config.field.tmp-directory',
        required: true
      }),
      new FormControlSelect({
        key: 'clientRole',
        label: 'label.config.rest.field.client-role',
        options: ['viewer'].map(entry => {
          return {label: entry, value: entry}
        }),
        required: true
      }),
      new FormControlSelect({
        key: 'filesLocation',
        label: 'label.config.field.file-location',
        options: ['local', 'aws', 'azure'].map(entry => {
          return {label: entry, value: entry}
        }),
        required: true
      }),
      new FileConverterModel(),
      new FormControlSelect({
        key: 'thumbnailImplementation',
        label: 'label.config.rest.field.thumbnail-implementation',
        options: ['no', 'thumb'].map(entry => {
          return {label: entry, value: entry}
        }),
        required: true
      }),
      [],
      FileLimitModel.fromJSON({}),
      [],
      new AwsModel(),
      new AzureModel(),
      new FormControlString({
        key: 'webconductor',
        label: 'label.config.field.content-folder',
        required: true
      }),
      new SyncConfigModel(),
      new CdnModel(),
      new FormControlBoolean({
        key: 'channelsEnabled',
        label: 'label.config.rest.field.channel-enabled',
        value: false
      }),
      new FormControlBoolean({
        key: 'exhibitionsEnabled',
        label: 'label.config.rest.field.exhibition-enabled',
        value: true
      }),
      new FormControlBoolean({
        key: 'stationsEnabled',
        label: 'label.config.rest.field.station-enabled',
        value: true
      }),
      new FormControlBoolean({
        key: 'contentEnabled',
        label: 'label.config.rest.field.content-enabled',
        value: true
      }),
      new FormControlBoolean({
        key: 'mapsEnabled',
        label: 'label.config.rest.field.map-enabled',
        value: false
      }),
      new FormControlBoolean({
        key: 'beaconsEnabled',
        label: 'label.config.rest.field.beacon-enabled',
        value: false
      }),
      new FormControlBoolean({
        key: 'groupsEnabled',
        label: 'label.config.rest.field.group-enabled',
        value: false
      }),
      new FormControlBoolean({
        key: 'hotspotsEnabled',
        label: 'label.config.rest.field.hotspot-enabled',
        value: false
      }),
      new FormControlBoolean({
        key: 'htmlEnabled',
        label: 'label.config.rest.field.html-enabled',
        value: false
      }),
      new FormControlBoolean({
        key: 'cdnEnabled',
        label: 'label.config.rest.field.cdn-enabled',
        value: false
      }),
      new FormControlBoolean({
        key: 'filesEnabled',
        label: 'label.config.rest.field.files-enabled',
        value: true
      }),
      new FormControlBoolean({
        key: 'toursEnabled',
        label: 'label.config.rest.field.tour-enabled',
        value: false
      }))
  }

  fromJSON(json: any, skipCheck = false): ServerConfigModel {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    return new ServerConfigModel(
      json._id,
      new FormControlString({
        key: 'tmpDirectory',
        label: 'label.config.field.tmp-directory',
        required: true,
        value: json.tmpDirectory
      }),
      new FormControlSelect({
        key: 'clientRole',
        label: 'label.config.rest.field.client-role',
        options: ['viewer'].map(entry => {
          return {label: entry, value: entry}
        }),
        value: json.clientRole
      }),
      new FormControlSelect({
        key: 'filesLocation',
        label: 'label.config.field.file-location',
        options: ['local', 'aws', 'azure'].map(entry => {
          return {label: entry, value: entry}
        }),
        value: json.filesLocation
      }),
      FileConverterModel.fromJSON(json.fileConverter),
      new FormControlSelect({
        key: 'thumbnailImplementation',
        label: 'label.config.rest.field.thumbnail-implementation',
        options: ['no', 'thumb'].map(entry => {
          return {label: entry, value: entry}
        }),
        value: json.thumbnailImplementation
      }),
      json.fileExtensions,
      FileLimitModel.fromJSON(json.fileLimits),
      json.languages,
      json.aws ? AwsModel.fromJSON(json.aws) : undefined,
      json.azure ? AzureModel.fromJSON(json.azure) : undefined,
      new FormControlString({
        key: 'webconductor',
        label: 'label.config.field.content-folder',
        value: json.webconductor
      }),
      json.sync ? SyncConfigModel.fromJSON(json.sync) : undefined,
      json.cdn ? CdnModel.fromJSON(json.cdn) : undefined,
      new FormControlBoolean({
        key: 'channelsEnabled',
        label: 'label.config.rest.field.channel-enabled',
        value: !!json.channelsEnabled
      }),
      new FormControlBoolean({
        key: 'exhibitionsEnabled',
        label: 'label.config.rest.field.exhibition-enabled',
        value: !!json.exhibitionsEnabled
      }),
      new FormControlBoolean({
        key: 'stationsEnabled',
        label: 'label.config.rest.field.station-enabled',
        value: !!json.stationsEnabled
      }),
      new FormControlBoolean({
        key: 'contentEnabled',
        label: 'label.config.rest.field.content-enabled',
        value: !!json.contentEnabled
      }),
      new FormControlBoolean({
        key: 'mapsEnabled',
        label: 'label.config.rest.field.map-enabled',
        value: !!json.mapsEnabled
      }),
      new FormControlBoolean({
        key: 'beaconsEnabled',
        label: 'label.config.rest.field.beacon-enabled',
        value: !!json.beaconsEnabled
      }),
      new FormControlBoolean({
        key: 'groupsEnabled',
        label: 'Groups Enabled',
        value: !!json.groupsEnabled
      }),
      new FormControlBoolean({
        key: 'hotspotsEnabled',
        label: 'label.config.rest.field.hotspot-enabled',
        value: !!json.hotspotsEnabled
      }),
      new FormControlBoolean({
        key: 'htmlEnabled',
        label: 'label.config.rest.field.html-enabled',
        value: !!json.htmlEnabled
      }),
      new FormControlBoolean({
        key: 'cdnEnabled',
        label: 'label.config.rest.field.cdn-enabled',
        value: !!json.cdnEnabled
      }),
      new FormControlBoolean({
        key: 'filesEnabled',
        label: 'label.config.rest.field.file-enabled',
        value: !!json.filesEnabled
      }),
      new FormControlBoolean({
        key: 'toursEnabled',
        label: 'label.config.rest.field.tour-enabled',
        value: !!json.toursEnabled
      }),

      json.version,
      json.created_on,
      json.creatorname,
      json.lastmodified,
      json.modifiedby,
    );
  }
}
