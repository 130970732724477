import { ModelHelper } from "./model.helper.interface";
import Utils from "../../utils/utils";
import * as moment from "moment";
import { HtmlFileField } from "../html-file-field.model";
import { MultiLangFileHelper } from "./multi-lang-file.helper";
import { Channel } from "../channel.model";

export class HtmlFileFieldHelper implements ModelHelper<HtmlFileField> {

  constructor(private multiLangFileHelper: MultiLangFileHelper) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id);
  }

  emptyModel(defaultChannels: Channel[], fieldName?: string): HtmlFileField {
    return new HtmlFileField(
      '',
      fieldName || '',
      this.multiLangFileHelper.fromJSON({})
    );
  }

  fromJSON(json: any, skipCheck = false): HtmlFileField {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    // create content object
    return new HtmlFileField(
      json._id,
      json.fieldName,
      this.multiLangFileHelper.fromJSON(json.content),
      json.version,
      json.created_on ? moment(json.created_on).toDate() : undefined,
      json.creatorname,
      json.lastmodified ? moment(json.lastmodified).toDate() : undefined,
      json.modifiedby
    );
  }
}
