import { Component, Input } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { TableBaseComponent } from '../table-base/table-base.component';

import { UserService } from '../../../../core/services/user.service';
import { GameLevel } from '../../../models/game-level.model';
import { ConfigurationService } from '../../../services/configuration.service';

@Component({
  selector: 'app-game-level-table',
  templateUrl: './game-level-table.component.html'
})
export class GameLevelTableComponent extends TableBaseComponent<GameLevel> {

  @Input() levelTypes: number[];
  @Input() detailLink = '/levels';

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected config: ConfigurationService,
    protected userService: UserService,
  ) {
    super(router, route, config, userService);
  }

  getGlobalFilterFields() {
    this.globalFilters = this.globalFilters || [{
      label: 'ID',
      field: 'id',
      active: this.showId
    }, {
      label: 'Name',
      field: 'name.value',
      active: true
    }];

    return this.globalFilters
      .filter(filter => filter.active)
      .map(filter => filter.field);
  }

  handleRowReorder(): void {
    if (this.options.canReorder) {
      this.onReorder.emit(this.data);
    }
  }
}
