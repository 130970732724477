import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Content } from '../../../../../app/shared/models/content.model';
import { Status } from '../../../../../app/shared/models/status.model';
import { ContentTypeUtils } from "../../../utils/content-type-utils";


@Component({
  selector: 'app-content-list-modal',
  templateUrl: './content-list-modal.component.html'
})
export class ContentListModalComponent {

  @Input() contents: Content[];
  @Input() contentTypes: number[];
  @Input() status: Status;

  selection: Content[] = [];

  constructor(
    private contentTypeUtils: ContentTypeUtils,
    public activeModal: NgbActiveModal
  ) {
    if (!this.contentTypes) {
      this.contentTypes = this.contentTypeUtils.getValues();
    }
  }
}
