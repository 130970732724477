import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Tour } from '../../../models/tour.model';
import { TourTypeUtils } from "../../../utils/tour-type-utils";


@Component({
  selector: 'app-tour-create-modal',
  templateUrl: './tour-create-modal.component.html'
})
export class TourCreateModalComponent implements OnInit {

  @Input() tour: Tour;
  @Input() tourTypes: number[];
  form = new FormGroup({});

  constructor(
    private tourTypeUtils: TourTypeUtils,
    public activeModal: NgbActiveModal) {
  }

  create(): void {
    this.activeModal.close(this.tour);
  }

  ngOnInit() {
    if (!this.tourTypes) {
      this.tourTypes = this.tourTypeUtils.getValues();
    }

    this.setTourTypeOptions();
  }

  private setTourTypeOptions() {
    if (this.tourTypes) {
      this.tour.type.options = this.tour.type.options.filter(tourType => {
        return this.tourTypes.indexOf(tourType.value) !== -1;
      });

      this.tour.type.value = this.tour.type.options[0].value;
    }
  }
}
