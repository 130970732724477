import { Injectable } from '@angular/core';

import { Status } from '../models/status.model';

import Utils from '../../../app/shared/utils/utils';
import { ConfigurationService } from './configuration.service';


@Injectable()
export class GoogleMapsService {

  private map: google.maps.Map;
  private marker: google.maps.Marker;
  // private geocoder: google.maps.Geocoder;
  private scriptLoadingPromise: Promise<void>;
  private readonly googleMapsKey: string;

  private options = {
    center: {lat: 48.208589, lng: 16.372893},
    scrollwheel: true,
    disableDefaultUI: true,
    draggableCursor: 'pointer',
    zoom: 8
  };

  constructor(private config: ConfigurationService) {
    // Loading script

    this.googleMapsKey = this.config.getGoogleMapsConfig().key;
    this.loadScriptLoadingPromise();

    // Loading other components
    // this.onReady().then(() => {
    //   this.geocoder = new google.maps.Geocoder();
    // });
  }

  getScriptSrc = (callback) => {
    return `https://maps.googleapis.com/maps/api/js?key=${this.googleMapsKey}&callback=${callback}`;
  };

  onReady(): Promise<void> {
    return this.scriptLoadingPromise;
  }

  initMap(
    mapHtmlElement: HTMLElement,
    options?: google.maps.MapOptions,
    startCoordinates?: { lat: number, lng: number },
    clickCallback?: Function,
    status?: Status,
  ): void {

    options = options || this.options;
    options.center = {
      lat: Utils.isDefined(startCoordinates.lat) ? startCoordinates.lat : this.options.center.lat,
      lng: Utils.isDefined(startCoordinates.lng) ? startCoordinates.lng : this.options.center.lng
    };

    status = status || new Status();
    status.setLoading();

    this.onReady().then(() => {
      this.map = new google.maps.Map(mapHtmlElement, options);

      if (Utils.isDefined(startCoordinates.lat) && Utils.isDefined(startCoordinates.lng)) {
        this.setMarker(startCoordinates.lat, startCoordinates.lng);
      }

      this.initClickListener(clickCallback);

      status.setSuccess();
    }, () => status.setError());
  }

  initClickListener(callback?: Function) {
    if (this.map) {
      google.maps.event.addListener(this.map, 'click', event => {
        this.clearMarker();
        this.setMarker(event.latLng.lat(), event.latLng.lng());

        if (callback) {
          callback(event.latLng.lat(), event.latLng.lng());
        }
      });
    }
  }

  private loadScriptLoadingPromise() {
    const script = window.document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    const callbackName = 'googleMapsCallback';
    script.src = this.getScriptSrc(callbackName);
    this.scriptLoadingPromise = new Promise<void>((resolve: Function, reject: Function) => {
      (<any>window)[callbackName] = () => {
        resolve();
      };

      script.onerror = (error: Event) => {
        reject(error);
      };
    });
    window.document.body.appendChild(script);
  }

  setMarker(lat?: number, lng?: number, center = false): void {
    this.clearMarker();

    if (this.map && Utils.isDefined(lat) && Utils.isDefined(lng)) {
      this.marker = new google.maps.Marker({
        position: {lat: Number(lat), lng: Number(lng)},
        map: this.map
      });

      if (center) {
        this.map.panTo(this.marker.getPosition());
      }
    }
  }

  clearMarker(): void {
    if (this.marker) {
      this.marker.setMap(null);
      this.marker = null;
    }
  }
}
