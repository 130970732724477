import { Component, Input } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { TableBaseComponent } from '../table-base/table-base.component';

import { Exhibition } from '../../../models/exhibition.model';
import { ConfigurationService } from "../../../services/configuration.service";
import { UserService } from "../../../../core/services/user.service";
import { PermissionEnum } from "../../../enums/permission.enum";


@Component({
  selector: 'app-exhibition-table',
  templateUrl: './exhibition-table.component.html'
})
export class ExhibitionTableComponent extends TableBaseComponent<Exhibition> {

  @Input() detailLink = '/exhibitions';

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected config: ConfigurationService,
    protected userService: UserService
  ) {
    super(router, route, config, userService);
  }

  getGlobalFilterFields() {
    this.globalFilters = this.globalFilters || [{
      label: 'ID',
      field: 'id',
      active: this.showId
    }, {
      label: 'Name',
      field: 'name.value',
      active: true
    }];

    return this.globalFilters
      .filter(filter => filter.active)
      .map(filter => filter.field);
  }

  goToDetailView(exhibition: Exhibition) {

    this.router.navigate(
      this.hasPermission(PermissionEnum.TOUR_READ) ? [this.detailLink, exhibition.id, 'tours'] : [this.detailLink, exhibition.id],
      {relativeTo: this.route}
    );
  }
}
