import { BaseModel } from './base.model';
import { FormControlBoolean } from './form-control/form-control-boolean.model';
import { Station } from './station.model';


export class Hotspot extends BaseModel {

  constructor(
    public id: string,
    public stationId: string,
    public substationId: string,
    public width: number,
    public height: number,
    public left: number,
    public top: number,
    public renderTransparent: FormControlBoolean,
    public blockedUsers: string[],
    public station?: Station,
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string
  ) {
    super(id, blockedUsers, createdOn, creatorName, lastModified, modifiedBy);
  }

  toJSON(): object {
    const json = super.toJSON();

    json['stationId'] = this.stationId;
    json['substationId'] = this.substationId;
    json['width'] = this.width;
    json['height'] = this.height;
    json['left'] = this.left;
    json['top'] = this.top;
    json['renderTransparent'] = this.renderTransparent.value;

    return json;
  }
}
