import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../../app/core/services/auth.service';

import { Status } from '../../../app/shared/models/status.model';


@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  status = new Status();

  constructor(
    private authService: AuthService
  ) { }

  logout() {
    this.authService.logout(this.status);
  }

  ngOnInit() {
    this.logout();
  }
}
