import { FormControlString } from './form-control/form-control-string.model';

export class Address {

  constructor(
    public street: FormControlString,
    public street2: FormControlString,
    public houseNumber: FormControlString,
    public zipCode: FormControlString,
    public city: FormControlString,
    public region: FormControlString,
    public country: FormControlString
  ) { }


  toJSON(): object {
    return {
      street: this.street.value,
      street2: this.street2.value,
      houseNumber: this.houseNumber.value,
      zipCode: this.zipCode.value,
      city: this.city.value,
      region: this.region.value,
      country: this.country.value
    };
  }

  static fromJSON(json) {
    return new Address(
      new FormControlString({
        key: 'street',
        label: 'label.address.field.street',
        value: json ? json.street || '' : ''
      }),
      new FormControlString({
        key: 'street2',
        label: 'label.address.field.street2',
        value: json ? json.street2 || '' : ''
      }),
      new FormControlString({
        key: 'houseNumber',
        label: 'label.address.field.houseNumber',
        value: json ? json.houseNumber || '' : ''
      }),
      new FormControlString({
        key: 'zipCode',
        label: 'label.address.field.zipCode',
        value: json ? json.zipCode || '' : ''
      }),
      new FormControlString({
        key: 'city',
        label: 'label.address.field.city',
        value: json ? json.city || '' : ''
      }),
      new FormControlString({
        key: 'region',
        label: 'label.address.field.region',
        value: json ? json.region || '' : ''
      }),
      new FormControlString({
        key: 'country',
        label: 'label.address.field.country',
        value: json ? json.country || '' : ''
      })
    );
  }
}
