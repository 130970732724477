import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { GameSolution } from '../../../models/game-solution.model';
import { Status } from '../../../models/status.model';
import { GameSolutionTypeUtils } from '../../../utils/game-solution-type-utils';

@Component({
  selector: 'app-game-solution-list-modal',
  templateUrl: './game-solution-list-modal.component.html'
})
export class GameSolutionListModalComponent {

  @Input() solutions: GameSolution[];
  @Input() solutionTypes: number[];
  @Input() status: Status;

  selection: GameSolution[] = [];

  constructor(
    private solutionTypeUtils: GameSolutionTypeUtils,
    public activeModal: NgbActiveModal
  ) {
    if (!this.solutionTypes) {
      this.solutionTypes = this.solutionTypeUtils.getTypeValues();
    }
  }
}
