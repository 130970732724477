import { Component, Input } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { TableBaseComponent } from '../../../../../app/shared/components/tables/table-base/table-base.component';

import { Content } from '../../../../../app/shared/models/content.model';
import { ConfigurationService } from "../../../services/configuration.service";
import { TourTypeUtils } from "../../../utils/tour-type-utils";
import { UserService } from "../../../../core/services/user.service";


@Component({
  selector: 'app-content-table',
  templateUrl: './content-table.component.html'
})
export class ContentTableComponent extends TableBaseComponent<Content> {

  @Input() contentTypes: number[];
  @Input() detailLink = '/content';

  tableConfig: any = this.getTableConfig('content');

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected config: ConfigurationService,
    protected userService: UserService
  ) {
    super(router, route, config, userService);
  }

  getGlobalFilterFields() {
    this.globalFilters = this.globalFilters || [{
      label: 'ID',
      field: 'id',
      active: this.showId
    }, {
      label: 'Title',
      field:  'title.' + this.data[0].title.defaultLocale + '.value',
      active: true
    }];

    return this.globalFilters
      .filter(filter => filter.active)
      .map(filter => filter.field);
  }

  goToDetailView(content: Content): void {
    this.router.navigate([this.detailLink, content.id], { relativeTo: this.route });
  }
}
