import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Status } from '../../../app/shared/models/status.model';

import { AppConfig } from '../../../app/app.config';
import { SyncMetaModel } from "../../shared/models/sync-meta.model";
import { Observable } from "rxjs";


@Injectable()
export class SyncService {

  private url = AppConfig.SYNC_SERVER + AppConfig.SYNC_BASE_URL;

  constructor(
    private http: HttpClient
  ) {
  }

  protected handleError(error?: any, status?: Status): Promise<any> {
    status = status || new Status();
    status.setError();
    return Promise.reject(error ? error.message : error);
  }

  getMeta(channel: string, status?: Status): Observable<SyncMetaModel> {
    status = status || new Status();
    status.setLoading();

    const url = this.url + '/meta';
    return this.http.get(url, {headers: {'channel': channel}}).pipe(map(response => {
        status.setSuccess();
        return SyncMetaModel.fromJSON(response);
      }), catchError(err => this.handleError(err, status))
    );
  }

  getLock(status = new Status()): Promise<boolean> {
    status.setLoading();
    const url = this.url + '/lock';

    return this.http
      .get(url)
      .toPromise()
      .then(reponse => {
        status.setSuccess();
        return reponse['locked'];
      }, () => status.setError());
  }

  lock(status = new Status()): Promise<any> {
    const url = this.url + '/lock';

    return this.http
      .put(url, {})
      .toPromise()
      .then(() => status.setSuccess(), () => status.setError());
  }

  createDelta(status = new Status()): Promise<any> {
    const url = this.url + '/createdelta';

    return this.http
      .put(url, {})
      .toPromise()
      .then(() => status.setSuccess(), () => status.setError())
      .catch(err => this.handleError(err, status));
  }

  createDeltaForChannel(channel: string, status = new Status()): Promise<any> {
    const url = this.url + '/createdelta';

    return this.http
      .put(url, {}, {headers: {'channel': channel}})
      .toPromise()
      .then(() => status.setSuccess(), () => status.setError())
      .catch(err => this.handleError(err, status));
  }

  unlock(status = new Status()): Promise<any> {
    const url = this.url + '/lock';

    return this.http
      .delete(url)
      .toPromise()
      .then(() => status.setSuccess(), () => status.setError());
  }
}
