import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { GameSelection } from '../../../models/game-selection.model';
import { Status } from '../../../models/status.model';
import { GameSelectionTypeUtils } from '../../../utils/game-selection-type-utils';

@Component({
  selector: 'app-game-selection-list-modal',
  templateUrl: './game-selection-list-modal.component.html'
})
export class GameSelectionListModalComponent {

  @Input() selections: GameSelection[];
  @Input() selectionTypes: number[];
  @Input() status: Status;

  selection: GameSelection[] = [];

  constructor(
    private selectionTypeUtils: GameSelectionTypeUtils,
    public activeModal: NgbActiveModal
  ) {
    if (!this.selectionTypes) {
      this.selectionTypes = this.selectionTypeUtils.getTypeValues();
    }
  }
}
