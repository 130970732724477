import { FormControlOptions } from '../interfaces/form-control-options.interface';
import Utils from '../utils/utils';

export class MultiLangBoolean {

  private readonly defaultFormControlOptions: FormControlOptions<boolean>;
  public readonly defaultLocation: string;

  constructor(locales: object = {}, defaultLocation: string) {
    Object.keys(locales).forEach((locale) => {
      this[locale] = locales[locale];
    });

    this.defaultLocation = defaultLocation;

    // save default formControlOptions for later usage
    this.defaultFormControlOptions = this[defaultLocation] || this[0] || {};
    this.defaultFormControlOptions = Utils.clone(this.defaultFormControlOptions);
    this.defaultFormControlOptions.value = undefined;
  }

  toJSON(): object {
    const json = {};

    for (const locale of Object.keys(this)) {
      if (Utils.isDefined(this[locale]) && Utils.isValidLocale(locale)) {
        json[locale] = this[locale].value;
      }
    }

    return Object.keys(json).length > 0 ? json : '';
  }

  getDefault(): string {
    return this[this.defaultLocation].value;
  }

  getLocales(): string[] {
    return Object.keys(this).filter(key => Utils.isValidLocale(key)).sort();
  }
}
