import { NgModule } from '@angular/core';

import { SharedModule } from '../../app/shared/shared.module';
import { ClientContentInfoComponent } from "./client-content-info/client-content-info.component";

@NgModule({
  declarations: [
    ClientContentInfoComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    ClientContentInfoComponent
  ],
  providers: []
})
export class SyncModule { }
