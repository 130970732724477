import * as moment from 'moment';
import { GameElementTypeUtils } from '../../utils/game-element-type-utils';
import Utils from '../../utils/utils';
import { FormControlNumber } from '../form-control/form-control-number.model';
import { FormControlSelect } from '../form-control/form-control-select.model';
import { GameElement } from '../game-element.model';
import { GameResultModelHelper } from './game-result-model.helper';
import { GameScoringSystemModelHelper } from './game-scoring-system-model.helper';
import { GameSelectionModelHelper } from './game-selection-model.helper';
import { GameSolutionModelHelper } from './game-solution-model.helper';
import { GameUtilHelper } from './game-util-helper';
import { MultiLangStringHelper } from './multi-lang-string.helper';

export class GameElementModelHelper {

  constructor(
    private readonly selectionHelper: GameSelectionModelHelper,
    private readonly solutionHelper: GameSolutionModelHelper,
    private readonly resultHelper: GameResultModelHelper,
    private readonly scoringSystemHelper: GameScoringSystemModelHelper,
    private readonly multiLangStringHelper: MultiLangStringHelper,
    private readonly elementTypeUtils: GameElementTypeUtils,
    private readonly gameUtilHelper: GameUtilHelper
  ) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json.id);
  }

  emptyModel(): GameElement {
    const scoringSystemType = this.elementTypeUtils.getScoringSystemType(this.elementTypeUtils.getDefaultType());

    return new GameElement(
      '',
      this.multiLangStringHelper.fromJSON(
        {},
        'title',
        this.elementTypeUtils.getFieldOptions(this.elementTypeUtils.getDefaultType(), 'title', {
          label: 'label.game.field.title',
          hidden: false
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'description',
        this.elementTypeUtils.getFieldOptions(this.elementTypeUtils.getDefaultType(), 'description', {
          label: 'label.game.field.description',
          hidden: false
        })
      ),

      new FormControlSelect({
        value: this.elementTypeUtils.getDefaultType(),
        key: 'type',
        label: 'label.general.type',
        options: this.elementTypeUtils.getTypeSelectOptions(),
        required: true,
        hidden: false
      }),
      undefined,
     undefined,
      this.scoringSystemHelper.emptyModel(scoringSystemType),
      [],
      [],
      [],
      [],
      [],
      [],
      []
    );
  }

  fromJSON(json: any, skipCheck = false): GameElement {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    const elementType = Number(json.type);
    const config = this.elementTypeUtils.getTypeConfig(elementType);

    const element = new GameElement(
      json.id,
      this.multiLangStringHelper.fromJSON(
        json.title,
        'title',
        this.elementTypeUtils.getFieldOptions(this.elementTypeUtils.getDefaultType(), 'title', {
          label: 'label.game.element.title',
          hidden: false
        }),
        json.title ? Object.keys(json.title) : []
      ),
      this.multiLangStringHelper.fromJSON(
        json.description,
        'description',
        this.elementTypeUtils.getFieldOptions(this.elementTypeUtils.getDefaultType(), 'description', {
          label: 'label.game.element.field.description',
          hidden: false
        }),
        json.description ? Object.keys(json.description) : []
      ),
      new FormControlSelect({
        value: elementType,
        key: 'type',
        label: 'label.general.type',
        options: this.elementTypeUtils.getTypeSelectOptions(),
        required: true,
        hidden: false
      }),
      json.category,
      json.sortOrder,
      this.scoringSystemHelper.fromJSON(json.scoringSystem, true),
      json.selections ? json.selections
        .filter(selection => this.selectionHelper.checkJSON(selection))
        .map(selection => this.selectionHelper.fromJSON(selection)) : [],
      json.solutions ? json.solutions
        .filter(solution => this.solutionHelper.checkJSON(solution))
        .map(solution => this.solutionHelper.fromJSON(solution)) : [],
      json.results ? json.results
        .filter(result => this.resultHelper.checkJSON(result))
        .map(result => this.resultHelper.fromJSON(result)) : [],
      this.gameUtilHelper.mapParameters(json.parameters, config),
      this.gameUtilHelper.mapMultiLangDictEntries(json.textDictionary, config),
      this.gameUtilHelper.mapAssetLangDictEntries(json.assetDictionary, config),
      json.blocked_users || [],
      json.created_on ? moment(json.created_on).toDate() : undefined,
      json.creatorname,
      json.lastmodified ? moment(json.lastmodified).toDate() : undefined,
      json.modifiedby,
      json.version
    );

    return element;
  }
}
