import { FormControlBase } from './form-control/form-control-base.model';

export class GameParameter {

  constructor(
    public ctrl: FormControlBase<any>,
    public type: 'string' | 'number' | 'boolean' | 'string_array',
    public invalid?: boolean,
    public hidden?: boolean,
  ) {
  }

  toJSON(): any {
    const { key, value } = this.ctrl;

    return { key, value, type: this.type, invalid: this.invalid };
  }

}
