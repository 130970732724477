import { Injectable } from '@angular/core';
import { FileType } from '../../enums/file-type.enum';
import { FormControlType } from '../../enums/form-control-type.enum';
import { FileTypeInterface } from '../../interfaces/file-type.interface';
import { ConfigurationService } from '../../services/configuration.service';
import { FileTypeUtils } from '../../utils/file-type-utils';
import Utils from '../../utils/utils';
import { File } from '../file.model';
import { FormControlNumber } from '../form-control/form-control-number.model';
import { FormControlSelect } from '../form-control/form-control-select.model';
import { FormControlString } from '../form-control/form-control-string.model';
import { ModelHelper } from './model.helper.interface';
import { MultiLangStringHelper } from './multi-lang-string.helper';

@Injectable({providedIn: 'root'})
export class FileModelHelper implements ModelHelper<File> {

  constructor(
    private config: ConfigurationService,
    private multiLangStringHelper: MultiLangStringHelper,
    private fileTypeUtils: FileTypeUtils) {
  }

  checkJSON(json: any): boolean {
    return json && json.filename;
  }

  emptyModel(): File {
    return new File(
      this.config.getApiConfig().contentUrl,
      '',
      new FormControlString({
        key: 'name',
        label: 'label.general.name',
        disabled: true,
        required: true
      }),
      FileType.FILE,
      new FormControlSelect({
        value: undefined,
        key: 'locale',
        label: 'label.general.language',
        options: this.config.getAvailableLocaleOptions()
      }),
      new FormControlSelect({
        value: '',
        key: 'attribute',
        label: 'label.file.attribute',
        options: []
      }),
      new FormControlString(
        this.fileTypeUtils.getFieldOptions('fieldgen1', {
          label: 'label.file.fieldgen1'
        })
      ),
      new FormControlString(
        this.fileTypeUtils.getFieldOptions('fieldgen2', {
          label: 'label.file.fieldgen2'
        })
      ),
      new FormControlString(
        this.fileTypeUtils.getFieldOptions('fieldgen3', {
          label: 'label.file.fieldgen3'
        })
      )
    );
  }

  fromJSON(json: any, type?: number, isAudioSlideImage?: boolean, fileTypeConfig?: FileTypeInterface): File {
    // integrity check
    if (!this.checkJSON(json)) {
      return undefined;
    }

    let hasDuration = true;
    let hasLanguage = true;
    if (fileTypeConfig) {
      hasDuration = !!fileTypeConfig.hasDuration;
      hasLanguage = !!fileTypeConfig.hasLanguage;
    }

    type = Utils.isDefined(type) ? type : (
      FileType[json.attribute.toUpperCase()] ? Number(FileType[json.attribute.toUpperCase()]) : undefined
    );
    type = Utils.isDefined(type) ? type : FileType.FILE;

    // create file object
    const file = new File(
      this.config.getApiConfig().contentUrl,
      json.subContentId,
      new FormControlString({
        value: json.filename,
        key: 'name',
        label: 'label.general.name',
        disabled: true,
        required: true
      }),
      type,
      hasLanguage || isAudioSlideImage ? new FormControlSelect({
        value: json.language || this.config.getLocalesConfig().default,
        key: 'locale',
        label: 'label.general.language',
        options: this.config.getAvailableLocaleOptions(json.language)
      }) : undefined,
      new FormControlSelect({
        value: json.attribute,
        key: 'attribute',
        label: 'label.file.attribut',
        options: []
      }),
      new FormControlString(
        this.fileTypeUtils.getFieldOptions('fieldgen1', {
          value: json.fieldgen1,
          label: 'label.file.fieldgen1'
        })
      ),
      new FormControlString(
        this.fileTypeUtils.getFieldOptions('fieldgen2', {
          value: json.fieldgen2,
          label: 'label.file.fieldgen2'
        })
      ),
      isAudioSlideImage ? new FormControlNumber(
        this.fileTypeUtils.getFieldOptions('fieldgen3', {
          value: json.fieldgen3,
          key: 'audioslideimage',
          label: 'label.content.starttime'
        })) : new FormControlString(
        this.fileTypeUtils.getFieldOptions('fieldgen3', {
          value: json.fieldgen3,
          label: 'label.file.fieldgen3',
          formControlType: FormControlType.TEXTAREA,
          rows: 5
        })
      ),
      hasDuration ? isNaN(json.duration) ? undefined : Number(json.duration) : undefined,
      isNaN(json.size) ? undefined : Number(json.size),
      json.href,
      json.width,
      json.height
    );

    file.randomId = Math.random().toString();

    return file;
  }
}
