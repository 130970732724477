import { GameResultCategory } from '../../game/enum/game-result-category.enum';
import { BaseModel } from './base.model';
import { FormControlBoolean } from './form-control/form-control-boolean.model';
import { FormControlNumber } from './form-control/form-control-number.model';
import { FormControlSelect } from './form-control/form-control-select.model';
import { GameAssetDictionaryEntry } from './game-asset-dictionary-entry';
import { GameMultiLangDictionaryEntry } from './game-multi-lang-dictionary-entry';
import { GameParameter } from './game-parameter';
import { MultiLangString } from './multi-lang-string.model';

export class GameResult extends BaseModel {

  constructor(
    public id: string,
    public title: MultiLangString,
    public description: MultiLangString,
    public type: FormControlSelect,
    public category: GameResultCategory,
    public minValue: FormControlNumber,
    public maxValue: FormControlNumber,
    public resolve: FormControlBoolean,
    public parameters: GameParameter[],
    public textDictionary: GameMultiLangDictionaryEntry[],
    public assetDictionary: GameAssetDictionaryEntry[]
  ) {
    super(id);
  }

  toJSON(): object {
    const json = {};

    json[ 'id' ] = this.id;
    json[ 'title' ] = this.title.toJSON();
    json[ 'type' ] = this.type.value;
    json[ 'category' ] = this.category;
    json[ 'description' ] = this.description.toJSON();
    json[ 'minValue' ] = this.minValue.value;
    json[ 'maxValue' ] = this.maxValue.value;
    json[ 'resolve' ] = this.resolve.value;
    json[ 'parameters' ] = this.parameters ? this.parameters.map(p => p.toJSON()) : [];
    json[ 'textDictionary' ] = this.textDictionary ? this.textDictionary.map(d => d.toJSON()) : [];
    json[ 'assetDictionary' ] = this.assetDictionary ? this.assetDictionary.map(d => d.toJSON()) : [];

    return json;
  }
}
