import { Injectable } from '@angular/core';
import { ModelHelper } from "./model.helper.interface";
import Utils from "../../utils/utils";
import { FormControlString } from "../form-control/form-control-string.model";
import * as moment from 'moment';
import { Organization } from "../organization.model";
import { Guid } from "guid-typescript";

export class OrganizationModelHelper implements ModelHelper<Organization> {

  constructor() {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id);
  }

  emptyModel(): Organization {
    return new Organization(
      '',
      Guid.create().toString(),
      new FormControlString({
        key: 'name',
        label: 'label.organization.field.name',
        apiParam: 'name',
        // checkFieldPermission: true,
        required: true
      }),
      false,
      []
    );
  }

  fromJSON(json: any, skipCheck = false): Organization {
    /// integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    return new Organization(
      json._id,
      json.tenantId,
      new FormControlString({
        key: 'name',
        label: 'label.organization.field.name',
        apiParam: 'name',
        value: json.name,
        required: true
      }),
      !!json.active,
      json.blocked_users || [],
      json.created_on ? moment(json.created_on).toDate() : undefined,
      json.creatorName,
      json.lastModified ? moment(json.lastModified).toDate() : undefined,
      json.modifiedBy
    );
  }
}
