import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GameCategory } from '../../../../game/enum/game-category.enum';
import { GameElementCategory } from '../../../../game/enum/game-element-category.enum';
import { GameElement } from '../../../models/game-element.model';
import { Game } from '../../../models/game.model';
import { GameScoringSystemModelHelper } from '../../../models/helper/game-scoring-system-model.helper';
import { GameElementTypeUtils } from '../../../utils/game-element-type-utils';

@Component({
  selector: 'app-game-element-create-modal',
  templateUrl: './game-element-create-modal.component.html'
})
export class GameElementCreateModalComponent implements OnInit {

  @Input() nextIndex: number;
  @Input() element: GameElement;
  @Input() elementTypes: number[];
  form = new FormGroup({});

  constructor(
    public activeModal: NgbActiveModal,
    private elementTypeUtils: GameElementTypeUtils,
    private readonly scoringSystemModelHelper: GameScoringSystemModelHelper
  ) {
  }

  ngOnInit() {
    if (this.elementTypes) {
      this.setGameElementTypeOptions();
    } else {
      this.elementTypes = this.elementTypeUtils.getTypeValues();
      this.setGameElementTypeOptions();
    }
  }

  create(): void {
    this.updateModel();
    this.activeModal.close(this.element);
  }

  updateModel(): void {
    const scoringSystemType = this.elementTypeUtils.getScoringSystemType(this.element.type.value);
    this.element.category = this.getCategoryOfType();
    this.element.parameters = this.elementTypeUtils.createDictionary(this.element.type.value, 'parameters');
    this.element.scoringSystem = this.scoringSystemModelHelper.emptyModel(scoringSystemType);
    this.element.textDictionary = this.elementTypeUtils.createDictionary(this.element.type.value, 'textDictionary');
    this.element.assetDictionary = this.elementTypeUtils.createDictionary(this.element.type.value, 'assetDictionary');
    this.element.sortOrder = this.nextIndex;
  }

  getCategoryOfType(): GameElementCategory {
    return GameElementCategory[ this.elementTypeUtils.getCategoryOfType(this.element.type.value).toUpperCase() ];
  }

  private setGameElementTypeOptions() {
    this.element.type.options = this.element.type.options.filter(elementType => {
      return this.elementTypes.indexOf(elementType.value) !== -1;
    });

    this.element.type.value = this.element.type.options[ 0 ].value;
    this.updateModel();
  }
}
