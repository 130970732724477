import { Pipe, PipeTransform } from '@angular/core';
import { TourTypeUtils } from "../utils/tour-type-utils";

@Pipe({
  name: 'tourType'
})
export class TourTypePipe implements PipeTransform {

  constructor(private tourTypeUtils: TourTypeUtils) {
  }

  transform(tourType: number | string): string {
    return this.tourTypeUtils.getLabel(tourType);
  }
}
