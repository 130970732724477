import { Pipe, PipeTransform } from '@angular/core';
import { ContentTypeUtils } from "../utils/content-type-utils";



@Pipe({
  name: 'contentType'
})
export class ContentTypePipe implements PipeTransform {

  constructor(private contentTypeUtils: ContentTypeUtils) {
  }

  transform(contentType: number | string): string {
    return this.contentTypeUtils.getLabel(contentType);
  }
}
