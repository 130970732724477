import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UserService } from '../../core/services/user.service';

import { Map } from '../models/map.model';
import { Status } from '../models/status.model';

import { MessageModalComponent } from '../../core/components/message-modal/message-modal.component';
import { MapListModalComponent } from '../components/modals/map-list-modal/map-list-modal.component';

import { ApiBaseService } from '../../core/services/api-base.service';
import { FileService } from './file.service';

import { CreateOptions } from '../interfaces/create-options.interface';
import { ModelMapperService } from "./mapper/model-mapper.service";


@Injectable()
export class MapService extends ApiBaseService<Map> {

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected userService: UserService,
    protected fileService: FileService,
    protected modalService: NgbModal,
    private modelMapper: ModelMapperService
  ) {
    super(Map, 'maps', http, router, userService, fileService, modalService);
  }

  select(exclude: Map[] = []): Promise<Map> {
    const modalRef = this.modalService.open(MapListModalComponent, {size: 'lg'});
    modalRef.componentInstance.status = new Status();

    this.getAll(modalRef.componentInstance.status)
      .subscribe((maps: Map[]) => {
        try {
          modalRef.componentInstance.maps = maps
            .filter(map => exclude.findIndex(myMap => map.id === myMap.id) < 0);
        } catch (e) {
        }
      });

    return modalRef.result;
  }

  create(options: CreateOptions<Map>): Promise<Map> {
    return super.create(Object.assign(options, {
      model: this.emptyModel()
    }));
  }

  delete(id: string, status?: Status): Promise<any> {
    const modalRef = this.modalService.open(MessageModalComponent);
    modalRef.componentInstance.confirm = true;
    modalRef.componentInstance.message = 'Are you sure that you want to delete the map?';

    return modalRef.result.then(() => super.delete(id, status), () => Promise.reject('Dimissed'));
  }

  checkJSON(json: any): boolean {
    return this.modelMapper.mapCheckJSON(json);
  }

  fromJSON(json: any, skipCheck?: boolean): Map {
    return this.modelMapper.mapFromJSON(json, skipCheck);
  }

  emptyModel(): Map {
    return this.modelMapper.mapEmptyModel();
  }
}
