import {Injectable} from "@angular/core";
import {FormGroup} from "@angular/forms";

@Injectable()
export class FormService {

  form: FormGroup;

  constructor() { }

  public setForm(form: FormGroup) {
    this.form = form;
  }

  public getForm(): FormGroup{
    return this.form;
  }
}
