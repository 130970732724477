import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GameLevelCategory } from '../../../../game/enum/game-level-category.enum';

import { GameLevel } from '../../../models/game-level.model';
import { GameLevelTypeUtils } from '../../../utils/game-level-type-utils';

@Component({
  selector: 'app-game-level-create-modal',
  templateUrl: './game-level-create-modal.component.html'
})
export class GameLevelCreateModalComponent implements OnInit {

  @Input() nextIndex: number;
  @Input() level: GameLevel;
  @Input() levelTypes: number[];
  form = new FormGroup({});

  constructor(
    public activeModal: NgbActiveModal,
    private levelTypeUtils: GameLevelTypeUtils
  ) {
  }

  ngOnInit() {
    if (this.levelTypes) {
      this.setLevelTypeOptions();
    } else {
      this.levelTypes = this.levelTypeUtils.getLevelTypeValues();
      this.setLevelTypeOptions();
    }
  }

  create(): void {
    this.updateModel();
    this.activeModal.close(this.level);
  }

  updateModel() {
    this.level.category = this.getCategoryOfType();
    this.level.parameters = this.levelTypeUtils.createDictionary(this.level.type.value, 'parameters');
    this.level.textDictionary = this.levelTypeUtils.createDictionary(this.level.type.value, 'textDictionary');
    this.level.assetDictionary = this.levelTypeUtils.createDictionary(this.level.type.value, 'assetDictionary');
    this.level.sortOrder = this.nextIndex;
  }

  getCategoryOfType(): GameLevelCategory {
    return GameLevelCategory[ this.levelTypeUtils.getCategoryOfType(this.level.type.value).toUpperCase() ];
  }

  private setLevelTypeOptions() {
    this.level.type.options = this.level.type.options.filter(levelType => {
      return this.levelTypes.indexOf(levelType.value) !== -1;
    });

    this.level.type.value = this.level.type.options[ 0 ].value;
    this.updateModel();
  }
}
