import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import * as moment from 'moment';
import { ConfigurationService } from "../services/configuration.service";


@Pipe({
  name: 'duration',
  pure: false
})
export class DurationPipe implements PipeTransform {

  constructor(private config: ConfigurationService) {
  }

  transform(value: any): any {
    return new DecimalPipe(this.config.getLocalesConfig().default).transform(moment.duration(value).hours(), '2.0') + ':' +
      new DecimalPipe(this.config.getLocalesConfig().default).transform(moment.duration(value).minutes(), '2.0') + ':' +
      new DecimalPipe(this.config.getLocalesConfig().default).transform(moment.duration(value).seconds(), '2.0');
  }
}
