import { ModelHelper } from "./model.helper.interface";
import { Channel } from "../channel.model";
import Utils from "../../utils/utils";
import { FormControlBoolean } from "../form-control/form-control-boolean.model";
import { FormControlString } from "../form-control/form-control-string.model";
import * as moment from "moment";
import { VersionModel } from "../base.model";
import { FileType } from "../../enums/file-type.enum";
import { StationModelHelper } from "./station-model.helper";
import { FileModelHelper } from "./file-model.helper";

export class ChannelModelHelper implements ModelHelper<Channel> {

  constructor(
    private fileHelper: FileModelHelper
  ) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id);
  }

  emptyModel(): Channel {
    return new Channel(
      '',
      new FormControlBoolean({
        label: 'label.general.active',
        value: false
      }),
      new FormControlBoolean({
        label: 'label.channel.field.is-default',
        value: false
      }),
      new FormControlString({
        label: 'label.channel.field.name',
        required: true
      }),
      [],
      [],
      [],
      []);
  }

  fromJSON(json: any, skipCheck = false, stationHelper?: StationModelHelper): Channel {

    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    // create tour object
    return new Channel(
      json._id,

      new FormControlBoolean({
        label: 'label.general.active',
        value: json.active || false
      }),

      new FormControlBoolean({
        label: 'label.channel.field.is-default',
        value: json.isDefault || false
      }),

      new FormControlString({
        value: json.name,
        label: 'label.channel.field.name',
        required: true
      }),
      json._embedded && json._embedded.stations ? json._embedded.stations
        .filter(station => stationHelper.checkJSON(station))
        .map(station => stationHelper.fromJSON(station)) : [],
      json.files ? json.files
        .filter(file => this.fileHelper.checkJSON(file))
        .map(file => this.fileHelper.fromJSON(file, FileType.FILE)) : [],
      [],
      json.blocked_users,
      json.created_on ? moment(json.created_on).toDate() : undefined,
      json.creatorname,
      json.lastmodified ? moment(json.lastmodified).toDate() : undefined,
      json.modifiedby,
      json.version,
      json.versions ? json.versions.map(ver => <VersionModel>{
        number: ver.number,
        date: moment(ver.date).toDate()
      }) : [],
      json.baseId
    );
  }
}
