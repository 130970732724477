import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Content } from '../../../models/content.model';
import { ContentTypeUtils } from "../../../utils/content-type-utils";


@Component({
  selector: 'app-content-create-modal',
  templateUrl: './content-create-modal.component.html'
})
export class ContentCreateModalComponent implements OnInit {

  @Input() content: Content;
  @Input() contentTypes: number[];
  form = new FormGroup({});

  constructor(
    public activeModal: NgbActiveModal,
    private contentTypeUtils: ContentTypeUtils
  ) {
  }

  create(): void {
    this.activeModal.close(this.content);
  }

  ngOnInit() {
    if (this.contentTypes) {
      this.setContentTypeOptions();
    } else {
      this.contentTypes = this.contentTypeUtils.getValues();
      this.setContentTypeOptions();
    }
  }

  private setContentTypeOptions() {
    this.content.type.options = this.content.type.options.filter(contentType => {
      return this.contentTypes.indexOf(contentType.value) !== -1;
    });

    this.content.type.value = this.content.type.options[0].value;
  }
}
