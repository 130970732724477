import {Component, Input} from '@angular/core';

import {TableSearch} from '../../../../app/shared/interfaces/table-search.interface';


@Component({
  selector: 'app-table-search',
  templateUrl: './table-search.component.html'
})
export class TableSearchComponent {

  @Input() table;
  @Input() globalFilters: TableSearch;

}
