import { ModelHelper } from "./model.helper.interface";
import Utils from "../../utils/utils";
import { Hotspot } from "../hotspot.model";
import { FormControlBoolean } from "../form-control/form-control-boolean.model";
import * as moment from "moment";
import { Channel } from "../channel.model";

export class HotspotModelHelper implements ModelHelper<Hotspot> {

  constructor() {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id);
  }

  emptyModel(defaultChannels: Channel[], stationId?: string, substationId?: string): Hotspot {
    return new Hotspot('',
      stationId || '',
      substationId || '',
      100,
      100,
      0,
      0,
      new FormControlBoolean({
        key: 'renderTransparent',
        label: 'label.hotspot.field.transparent',
        value: false,
        hidden: false
      }),
      []
    );
  }

  fromJSON(json: any, skipCheck = false): Hotspot {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    // create tour object
    return new Hotspot(
      json._id,
      json.stationId,
      json.substationId,
      json.width,
      json.height,
      json.left,
      json.top,
      new FormControlBoolean({
        key: 'renderTransparent',
        label: 'label.hotspot.field.transparent',
        value: json.renderTransparent,
        hidden: false
      }),
      json.blocked_users,
      undefined,
      json.created_on ? moment(json.created_on).toDate() : undefined,
      json.creatorname,
      json.lastmodified ? moment(json.lastmodified).toDate() : undefined,
      json.modifiedby
    );
  }
}
