import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiBaseService } from './api-base.service';

import { MessageModalComponent } from '../components/message-modal/message-modal.component';
import { User } from '../../shared/models/user.model';
import { Status } from '../../shared/models/status.model';
import { BaseModel } from '../../shared/models/base.model';

import { CreateOptions } from '../../shared/interfaces/create-options.interface';

import { AppConfig } from '../../app.config';
import { Organization } from '../../shared/models/organization.model';
import { ModelMapperService } from '../../shared/services/mapper/model-mapper.service';

@Injectable()
export class UserService extends ApiBaseService<User> {
  // private authUrl = AppConfig.AUTH_SERVER + AppConfig.AUTH_BASE_URL;

  private currentUser: User;

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected modalService: NgbModal,
    private modelMapper: ModelMapperService
  ) {
    super(User, 'users', http, router);
    this.userService = this;
    this.url = AppConfig.AUTH_SERVER + AppConfig.AUTH_BASE_URL + '/users';
  }

  getCurrentUser(reload = false, status?: Status): Promise<User> {
    status = status || new Status();

    if (!reload && this.currentUser) {
      status.setSuccess();
      return Promise.resolve(this.currentUser);
    }

    status.setNoResults();
    return Promise.resolve(undefined);

  }

  addCurrentUser(model: BaseModel): void {
    if (this.currentUser) {
      if (!model.id && !model.creatorName) {
        model.creatorName = this.currentUser.username.value;
      }
      model.modifiedBy = this.currentUser.username.value;
    }
  }

  hasCurrentUser(): boolean {
    return !!this.currentUser;
  }

  hasCurrentUserPermission(permission: string): boolean {
    return !!this.currentUser && !!this.currentUser.permissions.find(p => p === permission);
  }

  // excludeUsernames(user: User): Observable<User> {
  //   return this.getAll()
  //     .do(users => {
  //       if (!user || users.length === 0) {
  //         return;
  //       }
  //
  //       let regex = '^(?!(';
  //
  //       users.forEach((u, index) => {
  //         if (u.id !== user.id) {
  //           regex += u.username.value;
  //           if (index !== users.length) {
  //             regex += '|';
  //           }
  //         }
  //       });
  //
  //       regex += ')$).*$';
  //
  //       if (users && users.length > 0) {
  //         user.username.pattern = regex;
  //       } else {
  //         user.username.pattern = '';
  //       }
  //     })
  //     .map(() => user);
  // }

  setCurrentUser(user: User) {
    this.currentUser = user;
  }

  create(options: CreateOptions<User>): Promise<User> {
    return super.create(Object.assign(options, {
      model: this.emptyModel()
    }));
  }

  delete(id: string, status?: Status): Promise<any> {
    if (id !== this.currentUser.id) {

      const modalRef = this.modalService.open(MessageModalComponent);
      modalRef.componentInstance.confirm = true;
      modalRef.componentInstance.message = 'Are you sure that you want to delete the user?';

      return modalRef.result.then(() => super.delete(id, status), () => Promise.reject('Dimissed'));
    } else {
      return Promise.reject('You cannot delete your own user');
    }
  }

  clear() {
    this.currentUser = null;
  }

  checkJSON(json: any): boolean {
    return this.modelMapper.userCheckJSON(json);
  }

  emptyModel(organization?: Organization): User {
    return this.modelMapper.userEmptyModel(organization);
  }

  fromJSON(json: any, skipCheck?: boolean): User {
    return this.modelMapper.userFromJSON(json, skipCheck);
  }
}
