import { GameElementCategory } from '../../game/enum/game-element-category.enum';
import Utils from '../utils/utils';
import { BaseModel } from './base.model';
import { FormControlNumber } from './form-control/form-control-number.model';
import { FormControlSelect } from './form-control/form-control-select.model';
import { GameAssetDictionaryEntry } from './game-asset-dictionary-entry';
import { GameMultiLangDictionaryEntry } from './game-multi-lang-dictionary-entry';
import { GameParameter } from './game-parameter';
import { GameResult } from './game-result.model';
import { GameScoringSystem } from './game-scoring-system.model';
import { GameSelection } from './game-selection.model';
import { GameSolution } from './game-solution.model';
import { MultiLangString } from './multi-lang-string.model';

export class GameElement extends BaseModel {

  constructor(
    public id: string,
    public title: MultiLangString,
    public description: MultiLangString,
    public type: FormControlSelect,
    public category: GameElementCategory,
    public sortOrder: number,
    public scoringSystem: GameScoringSystem,
    public selections: GameSelection[],
    public solutions: GameSolution[],
    public results: GameResult[],
    public parameters: GameParameter[],
    public textDictionary: GameMultiLangDictionaryEntry[],
    public assetDictionary: GameAssetDictionaryEntry[],
    public blockedUsers: string[],
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string,
    public version?: number
  ) {
    super(id, blockedUsers, createdOn, creatorName, lastModified, modifiedBy, version);
  }

  toJSON(): object {
    const json = super.toJSON();

    json[ 'id' ] = this.id;
    json[ 'title' ] = this.title.toJSON();
    json[ 'description' ] = this.description.toJSON();
    json[ 'type' ] = this.type.value;
    json[ 'category' ] = this.category;
    json[ 'sortOrder' ] = this.sortOrder || 0;
    json[ 'solutions' ] = this.solutions ? this.solutions.map(s => s.toJSON()) : [];
    json[ 'results' ] = this.results ? this.results.map(s => s.toJSON()) : [];
    json[ 'selections' ] = this.selections ? this.selections.map(s => s.toJSON()) : [];
    json[ 'parameters' ] = this.parameters ? this.parameters.map(p => p.toJSON()) : [];
    json[ 'textDictionary' ] = this.textDictionary ? this.textDictionary.map(d => d.toJSON()) : [];
    json[ 'assetDictionary' ] = this.assetDictionary ? this.assetDictionary.map(d => d.toJSON()) : [];
    json[ 'scoringSystem' ] = this.scoringSystem ? this.scoringSystem.toJSON() : undefined;

    return json;
  }

  addSolution(newSolution: GameSolution): void {
    this.solutions = [ ...this.solutions, newSolution ];
    this.setChanged(true);
  }

  deleteSolution(solution: GameSolution): void {
    Utils.removeObjectFromArray(this.solutions, solution);
    this.solutions = [ ...this.solutions ];
    this.setChanged(true);
  }

  addResult(newResult: GameResult): void {
    this.results = [ ...this.results, newResult ];
    this.setChanged(true);
  }

  deleteResult(result: GameResult): void {
    Utils.removeObjectFromArray(this.results, result);
    this.results = [ ...this.results ];
    this.setChanged(true);
  }

  addSelection(newSelection: GameSelection): void {
    this.selections = [ ...this.selections, newSelection ];
    this.setChanged(true);
  }

  deleteSelection(selection: GameSelection): void {
    Utils.removeObjectFromArray(this.selections, selection);
    this.selections = [ ...this.selections ];
    this.setChanged(true);
  }
}
