import { ModelHelper } from "./model.helper.interface";
import { Exhibition } from "../exhibition.model";
import { FormControlString } from "../form-control/form-control-string.model";
import Utils from "../../utils/utils";
import * as moment from 'moment';
import { VersionModel } from "../base.model";
import { TourModelHelper } from "./tour-model.helper";
import { GroupModelHelper } from "./group-model.helper";
import { StationModelHelper } from "./station-model.helper";

export class ExhibitionModelHelper implements ModelHelper<Exhibition> {

  constructor(private tourHelper: TourModelHelper,
              private groupHelper: GroupModelHelper,
              private stationHelper: StationModelHelper) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id);
  }

  emptyModel(): Exhibition {
    return new Exhibition(
      '',
      new FormControlString({
        value: '',
        key: 'name',
        label: 'label.exhibition.field.title',
        required: true
      }),
      [],
      [],
      [],
      [],
      [],
      [],
      []
    );
  }

  fromJSON(json: any, skipCheck = false): Exhibition {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    // create exhibition object
    const exhibition = new Exhibition(
      json._id,
      new FormControlString({
        value: json.name,
        key: 'name',
        label: 'label.exhibition.field.title',
        required: true
      }),
      json._embedded && json._embedded.tours ? json._embedded.tours
        .filter(tour => this.tourHelper.checkJSON(tour))
        .map(tour => this.tourHelper.fromJSON(tour)) : [],
      json.tour_refs,
      json._embedded && json._embedded.groups ? json._embedded.groups
        .filter(group => this.groupHelper.checkJSON(group))
        .map(group => this.groupHelper.fromJSON(group)) : [],
      json.group_refs,
      json._embedded && json._embedded.stations ? json._embedded.stations
        .filter(station => this.stationHelper.checkJSON(station))
        .map(station => this.stationHelper.fromJSON(station)) : [],
      json.station_refs,
      json.blocked_users,
      json.created_on ? moment(json.created_on).toDate() : undefined,
      json.creatorname,
      json.lastmodified ? moment(json.lastmodified).toDate() : undefined,
      json.modifiedby,
      json.version,
      json.versions ? json.versions.map(ver => <VersionModel>{
        number: ver.number,
        date: moment(ver.date).toDate()
      }) : [],
      json.baseId,
    );

    // Sort based on ref objects
    exhibition.tours = Utils.mapOrder(exhibition.tours, exhibition.tourRefs, 'id');
    exhibition.groups = Utils.mapOrder(exhibition.groups, exhibition.groupRefs, 'id');
    exhibition.stations = Utils.mapOrder(exhibition.stations, exhibition.stationRefs, 'id');

    return exhibition;
  }
}
