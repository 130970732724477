import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Status } from '../../../../app/shared/models/status.model';


@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html'
})
export class SpinnerComponent {

  @Input() status: Status;
  @Output() retry = new EventEmitter();

  constructor() { }

  onRetry($event): void {
    this.retry.emit();
  }
}
