import { BaseModel, VersionModel } from './base.model';
import { Tour } from './tour.model';
import { Group } from './group.model';
import { Station } from './station.model';

import { FormControlString } from './form-control/form-control-string.model';

import Utils from '../utils/utils';


export class Exhibition extends BaseModel {

  constructor(
    public id: string,
    public name: FormControlString,
    public tours: Tour[],
    public tourRefs: string[],
    public groups: Group[],
    public groupRefs: string[],
    public stations: Station[],
    public stationRefs: string[],
    public blockedUsers: string[],
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string,
    public version?: number,
    public versions?: VersionModel[],
    public baseId?: string,
  ) {
    super(id, blockedUsers, createdOn, creatorName, lastModified, modifiedBy, version, versions, baseId);
  }

  toJSON(): object {
    const json = super.toJSON();

    json['name'] = this.name.value;

    // Fill ref objects based on current order
    json['tour_refs'] = Utils.getIds(this.tours);
    json['group_refs'] = Utils.getIds(this.groups);
    json['station_refs'] = Utils.getIds(this.stations);

    return json;
  }

  addTour(newTour: Tour): void {
    this.tours = [...this.tours.filter(tour => tour.id !== newTour.id), newTour];
    this.setChanged(true);
  }

  deleteTour(tour: Tour): void {
    Utils.removeObjectFromArray(this.tours, tour);
    this.tours = [...this.tours];
    this.setChanged(true);
  }

  addGroup(newGroup: Group): void {
    this.groups = [...this.groups.filter(group => group.id !== newGroup.id), newGroup];
    this.setChanged(true);
  }

  deleteGroup(group: Group): void {
    Utils.removeObjectFromArray(this.groups, group);
    this.groups = [...this.groups];
    this.setChanged(true);
  }

  addStation(newStation: Station): void {
    this.stations = [...this.stations.filter(station => station.id !== newStation.id), newStation];
    this.setChanged(true);
  }

  deleteStation(station: Station): void {
    Utils.removeObjectFromArray(this.stations, station);
    this.stations = [...this.stations];
    this.setChanged(true);
  }
}
