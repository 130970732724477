import { ConfigurationService } from "../../services/configuration.service";
import { FormControlOptions } from "../../interfaces/form-control-options.interface";
import Utils from "../../utils/utils";
import { MultiLangFile } from "../multi-lang-file.model";
import { FileModelHelper } from "./file-model.helper";

export class MultiLangFileHelper {
  constructor(private config: ConfigurationService,
              private fileHelper: FileModelHelper) {
  }

  fromJSON(json: object = {}, keyPrefix?: string, formControlOptions?: FormControlOptions<boolean>, presets: string[] = []): MultiLangFile {
    const newJson = {};

    for (const locale in json) {
      if (Utils.isDefined(json[locale])) {
        newJson[locale] = this.fileHelper.fromJSON(json[locale]);
      }
    }

    for (const locale of [...this.config.getLocalesConfig().available]) {
      if (Utils.isUndefined(newJson[locale])) {
        newJson[locale] = this.fileHelper.emptyModel();
      }
    }

    return new MultiLangFile(newJson || {}, this.getDefaultLocale());
  }

  getDefaultLocale(): string {
    return this.config.getLocalesConfig().default;
  }
}
