import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UserService } from '../../core/services/user.service';

import { Channel } from '../models/channel.model';
import { Status } from '../models/status.model';

import { MessageModalComponent } from '../../core/components/message-modal/message-modal.component';
import { ChannelListModalComponent } from '../components/modals/channel-list-modal/channel-list-modal.component';

import { ApiBaseService } from '../../core/services/api-base.service';
import { FileService } from './file.service';

import { CreateOptions } from '../interfaces/create-options.interface';
import { ModelMapperService } from "./mapper/model-mapper.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";


@Injectable()
export class ChannelService extends ApiBaseService<Channel> {

  private static CHANNEL_STORAGE_KEY = "default_channels";

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected userService: UserService,
    protected fileService: FileService,
    protected modalService: NgbModal,
    private modelMapper: ModelMapperService
  ) {
    super(Channel, 'channels', http, router, userService, fileService);
  }

  select(exclude: Channel[] = []): Promise<Channel[]> {
    const modalRef = this.modalService.open(ChannelListModalComponent, {size: 'lg'});
    modalRef.componentInstance.status = new Status();

    this.getAll(modalRef.componentInstance.status)
      .subscribe((channels: Channel[]) => {
        try {
          modalRef.componentInstance.channels = channels
            .filter(channel => exclude.findIndex(myChannel => channel.id === myChannel.id) < 0);
        } catch (e) {
        }
      });

    return modalRef.result;
  }

  create(options: CreateOptions<Channel>): Promise<Channel> {
    return super.create(Object.assign(options, {
      model: this.emptyModel()
    }));
  }

  getAll(status?: Status, params?: Array<{ param: string, value: string }>, path?: string): Observable<Channel[]> {
    return super.getAll(status, params, path).pipe(
      map(channels => {
        if(channels && Array.isArray(channels)) {
          this.setDefaultChannels(channels.filter(c => c.isDefault.value));
        }

        return channels;
      })
    );
  }

  getDefaultChannels(): Channel[] {
    const defaultChannels = JSON.parse(window.localStorage.getItem(ChannelService.CHANNEL_STORAGE_KEY));

    console.log('default channels: ', JSON.stringify(defaultChannels));

    if(defaultChannels && Array.isArray(defaultChannels) && defaultChannels.length > 0) {
      return defaultChannels.map(channel => this.fromJSON(channel))
    }

    return []
  }

  setDefaultChannels(channels: Channel[]): void {
    if(!channels || !Array.isArray(channels)) {
      return;
    }

    window.localStorage.setItem(ChannelService.CHANNEL_STORAGE_KEY, JSON.stringify(channels));
  }

  delete(id: string, status?: Status): Promise<any> {
    const modalRef = this.modalService.open(MessageModalComponent);
    modalRef.componentInstance.confirm = true;
    modalRef.componentInstance.message = 'Are you sure that you want to delete the channel?';

    return modalRef.result.then(() => super.delete(id, status), () => Promise.reject('Dimissed'));
  }

  checkJSON(json: any): boolean {
    return this.modelMapper.channelCheckJSON(json);
  }

  fromJSON(json: any, skipCheck?: boolean): Channel {
    return this.modelMapper.channelFromJSON(json, skipCheck);
  }

  emptyModel(): Channel {
    return this.modelMapper.channelEmptyModel();
  }
}
