import * as moment from 'moment';
import { ContentTypeEnum } from '../../enums/content-type.enum';
import { FormControlType } from '../../enums/form-control-type.enum';
import { ContentTypeUtils } from '../../utils/content-type-utils';
import Utils from '../../utils/utils';
import { VersionModel } from '../base.model';
import { Content } from '../content.model';
import { FormControlBoolean } from '../form-control/form-control-boolean.model';
import { FormControlSelect } from '../form-control/form-control-select.model';
import { FormControlString } from '../form-control/form-control-string.model';
import { ChannelModelHelper } from './channel-model.helper';
import { FileModelHelper } from './file-model.helper';
import { GameModelHelper } from './game-model.helper';
import { ModelHelper } from './model.helper.interface';
import { MultiLangStringHelper } from './multi-lang-string.helper';
import { FormControlNumber } from "../form-control/form-control-number.model";
import { Channel } from "../channel.model";

export class ContentModelHelper implements ModelHelper<Content> {

  constructor(
    private contentTypeUtils: ContentTypeUtils,
    private fileHelper: FileModelHelper,
    private channelHelper: ChannelModelHelper,
    private readonly gameHelper: GameModelHelper,
    private multiLangStringHelper: MultiLangStringHelper
  ) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id) && this.contentTypeUtils.isValid(json.type);
  }

  emptyModel(defaultChannels: Channel[]): Content {
    const content = new Content(
      '',
      new FormControlBoolean({
        value: false,
        key: 'active',
        label: 'label.general.active',
        hidden: false
      }),
      new FormControlBoolean(this.contentTypeUtils.getFieldOptions(this.contentTypeUtils.getDefault(), 'secured', {
        label: 'label.content.field.secured'
      })),
      this.multiLangStringHelper.fromJSON(
        {},
        'title',
        this.contentTypeUtils.getFieldOptions(this.contentTypeUtils.getDefault(), 'title', {
          label: 'label.content.field.title'
        })
      ),
      new FormControlString(this.contentTypeUtils.getFieldOptions(this.contentTypeUtils.getDefault(), 'code', {
        label: 'label.content.field.code'
      })),
      this.multiLangStringHelper.fromJSON(
        {},
        'description',
        this.contentTypeUtils.getFieldOptions(this.contentTypeUtils.getDefault(), 'description', {
          label: 'label.content.field.description',
          formControlType: FormControlType.TEXTAREA,
          rows: 15
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'copyright',
        this.contentTypeUtils.getFieldOptions(this.contentTypeUtils.getDefault(), 'copyright', {
          label: 'label.content.field.copyright',
          formControlType: FormControlType.TEXTAREA,
          rows: 3
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'mlgen1',
        this.contentTypeUtils.getFieldOptions(this.contentTypeUtils.getDefault(), 'mlgen1', {
          label: 'label.content.field.mlgen1',
          formControlType: FormControlType.TEXTAREA,
          rows: 3
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'mlgen2',
        this.contentTypeUtils.getFieldOptions(this.contentTypeUtils.getDefault(), 'mlgen2', {
          label: 'label.content.field.mlgen2',
          formControlType: FormControlType.TEXTAREA,
          rows: 3
        })
      ),
      new FormControlString(
        this.contentTypeUtils.getFieldOptions(this.contentTypeUtils.getDefault(), 'fieldgen1', {
          label: 'label.content.field.fieldgen1'
        })
      ),
      new FormControlNumber(
        this.contentTypeUtils.getFieldOptions(this.contentTypeUtils.getDefault(), 'nrgen1', {
          label: 'label.content.field.nrgen1'
        })
      ),
      new FormControlSelect({
        value: this.contentTypeUtils.getDefault(),
        key: 'type',
        label: 'label.general.type',
        options: this.contentTypeUtils.getSelectOptions(),
        required: true,
        hidden: false
      })
    );

    content.channels = defaultChannels;
    content.channelRefs = defaultChannels ? Utils.getIds(defaultChannels) : [];

    return content;
  }

  fromJSON(json: any, skipCheck = false): Content {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    const contentType = Number(json.type);

    // create content object
    const content = new Content(
      json._id,

      new FormControlBoolean({
        value: json.active,
        key: 'active',
        label: 'label.general.active',
        hidden: false
      }),

      new FormControlBoolean(this.contentTypeUtils.getFieldOptions(contentType, 'secured', {
        value: json.secured,
        label: 'label.content.field.secured'
      })),

      this.multiLangStringHelper.fromJSON(
        json.title,
        'title',
        this.contentTypeUtils.getFieldOptions(contentType, 'title', {
          label: 'label.content.field.title'
        }),
        json.title ? Object.keys(json.title) : []
      ),

      new FormControlString(this.contentTypeUtils.getFieldOptions(contentType, 'code', {
        value: json.code,
        label: 'label.content.field.code'
      })),

      this.multiLangStringHelper.fromJSON(
        json.description,
        'description',
        this.contentTypeUtils.getFieldOptions(contentType, 'description', {
          label: 'label.content.field.description',
          formControlType: FormControlType.TEXTAREA,
          rows: 15
        }),
        json.description ? Object.keys(json.description) : []
      ),

      this.multiLangStringHelper.fromJSON(
        json.copyright,
        'copyright',
        this.contentTypeUtils.getFieldOptions(contentType, 'copyright', {
          label: 'label.content.field.copyright',
          formControlType: FormControlType.TEXTAREA,
          rows: 3
        }),
        json.copyright ? Object.keys(json.copyright) : []
      ),
      this.multiLangStringHelper.fromJSON(
        json.mlgen1,
        'mlgen1',
        this.contentTypeUtils.getFieldOptions(contentType, 'mlgen1', {
          label: 'label.content.field.mlgen1',
          formControlType: FormControlType.TEXTAREA,
          rows: 3
        }),
        json.mlgen1 ? Object.keys(json.mlgen1) : []
      ),
      this.multiLangStringHelper.fromJSON(
        json.mlgen2,
        'mlgen2',
        this.contentTypeUtils.getFieldOptions(contentType, 'mlgen2', {
          label: 'label.content.field.mlgen2',
          formControlType: FormControlType.TEXTAREA,
          rows: 3
        }),
        json.mlgen2 ? Object.keys(json.mlgen2) : []
      ),
      new FormControlString(
        this.contentTypeUtils.getFieldOptions(contentType, 'fieldgen1', {
          value: json.fieldgen1,
          label: 'label.content.field.fieldgen1'
        })
      ),
      new FormControlNumber(
        this.contentTypeUtils.getFieldOptions(contentType, 'nrgen1', {
          value: isNaN(json.nrgen1) ? undefined : Number(json.nrgen1),
          label: 'label.content.field.nrgen1'
        })
      ),

      new FormControlSelect({
        value: contentType,
        key: 'type',
        label: 'label.general.type',
        options: this.contentTypeUtils.getSelectOptions(),
        required: true,
        hidden: false
      }),
      json.blocked_users,
      json.created_on ? moment(json.created_on).toDate() : undefined,
      json.creatorname,
      json.lastmodified ? moment(json.lastmodified).toDate() : undefined,
      json.modifiedby,
      json.is_refered,
      json.version,
      json.versions ? json.versions.map(ver => ({
        number: ver.number,
        date: moment(ver.date).toDate()
      } as VersionModel)) : [],
      json.baseId,
      json._embedded && json._embedded.channels ? json._embedded.channels
        .filter(channel => this.channelHelper.checkJSON(channel))
        .map(channel => this.channelHelper.fromJSON(channel)) : [],
      json.channel_refs,
      json.game_refs,
      json.files ? json.files
        .filter(file => this.fileHelper.checkJSON(file))
        .filter(file => Utils.isDefined(this.contentTypeUtils.getFileType(contentType, file.attribute)) || Utils.isThumbnail(file.attribute))
        .map(file => {
          const contentTypeConfig = this.contentTypeUtils.getFileTypes(contentType).find(ft => file.attribute === ft.attribute.value)
          const isAudioSlideImage = contentType === ContentTypeEnum.AUDIO_SLIDE && file.attribute === 'image';
          return this.fileHelper.fromJSON(file, contentTypeConfig.type, isAudioSlideImage, contentTypeConfig);
        }) : []
    );

    // Sort based on ref objects
    content.channels = Utils.mapOrder(content.channels, content.channelRefs, 'id');

    return content;
  }

  // getUploadLimit(contentType: string | number): number {
  //   return this.contentTypeUtils.getUploadLimit(contentType);
  // }
}
