import { FormControlBase } from './form-control-base.model';
import { FormControlOptions } from '../../../../app/shared/interfaces/form-control-options.interface';
import { FormControlType } from '../../enums/form-control-type.enum';


export class FormControlString extends FormControlBase<string> {
  formControlType: FormControlType = FormControlType.INPUT;
  inputType = 'text';
  rows = 5;

  constructor(options: FormControlOptions<string>) {
    super(options);
    this.formControlType = options['formControlType'] || this.formControlType;
    this.inputType = options['inputType'] || this.inputType;
    this.rows = options['rows'] || this.rows;
  }
}
