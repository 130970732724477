import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { UserService } from './user.service';
import { MessageModalComponent } from '../components/message-modal/message-modal.component';

import { User } from '../../shared/models/user.model';
import { Status } from '../../shared/models/status.model';
import { AccessToken } from '../../shared/models/access-token.model';

import { AppConfig } from '../../app.config';
import { PasswordModalComponent } from '../../shared/components/modals/password-modal/password-modal.component';
import { ConfigurationService } from "../../shared/services/configuration.service";
import * as moment from 'moment';
import { Organization } from "../../shared/models/organization.model";


@Injectable()
export class AuthService {

  // store the URL so we can redirect after logging in
  redirectUrl = '/';

  private static readonly EXPIRE_THRESHOLD = 300000; // 5 minutes

  private readonly authUrl = AppConfig.API_URL + AppConfig.API_REST_BASE_PATH + 'oauth';
  private loggedInSubject = new Subject<any>();
  private accessToken: AccessToken;
  private pollAccessTokenExpiration: any;
  private resumeWorkEnable: true;

  public modalRef;

  constructor(
    private http: HttpClient,
    private router: Router,
    private userService: UserService,
    private modalService: NgbModal,
    private config: ConfigurationService
  ) {
    if (AppConfig.AUTH_SERVER) {
      this.authUrl = AppConfig.AUTH_SERVER + AppConfig.AUTH_AUTH_URL;
    }

    if (this.isLoggedIn()) {
      const token = this.getAccessToken();
      const organisation = {tenantId: token.tenantId} as Organization;
      const user = this.userService.emptyModel(organisation);
      user.username.value = token.username;
      user.permissions = token.permissions;

      this.userService.setCurrentUser(user);
    }
  }

  /* UTIL */

  isLoggedIn(): boolean {
    const token = this.getAccessToken();
    const isExpired = !token || token.isExpired();

    return Boolean(token && !isExpired);
  }

  getAuthUrl(): string {
    return this.authUrl;
  }

  subscribeLoggedIn(): Observable<boolean> {
    return this.loggedInSubject.asObservable();
  }

  notifyLoggedIn(): void {
    this.loggedInSubject.next(this.isLoggedIn());
  }

  getCurrentUser(reload = false, status?: Status): Promise<User> {
    return this.userService.getCurrentUser(reload, status);
  }

  /* TOKEN */

  setAccessToken(accessToken: AccessToken, rememberMe = true): void {
    this.deleteAccessToken();
    this.saveAccessToken(accessToken, rememberMe);
  }

  saveAccessToken(accessToken, rememberMe = true): void {
    this.accessToken = accessToken;

    if (rememberMe) {
      window.localStorage.setItem('access_token', JSON.stringify(accessToken));
    }

    if (!this.pollAccessTokenExpiration) {
      this.pollAccessToken();
    }
  }

  deleteAccessToken(): void {
    this.accessToken = null;
    this.pollAccessTokenExpiration = undefined;
    window.localStorage.removeItem('access_token');
  }

  getAccessToken(): AccessToken {
    this.accessToken = this.accessToken || AccessToken.fromJSON(
      JSON.parse(window.localStorage.getItem('access_token'))
    );

    if (!this.pollAccessTokenExpiration && this.accessToken) {
      this.pollAccessToken();
    }

    return this.accessToken;
  }

  /* API */

  private handleError(error: any, status?: Status): Promise<any> {
    status = status || new Status();
    status.setError();
    return Promise.reject(error);
  }

  refresh(rememberMe = true, status?: Status): Observable<any> {
    status = status || new Status();
    status.setLoading();

    const token = this.getAccessToken();

    let body = new HttpParams();
    body = body.append('refresh_token', token.refreshToken);
    body = body.append('grant_type', 'refresh_token');
    body = body.append('client_id', AppConfig.AUTH_CLIENT_ID);
    body = body.append('client_secret', AppConfig.AUTH_CLIENT_SECRET);

    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        .set('nc-channel', AppConfig.NC_CHANNEL_HEADER),
    };

    return this.http.post(this.authUrl + '/token', body, options).pipe(
      map(response => this.setAccessToken(AccessToken.fromJSON(response), rememberMe)),
      tap(() => {
        if (this.isLoggedIn()) {
          status.setSuccess();
        }
      }),
      catchError(err => this.handleError(err, status)),
    );
  }

  login(user: User, rememberMe = true, status?: Status): Observable<any> {
    status = status || new Status();
    status.setLoading();

    this.clear();

    let queryParams = new HttpParams();
    queryParams = queryParams.append('client_id', AppConfig.AUTH_CLIENT_ID);
    queryParams = queryParams.append('response_type', 'code');

    let body = new HttpParams();
    body = body.append('username', encodeURIComponent(user.username.value));
    body = body.append('password', encodeURIComponent(user.password.value));

    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        .set('nc-channel', AppConfig.NC_CHANNEL_HEADER),
      params: queryParams
    };

    return this.http.post(this.authUrl + '/authorize', body, options).pipe(
      map(response => {
        body = new HttpParams();
        body = body.append('client_id', AppConfig.AUTH_CLIENT_ID);
        body = body.append('client_secret', AppConfig.AUTH_CLIENT_SECRET);
        body = body.append('grant_type', 'authorization_code');
        body = body.append('code', response['code']);

        if (response['msg']) {
          const modalRef = this.modalService.open(MessageModalComponent);
          modalRef.componentInstance.message = response['msg'];
        }
      }),
      mergeMap(code => this.http.post(this.authUrl + '/token', body, options)),
      map(response => this.setAccessToken(AccessToken.fromJSON(response), rememberMe)),
      tap(() => {
        if (this.isLoggedIn()) {

          const token = this.getAccessToken();
          const organisation = {tenantId: token.tenantId} as Organization;
          const user = this.userService.emptyModel(organisation);
          user.username.value = token.username;
          user.permissions = token.permissions;

          this.userService.setCurrentUser(user);

          status.setSuccess();
        }
        this.notifyLoggedIn();
      }),
      catchError(err => this.handleError(err, status)),);
  }

  private logoutHandler(status) {
    this.clear();
    status.setSuccess();
    this.notifyLoggedIn();
    this.resumeWorkEnable = true;
    this.router.navigate(['/login']);
  }

  logout(status?: Status): void {
    this.router.navigate(['/logout']).then(() => {
      status = status || new Status();
      status.setLoading();

      this.http.get(this.authUrl + '/logout').toPromise().then(
        () => this.logoutHandler(status),
        () => this.logoutHandler(status)
      );
    });
  }

  validateCaptcha(token: string): Promise<any> {
    const url = this.authUrl + '/validate_captcha';
    const params = {'token': token};
    return this.http.post(url, params).toPromise();
  }

  clear() {
    this.deleteAccessToken();
    this.config.clearConfig();
    this.userService.clear();
  }

  pollAccessToken() {
    this.pollAccessTokenExpiration = setTimeout(() => {
        this.accessToken ?
          this.checkRefresh() ?
              this.refresh().subscribe(() => {
                // nothing to do
              })
            : this.pollAccessToken()
          : this.router.navigate(['/login']);
      }, 5000
    );
  }

  private checkRefresh(): boolean {
    const diff = moment(this.accessToken.expirationTime).diff(moment());
    const threshold = this.config.getAuthenticationConfig() ? this.config.getAuthenticationConfig().expireThreshold || AuthService.EXPIRE_THRESHOLD : AuthService.EXPIRE_THRESHOLD ;
    return this.accessToken.isExpired() || diff <= threshold
  }

  enterPassword() {
    console.log('enterPassword called');
    this.modalRef = this.modalService.open(PasswordModalComponent);
    this.modalRef.componentInstance.title = 'Please login again';

    this.modalRef.result.then(password => {
      // this.refresh(password);
      // this.refresh();
      this.modalRef = null;
    }, () => this.modalRef = null);
  }

}
