import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ExhibitionListComponent } from '../../../app/exhibition/exhibition-list/exhibition-list.component';


const exhibitionListRoutes: Routes = [
  {
    path: '',
    component: ExhibitionListComponent,
    data: {
      title: 'Exhibitions',
      breadcrumb: false
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(exhibitionListRoutes)],
  exports: [RouterModule]
})
export class ExhibitionListRoutingModule { }
