import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FormControlService } from '../../../../app/core/services/form-control.service';

import { FormControlType } from '../../../../app/shared/enums/form-control-type.enum';
import { FormControlBase } from '../../../../app/shared/models/form-control/form-control-base.model';

import Utils from '../../../../app/shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';


// Form Control Options
interface Options {
  hideLabel?: boolean;
  styleClassLabel?: string;
  styleClassInput?: string;
  styleClassFormGroup?: string;
  showPlaceholder?: boolean;
  hideErrors?: boolean;
  rows?: number;
}

@Component({
  selector: 'app-form-control',
  templateUrl: './form-control.component.html'
})
export class FormControlComponent implements OnInit, OnDestroy {

  @Input() formControlBase: FormControlBase<any>;
  @Input() form: FormGroup;
  @Input() options: Options = {};
  @Input() patternError: string;
  @Input() errors: string[] = [];
  @Output() onBlur = new EventEmitter();
  @Output() onChange = new EventEmitter();

  prepared = false;
  formControlType = FormControlType;

  private randomId: string;

  constructor(
    private formControlService: FormControlService,
    protected translate: TranslateService
  ) {
    this.randomId = Math.random().toString();
  }

  get key(): string {
    return this.formControlBase.key + '_' + this.randomId;
  }

  get showError(): boolean {
    return this.form.controls[this.key]
      && this.form.controls[this.key].invalid && this.form.controls[this.key].dirty;
  }

  get showPatternError(): boolean {
    return this.showError
      && Utils.isDefined(this.form.controls[this.key].errors)
      && Utils.isDefined(this.form.controls[this.key].errors.pattern)
      && Utils.isDefined(this.patternError);
  }

  onBlurHandler(event) {
    this.onBlur.emit(event.data);
  }

  onChangeHandler(event) {
    if (event) {
      this.onChange.emit(event.data);
    }

    setTimeout(() => {
      if (this.errors && this.errors.length > 0 && !this.options.hideErrors) {
        this.form.controls[this.key].setErrors({'incorrect': true});
      }
    });
  }

  ngOnInit() {
    if (!this.formControlBase) {
      return;
    }

    Promise.resolve(null).then(() => {
      this.prepared = true;
      this.form.addControl(this.key, this.formControlService.toFormControl(this.formControlBase));
    });
  }

  ngOnDestroy() {
    if (!this.formControlBase) {
      return;
    }

    Promise.resolve(null).then(() => {
      this.form.removeControl(this.key);
    });
  }
}
