import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'app-form-errors',
  template: `
    <span class="text-warning mr-1" *ngIf="form.valid && (form.dirty || dirty)">
      <i class="fa fa-exclamation-circle"></i> Unsaved changes
    </span>
    <span class="text-danger mr-1" *ngIf="form.invalid && (form.dirty || dirty)">
      <i class="fa fa-exclamation-circle"></i> Please check for errors
    </span>
  `
})
export class FormErrorsComponent {

  @Input() form: FormGroup;
  @Input() dirty: boolean;

  constructor() { }
}
