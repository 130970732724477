import { Component, OnInit } from "@angular/core";
import { PermissionsComponent } from "../../shared/components/permissions/permissions.component";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "../../core/services/user.service";
import { SyncService } from "../../core/services/sync.service";
import { Status } from "../../shared/models/status.model";
import { SyncMetaModel } from "../../shared/models/sync-meta.model";

@Component({
  selector: 'app-client-content-info',
  templateUrl: './client-content-info.component.html'
})
export class ClientContentInfoComponent extends PermissionsComponent implements OnInit {

  syncMeta: SyncMetaModel;
  status = new Status();

  constructor(
    protected route: ActivatedRoute,
    protected userService: UserService,
    private syncService: SyncService
  ) {
    super(route, userService);
  }

  loadData(): void {
    // this.syncService.getMeta(this.status).then(response => {
    //   this.syncMeta = response;
    // });
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
  }

  createNewDelta() {
    this.syncService.createDelta(this.status).then(() => {
      this.syncMeta.building = true;
    });
  }

  lock() {
    if(this.syncMeta) {
      (this.syncMeta.locked ? this.syncService.unlock(this.status) : this.syncService.lock(this.status)).then(() => {
        this.loadData();
      });
    }
  }
}
