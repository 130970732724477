import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';


@Directive({
  selector: '[appBtnSpinner]'
})
export class BtnSpinnerDirective implements OnChanges {
  @Input('appBtnSpinnerLoading') appBtnSpinnerLoading: boolean;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    setTimeout(() => { // In some cases the class wasn't added without this hack
      this.renderer.addClass(this.el.nativeElement, 'loader');
    });
  }

  ngOnChanges(changes) {
    if (changes.appBtnSpinnerLoading.currentValue === true) {
      this.renderer.addClass(this.el.nativeElement, 'loading');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'loading');
    }
  }
}
