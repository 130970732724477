import { Component, Input } from '@angular/core';

import { BtnBaseComponent } from '../btn-base/btn-base.component';


@Component({
  selector: 'app-btn-edit',
  template: `
    <button type="button" class="btn btn-outline-primary" [disabled]="disabled"
      appBtnSpinner [appBtnSpinnerLoading]="status && status.loading"
      (click)="onButtonClick($event); $event.stopPropagation();">
      <i class="fa fa-edit"></i>
    </button>
  `
})
export class BtnEditComponent extends BtnBaseComponent {

  @Input() type: string;

  constructor() {
    super();
  }
}
