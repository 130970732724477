import { Component, Input, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { Organization } from "../../shared/models/organization.model";
import { Status } from "../../shared/models/status.model";
import { OrganizationService } from "../../shared/services/organization.service";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "../../core/services/user.service";
import { PermissionsComponent } from "../../shared/components/permissions/permissions.component";

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html'
})
export class OrganizationListComponent extends PermissionsComponent implements OnInit {

  @Input() detailLink = './';

  organizations$: Observable<Organization[]>;
  status = new Status();

  constructor(
    protected route: ActivatedRoute,
    protected userService: UserService,
    private organizationService: OrganizationService
  ) {
    super(route, userService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
  }

  loadData(): void {
    this.getOrganizations();
  }

  private getOrganizations(): void {
    this.organizations$ = this.organizationService.getAll(this.status);
  }

  create(): void {
    this.organizationService.create({route: this.route, url: this.detailLink || './'});
  }

  delete(organization: Organization): void {
    this.organizationService.delete(organization.id, this.status)
      .then(() => this.loadData(), () => {});
  }
}
