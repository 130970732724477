import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { AuthService } from '../../../app/core/services/auth.service';

import { Status } from '../../../app/shared/models/status.model';
import { PermissionEnum } from '../../shared/enums/permission.enum';

@Injectable()
export class PermissionGuard implements CanActivate {

  status = new Status();

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const permission: PermissionEnum = route.data.permission;

    return this.checkPermissions(permission);
  }

  checkPermissions(permission: string): Promise<boolean> {
    return this.authService.getCurrentUser(false, this.status).then(user => {
      const canRead = user && !!user.permissions.find(p => p === permission);

      console.log('' + permission + ' - canRead: ' + canRead);

      if (!canRead) {
        this.router.navigate([ '/accessdenied' ]);
      }

      return canRead;
    });
  }
}
