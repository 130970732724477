import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../core/services/auth.service';
import { BaseModel } from '../../models/base.model';
import { Status } from '../../models/status.model';
import { User } from '../../models/user.model';
import { OrganizationService } from '../../services/organization.service';
import { BlockedUserModalComponent } from './blocked-user-modal.component';

@Component({
  selector: 'app-blocked-user',
  templateUrl: './blocked-user.component.html'
})
export class BlockedUserComponent implements OnInit {

  @Input() model: BaseModel;
  status = new Status();

  users: User[];
  blockedUserList: string[] = [];

  constructor(private authService: AuthService,
              private modalService: NgbModal,
              private organizationService: OrganizationService) { }

  ngOnInit() {
      const token = this.authService.getAccessToken();
      this.organizationService.getUserForOrganizations(token.organizationId, this.status).subscribe(userList => {
        this.users = userList;

        this.blockedUserList = this.users.filter(u => this.model.blockedUsers.find(id => id === u.id))
          .map(u => u.username.value);
      });
  }

  getBlockedUserList(): string {

    return this.blockedUserList.join(', ') || ' ';
  }

  handleEditClick() {
    const modalRef = this.modalService.open(BlockedUserModalComponent);
    modalRef.componentInstance.userList = this.users;
    modalRef.componentInstance.model = this.model;

    modalRef.result.then(idList => {
      if (idList && Array.isArray(idList)) {
        this.model.blockedUsers = idList;
        this.blockedUserList = this.users.filter(u => idList.find(id => id === u.id)).map(u => u.username.value);
        this.model.setChanged(true);
      }
    }, () => {});
  }
}
