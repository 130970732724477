import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiConfig } from '../../../config/api-config.interface';
import { AppConfigOptions } from '../../../config/app-config.interface';
import { AuthenticationConfigOptions } from '../../../config/authentication-config.interface';
import { ConfigOptions } from '../../../config/config.interface';
import { ContentTypeConfigOptions } from '../../../config/content-type-config.interface';
import { FileTypeConfigOptions } from '../../../config/file-type-config.interface';
import { GameControlFlowTypeConfigOptions } from '../../../config/game-control-flow-type-config.interface';
import { GameElementTypeConfigOptions } from '../../../config/game-element-type-config.interface';
import { GameLevelTypeConfigOptions } from '../../../config/game-level-type-config.interface';
import { GameResultTypeConfigOptions } from '../../../config/game-result-type-config.interface';
import { GameScoringSystemTypeConfigOptions } from '../../../config/game-scoring-system-type-config.interface';
import { GameSelectionTypeConfigOptions } from '../../../config/game-selection-type-config.interface';
import { GameSolutionTypeConfigOptions } from '../../../config/game-solution-type-config.interface';
import { GameTypeConfigOptions } from '../../../config/game-type-config.interface';
import { LocalesConfigOptions } from '../../../config/locales-config.interface';
import { StationTypeConfigOptions } from '../../../config/station-type-config.interface';
import { tableConfigDefault } from '../../../config/table-config.default';
import { TableConfigOptions } from '../../../config/table-config.interface.ts';
import { TourTypeConfigOptions } from '../../../config/tour-type-config.interface';
import { UserContentTypeConfigOptions } from '../../../config/user-content-type-config.interface';
import { AppConfig } from '../../app.config';
import { ModuleType } from '../enums/module-type.enum';
import { FormControlSelectOptions } from '../interfaces/form-control-select-options.interface';
import { TableSearch } from '../interfaces/table-search.interface';
import { Status } from '../models/status.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService implements OnInit {

  private static readonly GOOGLE_MAPS_DEFAULT_KEY: string = 'AIzaSyBUiQkWGgLtHkPP2yrDaTqQzTtViEgDfwA';
  private static readonly GRECAPTCHA_DEFAULT_KEY: string = '6Lc5i1kUAAAAAFxlWmTh80sHKD2J9tUgLd7A9opE';
  private static readonly PASSWORD_MIN_LENGTH_DEFAULT = 6;
  private static readonly DEFAULT_PILLS_LIMIT = 6;

  private readonly url;
  private status = new Status();

  private configuration: ConfigOptions;

  constructor(private http: HttpClient) {
    this.url = AppConfig.CONFIG_SERVER + AppConfig.CONFIG_BASE_URL;
  }

  ngOnInit(): void {
    this.initConfig();
  }

  public initConfig(tenantId?: string): Observable<void> {
    console.log(`initConfig called [tenantId: ${tenantId}]`);

    if (!tenantId) {
      return of(null);
    }


    this.storeTenantId(tenantId);

    return this.getWebConfigForTenant(tenantId, this.status).pipe(
      map((response: ConfigOptions) => {
        if (this.checkJSON(response)) {
          this.configuration = response;
        }
      }),
      catchError(err => this.handleError(err))
    );
  }

  public getWebConfigForTenant(tenantId: string, status: Status): Observable<ConfigOptions> {
    if (!tenantId) {
      return of(null);
    }

    status.setLoading();
    const url = `${this.url}/web-config/${tenantId}`;
    return this.http.get(url).pipe(map((response: ConfigOptions) => {
      if (this.checkJSON(response)) {
        status.setSuccess()
        return response
      } else {
        status.setNoResults()
        return null;
      }
    }));
  }

  isLoadingConfig() {
    return this.status.loading;
  }

  clearConfig() {
    console.log('config clear called');
    this.configuration = null;
    window.localStorage.removeItem('tenant_id');
  }

  protected handleError(error?: any): Promise<any> {
    this.status.setError();
    return Promise.reject(error ? error.message : error);
  }

  async getAsyncData() {
    if (this.getStoredTenantId()) {
      await this.initConfig(this.getStoredTenantId()).toPromise();
    }
  }

  getAppConfig(): AppConfigOptions {
    if (this.configuration) {
      return this.configuration.app;
    }

    this.getAsyncData();
    return null;
  }

  getApiConfig(): ApiConfig {
    if (this.configuration) {
      return this.configuration.api;
    }

    this.getAsyncData();
    return null;
  }

  getAuthenticationConfig(): AuthenticationConfigOptions {
    if (this.configuration) {
      return this.configuration.auth;
    }

    this.getAsyncData();
    return null;
  }

  getPasswordMinLength(): number {
    if (!this.configuration) {
      return ConfigurationService.PASSWORD_MIN_LENGTH_DEFAULT;
    }

    return typeof this.configuration.auth.passwordMinLength !== 'undefined' ? this.configuration.auth.passwordMinLength
      : ConfigurationService.PASSWORD_MIN_LENGTH_DEFAULT;
  }

  getLocalesConfig(): LocalesConfigOptions {
    if (this.configuration) {
      let locales = this.configuration.locales;
      if (!locales) {
        locales = {default: 'en', available: ['en']};
      }

      locales.default = locales.default || 'en';
      locales.available = locales.available || ['en'];

      return locales;
    }

    this.getAsyncData();
    return {default: 'en', available: ['en']};
  }

  getAvailableLocaleOptions(locale?: string): FormControlSelectOptions<string> {
    const availables = this.getLocalesConfig().available;
    const localeOptions = availables.map(mylocale => {
      return {label: mylocale.toUpperCase(), value: mylocale};
    });

    if (locale && this.isValidLocale(locale) && availables.indexOf(locale) === -1) {
      localeOptions.push({label: locale.toUpperCase(), value: locale});
    }

    return localeOptions;
  }

  private isValidLocale(locale: string): boolean {
    return locale.length === 2;
  }

  getGoogleMapsConfig(): { key: string } {
    let gMaps = this.configuration ? this.configuration.googleMaps : undefined;
    if (!gMaps) {
      gMaps = {key: ConfigurationService.GOOGLE_MAPS_DEFAULT_KEY};
    }

    gMaps.key = gMaps.key || ConfigurationService.GOOGLE_MAPS_DEFAULT_KEY;
    return gMaps;
  }

  getGreCaptchaConfig(): { key: string } {
    let captcha = this.configuration ? this.configuration.grecaptcha : undefined;

    if (!captcha) {
      captcha = {key: ConfigurationService.GRECAPTCHA_DEFAULT_KEY};
    }

    captcha.key = captcha.key || ConfigurationService.GRECAPTCHA_DEFAULT_KEY;
    return captcha;
  }

  getTableConfig(): TableConfigOptions {
    const merge = (target, source) => {
      // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
      for (const key of Object.keys(source)) {
        if (source[key] instanceof Object) {
          Object.assign(source[key], merge(target[key], source[key]));
        }
      }
      // Join `target` and modified `source`
      Object.assign(target || {}, source);

      return target;
    };

    const tableConfig: TableConfigOptions = merge(tableConfigDefault, this.getAppConfig() ? this.getAppConfig().tableConfig : {});

    const types: any = this.getAppConfig() ? this.getAppConfig().types : {};

    // adds missing types to models
    Object.keys(types)
      .forEach(model => Object.keys(types[model])
        .forEach(type => tableConfig[model] && !tableConfig[model][type] ? tableConfig[model][type] = {} : undefined
        )
      );

    // merges default values into type values
    Object.keys(tableConfig)
      .forEach(model => Object.keys(tableConfig[model])
        .filter(type => type !== 'default')
        .forEach(type => tableConfig[model][type] = Object.assign({}, tableConfig[model].default, tableConfig[model][type]))
      );

    return tableConfig;
  }

  getStationTableSearchConfig(): TableSearch {
    if (this.getAppConfig()) {
      return this.getAppConfig().tableSearch ? this.getAppConfig().tableSearch.station : null;
    }

    return null;
  }

  getTabPillsLimit(): number {
    const limit = (this.getAppConfig() && this.getAppConfig().tabs && typeof this.getAppConfig().tabs.pillsLimit !== 'undefined') ?
      this.getAppConfig().tabs.pillsLimit : ConfigurationService.DEFAULT_PILLS_LIMIT;

    return limit;
  }

  getTourTypeConfig(): TourTypeConfigOptions {
    return this.getAppConfig() ? this.getAppConfig().types.tour : null;
  }

  getStationTypeConfig(): StationTypeConfigOptions {
    return this.getAppConfig() ? this.getAppConfig().types.station : null;
  }

  getContentTypeConfig(): ContentTypeConfigOptions {
    return this.getAppConfig() ? this.getAppConfig().types.content : null;
  }

  getGameTypeConfig(): GameTypeConfigOptions {
    return this.getAppConfig() ? this.getAppConfig().types.game : null;
  }

  getLevelTypeConfig(): GameLevelTypeConfigOptions {
    return this.getAppConfig() ? this.getAppConfig().types.gameLevel : null;
  }

  getGameElementTypeConfig(): GameElementTypeConfigOptions {
    return this.getAppConfig() ? this.getAppConfig().types.gameElement : null;
  }

  getSolutionTypeConfig(): GameSolutionTypeConfigOptions {
    return this.getAppConfig() ? this.getAppConfig().types.gameSolution : null;
  }

  getSelectionTypeConfig(): GameSelectionTypeConfigOptions {
    return this.getAppConfig() ? this.getAppConfig().types.gameSelection : null;
  }

  getResultTypeConfig(): GameResultTypeConfigOptions {
    return this.getAppConfig() ? this.getAppConfig().types.gameResult : null;
  }

  getScoringSystemTypeConfig(): GameScoringSystemTypeConfigOptions {
    return this.getAppConfig() ? this.getAppConfig().types.gameScoring : null;
  }

  getControlFlowTypeConfig(): GameControlFlowTypeConfigOptions {
    return this.getAppConfig() ? this.getAppConfig().types.gameControlFlow : null;
  }

  getUserContentTypeConfig(): UserContentTypeConfigOptions {
    return this.getAppConfig() ? this.getAppConfig().types.userContent : null;
  }

  getFileTypeConfig(): FileTypeConfigOptions {
    return this.getAppConfig() ? this.getAppConfig().types.file : null;
  }

  getEnabledModules(): ModuleType[] {
    return Object.keys(ModuleType).map(key => ModuleType[key])
      .filter(mt => ['exhibition', 'tour', 'group', 'station', 'content', 'channel', 'game', 'map', 'cdn', 'html'].indexOf(mt) !== -1);
  }

  private checkJSON(json: any): boolean {
    return !!json;
  }

  private storeTenantId(tenantId: string) {
    window.localStorage.setItem('tenant_id', tenantId);
  }

  private getStoredTenantId(): string {
    const tenantId = window.localStorage.getItem('tenant_id');

    return tenantId || undefined;
  }
}
