import { FormControlBase } from './form-control-base.model';
import { FormControlOptions } from '../../../../app/shared/interfaces/form-control-options.interface';
import { FormControlType } from '../../enums/form-control-type.enum';


export class FormControlPassword extends FormControlBase<string> {
  formControlType: FormControlType = FormControlType.INPUT;
  inputType = 'password';
  minLength = 1;
  maxLength = 256;

  constructor(options: FormControlOptions<string>) {
    super(options);
    this.minLength = options['minLength'] || this.minLength;
    this.maxLength = options['maxLength'] || this.maxLength;
  }
}
