import { ModelHelper } from "./model.helper.interface";
import Utils from "../../utils/utils";
import { FormControlString } from "../form-control/form-control-string.model";
import { FormControlSelect } from "../form-control/form-control-select.model";
import { AwsModel } from "../aws.model";
import { AzureModel } from "../azure.model";
import { SyncServerConfigModel } from "../sync-server-config.model";
import { SyncDataServerModel } from "../sync-dataserver.model";
import { FormControlNumber } from "../form-control/form-control-number.model";

export class SyncServerConfigModelHelper implements ModelHelper<SyncServerConfigModel> {

  constructor() {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json.id);
  }

  emptyModel(): SyncServerConfigModel {
    return new SyncServerConfigModel(
      '',
      new FormControlString({
        key: 'tmpDirectory',
        label: 'label.config.field.tmp-directory',
        required: true
      }),
      new FormControlSelect({
        key: 'filesLocation',
        label: 'label.config.field.file-location',
        options: ['local', 'aws', 'azure'].map(entry => {
          return {label: entry, value: entry}
        }),
        required: true
      }),
      [],
      new FormControlString({
        key: 'autUrl',
        label: 'label.config.field.aut-url',
        required: true
      }),
      new FormControlNumber({
        key: 'maxZipSize',
        label: 'label.config.sync.field.max-zip-size',
        required: true
      }),
      new FormControlNumber({
        key: 'numberOfKeptBuildPackages',
        label: 'label.config.sync.field.number-kept-builds',
        required: true
      }),
      new FormControlString({
        key: 'dbName',
        label: 'label.config.sync.field.db-name',
        required: true
      }),
      new FormControlNumber({
        key: 'buildTimeout',
        label: 'label.config.sync.field.build-timeout',
        required: true
      }),
      new FormControlString({
        key: 'schedulerCronJob',
        label: 'label.config.sync.field.schedule-job',
        required: true
      }),
      new AwsModel(),
      new AzureModel(),
      new FormControlString({
        key: 'localPath',
        label: 'label.config.field.content-folder',
        required: false
      }),
      new FormControlString({
        key: 'clientId',
        label: 'label.config.sync.field.client-id',
        required: true
      }),
      new FormControlString({
        key: 'clientSecret',
        label: 'label.config.sync.field.client-secret',
        required: true
      }),
      []
    )
  }

  fromJSON(json: any, skipCheck = false): SyncServerConfigModel {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    const model = new SyncServerConfigModel(
      json.id,
      new FormControlString({
        key: 'tmpDirectory',
        label: 'label.server-config.tmp.directory',
        required: true,
        value: json.tmpDirectory
      }),
      new FormControlSelect({
        key: 'filesLocation',
        label: 'label.config.field.file-location',
        options: ['local', 'aws', 'azure'].map(entry => {
          return {label: entry, value: entry}
        }),
        value: json.filesLocation
      }),
      json.languages,
      new FormControlString({
        key: 'autUrl',
        label: 'label.config.field.aut-url',
        required: true,
        value: json.authUrl
      }),
      new FormControlNumber({
        key: 'maxZipSize',
        label: 'label.config.sync.field.max-zip-size',
        value: json.maxZipSize,
        required: true
      }),
      new FormControlNumber({
        key: 'numberOfKeptBuildPackages',
        label: 'label.config.sync.field.max-zip-size',
        value: json.numberOfKeptBuildPackages,
        required: true
      }),
      new FormControlString({
        key: 'dbName',
        label: 'label.config.sync.field.db-name',
        value: json.dbName,
        required: true
      }),
      new FormControlNumber({
        key: 'buildTimeout',
        label: 'label.config.sync.field.build-timeout',
        value: json.buildTimeout,
        required: true
      }),
      new FormControlString({
        key: 'schedulerCronJob',
        label: 'label.config.sync.field.schedule-job',
        value: json.schedulerCronJob,
        required: true
      }),
      json.aws ? AwsModel.fromJSON(json.aws) : undefined,
      json.azure ? AzureModel.fromJSON(json.azure) : undefined,
      new FormControlString({
        key: 'localPath',
        label: 'label.config.field.content-folder',
        value: json.localPath
      }),
      new FormControlString({
        key: 'clientId',
        label: 'label.config.sync.field.client-id',
        required: true,
        value: json.clientId
      }),
      new FormControlString({
        key: 'clientSecret',
        label: 'label.config.sync.field.client-secret',
        required: true,
        value: json.clientSecret
      }),
      json.dataServers ? json.dataServers.map(ds => SyncDataServerModel.fromJSON(ds)) : [],
      json.version,
      json.created_on,
      json.creatorname,
      json.lastmodified,
      json.modifiedby,
    );

    return model;
  }
}
