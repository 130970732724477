import { GameControlFlowCategory } from '../../../game/enum/game-control-flow-category.enum';
import { GameSolutionCategory } from '../../../game/enum/game-solution-category.enum';
import { GameSolutionTypeUtils } from '../../utils/game-solution-type-utils';
import Utils from '../../utils/utils';
import { FormControlNumber } from '../form-control/form-control-number.model';
import { FormControlSelect } from '../form-control/form-control-select.model';
import { GameSolution } from '../game-solution.model';
import { GameUtilHelper } from './game-util-helper';
import { MultiLangStringHelper } from './multi-lang-string.helper';

export class GameSolutionModelHelper {

  constructor(
    private readonly multiLangStringHelper: MultiLangStringHelper,
    private readonly solutionTypeUtils: GameSolutionTypeUtils,
    private readonly gameUtilHelper: GameUtilHelper
  ) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json.id);
  }

  emptyModel(solutionTypes: number[]): GameSolution {
    const filteredTypes = this.solutionTypeUtils.getTypeSelectOptions().filter(solutionType => {
      return solutionTypes.indexOf(solutionType.value) !== -1;
    });

    const type = solutionTypes[0];
    const config = this.solutionTypeUtils.getTypeConfig(type);

    return new GameSolution(
      '',
      this.multiLangStringHelper.fromJSON(
        {},
        'title',
        this.solutionTypeUtils.getFieldOptions(this.solutionTypeUtils.getDefaultType(), 'title', {
          label: 'label.game.solution.field.title',
          hidden: false
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'description',
        this.solutionTypeUtils.getFieldOptions(this.solutionTypeUtils.getDefaultType(), 'description', {
          label: 'label.game.solution.field.description',
          hidden: false
        })
      ),
      new FormControlSelect({
        value: type,
        key: 'type',
        label: 'label.general.type',
        options: filteredTypes,
        required: true,
        hidden: false
      }),
      GameSolutionCategory[ this.solutionTypeUtils.getCategoryOfType(type).toUpperCase() ],
      new FormControlNumber(this.solutionTypeUtils.getFieldOptions(this.solutionTypeUtils.getDefaultType(), 'score', {
          label: 'Score',
          hidden: false
        })
      ),
      this.gameUtilHelper.createDictionary(config, 'parameters'),
      this.gameUtilHelper.createDictionary(config, 'textDictionary'),
      this.gameUtilHelper.createDictionary(config, 'assetDictionary')
    );
  }

  fromJSON(json: any, skipCheck = false): GameSolution {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    const solutionType = Number(json.type);
    const config = this.solutionTypeUtils.getTypeConfig(solutionType);

    // create solution object
    const solution = new GameSolution(
      json.id,
      this.multiLangStringHelper.fromJSON(
        json.title,
        'title',
        this.solutionTypeUtils.getFieldOptions(this.solutionTypeUtils.getDefaultType(), 'title', {
          label: 'label.game.solution.field.title',
          hidden: false
        }),
        json.title ? Object.keys(json.title) : []
      ),
      this.multiLangStringHelper.fromJSON(
        json.description,
        'description',
        this.solutionTypeUtils.getFieldOptions(this.solutionTypeUtils.getDefaultType(), 'description', {
          label: 'label.game.solution.field.description',
          hidden: false
        }),
        json.description ? Object.keys(json.description) : []
      ),
      new FormControlSelect({
        value: solutionType,
        key: 'type',
        label: 'label.general.type',
        options: this.solutionTypeUtils.getTypeSelectOptions(),
        required: true,
        hidden: false
      }),
      json.category,
      new FormControlNumber(this.solutionTypeUtils.getFieldOptions(solutionType, 'score', {
        value: json.score,
        label: 'Score',
        hidden: false
      })),
      this.gameUtilHelper.mapParameters(json.parameters, config),
      this.gameUtilHelper.mapMultiLangDictEntries(json.textDictionary, config),
      this.gameUtilHelper.mapAssetLangDictEntries(json.assetDictionary, config),
    );

    return solution;
  }
}
