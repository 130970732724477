import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { BaseComponent } from '../../shared/components/base/base.component';


@Component({
  selector: 'app-login-status',
  template: `
    <span class="login-status" *ngIf="authService.isLoggedIn()">
      <app-username [username]="authService.getAccessToken().username"
                    [userId]="authService.getAccessToken().userId"></app-username>
        | <a class="text-white" i18n="@@logout" routerLink="/logout">Logout</a>
    </span>
  `
})
export class LoginStatusComponent extends BaseComponent implements OnInit {
  //(<small title="Screen Lock in..."><i class="fa fa-clock-o"></i> {{authService.getAccessToken().expiresIn}}</small>)
  constructor(public authService: AuthService) {
    super();
  }

  ngOnInit() {
  }
}
