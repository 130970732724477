import { Permission } from './permission.model';
import { BaseModel } from "./base.model";
import { FormControlString } from "./form-control/form-control-string.model";

export class Role extends BaseModel {

  constructor(
    public id: string,
    public name: FormControlString,
    public permissions: Permission[],
    public tenantId: string,
    public blockedUsers: string[],
    public version?: number,
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string,
  ) {
    super(id, blockedUsers, createdOn, creatorName, lastModified, modifiedBy, version);
  }

  toJSON(): object {
    const json = super.toJSON();

    json['id'] = this.id;
    json['name'] = this.name ? this.name.value : undefined;
    json['tenantId'] = this.tenantId;
    json['permissions'] = this.permissions ? this.permissions.map(p => p.toJSON()) : [];

    return json;
  }
}
