import * as moment from 'moment';
import Utils from '../utils/utils';
import { BaseModel } from './base.model';
import { Dictionary } from './dictionary';
import { ElementRef } from './element-ref';
import { File } from './file.model';
import { FormControlSelect } from './form-control/form-control-select.model';
import { MultiLangString } from './multi-lang-string.model';
import { PropertyDictionaryEntry } from './property-dictionary-entry';

export class UserContent extends BaseModel {

  constructor(
    public id: string,
    public title: MultiLangString,
    public description: MultiLangString,
    public status: FormControlSelect,
    public type: FormControlSelect,
    public channelIds: string[],
    public elementRef: ElementRef,
    public properties: Dictionary<PropertyDictionaryEntry>,
    public files: File[],
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string,
    public version?: number
  ) {
    super(id, [], createdOn, creatorName, lastModified, modifiedBy, version);
  }

  toJSON(): object {
    const json = {
    };

    json[ 'createdOn' ] = Utils.isValidId(this.id) ? moment(this.createdOn).valueOf() : moment().valueOf();
    json[ 'creatorname' ] = this.creatorName;
    json[ 'lastModified' ] = moment().valueOf();
    json[ 'modifiedBy' ] = this.modifiedBy;
    json[ 'version' ] = this.version;
    json[ 'id' ] = this.id;
    json[ 'title' ] = this.title.toJSON();
    json[ 'description' ] = this.description.toJSON();
    json[ 'status' ] = this.status.value;
    json[ 'type' ] = this.type.value;
    json[ 'channelIds' ] = this.channelIds;
    json[ 'elementRef' ] = this.elementRef;
    json[ 'properties' ] = this.properties;
    json[ 'files' ] = this.files.map(file => file.toJSON());

    return json;
  }
}
