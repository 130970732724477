export enum GameElementCategory {
  IMAGE_QUESTION = 'image_question',
  TEXT_QUESTION = 'text_question',
  CHAT_TEXT = 'chat_text',
  CHAT_TEXT_CHOICE = 'chat_text_choice',
  LEAP_TEXT = 'leap_text',
  SEARCH_IMAGE_ELEMENT = 'search_image_element',
}

export const selectionElementCategories = [
  GameElementCategory.IMAGE_QUESTION,
  GameElementCategory.TEXT_QUESTION,
  GameElementCategory.CHAT_TEXT_CHOICE
];
