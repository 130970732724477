import { PropertyValueType } from '../enums/property-value-type.enum';

export class PropertyDictionaryEntry {

  constructor(
    public value: any,
    public type: PropertyValueType,
    public invalid: boolean
  ) {
  }
}
