import { FileType } from '../enums/file-type.enum';
import { BaseFilesModel } from './base-files.model';
import { File } from './file.model';

export class GameAssetDictionaryEntry extends BaseFilesModel {

  constructor(
    public id: string,
    public key: string,
    public files: File[],
    public type: FileType,
    public invalid?: boolean,
    public version?: number,
    public created_on?: string,
    public creatorname?: string,
    public lastmodified?: string,
    public modifiedby?: string,
  ) {
    super(id, []);
  }

  toJSON(): any {
    const json = super.toJSON();

    json[ 'id' ] = this.id;
    json[ 'key' ] = this.key;
    json[ 'invalid' ] = this.invalid;
    json[ 'version' ] = this.version;
    json[ 'created_on' ] = this.created_on;
    json[ 'creatorname' ] = this.creatorname;
    json[ 'lastmodified' ] = this.lastmodified;
    json[ 'modifiedby' ] = this.modifiedby;
    // TODO adapt Game asset mapping
    json[ 'files' ] = this.files.map(file => {file.locale = undefined; return file.toJSON()});

    return json;
  }
}
