import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GameElement } from '../../../models/game-element.model';
import { Status } from '../../../models/status.model';
import { GameElementTypeUtils } from '../../../utils/game-element-type-utils';

@Component({
  selector: 'app-game-element-list-modal',
  templateUrl: './game-element-list-modal.component.html'
})
export class GameElementListModalComponent {

  @Input() elements: GameElement[];
  @Input() elementTypes: number[];
  @Input() status: Status;

  selection: GameElement[] = [];

  constructor(
    private elementTypeUtils: GameElementTypeUtils,
    public activeModal: NgbActiveModal
  ) {
    if (!this.elementTypes) {
      this.elementTypes = this.elementTypeUtils.getTypeValues();
    }
  }
}
