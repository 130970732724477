import { FormControlOptions } from '../../../../app/shared/interfaces/form-control-options.interface';
import { FormControlType } from '../../enums/form-control-type.enum';


export abstract class FormControlBase<T>{
  value: T;
  key: string;
  label: string;
  required: boolean;
  min: number;
  max: number;
  minLength: number;
  maxLength: number;
  pattern: string;
  formControlType: FormControlType;
  disabled: boolean;
  hidden: boolean;
  apiParam?: string;

  constructor(options: FormControlOptions<T>) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.min = options.min;
    this.max = options.max;
    this.minLength = options.minLength;
    this.maxLength = options.maxLength;
    this.pattern = options.pattern;
    this.formControlType = options.formControlType;
    this.disabled = options.disabled;
    this.hidden = options.hidden;
    this.apiParam = options.apiParam;
  }
}
