import * as moment from 'moment';
import { GameLevelTypeUtils } from '../../utils/game-level-type-utils';
import Utils from '../../utils/utils';
import { FormControlNumber } from '../form-control/form-control-number.model';
import { FormControlSelect } from '../form-control/form-control-select.model';
import { GameLevel } from '../game-level.model';
import { GameControlFlowModelHelper } from './game-control-flow-model.helper';
import { GameElementModelHelper } from './game-element-model.helper';
import { GameResultModelHelper } from './game-result-model.helper';
import { GameUtilHelper } from './game-util-helper';
import { ModelHelper } from './model.helper.interface';
import { MultiLangStringHelper } from './multi-lang-string.helper';

export class GameLevelModelHelper implements ModelHelper<GameLevel> {

  constructor(
    private readonly elementHelper: GameElementModelHelper,
    private readonly resultHelper: GameResultModelHelper,
    private readonly multiLangStringHelper: MultiLangStringHelper,
    private readonly controlFlowHelper: GameControlFlowModelHelper,
    private readonly levelTypeUtils: GameLevelTypeUtils,
    private readonly gameUtilHelper: GameUtilHelper
  ) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json.id);
  }

  emptyModel(): GameLevel {
    return new GameLevel(
      '',
      this.multiLangStringHelper.fromJSON(
        {},
        'title',
        this.levelTypeUtils.getFieldOptions(this.levelTypeUtils.getDefaultType(), 'title', {
          label: 'label.game.level.field.title',
          hidden: false
        })
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'description',
        this.levelTypeUtils.getFieldOptions(this.levelTypeUtils.getDefaultType(), 'description', {
          label: 'label.game.level.field.description',
          hidden: false
        })
      ),
      new FormControlSelect({
        value: this.levelTypeUtils.getDefaultType(),
        key: 'type',
        label: 'label.general.type',
        options: this.levelTypeUtils.getTypeSelectOptions(),
        required: true,
        hidden: false
      }),
      undefined,
      undefined,
      [],
      [],
      [],
      [],
      [],
      [],
      []
    );
  }

  fromJSON(json: any, skipCheck = false): GameLevel {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    const levelType = Number(json.type);
    const config = this.levelTypeUtils.getTypeConfig(levelType);

    // create level object
    const level = new GameLevel(
      json.id,
      this.multiLangStringHelper.fromJSON(
        json.title,
        'title',
        this.levelTypeUtils.getFieldOptions(this.levelTypeUtils.getDefaultType(), 'title', {
          label: 'label.game.level.field.title',
          hidden: false
        }),
        json.title ? Object.keys(json.title) : []
      ),
      this.multiLangStringHelper.fromJSON(
        json.description,
        'description',
        this.levelTypeUtils.getFieldOptions(this.levelTypeUtils.getDefaultType(), 'description', {
          label: 'label.game.level.field.description',
          hidden: false
        }),
        json.description ? Object.keys(json.description) : []
      ),
      new FormControlSelect({
        value: levelType,
        key: 'type',
        label: 'label.general.type',
        options: this.levelTypeUtils.getTypeSelectOptions(),
        required: true,
        hidden: false
      }),
      json.category,
      json.sortOrder,
      json.elements || [],
      json.results ? json.results
        .filter(result => this.resultHelper.checkJSON(result))
        .map(result => this.resultHelper.fromJSON(result)) : [],
      this.gameUtilHelper.mapParameters(json.parameters, config),
      this.gameUtilHelper.mapMultiLangDictEntries(json.textDictionary, config),
      this.gameUtilHelper.mapAssetLangDictEntries(json.assetDictionary, config),
      json.controlFlows.map(d => this.controlFlowHelper.fromJSON(d)),
      json.blocked_users || [],
      json.created_on ? moment(json.created_on).toDate() : undefined,
      json.creatorname,
      json.lastmodified ? moment(json.lastmodified).toDate() : undefined,
      json.modifiedby,
      json.version
    );

    return level;
  }
}
