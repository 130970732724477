import { ModelHelper } from "./model.helper.interface";
import Utils from "../../utils/utils";
import { FileModelHelper } from "./file-model.helper";
import { HtmlBaseInformation } from "../html-base-information.model";
import { FormControlBoolean } from "../form-control/form-control-boolean.model";
import { FileType } from "../../enums/file-type.enum";
import * as moment from "moment";
import { FormControlType } from "../../enums/form-control-type.enum";
import { MultiLangStringHelper } from "./multi-lang-string.helper";

export class HtmlBaseModelHelper implements ModelHelper<HtmlBaseInformation> {

  constructor(
    private fileHelper: FileModelHelper,
    private multiLangStringHelper: MultiLangStringHelper
  ) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json._id);
  }

  emptyModel(): HtmlBaseInformation {
    return new HtmlBaseInformation(
      '',
      new FormControlBoolean({
        label: 'label.general.active',
        value: false
      }),
      this.multiLangStringHelper.fromJSON(
        {},
        'title',
        {label: 'label.html.field.title', apiParam: 'title', formControlType: FormControlType.INPUT}
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'subtitle',
        {label: 'label.html.field.subtitle', apiParam: 'subtitle', formControlType: FormControlType.INPUT}
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'menulabel',
        {label: 'label.html.field.menu-label', apiParam: 'menu_label', formControlType: FormControlType.INPUT}
      ),
      this.multiLangStringHelper.fromJSON(
        {},
        'languagelabel',
        {label: 'label.html.field.language-label', apiParam: 'language_label', formControlType: FormControlType.INPUT}
      ),
      []);
  }

  fromJSON(json: any, skipCheck = false): HtmlBaseInformation {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    return new HtmlBaseInformation(
      json._id,
      new FormControlBoolean({
        label: 'label.general.active',
        value: !!json.active
      }),
      this.multiLangStringHelper.fromJSON(
        json.title,
        'title',
        {
          apiParam: 'label.html.field.title',
          label: 'Title'
        },
        json.title ? Object.keys(json.title) : []
      ),
      this.multiLangStringHelper.fromJSON(
        json.subtitle,
        'subtitle',
        {
          apiParam: 'subtitle',
          label: 'label.html.field.subtitle'
        },
        json.title ? Object.keys(json.title) : []
      ),
      this.multiLangStringHelper.fromJSON(
        json.menu_label,
        'menulabel',
        {
          apiParam: 'menu_label',
          label: 'label.html.field.menu-label'
        },
        json.title ? Object.keys(json.title) : []
      ),
      this.multiLangStringHelper.fromJSON(
        json.language_label,
        'languagelabel',
        {
          apiParam: 'language_label',
          label: 'label.html.field.language-label'
        },
        json.title ? Object.keys(json.title) : []
      ),
      json.blocked_users,
      json.logo ? this.fileHelper.fromJSON(json.logo, FileType.IMAGE) : undefined,
      json.version,
      json.created_on ? moment(json.created_on).toDate() : undefined,
      json.creatorname,
      json.lastmodified ? moment(json.lastmodified).toDate() : undefined,
      json.modifiedby
    );
  }
}
