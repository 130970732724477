import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Map } from '../../../../../app/shared/models/map.model';
import { Status } from '../../../../../app/shared/models/status.model';


@Component({
  selector: 'app-map-list-modal',
  templateUrl: './map-list-modal.component.html'
})
export class MapListModalComponent {

  @Input() maps: Map[];
  @Input() status: Status;

  selection: Map[] = [];

  constructor(
    public activeModal: NgbActiveModal
  ) { }
}
