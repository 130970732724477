import Utils from '../../../app/shared/utils/utils';
import { BaseFilesModel } from './base-files.model';
import { VersionModel } from './base.model';
import { Channel } from './channel.model';
import { Content } from './content.model';
import { File } from './file.model';
import { FormControlBoolean } from './form-control/form-control-boolean.model';
import { FormControlNumber } from './form-control/form-control-number.model';
import { FormControlSelect } from './form-control/form-control-select.model';
import { FormControlString } from './form-control/form-control-string.model';
import { Game } from './game.model';
import { Hotspot } from './hotspot.model';
import { Map } from './map.model';
import { MultiLangString } from './multi-lang-string.model';

export class Station extends BaseFilesModel {

  constructor(
    public id: string,
    public active: FormControlBoolean,
    public type: FormControlSelect,
    public code: FormControlNumber,
    public title: MultiLangString,
    public shortText: MultiLangString,
    public description: MultiLangString,
    public number: FormControlNumber,
    public inventoryNumber: FormControlString,
    public copyright: MultiLangString,
    public creatorBirth: FormControlString,
    public creatorDeath: FormControlString,
    public creationDate: FormControlString,
    public mapId: string,
    public mapX: number,
    public mapY: number,
    public lat: FormControlNumber,
    public lng: FormControlNumber,
    public threeDObjects: string[],
    public locationText: MultiLangString,
    public fieldgen: FormControlString,
    public fieldgen1: MultiLangString,
    public fieldgen2: MultiLangString,
    public fieldgen3: MultiLangString,
    public fieldgen4: MultiLangString,
    public fieldgen5: MultiLangString,
    public fieldgen6: MultiLangString,
    public flaggen: FormControlBoolean,
    public flaggen2: FormControlBoolean,
    public flaggen3: FormControlBoolean,
    public flaggen4: FormControlBoolean,
    public flaggen5: FormControlBoolean,
    public flaggen6: FormControlBoolean,
    public choice1: FormControlSelect,
    public choice2: FormControlSelect,
    public choice3: FormControlSelect,
    public choice4: FormControlSelect,
    public subStations: Station[],
    public subStationRefs: string[],
    public contents: Content[],
    public contentRefs: string[],
    public channels: Channel[],
    public channelRefs: string[],
    public maps: Map[],
    public files: File[],
    public filesToDelete: File[],
    public hotspots: Hotspot[],
    public gameRefs: string[] = [],
    public blockedUsers: string[],
    public isReferred?: boolean,
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string,
    public version?: number,
    public versions?: VersionModel[],
    public baseId?: string,
  ) {
    super(id, blockedUsers, createdOn, creatorName, lastModified, modifiedBy, version, versions, baseId, files, filesToDelete);
  }

  toJSON(): object {
    const json = super.toJSON();

    json[ 'active' ] = this.active.value;
    json[ 'type' ] = this.type.value;
    json[ 'code' ] = this.code.value;
    json[ 'title' ] = this.title.toJSON();
    json[ 'textshort' ] = this.shortText.toJSON();
    json[ 'textlong' ] = this.description.toJSON();
    json[ 'number' ] = this.number.value ? this.number.value.toString() : undefined;
    json[ 'invnumber' ] = this.inventoryNumber.value || '';
    json[ 'copyrighttext' ] = this.copyright.toJSON();
    json[ 'creatorbirth' ] = this.creatorBirth.value;
    json[ 'creatordeath' ] = this.creatorDeath.value;
    json[ 'creationdate' ] = this.creationDate.value;

    json[ 'map_ref' ] = this.mapId;

    if (Utils.isDefined(this.mapX) && Utils.isDefined(this.mapY)) {
      json[ 'mapPosition' ] = [ this.mapX, this.mapY ];
    }

    json[ 'geolat' ] = Utils.isDefined(this.lat.value) ? Number(this.lat.value) : undefined;
    json[ 'geolong' ] = Utils.isDefined(this.lng.value) ? Number(this.lng.value) : undefined;
    json[ 'threeDObjects' ] = this.threeDObjects;
    json[ 'locationText' ] = this.locationText.toJSON();
    json[ 'fieldgen' ] = this.fieldgen.value;
    json[ 'fieldgen1' ] = this.fieldgen1.toJSON();
    json[ 'fieldgen2' ] = this.fieldgen2.toJSON();
    json[ 'fieldgen3' ] = this.fieldgen3.toJSON();
    json[ 'fieldgen4' ] = this.fieldgen4.toJSON();
    json[ 'fieldgen5' ] = this.fieldgen5.toJSON();
    json[ 'fieldgen6' ] = this.fieldgen6.toJSON();
    json[ 'flaggen' ] = this.flaggen.value;
    json[ 'flaggen2' ] = this.flaggen2.value;
    json[ 'flaggen3' ] = this.flaggen3.value;
    json[ 'flaggen4' ] = this.flaggen4.value;
    json[ 'flaggen5' ] = this.flaggen5.value;
    json[ 'flaggen6' ] = this.flaggen6.value;
    json[ 'choice1' ] = this.choice1.value;
    json[ 'choice2' ] = this.choice2.value;
    json[ 'choice3' ] = this.choice3.value;
    json[ 'choice4' ] = this.choice4.value;

    // Fill ref objects based on current order
    json[ 'substation_order' ] = Utils.getIds(this.subStations);
    json[ 'content_refs' ] = Utils.getIds(this.contents);
    json[ 'channel_refs' ] = Utils.getIds(this.channels);
    json[ 'game_refs' ] = this.gameRefs;

    return json;
  }

  deleteSubStation(station: Station): void {
    Utils.removeObjectFromArray(this.subStations, station);
    this.subStations = [ ...this.subStations ];
    this.setChanged(true);
  }

  addContent(newContent: Content): void {
    this.contents = [ ...this.contents.filter(content => content.id !== newContent.id), newContent ];
    this.setChanged(true);
  }

  deleteContent(content: Content): void {
    Utils.removeObjectFromArray(this.contents, content);
    this.contents = [ ...this.contents ];
    this.setChanged(true);
  }

  addChannel(newChannel: Channel): void {
    this.channels = [ ...this.channels.filter(channel => channel.id !== newChannel.id), newChannel ];
    this.setChanged(true);
  }

  deleteChannel(channel: Channel): void {
    Utils.removeObjectFromArray(this.channels, channel);
    this.channels = [ ...this.channels ];
    this.setChanged(true);
  }

  addGame(newGame: Game): void {
    // this.games = [ ...this.games.filter(game => game.id !== newGame.id), newGame ];
    this.gameRefs = [ ...this.gameRefs.filter(id => id !== newGame.id), newGame.id ];
    this.setChanged(true);
  }

  deleteGame(game: Game): void {
    // Utils.removeObjectFromArray(this.games, game);
    // this.games = [ ...this.games ];
    this.gameRefs = this.gameRefs.filter(id => id !== game.id);
    this.setChanged(true);
  }

  hasMap() {
    return this.maps[ 0 ] && this.maps[ 0 ].files[ 0 ];
  }

  setMap(map?: Map) {
    this.mapId = map ? map.id : '';
    this.maps = map ? [ map ] : [];
    this.setMapCoordinates(0, 0);
    this.setChanged(true);
  }

  setMapCoordinates(x: number, y: number): void {
    this.mapX = x;
    this.mapY = y;
    this.setChanged(true);
  }

  setGeoLocation(lat: number, lng: number): void {
    this.lat.value = Number(lat);
    this.lng.value = Number(lng);
    this.setChanged(true);
  }

  clearGeoLocation(): void {
    this.lat.value = undefined;
    this.lng.value = undefined;
    this.setChanged(true);
  }
}
