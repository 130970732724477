import { FormControlString } from "./form-control/form-control-string.model";
import { FormControlSelect } from "./form-control/form-control-select.model";

export class SyncDataServerModel {

  constructor(
    public type: FormControlSelect = new FormControlSelect({
      key: 'type',
      label: 'label.config.sync.field.dataserver-type',
      required: true,
      options: [{label: 'CMS_DATA', value: 'CMS_DATA'}, {label: 'CMS_GAME', value: 'CMS_GAME'}]
    }),
    public url: FormControlString = new FormControlString({
      key: 'url',
      label: 'label.config.sync.field.dataserver-url',
      required: true
    })
  ) {
  }

  static checkJSON(json: any): boolean {
    return json;
  }

  static fromJSON(json): SyncDataServerModel {
    return new SyncDataServerModel(
      new FormControlSelect({
        value: json.name,
        key: 'type',
        label: 'label.config.sync.field.dataserver-type',
        required: true,
        options: [{label: 'CMS_DATA', value: 'CMS_DATA'}, {label: 'CMS_GAME', value: 'CMS_GAME'}]
      }),
      new FormControlString({
        key: 'url',
        label: 'label.config.sync.field.dataserver-url',
        required: true,
        value: json.url
      })
    );
  }

  toJSON() {
    const json = {
      name: this.type.value,
      url: this.url.value
    };

    return json;
  }

}
