import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable } from 'rxjs';
import { AppConfig } from '../../app.config';

import { UserService } from '../../core/services/user.service';
import { GameElement } from '../models/game-element.model';

import { GameSolution } from '../models/game-solution.model';
import { Status } from '../models/status.model';

import { MessageModalComponent } from '../../core/components/message-modal/message-modal.component';
import { GameSolutionCreateModalComponent } from '../components/modals/game-solution-create-modal/game-solution-create-modal.component';
import { GameSolutionListModalComponent } from '../components/modals/game-solution-list-modal/game-solution-list-modal.component';

import { ApiBaseService } from '../../core/services/api-base.service';
import Utils from '../utils/utils';
import { FileService } from './file.service';

import { CreateOptions } from '../interfaces/create-options.interface';
import { ModelMapperService } from './mapper/model-mapper.service';

@Injectable()
export class GameSolutionService extends ApiBaseService<GameSolution> {

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected userService: UserService,
    protected fileService: FileService,
    protected modalService: NgbModal,
    private modelMapper: ModelMapperService
  ) {
    super(GameSolution, 'solutions', http, router, userService, fileService);

    this.url = AppConfig.GAME_SERVER + AppConfig.GAME_BASE_URL;
  }

  select(exclude: GameSolution[] = [], solutionTypes?: number[]): Promise<GameSolution[]> {
    const modalRef = this.modalService.open(GameSolutionListModalComponent, { size: 'lg' });
    modalRef.componentInstance.status = new Status();

    this.getAll(modalRef.componentInstance.status)
      .subscribe((solutions: GameSolution[]) => {
        try {
          modalRef.componentInstance.solutions = solutions
            .filter(solution => exclude.findIndex(mySolution => solution.id === mySolution.id) < 0);

          if (solutionTypes && solutionTypes.length > 0) {
            modalRef.componentInstance.solutionTypes = solutionTypes;
          }
        } catch (e) {
        }
      });

    return modalRef.result;
  }

  create(options: CreateOptions<GameSolution>, solutionTypes?: number[], gameTypes?: number[]): Promise<GameSolution> {
    return super.create(Object.assign(options, {
      component: GameSolutionCreateModalComponent,
      componentOptions: {
        solution: this.emptyModel(),
        solutionTypes,
        gameTypes
      }
    }));
  }

  getElements(solutionId: string, status?: Status): Observable<GameElement[]> {
    status = status || new Status();
    status.setLoading();

    const url = `${this.url}/${solutionId}/elements`;
    const responseKey = '_embedded.elements';

    return this.http
      .get(url).pipe(
        map(response => {
          if (Utils.deepAccessUsingString(response, responseKey) && Utils.deepAccessUsingString(response, responseKey).length > 0) {
            status.setSuccess();
            return Utils.deepAccessUsingString(response, responseKey)
              .filter(object => this.modelMapper.gameElementCheckJSON(object))
              .map(object => this.modelMapper.gameElementFromJSON(object));
          } else {
            status.setNoResults();
          }
        }),
        catchError(err => this.handleError(err, status)));
  }

  delete(id: string, status?: Status): Promise<any> {
    const modalRef = this.modalService.open(MessageModalComponent);
    modalRef.componentInstance.confirm = true;
    modalRef.componentInstance.message = 'Are you sure that you want to delete the solution?';

    return modalRef.result.then(() => super.delete(id, status), () => Promise.reject('Dimissed'));
  }

  checkJSON(json: any): boolean {
    return this.modelMapper.solutionCheckJSON(json);
  }

  emptyModel(): GameSolution {
    return this.modelMapper.solutionEmptyModel();
  }

  fromJSON(json: any, skipCheck?: boolean): GameSolution {
    return this.modelMapper.solutionFromJSON(json, skipCheck);
  }
}
