import { tap } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { MessageModalComponent } from '../components/message-modal/message-modal.component';

import { AppConfig } from '../../app.config';
import { ConfigurationService } from "../../shared/services/configuration.service";


@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private authService: AuthService;
  private modalService: NgbModal;

  private modalRef;

  constructor(
    private injector: Injector,
    private router: Router,
    private config: ConfigurationService
  ) {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.authService = this.injector.get(AuthService);
    this.modalService = this.injector.get(NgbModal);

    const accessToken = this.authService.getAccessToken();

    if (accessToken && !request.url.includes('/oauth/token')) {
      const channel = request.headers.get('channel') || AppConfig.NC_CHANNEL_HEADER;
      if(request.headers['channel']) {
        delete request.headers['channel'];
      }


      request = request.clone({
        setHeaders: {
          'nc-authentication': `Bearer ${accessToken.accessToken}`,
          'nc-channel': channel,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        }
      });
    }

    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      // if (event instanceof HttpResponse) {
      //   if (accessToken && !event.url.includes('/oauth/token')) {
          // TODO clarify if refresh token with ervery call is necessary
          // this.authService.refresh().subscribe(() => {
            // nothing to do
          // });
        // }
      // }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {

        if (err.status === 400 && err.error && err.error.msg && !this.modalRef &&
          (typeof err.error.msg === 'string' || Object.keys(err.error.msg).length !== 0)) {
          this.modalRef = this.modalService.open(MessageModalComponent);
          this.modalRef.componentInstance.title = 'Error';
          this.modalRef.componentInstance.message = err.error.msg;

          this.modalRef.result.then(() => this.modalRef = null, () => this.modalRef = null);

        } else if (accessToken && err.status === 401) {


          const navigateToLogin: any = () => this.router.navigate(['/login']);

          if (err.url.includes('/oauth/token')) {
            navigateToLogin();
          }

          this.authService.refresh().subscribe(() => {
            if (this.authService.isLoggedIn()) {
              if (err.url.indexOf(this.authService.getAuthUrl()) === -1 && !this.authService.modalRef) {
                this.config.getAuthenticationConfig().resumeWorkEnabled ? this.authService.enterPassword() : navigateToLogin();
              }
            } else {
              navigateToLogin();
            }
          });
        } else if (err.status === 403 && this.authService.isLoggedIn()) {
          this.router.navigate(['/accessdenied']);
        }
      }
    }));
  }
}
