import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from "../../models/user.model";
import { BaseModel } from "../../models/base.model";
import { FormControlSelect } from "../../models/form-control/form-control-select.model";
import { FormControlSelectOption } from "../../interfaces/form-control-select-options.interface";


@Component({
  selector: 'app-tour-create-modal',
  templateUrl: './blocked-user-modal.component.html'
})
export class BlockedUserModalComponent implements OnInit {

  @Input() userList: User[];
  @Input() model: BaseModel;

  form = new FormGroup({});
  userSelectionControl: FormControlSelect;

  constructor(public activeModal: NgbActiveModal) {
  }

  submit(): void {
    this.activeModal.close(this.userSelectionControl.value || []);
  }

  ngOnInit() {
    this.userSelectionControl = new FormControlSelect({multiple: true});
    if(this.userList) {
      this.userSelectionControl.options = this.userList.map(u => {
        return {label: u.username.value, value: u.id} as FormControlSelectOption<string>;
      });
    }

    if (this.model && this.model.blockedUsers) {
      this.userSelectionControl.value = this.model.blockedUsers;
    }
  }
}
