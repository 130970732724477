import Utils from '../utils/utils';

export class MultiLangFile {

  public readonly defaultLocale: string;

  constructor(locales: object = {}, defaultLocale: string) {
    Object.keys(locales).forEach((locale) => {
      this[locale] = locales[locale];
    });

    this.defaultLocale = defaultLocale;
  }

  toJSON(): object {
    const json = {};

    for (const locale of Object.keys(this)) {
      if (this[locale] && Utils.isDefined(this[locale]) && Utils.isValidLocale(locale)) {
        json[locale] = this[locale] ? this[locale].toJSON() : undefined;
      }
    }

    return Object.keys(json).length > 0 ? json : '';
  }

  getDefault(): string {
    return this[this.defaultLocale];
  }

  getLocales(): string[] {
    return Object.keys(this).filter(key => Utils.isValidLocale(key)).sort();
  }
}
