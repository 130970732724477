import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { TableBaseComponent } from '../table-base/table-base.component';

import { Channel } from '../../../models/channel.model';
import { ConfigurationService } from "../../../services/configuration.service";
import { UserService } from "../../../../core/services/user.service";
import { SyncService } from "../../../../core/services/sync.service";


@Component({
  selector: 'app-channel-table',
  templateUrl: './channel-table.component.html'
})
export class ChannelTableComponent extends TableBaseComponent<Channel> {

  @Input() detailLink = '/channels';
  @Output() onBuild = new EventEmitter<Channel>();

  protected channelSyncInfo = {};

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected config: ConfigurationService,
    protected syncService: SyncService,
    protected userService: UserService
  ) {
    super(router, route, config, userService);
  }

  getGlobalFilterFields() {
    this.globalFilters = this.globalFilters || [{
      label: 'ID',
      field: 'id',
      active: this.showId
    }, {
      label: 'Name',
      field: 'name.value',
      active: true
    }];

    return this.globalFilters
      .filter(filter => filter.active)
      .map(filter => filter.field);
  }

  build(model: Channel): void {
    this.onBuild.emit(model);
  }

  ngOnInit() {
    super.ngOnInit();

    console.log(JSON.stringify(this.data));

    this.data.forEach(channel => {
      this.getMetaInfoForChannel(channel);
    })
  }

  getMetaInfoForChannel(channel: Channel) {
    const channelName = channel.name.value;
    return this.syncService.getMeta(channelName).subscribe(
      meta => {
        this.channelSyncInfo[channelName] = meta;
      })
  }

  isBuilding(channelName: string) : boolean {
    return this.channelSyncInfo[channelName] && this.channelSyncInfo[channelName].building;
  }

  isLastBuildFailed(channelName: string) : boolean {
    return this.channelSyncInfo[channelName] && this.channelSyncInfo[channelName].lastBuildFailed;
  }

  isDirty(channelName: string) : boolean {
    return this.channelSyncInfo[channelName] && this.channelSyncInfo[channelName].dirty;
  }
}
