import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Status } from '../../../models/status.model';
import { UserContent } from '../../../models/user-content.model';

@Component({
  selector: 'app-user-content-list-modal',
  templateUrl: './user-content-list-modal.component.html'
})
export class UserContentListModalComponent {

  @Input() userContents: UserContent[];
  @Input() status: Status;

  selection: UserContent[] = [];

  constructor(
    public activeModal: NgbActiveModal
  ) { }
}
