import { Component, Input } from '@angular/core';

import Utils from '../../../../app/shared/utils/utils';


@Component({
  selector: 'app-station-type',
  template: `
    <span class="type" *ngIf="show">
      <i class="icon-station"></i>
      {{(stationType | stationType) | translate}}</span>
  `
})
export class StationTypeComponent {
  @Input() stationType: number | string;

  constructor() { }

  get show(): boolean {
    return Utils.isDefined(this.stationType);
  }
}
