import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { GameSolution } from '../../../models/game-solution.model';

import { TableBaseComponent } from '../table-base/table-base.component';

import { UserService } from '../../../../core/services/user.service';
import { ConfigurationService } from '../../../services/configuration.service';

@Component({
  selector: 'app-game-solution-table',
  templateUrl: './game-solution-table.component.html'
})
export class GameSolutionTableComponent extends TableBaseComponent<GameSolution> {

  @Input() detailLink = '/levels';

  tableConfig: any = this.getTableConfig('level');
  @Output() readonly selected: EventEmitter<GameSolution> = new EventEmitter<GameSolution>();

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected config: ConfigurationService,
    protected userService: UserService
  ) {
    super(router, route, config, userService);
  }

  getGlobalFilterFields() {
    this.globalFilters = this.globalFilters || [{
      label: 'ID',
      field: 'id',
      active: this.showId
    }, {
      label: 'Name',
      field: 'name.value',
      active: true
    }];

    return this.globalFilters
      .filter(filter => filter.active)
      .map(filter => filter.field);
  }
}
