import { Component, OnInit } from '@angular/core';

import { AuthService } from '../core/services/auth.service';
import { PermissionEnum } from "../shared/enums/permission.enum";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  Permissions: typeof PermissionEnum = PermissionEnum;

  permissionList: string[];
  tries = 0;

  constructor(
    public authService: AuthService
  ) {
  }

  ngOnInit() {
    this.authService.getCurrentUser().then(user => {
      if (user) {
        this.permissionList = user.permissions ? user.permissions : [];
      } else if (this.tries < 10) {
        setTimeout(() => {
          this.ngOnInit();
        }, 100);
        this.tries++;
      }
    });
  }

  hasPermission(permission: PermissionEnum): boolean {
    return !!this.permissionList && !!this.permissionList.find(p => p === permission)
  }
}
