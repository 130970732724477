import { GameCategory } from '../../game/enum/game-category.enum';
import Utils from '../utils/utils';
import { BaseModel } from './base.model';
import { File } from './file.model';
import { FormControlBoolean } from './form-control/form-control-boolean.model';
import { FormControlSelect } from './form-control/form-control-select.model';
import { FormControlString } from './form-control/form-control-string.model';
import { GameAssetDictionaryEntry } from './game-asset-dictionary-entry';
import { GameLevel } from './game-level.model';
import { GameMultiLangDictionaryEntry } from './game-multi-lang-dictionary-entry';
import { GameParameter } from './game-parameter';
import { GameResult } from './game-result.model';
import { MultiLangBoolean } from './multi-lang-boolean.model';
import { MultiLangString } from './multi-lang-string.model';

export class Game extends BaseModel {

  constructor(
    public id: string,
    public active: MultiLangBoolean,
    public title: MultiLangString,
    public description: MultiLangString,
    public type: FormControlSelect,
    public category: GameCategory,
    public reportScore: FormControlBoolean,
    public reportUrl: FormControlString,
    public levels: string[],
    public results: GameResult[],
    public parameters: GameParameter[],
    public textDictionary: GameMultiLangDictionaryEntry[],
    public assetDictionary: GameAssetDictionaryEntry[],
    public styleFile: File,
    public gameFile: File,
    public htmlFile: File,
    public blockedUsers: string[],
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string,
    public version?: number
  ) {
    super(id, blockedUsers, createdOn, creatorName, lastModified, modifiedBy, version);
  }

  toJSON(): object {
    const json = super.toJSON();

    json[ 'id' ] = this.id;
    json[ 'active' ] = this.active.toJSON();
    json[ 'title' ] = this.title.toJSON();
    json[ 'type' ] = this.type.value;
    json[ 'category' ] = this.category;
    json[ 'description' ] = this.description.toJSON();
    json[ 'reportScore' ] = this.reportScore.value;
    json[ 'reportUrl' ] = this.reportUrl.value;
    json[ 'levels' ] = this.levels;
    json[ 'results' ] = this.results ? this.results.map(r => r.toJSON()) : [];
    json[ 'parameters' ] = this.parameters ? this.parameters.map(p => p.toJSON()) : [];
    json[ 'textDictionary' ] = this.textDictionary ? this.textDictionary.map(d => d.toJSON()) : [];
    json[ 'assetDictionary' ] = this.assetDictionary ? this.assetDictionary.map(d => d.toJSON()) : [];
    json[ 'styleFile' ] = this.styleFile && this.styleFile.toJSON();
    json[ 'gameFile' ] = this.gameFile && this.gameFile.toJSON();
    json[ 'htmlFile' ] = this.htmlFile && this.htmlFile.toJSON();

    return json;
  }

  addLevel(id: string): void {
    this.levels = [ ...this.levels.filter(level => level !== id), id ];
    this.setChanged(true);
  }

  deleteLevel(level: GameLevel): void {
    this.levels = this.levels.filter(levelId => levelId !== level.id);
    this.setChanged(true);
  }

  addResult(newResult: GameResult): void {
    this.results = [ ...this.results, newResult ];
    this.setChanged(true);
  }

  deleteResult(result: GameResult): void {
    Utils.removeObjectFromArray(this.results, result);
    this.results = [ ...this.results ];
    this.setChanged(true);
  }
}
