import { Component } from '@angular/core';
import { TableBaseComponent } from "../table-base/table-base.component";
import { Role } from "../../../models/role.model";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfigurationService } from "../../../services/configuration.service";
import { TourTypeUtils } from "../../../utils/tour-type-utils";
import { UserService } from "../../../../core/services/user.service";

@Component({
  selector: 'app-role-table',
  templateUrl: './role-table.component.html'
})
export class RoleTableComponent extends TableBaseComponent<Role> {

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected config: ConfigurationService,
    protected userService: UserService
  ) {
    super(router, route, config, userService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getGlobalFilterFields() {
    this.globalFilters = this.globalFilters || [{
      label: 'Name',
      field: 'name.value',
      active: true
    }];

    return this.globalFilters
      .filter(filter => filter.active)
      .map(filter => filter.field);
  }

}
