import { Component, Input } from '@angular/core';

import Utils from '../../../../app/shared/utils/utils';


@Component({
  selector: 'app-content-type',
  template: `
    <span class="type" *ngIf="show">
      <i class="icon-content"></i>
      {{(contentType | contentType) | translate}}</span>
  `
})
export class ContentTypeComponent {
  @Input() contentType: number | string;

  constructor() { }

  get show(): boolean {
    return Utils.isDefined(this.contentType);
  }
}
