import { MultiLangString } from './multi-lang-string.model';
import { MultiLangBoolean } from './multi-lang-boolean.model';

import { BaseModel, VersionModel } from './base.model';
import { Station } from './station.model';
import { Content } from './content.model';
import { Channel } from './channel.model';

import Utils from '../utils/utils';


export class Group extends BaseModel {

  constructor(
    public id: string,
    public active: MultiLangBoolean,
    public type: number,
    public title: MultiLangString,
    public groups: Group[],
    public groupRefs: string[],
    public stations: Station[],
    public stationRefs: string[],
    public contents: Content[],
    public contentRefs: string[],
    public channels: Channel[],
    public channelRefs: string[],
    public blockedUsers: string[],
    public createdOn?: Date,
    public creatorName?: string,
    public lastModified?: Date,
    public modifiedBy?: string,
    public version?: number,
    public versions?: VersionModel[],
    public baseId?: string,
  ) {
    super(id, blockedUsers, createdOn, creatorName, lastModified, modifiedBy, version, versions, baseId);
  }

  toJSON(): object {
    const json = super.toJSON();

    json['active'] = this.active.toJSON();
    json['type'] = this.type;
    json['title'] = this.title.toJSON();

    // Fill ref objects based on current order
    json['group_refs'] = Utils.getIds(this.groups);
    json['station_refs'] = Utils.getIds(this.stations);
    json['content_refs'] = Utils.getIds(this.contents);
    json['channel_refs'] = Utils.getIds(this.channels);

    return json;
  }

  addGroup(newGroup: Group): void {
    this.groups = [...this.groups.filter(group => group.id !== newGroup.id), newGroup];
    this.setChanged(true);
  }

  // deleteGroup(group: Group): void {
  //   Utils.removeObjectFromArray(this.groups, group);
  //   this.groups = [...this.groups];
  //   this.setChanged(true);
  // }

  addStation(newStation: Station): void {
    this.stations = [...this.stations.filter(station => station.id !== newStation.id), newStation];
    this.setChanged(true);
  }

  deleteStation(station: Station): void {
    Utils.removeObjectFromArray(this.stations, station);
    this.stations = [...this.stations];
    this.setChanged(true);
  }

  // addContent(newContent: Content): void {
  //   this.contents = [...this.contents.filter(content => content.id !== newContent.id), newContent];
  //   this.setChanged(true);
  // }
  //
  // deleteContent(content: Content): void {
  //   Utils.removeObjectFromArray(this.contents, content);
  //   this.contents = [...this.contents];
  //   this.setChanged(true);
  // }

  addChannel(newChannel: Channel): void {
    this.channels = [...this.channels.filter(channel => channel.id !== newChannel.id), newChannel];
    this.setChanged(true);
  }

  deleteChannel(channel: Channel): void {
    Utils.removeObjectFromArray(this.channels, channel);
    this.channels = [...this.channels];
    this.setChanged(true);
  }

  // addLocale(locale: string): void {
  //   if (Utils.isValidLocale(locale)) {
  //     this.active.addLocale(locale);
  //     this.title.addLocale(locale);
  //   }
  // }
  //
  // deleteLocale(locale: string): void {
  //   if (Utils.isValidLocale(locale)) {
  //     this.active.deleteLocale(locale);
  //     this.title.deleteLocale(locale);
  //   }
  // }
}
