import { Injectable } from '@angular/core';
import { GameControlFlowCategory } from '../../../game/enum/game-control-flow-category.enum';
import { GameResultCategory } from '../../../game/enum/game-result-category.enum';
import { GameControlFlowTypeUtils } from '../../utils/game-control-flow-type-utils';
import Utils from '../../utils/utils';
import { FormControlSelect } from '../form-control/form-control-select.model';
import { GameControlFlow } from '../game-control-flow';
import { GameUtilHelper } from './game-util-helper';

@Injectable({ providedIn: 'root' })
export class GameControlFlowModelHelper {

  constructor(
    private readonly controlFlowTypeUtils: GameControlFlowTypeUtils,
    private readonly gameUtilHelper: GameUtilHelper,
  ) {
  }

  checkJSON(json: any): boolean {
    return json && Utils.isValidId(json.id);
  }

  emptyModel(controlFlowTypes: number[]): GameControlFlow {
    const filteredTypes = this.controlFlowTypeUtils.getTypeSelectOptions().filter(controlFlowType => {
      return controlFlowTypes.indexOf(controlFlowType.value) !== -1;
    });

    const type = controlFlowTypes[0];
    const config = this.controlFlowTypeUtils.getTypeConfig(type);

    return new GameControlFlow(
      '',
      new FormControlSelect({
        value: type,
        key: 'type',
        label: 'label.general.type',
        options: filteredTypes,
        required: true,
        hidden: false
      }),
      GameControlFlowCategory[ this.controlFlowTypeUtils.getCategoryOfType(type).toUpperCase() ],
      new FormControlSelect({
        key: 'trigger',
        label: 'label.game.field.trigger',
        options: [],
        required: true,
        hidden: false
      }),
      new FormControlSelect({
        key: 'action',
        label: 'label.game.field.action',
        options: [],
        required: true,
        hidden: false
      }),
      this.gameUtilHelper.createDictionary(config, 'parameters'),
      this.gameUtilHelper.createDictionary(config, 'textDictionary'),
      this.gameUtilHelper.createDictionary(config, 'assetDictionary')
    );
  }

  fromJSON(json: any, skipCheck = false): GameControlFlow {
    // integrity check
    if (!skipCheck && !this.checkJSON(json)) {
      return undefined;
    }

    const controlFlowType = Number(json.type);
    const config = this.controlFlowTypeUtils.getTypeConfig(controlFlowType);

    // create element object
    return new GameControlFlow(
      json.id,
      new FormControlSelect({
        value: controlFlowType,
        key: 'type',
        label: 'label.general.type',
        options: this.controlFlowTypeUtils.getTypeSelectOptions(),
        required: true,
        hidden: false
      }),
      json.category,
      new FormControlSelect({
        value: json.trigger,
        key: 'trigger',
        label: 'label.general.trigger',
        options: [],
        required: true,
        hidden: false
      }),
      new FormControlSelect({
        value: json.action,
        key: 'action',
        label: 'label.game.control-flow.field.action',
        options: [],
        required: true,
        hidden: false
      }),
      this.gameUtilHelper.mapParameters(json.parameters, config),
      this.gameUtilHelper.mapMultiLangDictEntries(json.textDictionary, config),
      this.gameUtilHelper.mapAssetLangDictEntries(json.assetDictionary, config),
    );
  }
}
