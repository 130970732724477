import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { FormControlBase } from '../../../app/shared/models/form-control/form-control-base.model';


@Injectable()
export class FormControlService {

  constructor() { }

  toFormControl(formControlBase: FormControlBase<any>) {

    const validators = [];

    if (formControlBase.required) {
      validators.push(Validators.required);
    }

    if (formControlBase.min) {
      validators.push(Validators.min(formControlBase.min));
    }

    if (formControlBase.max) {
      validators.push(Validators.max(formControlBase.max));
    }

    if (formControlBase.minLength) {
      validators.push(Validators.minLength(formControlBase.minLength));
    }

    if (formControlBase.maxLength) {
      validators.push(Validators.maxLength(formControlBase.maxLength));
    }

    if (formControlBase.pattern) {
      validators.push(Validators.pattern(formControlBase.pattern));
    }

    return new FormControl({value: formControlBase.value || '', disabled: formControlBase.disabled}, validators);
  }
}
