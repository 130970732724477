import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfigurationService } from "../../../services/configuration.service";
import { LocalesConfigOptions } from "../../../../../config/locales-config.interface";


@Component({
  selector: 'app-btn-locales',
  templateUrl: './btn-locales.component.html'
})
export class BtnLocalesComponent {

  @Input() locales: string[];
  @Output() onAdd = new EventEmitter();
  @Output() onDelete = new EventEmitter();

  localsConfig: LocalesConfigOptions;

  constructor(private config: ConfigurationService) {
    this.localsConfig = this.config.getLocalesConfig();
  }

  isActive(locale: string): boolean {
    return this.locales.indexOf(locale) >= 0;
  }

  addAll() {
    for (const locale of this.localsConfig.available) {
      if (!this.isActive(locale)) {
        this.onAdd.emit(locale);
      }
    }
  }

  onButtonClick($event, locale: string) {
    if (this.isActive(locale)) {
      this.onDelete.emit(locale);
    } else {
      this.onAdd.emit(locale);
    }
  }
}
