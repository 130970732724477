import { Component, Input } from '@angular/core';

import { BtnBaseComponent } from '../btn-base/btn-base.component';


@Component({
  selector: 'app-btn-play',
  template: `
  <button type="button" class="btn btn-outline-primary"
    (click)="onButtonClick($event); $event.stopPropagation();">
    <i class="fa" [ngClass]="isPlaying ? 'fa-pause' : 'fa-play'"></i>
  </button>
  `
})
export class BtnPlayComponent extends BtnBaseComponent {

  @Input() isPlaying: boolean;

  constructor() {
    super();
  }
}
