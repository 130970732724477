import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, publishReplay, refCount, share, tap } from 'rxjs/operators';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable } from 'rxjs';
import { AppConfig } from '../../app.config';

import { UserService } from '../../core/services/user.service';
import { BaseFilesModel } from '../models/base-files.model';
import { Game } from '../models/game.model';

import { GameLevel } from '../models/game-level.model';
import { Status } from '../models/status.model';

import { MessageModalComponent } from '../../core/components/message-modal/message-modal.component';
import { GameLevelCreateModalComponent } from '../components/modals/game-level-create-modal/game-level-create-modal.component';
import { GameLevelListModalComponent } from '../components/modals/game-level-list-modal/game-level-list-modal.component';

import { ApiBaseService } from '../../core/services/api-base.service';
import Utils from '../utils/utils';
import { FileService } from './file.service';

import { CreateOptions } from '../interfaces/create-options.interface';
import { ModelMapperService } from './mapper/model-mapper.service';

@Injectable()
export class GameLevelService extends ApiBaseService<GameLevel> {

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected userService: UserService,
    protected fileService: FileService,
    protected modalService: NgbModal,
    private modelMapper: ModelMapperService
  ) {
    super(GameLevel, 'levels', http, router, userService, fileService);

    this.url = AppConfig.GAME_SERVER + AppConfig.GAME_BASE_URL + '/level';
  }

  select(exclude: string[] = [], levelTypes?: number[]): Promise<GameLevel[]> {
    const modalRef = this.modalService.open(GameLevelListModalComponent, { size: 'lg' });
    modalRef.componentInstance.status = new Status();

    this.getAll(modalRef.componentInstance.status)
      .subscribe((levels: GameLevel[]) => {
        try {
          modalRef.componentInstance.levels = levels
            .filter(level => exclude.findIndex(myLevelId => level.id === myLevelId) < 0);

          if (levelTypes && levelTypes.length > 0) {
            modalRef.componentInstance.levelTypes = levelTypes;
          }
        } catch (e) {
        }
      });

    return modalRef.result;
  }

  getAll(status?: Status, params?: Array<{ param: string, value: string }>, path?: string): Observable<GameLevel[]> {
    status = status || new Status();
    status.setLoading();

    const url = path ? AppConfig.GAME_SERVER + AppConfig.GAME_BASE_URL + path : this.url;

    let queryParams = new HttpParams();

    if (params) {
      params.forEach(param => queryParams = queryParams.append(param.param, param.value));
    }

    return this.http
      .get(url, { params: queryParams }).pipe(
        share(),
        publishReplay(1),
        refCount(),
        map(response => {
          if (response && Array.isArray(response) && response.length > 0) {
            status.setSuccess();
            return response
              .filter(object => this.checkJSON(object))
              .map(object => this.fromJSON(object));
          } else {
            status.setNoResults();
            return [];
          }
        }),
        catchError(err => this.handleError(err, status)));
  }

  create(options: CreateOptions<GameLevel>, nextIndex: number = 0, levelTypes?: number[], gameTypes?: number[]): Promise<GameLevel> {
    return super.create(Object.assign(options, {
      component: GameLevelCreateModalComponent,
      componentOptions: {
        level: this.emptyModel(),
        nextIndex,
        levelTypes,
        gameTypes
      }
    }));
  }

  update(model: any, status?: Status): Observable<any> {
    status = status || new Status();
    status.setLoading();

    const url = `${this.url}/${model.id}`;

    if (this.userService) {
      this.userService.addCurrentUser(model);
    }

    return this.http[ Utils.isValidId(model.id) ? 'put' : 'post' ](
      url,
      model.toJSON()
    ).pipe(
      map(response => {

        if (model.id || this.checkJSON(response)) {
          status.setSuccess();
          return model.id ? model : this.fromJSON(response);
        } else {
          status.setError();
        }
      }),
      tap(() => {
        if (this.fileService && model instanceof BaseFilesModel
          && model.filesToDelete && model.filesToDelete.length > 0) {
          for (const file of model.filesToDelete) {
            this.fileService.delete(file.href, true)
              .then(() => {
              }, () => {
              });
          }
        }
      }),
      catchError(err => this.handleError(err, status)));
  }

  delete(id: string, status?: Status): Promise<any> {
    const modalRef = this.modalService.open(MessageModalComponent);
    modalRef.componentInstance.confirm = true;
    modalRef.componentInstance.message = 'Are you sure that you want to delete the level?';

    return modalRef.result.then(() => super.delete(id, status), () => Promise.reject('Dimissed'));
  }

  checkJSON(json: any): boolean {
    return this.modelMapper.levelCheckJSON(json);
  }

  emptyModel(): GameLevel {
    return this.modelMapper.levelEmptyModel();
  }

  fromJSON(json: any, skipCheck?: boolean): GameLevel {
    return this.modelMapper.levelFromJSON(json, skipCheck);
  }
}
